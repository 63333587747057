import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';



class AccountSidebar extends Component {
    
    componentDidMount(){
    }
    componentWillUnmount(){
    }
    render() {
        let currentUser = JSON.parse(localStorage.getItem('user'))
        let userPermission = JSON.parse(localStorage.getItem('permissions'))
        let groupId = localStorage.getItem("groupId")
        let CREDITS;
        let ADDRESSES;
        if(userPermission) {
            CREDITS = userPermission.find(
            opt => opt.key === 'CREDITS')
            ADDRESSES = userPermission.find(
            opt => opt.key === 'ADDRESSES')
        }
        return (
            <div className="sticky-box-container">
                <div className="sticky-box">
                     <ul>
                         <li>
                            <NavLink to='/account/information' activeClassName="active">Account Information</NavLink>
                         </li>
                         {groupId === "3" && <li>
                             <NavLink to='/account/customers' activeClassName="active">Customers</NavLink>
                         </li>}
                         <li>
                            <NavLink to='/account/members' activeClassName="active">Members</NavLink>
                         </li>
                         {currentUser.isMember === false || (currentUser.isMember === true && CREDITS !== undefined) ? (
                            <li>
                                <NavLink to='/account/credits' activeClassName="active">Credits</NavLink>
                            </li>
                         ):('')}
                         {currentUser.isMember === false || (currentUser.isMember === true && ADDRESSES !== undefined) ? (
                            <li>
                                <NavLink to='/account/address' activeClassName="active">Address Book</NavLink>
                            </li>
                         ):('')}
                        <li>
                            <NavLink to='/account/press-template' activeClassName="active">My Press Settings</NavLink>
                        </li>
                     </ul>
                </div>
            </div>
        );
    }
}

export default AccountSidebar;
