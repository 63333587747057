import React, { Component } from "react";
import { Link } from "react-router-dom";

import OrderSearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";

import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
// let qs = require("query-string");
let moment = require("moment");

class OrderListView extends Component {
  constructor(props) {
    super(props);
    let orderStatus = props.status;
    this.state = {
      orderStatus,
      orderList: {},
      pp: "25",
      searchValue: "",
      showFilterBtn:false,
      isSorted:"",
      searchByAttributes:{"id":"","name":"","projectDescription":"","projectName":""},
    };
    this.getOrderList = this.getOrderList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.resize = this.resize.bind(this);
  }


  _isMounted = false;
  showSidebar() {
    let box = document.getElementById('stats-box');

      box.classList.add('opened')
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let orderStatus = nextProps.status;
    this.setState({
      orderStatus
    });

    this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    window.scroll(0,0)
    let searchValue = this.state.searchValue,
      status = this.state.orderStatus,
      start = "0",
      end = this.state.pp,
      reset = true,
     searchByAttributes=this.state.searchByAttributes

    this.getOrderList(searchValue, status, start, end, 1, reset,"","","",searchByAttributes);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize()
  }
  resize() {
    if(window.innerWidth <= 1310) {
      this.setState({showFilterBtn: true});
    }else {
      this.setState({showFilterBtn: false});
    }
  }
  getOrderList = async (
    searchValue = "",
    status,
    start = "0",
    end,
    pageNumber,
    reset = true,
    showLoader = false,

    // added by me

    orderSort,
    orderSortType,
    searchByAttributes

  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ orderList: [] });
    } 
    let loader = document.getElementById('loader')

    let requestUrl = `/orders/getList`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.advancedSearch = searchValue;
    }
    if (status !== undefined) {
      queryParams.statusId = status;
    }
    if (status !== undefined) {
      queryParams._start = start;
    }
    if (status !== undefined) {
      queryParams._end = end;
    }

    // added by me

    if (status !== undefined) {
      queryParams._orderSort = orderSort;
    }
    if (status !== undefined) {
      queryParams._orderSortType = orderSortType;
    }
      queryParams.searchByAttributes = searchByAttributes;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      // console.log(data);
      let orderList = data.payload.orders.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.orders.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          orderList
        },
        () => {
          currentComponent.props.orderListReady(true);
          setTimeout(function(){
            if(loader.classList.contains('show')) {
              loader.classList.remove('show')
            }
          },100)
          if(showLoader) {
            setTimeout(function(){
              window.scrollTo({
                top: 100,
                left: 0,
                behavior: 'smooth'
              })
            },100)
          }
        }
      );
    }
  };

  componentWillUnmount() {}

  handlePageChange(pageNumber) {
    let loader = document.getElementById('loader')
    let { searchValue, pp, orderStatus,searchByAttributes } = this.state;
    let status = orderStatus,
      start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getOrderList(searchValue, status, start, end, pageNumber, false, true,"","",searchByAttributes);
    if(!loader.classList.contains('show')) {
      loader.classList.add('show')
    }
  }

  handleSearchValue = childData => {
    let { pp, orderStatus} = this.state;
    let loader = document.getElementById('loader')
    let data = childData.target.value
    let searchByAttributes = {"id":"","name":"","projectDescription":"","projectName":""}
    this.setState({
        searchValue: data
    });

    let dataName=childData.target.getAttribute("data-name")
    switch(dataName){
      case "id":
        searchByAttributes["id"]=data;
        this.setState((prev)=>({

          searchByAttributes: {...prev.searchByAttributes,"id":data},
        }));
        break;
      case "name":
        searchByAttributes["projectName"]=data;
        this.setState((prev)=>({

          searchByAttributes: {...prev.searchByAttributes,"projectName":data},
        }));
        break;
      case "projectDescription":
        searchByAttributes["projectDescription"]=data;
        this.setState((prev) =>({
          searchByAttributes: {...prev.searchByAttributes,"projectDescription":data},
        }));
        break;
      case "projectName":
        searchByAttributes["name"]=data;
        this.setState((prev)=>({
          searchByAttributes: {...prev.searchByAttributes,"name":data},
        }));
        break;
      default:

    }

    this.getOrderList( "",orderStatus, 0, pp, 1, false,"","","",searchByAttributes)
    if(!loader.classList.contains('show')) {
      loader.classList.add('show')
    }
  };
  handleReorder(e) {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("data-id");
    window.location = `/order/${id}/reorder`;
  }

  handleSortClick = (e) => {
    e.stopPropagation()
    let sortType

    if(this.state.isSorted){
      sortType = "asc"
      this.setState({isSorted:false})
    }
    else {
      sortType = "desc"
      this.setState({isSorted:true})
    }
    let orderSortName = e.currentTarget.id
    this.getOrderList("", "", 0, 25, 1,"","",orderSortName,sortType);
  }
  sortingIconHandler = () => {
    return(
        <div className="sorting-icon" >
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>arrow-triangle-up-down-glyph</title>
            <path d="M93.44,225.39H418.56A10.8,10.8,0,0,0,427,207.86L264.44,4.06a10.79,10.79,0,0,0-16.87,0L85,207.86a10.8,10.8,0,0,0,8.44,17.53Z" fill={this.state.isSorted ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.3)"}/>
            <path d="M418.56,286.61H93.44A10.8,10.8,0,0,0,85,304.14L247.56,507.94a10.79,10.79,0,0,0,16.87,0L427,304.14a10.8,10.8,0,0,0-8.43-17.53Z" fill={!this.state.isSorted ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.3)" }/>
          </svg>
        </div>
    )
  }
  render() {
    let groupId =localStorage.getItem('groupId')
    if (this._isMounted !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {
      pp,
      total,
      activePage,
      orderList,
      searchValue,
      orderStatus,
      showFilterBtn,

    } = this.state;
    let statusText,satusList = this.props.orderStatuses;
    if(satusList) {
      statusText = satusList.find(x => x.id === parseInt(orderStatus));
    }
    let text = "";
    if (statusText !== undefined && statusText.name) {
      text = statusText.name;
    } else {
      text = "";
    }

    // let userPermission = JSON.parse(localStorage.getItem('permissions'));
    // let REORDER;
    // if(userPermission) {
    //   REORDER = userPermission.find(
    //     opt => opt.key === 'REORDER'
    //   )
    // }
    return (
      <React.Fragment>
        <div className="container-header">
          {showFilterBtn ? (
            <div onClick={this.showSidebar} className="filter-btn">
              <span></span>
              <span></span>
              <span></span>
            </div>
          ):('')}
          




        </div>
        <div className="order-list-box table-grid">
        <div id="loader" className="loader circle ">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
                
          {orderList.length > 0 ? (
            <React.Fragment>
              <div className="table-row table-head">
                <div className="table-cell">
                  <div className="sorting-wrapper" id="id" onClick={this.handleSortClick}>
                    <div className="text">ID</div>
                    {this.sortingIconHandler()}
                  </div>
                  <OrderSearchView searchValue={this.handleSearchValue} placeholder="Search by id" dataName="id"/>

                </div>

                <div className="table-cell" >
                  <div className="sorting-wrapper" id="projectName" onClick={this.handleSortClick}>
                    <div className="text">PROJECT NAME</div>
                    {this.sortingIconHandler()}
                  </div>
                  <OrderSearchView searchValue={this.handleSearchValue} placeholder="Search by project name" dataName="name"/>
                </div>
                <div className="table-cell project-description-cell" >
                  <div className="sorting-wrapper" id="projectDescription" onClick={this.handleSortClick}>
                    <div className="text">DESCRIPTION</div>
                    {this.sortingIconHandler()}
                  </div>
                  <OrderSearchView searchValue={this.handleSearchValue} placeholder="Search by project description" dataName="projectDescription"/>
                </div>
                <div className="table-cell" >
                  <div className="sorting-wrapper" id="name" onClick={this.handleSortClick}>
                    <div className="text">PRODUCT NAME</div>
                    {this.sortingIconHandler()}
                  </div>
                  <OrderSearchView searchValue={this.handleSearchValue} placeholder="Search by product name" dataName="projectName"/>
                </div>
                {groupId === "3" && <div className="table-cell">
                  <div className="sorting-wrapper" id="customer" onClick={this.handleSortClick}>
                    <div className="text">CUSTOMER</div>
                    {this.sortingIconHandler()}
                  </div>
                  <OrderSearchView searchValue={this.handleSearchValue} placeholder="Search by customer"
                                   dataName="customer"/>
                </div>}
                <div className="table-cell date-created-cell" >
                  <div className="sorting-wrapper" id="createdAt" onClick={this.handleSortClick}>
                    <div className="text">DATE CREATED</div>
                    {this.sortingIconHandler()}
                  </div>

                </div>
                <div className="table-cell date-modified-cell" >
                  <div className="sorting-wrapper" id="updatedAt" onClick={this.handleSortClick}>
                    <div className="text">DATE MODIFIED</div>
                    {this.sortingIconHandler()}
                  </div>

                </div>
                <div className="table-cell status-cell">
                  <div className="text">STATUS</div>

                </div>

                {/* <div className="table-cell"></div> */}
              </div>

              {orderList.map((item, key) => {
                return (
                  <Link
                    to={item.statusId==3?`/order/${item.orderId}/approve`:`/order/${item.orderId}/projectDetails`}
                    key={key}
                    className="table-row"
                  >
                    <div className="table-cell id-cell">
                      <div className="text" title={item.id}>
                        <div>ID:</div>
                        <div>{item.id}</div>
                      </div>
                    </div>

                    <div className="table-cell project-name-cell">
                      <div className="text" title={item.projectName}>
                        <div>Project Name:</div>
                        <div>{item.projectName}</div>
                      </div>
                    </div>
                    <div className="table-cell project-description-cell">
                      <div className="text" title={item.projectDescription}>
                        <div>Description:</div>
                        <div>{item.projectDescription}</div>
                      </div>
                    </div>
                    <div className="table-cell product-cell">
                      <div className="text" title={item.name}>
                        <div>Product Name:</div>
                        <div>{item.name}</div>
                      </div>
                    </div>
                    {groupId === "3" &&  <div className="table-cell product-cell">
                      <div className="text" title={item.name}>
                        <div>Customer:</div>
                        <div>{item.customer  ? (item.customer.firstname + " " + item.customer.lastname) : "-"}</div>
                      </div>
                    </div>}
                    <div className="table-cell date-created-cell">
                      <div className="text">
                        <div>Date Created:</div>
                        <div>{moment(item.createdAt, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                      </div>
                    </div>
                    <div className="table-cell date-modified-cell">
                      <div className="text">
                        <div>Date Modified:</div>
                        <div>{moment(item.updatedAt, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                      </div>
                    </div>
                    <div className="table-cell status-cell">
                      <div className="text">
                        <div>Status:</div>
                        <div>
                          <span className="item-text" style={{ background: `${item.statusColor}` }}>{item.statusName}</span>
                        </div>
                      </div>
                    </div>

                    {/* <div className="table-cell btn-cell">
                      {item.statusId === 5 ? 
                        currentUser.isMember === false || (currentUser.isMember === true && REORDER !== undefined) ?
                        (
                          <div
                            className="action-btn btn green"
                            onClick={this.handleReorder}
                            data-id={item.orderId}
                          >
                            <span>Reorder</span>
                          </div>
                        ):('')
                       : (
                        ""
                      )}
                    </div> */}
                  </Link>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-result-box">
              <div className="text">
                {searchValue
                  ? `No result for: ${searchValue}`
                  : `No ${text} orders`}
              </div>
            </div>
          )}
        </div>
        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={Number(pp)}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default OrderListView;
