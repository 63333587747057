import React, { Component } from 'react';
import {
  Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import MetaTags from "../metaTags"

// import 'react-accessible-accordion/dist/fancy-example.css';
// Demo styles, see 'Styles' section below for some notes on use.
class FaqPage extends Component {

  componentDidMount(){
    document.body.classList.add('faq');
    window.scroll(0,0)
  }
  componentWillUnmount(){
    document.body.classList.remove('faq');
  }
  
  render(){
    const faqList = [
      {
        title:"How do I register with Flexoeasy.com?",
        content:"<p>To use our services, click <a href='/login' target='_blank'>Sign In</a> or <a href='/register' target='_blank'>Register</a> and fill in the registration form. You will be asked to provide your name and surname, email address, and password which will be used to login to the account every time. You will then have to confirm your account information from your lately provided email address.</p>"
      },
      {
        title:"How do I order products?",
        content:"<p>Login to your account on www.flexoeasy.com to get an immediate quote. Choose the required options from the order form and proceed to check out. As soon as you complete an order, you will receive a confirmation email with the details of the articles selected. Our products can only be purchased online. During this process, you can ask for the help of the support team through the live chat on the website.</p>"
      },
      {
        title:"When and how do I upload my file?",
        content:"<p>You can upload your file while selecting the desired product on the same page.</p><p>Please note that the limit for each file is 500 MB.</p>"
      },
      {
        title:"Your website is displayed correctly, and I cannot place orders, what should I do?",
        content:"<p>To ensure that they can work properly, all websites store data in your computer. Each time we improve our systems we also update our data, which is then stored by your browser. To correctly view our website, we recommend you regularly update your browser to the latest available version.</p> <p>If this does not solve the issue, follow the simple instructions provided below to solve the problems encountered in viewing our website.</p> <p>If you have encountered problems viewing this website or with the upload form, please proceed as follows:</p> <p>- Cancel all search and browser history.<br> - Close your browser and start it again.</p> <p>For issues with the upload form, we recommend you follow the previous steps in addition to the following:</p> <p>- Check that the file you are trying to upload meets the specifications supplied by the website in the estimate stage.<br> - Once the file has been uploaded (the upload progress bar is filled), you will have to wait until it has been processed by the system (usually a few moments). If you are uploading several files at the same time, this process will take longer. To avoid errors during the upload, do not try to upload more than 2 or 3 files at the same time.</p> <p>If you have any further problems, please <a href='/contact-us'><strong>contact us</strong></a>.</p>"
      },
      {
        title:"I have forgotten my username and password. What should I do?",
        content:`<p>To retrieve your username and password, click on the SIGN IN button at the top of our homepage and in "Forget Password?" section enter the email address you are registered with and click on "Submit" button. Within a few minutes, you will receive an email with a password reset link. Check your spam emails too, in case the email with login details ended up in there.</p>`
      },
      {
        title:"Where and how can I see my order status?",
        content:`<p>Sign in into your account and click on the "My Dashboard” section.</p>`
      },
      {
        title:"Can I add a product to a completed order?",
        content:"<p>You cannot add products to a completed order; this is because our systems are highly automated to ensure quick and punctual production times.</p>"
      },
      {
        title:"Can I modify or cancel the order?",
        content:`<p>Yes, but only if the order has not been processed yet. You can check the status of your order by clicking on "My Dashboard".</p>`
      },
      {
        title:"Which software should I use to create my artwork?",
        content:`<p>To create your vector format files, we suggest you use Adobe Illustrator; to create raster formats (suitable for images) we recommend Adobe Photoshop. Adobe InDesign is an excellent program for multi-page documents and Adobe Acrobat is useful for a last check before sending. In general, any application which generates native PDF file will be acceptable.</p> <p>RGB images or images using PANTONE colors are automatically converted upon reception with a standard separation profile. The most suitable format for printing is high-resolution PDF that isn't password protected (this type of file can be created using page layout programs such as (Illustrator, InDesign, ArtPro).</p>`
      },
      {
        title:"How much bleed do I need to add to my file?",
        content:`<p>Bleeds provide a minimum safe margin when your products are trimmed. They ensure an impeccable end result.</p>
        <p>The bleed needed for labels is 2 mm and for corrugated is 5 mm.</p>`
      },
      {
        title:"Where can I find the invoice for my order?",
        content:`<p>In order to get your invoice, you need to contact us at support@flexoeasy.com. Anyway, while placing your order online, you will receive an email notification and you can follow your order from there.</p>`
      },
      {
        title:"Can I send my orders to an address that is not the invoicing address?",
        content:`<p>Yes. When placing an order, you will be asked if the shipping address is the same as the billing address. If it is different, you will be able to enter a new shipping address.</p>`
      },
      {
        title:"Are there any countries from which you do not accept orders?",
        content:`<p>Orders from Syria, North Korea, Iran, Somalia and Qatar would not be honored as there are international sanctions being imposed against those countries.</p>`
      },
      {
        title:"What are the accepted payment methods?",
        content:`<p>You can pay using a Visa or MasterCard, or PayPal.</p>`
      },
      {
        title:"Which currency can be used to make my payments?",
        content:`<p>All payments should be made using the currency of United Arab Emirates Dirham (AED) only.</p>`
      },
      {
        title:"Will I pay VAT if I make an international order?",
        content:`<p>Everything depends on the delivery location. If the ordered product(s) are delivered in the UAE, then VAT will be applicable even if the order is made from out of the UAE. If the product(s) are delivered abroad (out of the UAE), then VAT will not be applicable.</p>`
      },
      {
        title:"Which products can be returned?",
        content:`<p>If item(s) are sold with a no-refund policy, such policy will be mentioned prior to or simultaneously with making the order for the item(s).</p>`
      },
      {
        title:"Can I get a refund for my shipping costs?",
        content:`<p>If you provide us with a reasonable basis as to why you are not satisfied with your product, you may return an item within 48 hours from the date of its delivery to you. We will arrange for the return shipment at no extra charge to you. In any cases you can contact our Customer Services Team to arrange a courier pick-up for your item and a quick Return.</p>`
      },
      {
        title:"How can I track my shipment?",
        content:`<p>All of our deliveries are carried out by our logistics partners Aramex (for shipments inside UAE) and DHL for international orders, and you can track your shipments on their websites. You will receive an email confirming your order and details of the same.</p>`
      },
      {
        title:"Can I change my delivery address?",
        content:`<p>Sure! You can change your delivery address any time you want from your personal account: in "Address Book" section.</p>`
      },

      {
        title:"Where can I read your complete list of policies?",
        content:`<p>Check Our Policies <a href='/legal#privacy-policy' target='_blank'>here</a></p>`
      },
      {
        title:"Will the printed colors look like those on my computer?",
        content:`<p>There are many different types of monitors and they can all be calibrated differently; as a result, the print may not be an exact match of the image you see on your computer monitor. Before sending us your final file, we suggest you use a CMYK color atlas or similar book references. Although, upon request and purchase, Flexoeasy.com will provide you a calibrated print proof with 90% of color accuracy. This accuracy can still be improved if your printing press is calibrated through flexoeasy.com fingerprint and color profile.</p>`
      },
      {
        title:"Can you make the artwork for me?",
        content:`<p>Yes, we can. Please <a href='/contact-us' target='_blank'>contact us</a>.</p>`
      },
    ]
    return(
        <div className="faq-container">
          <MetaTags/>
          <div className="wrapper-medium">
            <h1 className="page-title">FAQ</h1>
            <Accordion allowZeroExpanded={true}  preExpanded={[0]}>
              {faqList.map((list , key) => {
                return (
                  <AccordionItem key={key} uuid={key}>
                      <AccordionItemHeading>
                          <AccordionItemButton>
                            {list.title}
                          </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                          <p dangerouslySetInnerHTML={{__html: list.content}}>
                          </p>
                      </AccordionItemPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </div>
          
        </div>
    )

  }

}



export default FaqPage;
