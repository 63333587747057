import React, { Component } from "react";
import AccountSidebar from "../sidebar";
import MetaTags from "../../metaTags"

import MembersListView from "./membersList";
class AccountMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.body.classList.add("account", "account-members");
  }
  componentWillUnmount() {
    document.body.classList.remove("account", "account-members");
  }
  membersListReady = childData => {
    let { membersListReady } = this.state;

    if (membersListReady === false) {
      this.setState({
        membersListReady: childData
      });
    }
  };
  render() {
    // const { showModal, isEdit, errors, errorClasses, member } = this.state;
    // console.log(this.state);
    return (
      <div className="account-container account-members-container">
        <MetaTags/>
        {/* <div className="wrapper">
          <h1 className="page-title">Members</h1>
        </div> */}
        <div className="wrapper">
          <AccountSidebar />

          <div className="members-container main-box">
            <h1 className="page-title">Members</h1>
            <div className="main-wrap">
              <MembersListView membersListReady={this.membersListReady} />
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default AccountMembers;
