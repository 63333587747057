import React, { Component } from "react";
import MembersListView from "./membersList";

class OrderMembers extends Component {
  constructor(props) {
    super(props);

    this.state = { sad: "" };
  }

  componentDidMount() {}
  componentWillUnmount() {}
  membersListReady = childData => {
    let { membersListReady } = this.state;

    if (membersListReady === false) {
      this.setState({
        membersListReady: childData
      });
    }
  };
  render() {
    return (
      <React.Fragment>
        <div className="members-container ">
          <MembersListView
            orderId={this.props.orderId}
            membersListReady={this.membersListReady}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default OrderMembers;
