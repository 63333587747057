import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { authHeader } from "../../_helpers";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
class OrederNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationCount:0
    };
    this.getNotificationCount = this.getNotificationCount.bind(this);
  }
  componentDidMount() {
    if(this.props.tabId !== 'notifications') {
      this.getNotificationCount()
    }
  }
  componentWillUnmount() {}

  getNotificationCount = async e => {
    //orderdetails/getCountOfNotifications/00002_0000001114
    let requestUrl = `/orderdetails/getCountOfNotifications/${this.props.orderId}`;
    let currentComponent = this;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let notificationCount = data.payload.count
      currentComponent.setState(
        {
          notificationCount
        },
        () => {}
      );
    }
  }
  render() {
    let {notificationCount} = this.state
    return (
      <div className="order-navigation">
        <div className="order-menu">
          <div className="menu-item">
            <NavLink
              to={`/order/${this.props.orderId}/projectDetails`}
              activeClassName="active"
              onClick={this.getNotificationCount}
            >
              Project Details
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink
              to={`/order/${this.props.orderId}/projectDetailsReport`}
              activeClassName="active"
            >
              Project Details Report
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink
                to={`/order/${this.props.orderId}/approve`}
                activeClassName="active"
                onClick={this.getNotificationCount}

            >
              Files for Approval
            </NavLink>
          </div>
          <div className="menu-item">
            <NavLink
              to={`/order/${this.props.orderId}/files`}
              activeClassName="active"
              onClick={this.getNotificationCount}

            >
              Received Files
            </NavLink>
          </div>

          <div className="menu-item"> 
            <NavLink
              to={`/order/${this.props.orderId}/downloadables`}
              activeClassName="active"
              onClick={this.getNotificationCount}

            >
              Downloadables
            </NavLink>
          </div>

          <div className="menu-item">
            <NavLink
              to={`/order/${this.props.orderId}/members`}
              activeClassName="active"
            >
              Members
            </NavLink>
          </div>
          
          <div className="menu-item">
            <NavLink
              to={`/order/${this.props.orderId}/notifications`}
              activeClassName="active"

            >
              {notificationCount > 0 ? (
                <div id="notification-count" className="notification-count">
                  <span>{ notificationCount >= 100 ? '99+' : notificationCount}</span>
                </div>
              ):('')}
              
              Notifications
            </NavLink>
          </div>
        </div>
      </div>
    );
  }
}

export default OrederNavigation;
