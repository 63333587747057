import React, { Component } from 'react';
import MetaTags from "../metaTags"



class NotFound extends Component {
  componentDidMount(){
    document.body.classList.add('not-found');
  }
  componentWillUnmount(){
    document.body.classList.remove('not-found');
  }
  render() {
    return (
      <div className="not-found-container">
            <MetaTags/>
            <div className="wrapper">
              <div className="table">
                <div className="cell">
                  <div className="title">
                    404
                  </div>
                  <div className="text">
                    The requested page was not found
                  </div>
                </div>
              </div>
            </div>
      </div>
    );
  }
}

export default NotFound;
