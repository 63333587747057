import React, {Component} from "react";
import Select from "react-select";
import {authHeader} from "../../_helpers";

import validator from "validator";
import FormValidator from "../validations";
import ReactModal from "react-modal";
import {Lightbox} from "react-modal-image";
import {Scrollbars} from "react-custom-scrollbars";
import Dropbox from "./dropboxPicker";
import GooglePicker from "./googlePicker";
import InputMask from "react-input-mask";
import SVG from "../svg.json"
// import ReactDropzone from "react-dropzone";
// import request from "superagent";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");

class ProductOptions extends Component {
    constructor(props) {
        super(props);

        const productIsExist = props.productIsExist;
        const productUrlName = props.productUrlName;
        const productId = props.productId;
        const productIsTesting = props.productIsTesting;
        const productAdditionalUploadFile = props.productAdditionalUploadFile;
        const producthasBothSide = props.producthasBothSide;
        const productHasCustomTemplate = props.productHasCustomTemplate;
        const productHasAttachFile = props.productHasAttachFile;
        const couponCode = props.couponCode;
        this.state = {
            productUrlName,
            productIsExist,
            productId,
            productIsTesting,
            productAdditionalUploadFile,
            producthasBothSide,
            productHasCustomTemplate,
            showSuccessModal: false,
            uploadType: '',
            errors: [],
            errorClasses: [],
            template: "",
            productOptions: [],
            productField: [],
            productCheckBoxList: [],
            productGlobalInfo: {
                projectName: "",
                projectDescription: ""
            },
            customProjectName: '',
            saveAsTemplate: false,
            saveForCustomTemplate: false,
            productTemplate: "",
            customProductTemplate: "",
            showUploadModal: false,
            dragging: false,
            globalUploadeCount: 0,
            selectedFile: [],
            urlToLargeImageFile: "",
            uploadedFiles: [],
            uploadedFilesBack: [],
            selectedFile2: [],
            uploadedFiles2: [],
            additionalUploadeCount: 0,
            showPermissionModal: false,
            bothSideUpload: false,
            checkBoxList: [],
            printingMethodUpload: false,
            productHasAttachFile,
            mar: false,
            couponCode:null,
            customerList: {},
            selectedCustomer: ""
        };

        this.renderThumb = this.renderThumb.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.getProductOption = this.getProductOption.bind(this);
        this.getProductChildren = this.getProductChildren.bind(this);
        this.drawOptions = this.drawOptions.bind(this);
        this.configOptions = this.configOptions.bind(this);
        this.drawNumberField = this.drawNumberField.bind(this);
        this.drawDownloadableFileSwatchField = this.drawDownloadableFileSwatchField.bind(this);
        this.setupProductForState = this.setupProductForState.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.handelChecked = this.handelChecked.bind(this);
        this.handelCustomTemplateChecked = this.handelCustomTemplateChecked.bind(this);
        this.handleSwatchChange = this.handleSwatchChange.bind(this);
        this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
        this.showUploadModal = this.showUploadModal.bind(this);
        this.hideUploadModal = this.hideUploadModal.bind(this);

        this.handleSelectFileClick = this.handleSelectFileClick.bind(this);
        this.handleDrag = this.handleDrag.bind(this);
        this.handleDragIn = this.handleDragIn.bind(this);
        this.handleDragOut = this.handleDragOut.bind(this);
        this.handleDrop = this.handleDrop.bind(this);

        this.fileUpload = this.fileUpload.bind(this);
        this.fileUpload2 = this.fileUpload2.bind(this);
        this.handleRemoveFile = this.handleRemoveFile.bind(this);
        this.handleRemoveFile2 = this.handleRemoveFile2.bind(this);

        this.handleSaveClick = this.handleSaveClick.bind(this);

        this.handleInputFileChange = this.handleInputFileChange.bind(this);

        this.getTemplateByProduct = this.getTemplateByProduct.bind(this);
        this.getCustomTemplateByProduct = this.getCustomTemplateByProduct.bind(this);

        this.globalInfoHandleChange = this.globalInfoHandleChange.bind(this);
        this.templateDropdownChange = this.templateDropdownChange.bind(this);
        this.customTemplateDropdownChange = this.customTemplateDropdownChange.bind(this);

        this.getTemplateData = this.getTemplateData.bind(this);
        this.getCustomTemplateData = this.getCustomTemplateData.bind(this);
        this.getProductUploadedFiles = this.getProductUploadedFiles.bind(this);
        this.getProductUploadedFiles2 = this.getProductUploadedFiles2.bind(this);

        this.zoomImage = this.zoomImage.bind(this);
        this.closeLightbox = this.closeLightbox.bind(this);

        this.showSuccessModal = this.showSuccessModal.bind(this);
        this.hideSuccessModal = this.hideSuccessModal.bind(this);

        this.showFaildModal = this.showFaildModal.bind(this);
        this.hideFaildModal = this.hideFaildModal.bind(this);

        this.showPermissionModal = this.showPermissionModal.bind(this);
        this.hidePermissionModal = this.hidePermissionModal.bind(this);

        this.dropBoxOnSuccess = this.dropBoxOnSuccess.bind(this);
        this.googleOnSuccess = this.googleOnSuccess.bind(this);

        this.getOrderData = this.getOrderData.bind(this);
        this.getCustomersList = this.getCustomersList.bind(this);
        this.selectedCustomerChange = this.selectedCustomerChange.bind(this);
    }

    _isMounted = false;
    _isProductField = false;

    hideSuccessModal() {
        this.setState({
            showSuccessModal: false
        });
    }

    showSuccessModal() {
        this.setState({
            showSuccessModal: true
        });
    }

    hideFaildModal() {
        this.setState({
            showFaildModal: false
        });
    }

    showFaildModal() {
        this.setState({
            showFaildModal: true
        });
    }

    hidePermissionModal() {
        this.setState({
            showPermissionModal: false
        });
    }

    showPermissionModal() {
        this.setState({
            showPermissionModal: true
        });
    }

    handleDrag(e) {
        e.preventDefault();
        e.stopPropagation();
        // let div = document.getElementById("drop_file_zone");
        // if (!div.classList.contains("dragging")) {
        //   div.classList.add("dragging");
        // }
    }

    handelChecked(e) {

        if (e.target.checked) {
            this.setState({
                customProductTemplate: '',
                productTemplate: "",
                saveAsTemplate: true
            });
        } else {
            this.setState({
                customProductTemplate: '',
                productTemplate: "",
                saveAsTemplate: false
            });
        }
    }

    handelCustomTemplateChecked(e) {

        if (e.target.checked) {
            this.setState({
                customProductTemplate: '',
                productTemplate: "",
                saveForCustomTemplate: true
            });
        } else {
            this.setState({
                customProductTemplate: '',
                productTemplate: "",
                saveForCustomTemplate: false
            });
        }
    }

    handleDragIn(e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true});
        }
    }

    handleDragOut(e) {
        e.preventDefault();
        e.stopPropagation();
        this.dragCounter--;
        // let div = document.getElementById("drop_file_zone");
        // if (div.classList.contains("dragging")) {
        //   div.classList.remove("dragging");
        // }
        if (this.dragCounter > 0) return;
        this.setState({dragging: false});
    }

    handleDrop(e) {
        e.preventDefault();
        e.stopPropagation();
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragging: false});

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            let inputField = document.getElementById("selectFile");
            inputField.setAttribute("data-value", "1");
            // let div = document.getElementById("drop_file_zone");
            // if (div.classList.contains("dragging")) {
            //   div.classList.remove("dragging");
            // }
            let files = e.dataTransfer.files;
            if (this.state.uploadType === 'additional') {
                this.fileUpload2(files, "direct");
            } else {
                this.fileUpload(files, "direct");
            }
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }

    handleSelectFileClick(e) {
        let inputField = document.getElementById("selectFile");
        inputField.click();
        inputField.setAttribute("data-value", "1");
    }

    handleInputFileChange(e) {
        let files = e.target.files;
        if (files && files.length > 0) {
            // let div = document.getElementById("drop_file_zone");
            // if (div.classList.contains("dragging")) {
            //   div.classList.remove("dragging");
            // }
            if (this.state.uploadType === 'additional') {
                this.fileUpload2(files, "direct");
            } else {
                this.fileUpload(files, "direct");
            }
        }
        // console.log(files);
    }

    dropBoxOnSuccess(e) {

        let inputField = document.getElementById("selectFile");
        inputField.setAttribute("data-value", "1");
        if (this.state.uploadType === 'additional') {
            this.fileUpload2(e, "dropbox");
        } else {
            this.fileUpload(e, "dropbox");
        }
        // this.fileUpload(e, "dropbox");
    }

    googleOnSuccess(e) {
        if (e.action === "picked") {
            let {googleToken} = this.state;
            let inputField = document.getElementById("selectFile");
            inputField.setAttribute("data-value", "1");
            // console.log(e);
            if (this.state.uploadType === 'additional') {
                this.fileUpload2(e["docs"], "google", googleToken);
            } else {
                this.fileUpload(e["docs"], "google", googleToken);
            }
            // this.fileUpload(e["docs"], "google", googleToken);
        }
    }

    fileUpload = async (files, type, token = 0) => {
        let {globalUploadeCount, productId, uploadType, bothSideUpload} = this.state;
        let loader = document.getElementById("popup-loader");
        let inputField = document.getElementById("selectFile");

        this.setState({
            uploadErrorMsgText: ""
        });
        // console.log(globalUploadeCount);

        loader.classList.add("show");
        var glogalSize = 0;

        let requestUrl = `/orderfiles/create`;
        let currentComponent = this;
        if (type === "dropbox") {
            if (files !== undefined) {
                globalUploadeCount = files.length + globalUploadeCount;
            }
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.bytes);
            }
        } else if (type === "onedrive") {
            globalUploadeCount = files.value.length + globalUploadeCount;
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.size);
            }
        } else if (type === "google") {
            if (files !== undefined) {
                globalUploadeCount = files.length + globalUploadeCount;
            }
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.sizeBytes);
            }
        } else {
            globalUploadeCount = files.length + globalUploadeCount;
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.size);
            }
        }
        var size = glogalSize / (1024 * 1024);
        let maxFileCount = bothSideUpload ? 64 : 32
        if (
            files !== undefined &&
            (files.length > 0 ||
                (files.value !== undefined && files.value.length > 0)) &&
            size < 501 &&
            globalUploadeCount < maxFileCount
        ) {
            var formData = new FormData();

            if (type === "dropbox") {
                const links = [];
                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    links.push(item);
                    // formData.append("files[" + i + "][link]", item.link);
                    // formData.append("files[" + i + "][name]", item.name);
                }
                formData.append("files", JSON.stringify(links));
            } else if (type === "google") {
                const links = [];
                formData.append("access_token", token);

                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    links.push(item);
                    // formData.append("files[" + i + "][link]", item.id);
                    // formData.append("files[" + i + "][name]", item.name);
                    // formData.append("files[" + i + "][extension]", item.mimeType);
                }
                formData.append("files", JSON.stringify(links));
            } else {
                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    formData.append("files[" + i + "]", item);
                }
            }

            formData.append("productId", productId);
            formData.append("cloudName", type);

            if (uploadType === 'backSide') {
                formData.append("bothSide", 1);
            } else {
                formData.append("bothSide", 0);
            }


            let res = await axios.post(apiUrl + requestUrl, formData, {
                headers: authHeader()
            });
            let {data} = res;

            if (data.error === null) {
                let {globalUploadeCount} = this.state;
                let files = data.payload;
                globalUploadeCount = globalUploadeCount + Object.keys(files).length;
                // console.log(files);

                setTimeout(function () {
                    if (loader.classList.contains("show")) {
                        loader.classList.remove("show");
                    }
                    let selectedFiles = [];
                    let selectedFile = currentComponent.state.selectedFile;

                    Object.keys(files).map(function (i) {
                        let item = files[i];
                        return selectedFiles += selectedFile.push(item);
                    });
                    // for (let i = 0; i < Object.keys(files).length; i++) {
                    //   let item = files[i];
                    //   selectedFiles += selectedFile.concat(item);
                    // }
                    currentComponent.setState({
                        selectedFile
                    });
                    inputField.value = ''
                }, 500);

                this.setState({
                    globalUploadeCount
                });
            }
        } else {
            if (loader.classList.contains("show")) {
                loader.classList.remove("show");
            }

            document.getElementById("selectFile").val = "";
            this.setState({
                uploadErrorMsgText: ""
            });
            // $('#drop_file_zone .error').empty();
            if (globalUploadeCount >= maxFileCount + 1) {
                // $('#drop_file_zone .error').text('')
                this.setState({
                    uploadErrorMsgText: `Maximum files count is ${maxFileCount}.`
                });
            } else {
                this.setState({
                    uploadErrorMsgText: "Maximum upload size is 500 mb."
                });
                // $('#drop_file_zone .error').text('Maximum upload size is 300 mb.')
            }

        }
    };

    fileUpload2 = async (files, type, token = 0) => {
        let {additionalUploadeCount, productId} = this.state;
        let loader = document.getElementById("popup-loader");
        let inputField = document.getElementById("selectFile");
        this.setState({
            uploadErrorMsgText: ""
        });
        // console.log(globalUploadeCount);

        loader.classList.add("show");
        var glogalSize = 0;

        let requestUrl = `/orderfiles/create`;
        let currentComponent = this;
        if (type === "dropbox") {
            if (files !== undefined) {
                additionalUploadeCount = files.length + additionalUploadeCount;
            }
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.bytes);
            }
        } else if (type === "onedrive") {
            additionalUploadeCount = files.value.length + additionalUploadeCount;
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.size);
            }
        } else if (type === "google") {
            if (files !== undefined) {
                additionalUploadeCount = files.length + additionalUploadeCount;
            }
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.sizeBytes);
            }
        } else {
            additionalUploadeCount = files.length + additionalUploadeCount;
            for (let i = 0; i <= files.length - 1; i++) {
                let item = files[i];
                glogalSize += parseInt(item.size);
            }
        }
        var size = glogalSize / (1024 * 1024);

        if (
            files !== undefined &&
            (files.length > 0 ||
                (files.value !== undefined && files.value.length > 0)) &&
            size < 501 &&
            additionalUploadeCount < 2
        ) {
            var formData = new FormData();

            if (type === "dropbox") {
                const links = [];
                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    links.push(item);
                    // formData.append("files[" + i + "][link]", item.link);
                    // formData.append("files[" + i + "][name]", item.name);
                }
                formData.append("files", JSON.stringify(links));
            } else if (type === "google") {
                const links = [];
                formData.append("access_token", token);

                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    links.push(item);
                    // formData.append("files[" + i + "][link]", item.id);
                    // formData.append("files[" + i + "][name]", item.name);
                    // formData.append("files[" + i + "][extension]", item.mimeType);
                }
                formData.append("files", JSON.stringify(links));
            } else {
                for (let i = 0; i <= files.length - 1; i++) {
                    let item = files[i];
                    formData.append("files[" + i + "]", item);
                }
            }

            formData.append("productId", productId);
            formData.append("cloudName", type);
            let additionalUpload = 1

            formData.append("additionalUpload", additionalUpload);

            let res = await axios.post(apiUrl + requestUrl, formData, {
                headers: authHeader()
            });
            let {data} = res;

            if (data.error === null) {
                let {additionalUploadeCount} = this.state;
                let files = data.payload;
                additionalUploadeCount = additionalUploadeCount + Object.keys(files).length;
                // console.log(files);

                setTimeout(function () {
                    if (loader.classList.contains("show")) {
                        loader.classList.remove("show");
                    }
                    let selectedFiles2 = [];
                    let selectedFile2 = currentComponent.state.selectedFile2;

                    Object.keys(files).map(function (i) {
                        let item = files[i];

                        return selectedFiles2 += selectedFile2.push(item);
                    });
                    // for (let i = 0; i < Object.keys(files).length; i++) {
                    //   let item = files[i];
                    //   selectedFiles += selectedFile.concat(item);
                    // }
                    currentComponent.setState({
                        selectedFile2
                    });
                    inputField.value = '';
                }, 500);

                this.setState({
                    additionalUploadeCount
                });
            }
        } else {
            if (loader.classList.contains("show")) {
                loader.classList.remove("show");
            }

            document.getElementById("selectFile").val = "";
            this.setState({
                uploadErrorMsgText: ""
            });
            // $('#drop_file_zone .error').empty();
            if (additionalUploadeCount >= 2) {
                // $('#drop_file_zone .error').text('')
                this.setState({
                    uploadErrorMsgText: `Maximum files count is 1.`
                });
            } else {
                this.setState({
                    uploadErrorMsgText: "Maximum upload size is 500 mb."
                });
                // $('#drop_file_zone .error').text('Maximum upload size is 300 mb.')
            }

            console.log("mets");
        }
    };

    showUploadModal(e) {
        let buttonType = e.target.getAttribute('data-type')
        let uploadType = ''


        if (buttonType === 'additional-file') {
            uploadType = 'additional'
        } else if (buttonType === 'back-side') {
            uploadType = 'backSide'
        } else if (buttonType === 'printing-method') {
            uploadType = 'printingMethod'

        } else {
            uploadType = 'global'
        }
        this.setState({
            showUploadModal: true,
            selectedFile: [],
            selectedFile2: [],
            uploadType
        });
    }

    hideUploadModal() {
        this.setState(
            {
                showUploadModal: false,
                uploadErrorMsgText: '',
                selectedFile: [],
                selectedFile2: [],
            },
            () => {
                // let projectPopup = document.getElementsByClassName("new-project");
                // if (projectPopup[0].classList.contains("product-list")) {
                //   projectPopup[0].classList.remove("product-list");
                // }
            }
        );
    }

    handleSaveClick(e) {
        let inputField = document.getElementById("selectFile");
        let inputData = inputField.getAttribute("data-value");
        // console.log(inputField.files);
        // console.log(inputData);

        if (this.state.uploadType === 'additional') {
            if (
                inputData !== undefined &&
                inputData !== "" &&
                this.state.additionalUploadeCount > 0
            ) {
                this.getProductUploadedFiles2(this.state.productId);
                this.setState({
                    showUploadModal: false,
                    selectedFile: [],
                    selectedFile2: [],
                });
            } else {
                this.setState({
                    uploadErrorMsgText: "Select file before save",
                    selectedFile: [],
                    selectedFile2: [],
                });
            }
        } else {
            if (
                inputData !== undefined &&
                inputData !== "" &&
                this.state.globalUploadeCount > 0
            ) {
                this.getProductUploadedFiles(this.state.productId);
                if (this.state.productAdditionalUploadFile) {
                    this.getProductUploadedFiles2(this.state.productId);
                }
                this.setState({
                    showUploadModal: false,
                    selectedFile: [],
                    selectedFile2: [],
                });
            } else {
                this.setState({
                    uploadErrorMsgText: "Select file before save",
                    selectedFile: [],
                    selectedFile2: [],
                });
            }
        }
    }

    handleRemoveFile = async e => {
        let loader = document.getElementById("popup-loader");

        let {selectedFile, uploadedFiles, globalUploadeCount, backSideCount, faceSideCount} = this.state;
        let type = e.currentTarget.getAttribute("data-type");
        let id = e.currentTarget.getAttribute("data-id");
        if (type === "popup") {
            loader.classList.add("show");
        }
        let requestUrl = `/orderfiles/delete/${id}`;
        let res = await axios.delete(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let foundIndex;
            if (type === "popup") {
                foundIndex = selectedFile.findIndex(x => x.id === parseInt(id));
                // console.log(foundIndex);
                selectedFile.splice(foundIndex, 1);
                this.setState({
                    selectedFile,
                    globalUploadeCount: globalUploadeCount - 1
                });
                if (loader.classList.contains("show")) {
                    loader.classList.remove("show");
                }
            } else {
                foundIndex = uploadedFiles.findIndex(x => x.id === parseInt(id));
                // console.log(foundIndex);
                // console.log(id);
                if (type === 'back') {
                    backSideCount = backSideCount - 1
                }
                if (type === 'face') {
                    faceSideCount = faceSideCount - 1
                }
                uploadedFiles.splice(foundIndex, 1);
                this.setState({
                    backSideCount,
                    faceSideCount,
                    uploadedFiles,
                    globalUploadeCount: globalUploadeCount - 1
                });
            }
        }
    };


    handleRemoveFile2 = async e => {
        let loader = document.getElementById("popup-loader");

        let {selectedFile2, uploadedFiles2, additionalUploadeCount} = this.state;
        let type = e.currentTarget.getAttribute("data-type");
        let id = e.currentTarget.getAttribute("data-id");

        let templateID = e.currentTarget.getAttribute('data-templateid')
        if (type === "popup") {
            loader.classList.add("show");
        }
        let requestUrl = `/orderfiles/delete/${id}`;
        const queryParams = {};
        if (templateID !== '') {
            queryParams.templateId = templateID;
        }

        let res = await axios.delete(apiUrl + requestUrl, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            let foundIndex;
            if (type === "popup") {
                foundIndex = selectedFile2.findIndex(x => x.id === parseInt(id));
                // console.log(foundIndex);
                selectedFile2.splice(foundIndex, 1);
                this.setState({
                    selectedFile2,
                    additionalUploadeCount: additionalUploadeCount - 1
                });
                if (loader.classList.contains("show")) {
                    loader.classList.remove("show");
                }
            } else {
                foundIndex = uploadedFiles2.findIndex(x => x.id === parseInt(id));
                // console.log(foundIndex);
                // console.log(id);
                uploadedFiles2.splice(foundIndex, 1);
                this.setState({
                    uploadedFiles2,
                    additionalUploadeCount: additionalUploadeCount - 1
                });
            }
        }
    };


    getProductUploadedFiles = async id => {
        this.setState({
            uploadedFiles: []
            // uploadedFilesReady: true
        });
        // console.log(id);
        let requestUrl = `/orderfiles/getfiles/`;
        const queryParams = {};
        queryParams.productId = id;

        let res = await axios.get(apiUrl + requestUrl, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            let uploadedFiles = data.payload
            let backSideCount = 0, faceSideCount = 0;
            for (let i = 0; i < uploadedFiles.length; i++) {
                if (uploadedFiles[i].bothSide === true) {
                    backSideCount++
                } else {
                    faceSideCount++
                }
            }
            this.setState({
                faceSideCount,
                backSideCount,
                uploadedFiles,
                globalUploadeCount: data.payload.length,
                uploadedFilesReady: true
            }, () => {
            });
        }
    };


    getProductUploadedFiles2 = async id => {
        this.setState({
            uploadedFiles2: []
            // uploadedFilesReady: true
        });
        // console.log(id);
        let requestUrl = `/orderfiles/getadditionalfiles`;
        const queryParams = {};
        queryParams.productId = id;
        queryParams.templateId = this.state.productTemplate;
        let res = await axios.get(apiUrl + requestUrl, {
            params: queryParams,
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            this.setState({
                uploadedFiles2: data.payload,
                additionalUploadeCount: data.payload.length,
                // uploadedFilesReady: true
            });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        let productUrlName = nextProps.productUrlName;
        let productIsExist = nextProps.productIsExist;
        let handleSubmit = nextProps.handleSubmit;
        let couponCode = nextProps.couponCode;

        const productId = nextProps.productId;
        if (productIsExist) {
            this._isMounted = true;
            this.setState(
                {
                    productId,
                    productUrlName,
                    productIsExist
                },
                () => {
                }
            );
        }
        if (handleSubmit) {
            console.log("coupon",couponCode)
            this.setState({
                couponCode:couponCode
            },this.handleSubmit);
        }
        // console.log(" submit : " + handleSubmit);
    }

    handleValidation() {
        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        let {productOptions, productField} = this.state;
        let validationRules = [];
        Object.keys(productOptions).map(function (i) {
            if (productOptions[i].required === true && productOptions[i].fieldType !== 'checkbox' && productOptions[i].fieldType !== 'staticText') {
                var validationRule = {
                    field: productOptions[i].id,
                    method: validator.isEmpty,
                    validWhen: false,
                    message: "This field is required"
                };
                if (productOptions[i].fieldType === "number" && productOptions[i].min !== undefined) {
                    let fieldMin = productOptions[i].min;
                    let fieldMax = productOptions[i].max;
                    let units = productOptions[i].units;

                    let unitsText = "";

                    if (units === "mm") {
                        unitsText = "mm";
                    }
                    if (units === "percent") {
                        unitsText = "%";
                    }
                    validationRule = {
                        field: productOptions[i].id,
                        method: validator.isEmpty,
                        validWhen: false,
                        message: `minimum ${fieldMin}${unitsText} and maximum ${fieldMax}${unitsText}`
                    };
                }
                if (productOptions[i].fieldType === "cylinder") {
                    validationRule = {
                        field: productOptions[i].id,
                        method: validator.isLength,
                        args: [
                            {
                                min: 4
                            }
                        ],
                        validWhen: true,
                        message: "Minimum digits qty : 4"
                    };
                }
                validationRules.push(validationRule);
            }

            return validationRule;
        });
        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(productField);
        if (!validation.isValid) {
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid") {
                    if (validation[variable]["isInvalid"]) {
                        errors[variable] = validation[variable]["message"];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }
        }
        this.setState({errors: errors, errorClasses: errorClasses});

        return formIsValid;

    }

    handleGlobalInfoValidation() {
        let errors = {};
        let errorClasses = {};
        let formIsValid = true;
        const validationRules = [
            {
                field: "projectName",
                method: validator.isEmpty,
                validWhen: false,
                message: "This field is required"
            },
            {
                field: "projectDescription",
                method: validator.isEmpty,
                validWhen: false,
                message: "This field is required"
            },
        ];


        const validatorObj = new FormValidator(validationRules);
        const validation = validatorObj.validate(this.state.productGlobalInfo);
        if (!validation.isValid) {
            formIsValid = false;
            for (var variable in validation) {
                if (validation.hasOwnProperty(variable) && variable !== "isValid") {
                    if (validation[variable]["isInvalid"]) {
                        errors[variable] = validation[variable]["message"];
                        errorClasses[variable] = "is-invalid";
                    }
                }
            }
        }
        this.setState({errors: errors, errorClasses: errorClasses});
        return formIsValid;
    }

    scrollToInvalidField(fieldId) {
        setTimeout(function () {
            var x = fieldId ? document.getElementsByClassName(`checkbox-${fieldId}`) : document.getElementsByClassName("is-invalid");
            if (x.length > 0) {
                x[0].scrollIntoView(true);

                const yourHeight = 105 + 20;
                const scrolledY = window.scrollY;

                if (scrolledY) {
                    window.scroll(0, scrolledY - yourHeight);
                }
            }
            return false;

        }, 100);
    }

    handleSubmit = async e => {
        let {
            productOptions,
            productField,
            productId,
            productGlobalInfo,
            productTemplate,
            saveAsTemplate,
            productCheckBoxList,
            bothSideUpload,
            errors,
            saveForCustomTemplate,
            customTemplateProductField,
            customTemplateCheckBoxList,
            customProjectName,
            selectedCustomer
        } = this.state;

        let currentUser = JSON.parse(localStorage.getItem('user'))
        let userPermission = JSON.parse(localStorage.getItem('permissions'))
        let createOrder;
        let currentComponent = this
        if (userPermission) {
            createOrder = userPermission.find(
                opt => opt.key === 'CREATE_ORDER'
            )
        }
        this.scrollToInvalidField()
        if (this.handleValidation() && this.handleGlobalInfoValidation()) {
            if (currentUser.isMember === false || (currentUser.isMember === true && createOrder !== undefined)) {
                for (let [key] of Object.entries(productCheckBoxList)) {
                    let productCheckBoxRequired = productOptions.find(opt => opt.id === Number(key)).required

                    if (productCheckBoxRequired && Object.values(productCheckBoxList[key]).indexOf(true) === -1) {
                        errors[key] = 'Pleas select at last one item';
                        currentComponent.setState({
                            errors,
                        });

                        this.scrollToInvalidField(productOptions.find(opt => opt.id === Number(key)).optionsFields[0].id)

                        return false;
                    }
                }
                // console.log(errors);

                let loader = document.getElementById("global-loader");
                loader.classList.add("show");
                var selected = {};
                var customTemplate = {}

                productField.map((item, key) => {
                    return selected[key] = item;
                });
                Object.keys(productCheckBoxList).map(function (key, item) {
                    return selected[key] = productCheckBoxList[key]
                })
                let customTemplateName = productGlobalInfo.projectName

                if (saveForCustomTemplate === true) {
                    customTemplateProductField.map((item, key) => {
                        return customTemplate[key] = item;
                    });
                    Object.keys(customTemplateCheckBoxList).map(function (key, item) {
                        return customTemplate[key] = customTemplateCheckBoxList[key]
                    })

                    customTemplateName = customProjectName
                }


                let postData = {
                    customProjectName: customTemplateName,
                    productId,
                    optionsJson: JSON.stringify(selected),
                    projectName: productGlobalInfo.projectName,
                    projectDescription: productGlobalInfo.projectDescription,
                    productTemplate,
                    saveAsTemplate,
                    saveAsCustomTemplate: saveForCustomTemplate,
                    optionsCustomJson: JSON.stringify(customTemplate),
                    couponCode:this.state.couponCode,
                    customerId:selectedCustomer
                };

                if (bothSideUpload) {
                    postData.bothSide = 1
                } else {
                    postData.bothSide = 0
                }
                let requestUrl = `/orders/create`;
                let res = await axios.post(apiUrl + requestUrl, postData, {
                    headers: authHeader()
                });

                let {data} = res;

                if (data.error === null) {
                    if (loader.classList.contains("show")) {
                        loader.classList.remove("show");
                        console.log("success")
                    }
                    this.showSuccessModal();
                    setTimeout(() => {
                        // window.location = "/dashboard";
                    }, 3000);
                } else {
                    if (
                        data.error.uploadFileError !== undefined &&
                        data.error.uploadFileError === true
                    ) {
                        if (loader.classList.contains("show")) {
                            loader.classList.remove("show");
                        }
                        this.setState({
                            uploadType: 'global',
                            showUploadModal: true,
                            uploadErrorMsgText: "Upload file is required"
                        });
                    } else {
                        if (data.error.additionalUploadFileError !== undefined &&
                            data.error.additionalUploadFileError === true) {
                            if (loader.classList.contains("show")) {
                                loader.classList.remove("show");
                            }
                            this.setState({
                                uploadType: 'additional',
                                showUploadModal: true,
                                uploadErrorMsgText: "Upload file is required"
                            });
                        } else if (data.error.backSideUploadFileError !== undefined &&
                            data.error.backSideUploadFileError === true) {
                            if (loader.classList.contains("show")) {
                                loader.classList.remove("show");
                            }
                            this.setState({
                                uploadType: 'backSide',
                                showUploadModal: true,
                                uploadErrorMsgText: "Upload file is required"
                            });
                        } else {
                            if (loader.classList.contains("show")) {
                                loader.classList.remove("show");
                            }
                            this.setState({
                                showFaildModal: true
                            });
                        }

                    }
                }
            } else {
                this.setState({
                    showPermissionModal: true
                })
            }
        }
    };

    componentDidMount() {
        let {productUrlName, productIsTesting} = this.state;
        let user = JSON.parse(localStorage.getItem("user"));



        if (user && productIsTesting === false) {
            this.getTemplateByProduct();
            this.getCustomTemplateByProduct();
        } else {
            if (productIsTesting) {
                this.getCustomTemplateByProduct();
            }
            this.getProductOption(productUrlName);
        }
        this.dragCounter = 0;
        let start = "0",
            end = "15",
            reset = true;
        this.getCustomersList( start, end, 1, reset);
    }

    getTemplateByProduct = async () => {
        let {productUrlName} = this.state;
        let requestUrl = `/templates/getTemplateByProduct/${productUrlName}`;
        let res = await axios.get(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            if (data.payload.isEmpty !== true) {
                this.setState(
                    {
                        templateOptions: data.payload.templates,
                        templateField: true
                    },
                    () => {
                        this.getProductOption(productUrlName);
                    }
                );
            } else {
                this.getProductOption(productUrlName);
            }

            let templateIdFromUrl = "";
            if (window.location.search) {
                let id = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true
                }).templateId;
                if (id !== undefined && id !== "") {
                    templateIdFromUrl = {value: id};
                    this.templateDropdownChange(templateIdFromUrl, "");
                }
            }
        }
    };

    getCustomTemplateByProduct = async () => {
        let {productUrlName} = this.state;
        let requestUrl = `/customTemplates/getTemplateByProduct/${productUrlName}`;
        let res = await axios.get(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;

        if (data.error === null) {
            if (data.payload.isEmpty !== true) {
                this.setState(
                    {
                        customTemplateOptions: data.payload.templates,
                        customTemplateField: true
                    },
                    () => {
                        this.getProductOption(productUrlName);
                    }
                );
            } else {
                this.getProductOption(productUrlName);
            }

            let templateIdFromUrl = "";
            if (window.location.search) {
                let id = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true
                }).pressId;
                if (id !== undefined && id !== "") {
                    templateIdFromUrl = {value: id};
                    this.customTemplateDropdownChange(templateIdFromUrl, "");
                }
            }
        }
    };

    getProductOption = async productUrlName => {
        let requestUrl = `/productoptions/${productUrlName}`;

        let res = await axios.get(apiUrl + requestUrl);
        let user = localStorage.getItem('user')
        let {data} = res;
        if (data.error === null) {
            if (user) {
                this.getProductUploadedFiles(this.state.productId);

                if (this.state.productAdditionalUploadFile) {
                    this.getProductUploadedFiles2(this.state.productId);
                }
            }


            this.setState({
                productOptions: data.payload.options
            });
            this.setupProductForState(data.payload.options);

            if (window.location.search) {
                let id = qs.parse(window.location.search, {
                    ignoreQueryPrefix: true
                }).orderId;
                if (id !== undefined && id !== "") {
                    this.getOrderData(id);
                }
            }
        }
    };

    getProductChildren = async (templateData = "") => {
        let loader = document.getElementById("loader");

        if (loader !== null) {
            loader.classList.add("show");
        }
        let requestUrl = `/getoptionchildren`;
        let {productId, productField, productCheckBoxList} = this.state;
        var selected = {};
        if (templateData === "") {
            productField.map((item, key) => {
                return selected[key] = item;
            });
            Object.keys(productCheckBoxList).map(function (key, item) {
                return selected[key] = productCheckBoxList[key]
            })

        } else {
            selected = templateData;
        }
        const queryParams = {
            productId: productId,
            selected: templateData === "" ? JSON.stringify(selected) : selected
        };
        let res = await axios.post(apiUrl + requestUrl, queryParams);

        let {data} = res;
        this.setupProductForState(data.payload.options);
        this.setState({
            productOptions: []
        })
        this.setState(
            {
                productOptions: data.payload.options
            },
            () => {
                this.calculatePrice();

                if (loader !== null && loader.classList.contains("show")) {
                    loader.classList.remove("show");
                }
            }
        );
    };

    configOptions(options) {
        if (options.length === 0) return [];
        const customizedOptions = [];
        const oneFieldsOptions = ["swatch", "textarea", "checkbox", "staticText", "downloadableFile"];
        for (let i = 0; i <= options.length - 1; i++) {
            const item = options[i];
            if (item.isForOneLine) {
                customizedOptions.push({
                    items: [item],
                    half: false,
                    isForOneLine: true
                });
            } else {
                if (oneFieldsOptions.includes(item.fieldType)) {
                    customizedOptions.push({
                        items: [item],
                        half: false,
                        isForOneLine: false
                    });
                } else {
                    const lastItem = customizedOptions[customizedOptions.length - 1];
                    if (
                        lastItem &&
                        lastItem !== undefined &&
                        lastItem.half &&
                        lastItem.items.length === 1
                    ) {
                        customizedOptions[customizedOptions.length - 1].items.push(item);
                    } else {
                        customizedOptions.push({
                            items: [item],
                            half: true,
                            isForOneLine: false
                        });
                    }
                }
            }
        }
        return customizedOptions;
    }

    setupProductForState(data) {

        let field = [];
        let customTemplateField = [];
        const checkBoxList = {};
        var selected = {}
        let customTemplateCheckBoxList = {};
        let checkBoxOption = {}
        let mar = false;
        let printingMethodUpload = false;
        for (var i = 0; i <= data.length - 1; i++) {
            const item = data[i];
            if (item.fieldType === "swatch") {
                if (item.value !== "") {
                    field[item.id] = item.value;
                    if (item.isForCustomTemplate) {
                        customTemplateField[item.id] = item.value
                    }
                } else {
                    field[item.id] = ``;
                    if (item.isForCustomTemplate) {
                        customTemplateField[item.id] = `${item.optionsFields[0].id}`;
                    }
                }
            } else if (item.fieldType === "downloadableFile") {
                // eslint-disable-next-line
                item.optionsFields.map((list) => {
                    if (list.id === Number(item.value) && list.hasAttachFile === true) {
                        mar = true;
                        printingMethodUpload = true;
                    }
                    return true;
                })
                if (item.value !== "") {
                    field[item.id] = item.value;
                } else {
                    field[item.id] = ``;
                }
            } else if (item.fieldType === "checkbox") {
                let itemId = item.id;
                let options = item.optionsFields;
                if (item.sku === 'ordertypes') {
                    checkBoxOption = options
                }

                for (var j = 0; j <= options.length - 1; j++) {
                    const optionItem = options[j]
                    const optionID = optionItem.id
                    const optionVal = optionItem.selected
                    selected[optionID] = optionVal
                    if (optionItem.parentId === itemId) {
                        checkBoxList[itemId] = checkBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
                        checkBoxList[itemId][optionID] = optionVal
                    }

                }
                if (item.isForCustomTemplate) {
                    for (var j1 = 0; j1 <= options.length - 1; j1++) {
                        const optionItem = options[j1]
                        const optionID = optionItem.id
                        const optionVal = optionItem.selected
                        if (optionItem.parentId === itemId) {
                            customTemplateCheckBoxList[itemId] = customTemplateCheckBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
                            customTemplateCheckBoxList[itemId][optionID] = optionVal
                        }

                    }
                }
            } else if (item.fieldType === "staticText") {

            } else {
                field[item.id] = item.value;
                if (item.isForCustomTemplate) {
                    customTemplateField[item.id] = item.value;
                }
            }
        }
        this.setState(
            {
                printingMethodUpload,
                mar,
                productCheckBoxList: checkBoxList,
                customTemplateCheckBoxList: customTemplateCheckBoxList,
                checkBox: selected,
                productField: field,
                customTemplateProductField: customTemplateField,
                productFieldReady: true
            },
            () => {

            }
        );
        this.handleProductFieldReady(true);
        this.props.productServiceType(checkBoxOption)
    }

    handleProductFieldReady = param => {
        this.props.productFieldReady(param);
    };

    drawOptions() {
        let {productOptions} = this.state;
        const productOptionData = this.configOptions(productOptions);
        return productOptionData.map((list, key) => {
            let items = list.items;
            if (list.isForOneLine) {
                return items.map((item, key) => {
                    if (item.fieldType === "swatch") {
                        return this.drawSwatchField(item);
                    }
                    if (item.fieldType === "downloadableFile") {
                        return this.drawDownloadableFileSwatchField(item);
                    }
                    if (item.fieldType === "textarea") {
                        return this.drawTextAreaField(item);
                    }
                    if (item.fieldType === "checkbox") {
                        return this.drawCheckboxField(item);
                    }

                    if (item.fieldType === "staticText") {
                        return this.drawStaticText(item);
                    }

                    if (item.fieldType === "number") {
                        return (
                            <div className="row row-half clear" key={item.id}>
                                {this.drawNumberField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "cylinder") {
                        return (
                            <div className="row row-half clear" key={item.id}>
                                {this.drawCylinderField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "text") {
                        return (
                            <div className="row row-half clear" key={item.id}>
                                {this.drawTextField(item)}
                            </div>
                        )
                    }
                    if (item.fieldType === "dropdown") {
                        return (
                            <div className="row row-half clear" key={item.id}>
                                {this.drawDropDownField(item)}
                            </div>
                        )
                    }
                    return true
                });
            } else {
                if (list.half) {
                    return (
                        <div className="row row-half clear" key={key}>
                            {items.map((item, key) => {
                                if (item.fieldType === "number") {
                                    return this.drawNumberField(item);
                                }
                                if (item.fieldType === "cylinder") {
                                    return this.drawCylinderField(item);
                                }
                                if (item.fieldType === "text") {
                                    return this.drawTextField(item);
                                }
                                if (item.fieldType === "dropdown") {
                                    return this.drawDropDownField(item);
                                }
                                return true
                            })}
                        </div>
                    );
                } else {
                    return items.map((item, key) => {
                        if (item.fieldType === "downloadableFile") {
                            return this.drawDownloadableFileSwatchField(item);
                        }
                        if (item.fieldType === "swatch") {
                            return this.drawSwatchField(item);
                        }
                        if (item.fieldType === "textarea") {
                            return this.drawTextAreaField(item);
                        }
                        if (item.fieldType === "checkbox") {
                            return this.drawCheckboxField(item);
                        }
                        if (item.fieldType === "staticText") {
                            return this.drawStaticText(item);
                        }

                        return true
                    });
                }
            }

        });
    }

    drawStaticText(data) {
        return (
            <div className="row row-static-text" key={data.id}>
                {data.description}
            </div>
        );
    }

    drawCheckboxField(data) {
        const {errors} = this.state;
        return (
            <div className="row row-checkbox" key={data.id}>
                <div className="row-wrap">
                    <div className="label"><span className="name">{data.label}</span> {data.required ? (
                        <span className="sign">*</span>) : ''}</div>
                    <div className="wrap-box">
                        {data.optionsFields.map((list, key) => {

                            return (
                                <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                                    <input
                                        type="checkbox"
                                        id={list.id}
                                        defaultChecked={list.selected}
                                        name={data.id}
                                        onChange={this.handleCheckBoxChange}
                                        data-refresh={data.need_reload}
                                    />
                                    <label
                                        htmlFor={list.id}
                                        className={`custom-label-checkbox checkbox-${list.id} ${list.image ? "with-image" : ""}`}
                                    >
                                        {list.image ? <img src={list.image} alt={list.title}/> : ""}

                                        <div className="icons">
                                            <svg className="icon-check" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 14 14">
                                                <rect className="cls-1" width="14" height="14"/>
                                                <polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                            </svg>
                                            <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 14 14">
                                                <rect className="cls-1" width="14" height="14"/>
                                                <path className="cls-2" d="M7,11.5v-9M2.5,7h9"/>
                                            </svg>
                                        </div>
                                        <div className="text">
                                            <div className="text-box">
                                                {list.title}
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            );
                        })}

                    </div>
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left">
                                {data.description}
                            </div>
                        </div>
                    ) : ('')}
                </div>
            </div>
        );
    }

    drawNumberField(data) {

        const {errors, errorClasses, productField} = this.state;

        let fieldLabelText = data.label === "CYLINDER OR GEAR SIZE" ? <b>1 Gear = 3.175mm</b> : "";

        if (data.units === "mm") {
            fieldLabelText = "mm";
        }

        if (data.units === "percent") {
            fieldLabelText = "%";
        }
        if (data.units === "gears") {
            fieldLabelText = "gear teeth";
        }

        return (
            <div className="item" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <div className={`wrap-box ${data.units}`}>

                    <input
                        value={productField[data.id] ? productField[data.id] : ''}
                        id={data.id}
                        min={data.min}
                        max={data.max}
                        type={'text'}
                        data-type={data.fieldType}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        onChange={this.handleChange}
                        placeholder={data.min ? `Min:${data.min}${fieldLabelText} - Max:${data.max}${fieldLabelText}` : ''}
                        data-unit={fieldLabelText}
                        data-filed={data.isNumeric !== undefined ? 'isNumeric' : 'isFloat'}
                    />
                    {fieldLabelText ? (
                        <>
                            <div className="field-label">{fieldLabelText}</div>
                            {data.units === "gears" ? <p className="unit-info">1 gear teeth = 3.175mm</p> : ""}
                        </>


                    ) : (
                        ""
                    )}

                </div>
                <div className="invalid-feedback">{errors[[data.id]]}</div>
                {data.description !== '' && data.description !== undefined ? (
                    <div className="tooltip">
                        <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 100 100">
                            <path
                                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                transform="translate(6544 153.999)"
                                fill="#8f9293"/>
                        </svg>
                        <div class="left">
                            {data.description}
                        </div>
                    </div>
                ) : ('')}
            </div>
        );
    }

    drawCylinderField(data) {
        const {errors, errorClasses, productField} = this.state;

        let fieldLabelText = "";

        if (data.units === "mm") {
            fieldLabelText = "mm";
        }
        if (data.units === "percent") {
            fieldLabelText = "%";
        }
        return (
            <div className="item" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <div className={`wrap-box mm`}>
                    <InputMask
                        id={data.id}
                        min={data.min}
                        max={data.max}
                        type={'text'}
                        data-type={data.fieldType}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        data-unit={fieldLabelText}
                        value={productField[data.id] ? productField[data.id].toString() : ''}
                        mask="9 9 9 9"
                        // maskchar= '_'
                        maskPlaceholder="_"
                        onChange={this.handleChange}
                    />
                    {fieldLabelText ? (
                        <div className="field-label">{fieldLabelText}</div>
                    ) : (
                        ""
                    )}
                    <div className="field-label">mm</div>
                </div>
                <div className="invalid-feedback">{errors[[data.id]]}</div>
                {data.description !== '' && data.description !== undefined ? (
                    <div className="tooltip">
                        <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 100 100">
                            <path
                                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                transform="translate(6544 153.999)"
                                fill="#8f9293"/>
                        </svg>
                        <div class="left">
                            {data.description}
                        </div>
                    </div>
                ) : ('')}
            </div>
        );
    }

    drawTextField(data) {
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="item" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <input
                    value={productField[data.id] ? productField[data.id] : ''}
                    onKeyPress={this.handleKeyPress}
                    type={data.fieldType}
                    name={data.id}
                    className={errorClasses[[data.id]]}
                    onChange={this.handleChange}
                    data-sku={data.sku !== '' ? data.sku : ''}
                />
                <div className="invalid-feedback">{errors[[data.id]]}</div>
                {data.description !== '' && data.description !== undefined ? (
                    <div className="tooltip">
                        <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 100 100">
                            <path
                                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                transform="translate(6544 153.999)"
                                fill="#8f9293"/>
                        </svg>
                        <div class="left">
                            {data.description}
                        </div>
                    </div>
                ) : ('')}
            </div>
        );
    }

    drawDropDownField(data) {
        // console.log(data.optionsFields);
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="item" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <Select
                    classNamePrefix="react-select"
                    isMulti={false}
                    isSearchable={false}
                    value={[
                        data.optionsFields.find(
                            opt => opt.value === Number(productField[data.id])
                        )
                    ]}
                    name={data.id}
                    options={data.optionsFields}
                    onChange={(newValue, actionMeta) =>
                        this.dropdownChange(newValue, actionMeta)
                    }
                    data-refresh={data.need_reload}
                    className={errorClasses[data.id] + " react-select"}
                    placeholder="Please Select"
                />
                <div className="invalid-feedback">{errors[data.id]}</div>
                {data.description !== '' && data.description !== undefined ? (
                    <div className="tooltip">
                        <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                             viewBox="0 0 100 100">
                            <path
                                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                transform="translate(6544 153.999)"
                                fill="#8f9293"/>
                        </svg>
                        <div className="left">
                            {data.description}
                        </div>
                    </div>
                ) : ('')}

            </div>
        );
    }


    drawSvg(data) {
        return SVG[data]
    }


    drawSwatchField(data) {
        // console.log(data);
        const {productField, errorClasses, errors} = this.state;
        let description = data.description;
        description = description.replace(/(\r\n|\n|\r)/gm, "<br />");

        return (
            <div className="row row-swatch clear" key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <input
                    type="text"
                    value={productField[data.id] ? productField[data.id] : ''}
                    name={data.id}
                    className={`hidden ${errorClasses[[data.id]]}`}
                    readOnly={true}
                />
                <div className="wrap-box">

                    {data.optionsFields.map((list, key) => {
                        return (
                            <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                                <input
                                    type="radio"
                                    id={list.id}
                                    defaultChecked={list.id === Number(data.value) ? true : false}
                                    name={data.id}
                                    onChange={this.handleSwatchChange}
                                    data-refresh={data.need_reload}
                                />
                                <label
                                    htmlFor={list.id}
                                    className={`custom-label-radio radio-${list.id} ${list.image || list.sku ? "with-image" : ""}`}
                                >
                                    {list.sku !== '' && list.sku !== undefined ?
                                        (
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.drawSvg(list.sku)
                                            }}>
                                            </div>
                                        )
                                        :

                                        list.image ? <img src={list.image} alt={list.title}/> :
                                            (
                                                <div className="icons">
                                                    <svg className="icon-check" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <polyline className="cls-2"
                                                                  points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                                    </svg>
                                                    <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <circle className="cls-2" cx="7" cy="7" r="3.63"/>
                                                    </svg>
                                                </div>
                                            )
                                    }

                                    <div className="text">
                                        <div className="text-box">
                                            {list.title}
                                        </div>
                                    </div>
                                </label>

                            </div>
                        );
                    })}
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left" dangerouslySetInnerHTML={{
                                __html: description
                            }}>
                            </div>
                        </div>
                    ) : ('')}

                </div>

            </div>
        );
    }

    drawDownloadableFileSwatchField(data) {
        const {errors, productField, errorClasses, mar} = this.state;
        let description = data.description;
        description = description.replace(/(\r\n|\n|\r)/gm, "<br />");

        let aaa = ''
        if (mar) {
            aaa = "row-margin"
        } else {
            aaa = ''
        }

        return (
            <div className={`row row-swatch ${aaa} clear`} key={data.id}>
                <div className="label"><span className="name">{data.label}</span> {data.required ? (
                    <span className="sign">*</span>) : ''}</div>
                <input
                    type="text"
                    value={productField[data.id] ? productField[data.id] : ''}
                    name={data.id}
                    className={`hidden ${errorClasses[[data.id]]}`}
                    readOnly={true}
                />
                <div className="wrap-box">
                    {data.optionsFields.map((list, key) => {
                        return (
                            <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                                <input
                                    type="radio"
                                    id={list.id}
                                    defaultChecked={list.id === Number(data.value) ? true : false}
                                    name={data.id}
                                    onChange={this.handleSwatchChange}
                                    data-refresh={data.need_reload}
                                    data-downloadable={list.hasAttachFile}
                                />

                                <label
                                    htmlFor={list.id}
                                    className={`custom-label-radio  ${list.image || list.sku ? "with-image" : ""}`}
                                >
                                    {list.sku !== '' && list.sku !== undefined ?
                                        (
                                            <div dangerouslySetInnerHTML={{
                                                __html: this.drawSvg(list.sku)
                                            }}>
                                            </div>
                                        )
                                        :

                                        list.image ? <img src={list.image} alt={list.title}/> :
                                            (
                                                <div className="icons">
                                                    <svg className="icon-check" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <polyline className="cls-2"
                                                                  points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                                    </svg>
                                                    <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <circle className="cls-2" cx="7" cy="7" r="3.63"/>
                                                    </svg>
                                                </div>
                                            )
                                    }

                                    <div className="text">
                                        <div className="text-box">
                                            {list.title}
                                        </div>
                                    </div>
                                </label>
                                {list.hasAttachFile ? (
                                    <div className="downlodable-file-box">
                                        <div className="downlodable-file-title">{list.downloadableDescription}</div>
                                        <a target="_blank" rel="noopener noreferrer" href={list.downloadableFile}>Download
                                            file</a>
                                    </div>
                                ) : ('')}

                            </div>
                        );
                    })}
                    <div className="invalid-feedback">{errors[[data.id]]}</div>

                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left" dangerouslySetInnerHTML={{
                                __html: description
                            }}>
                            </div>
                        </div>
                    ) : ('')}
                </div>
            </div>


        );
    }

    drawTextAreaField(data) {
        // console.log(data);
        const {errors, errorClasses, productField} = this.state;
        return (
            <div className="row" key={data.id}>
                <div className="item" key={data.id}>
                    <div className="label"><span className="name">{data.label}</span> {data.required ? (
                        <span className="sign">*</span>) : ''}</div>
                    <textarea
                        value={productField[data.id]}
                        onKeyPress={this.handleKeyPress}
                        name={data.id}
                        className={errorClasses[[data.id]]}
                        onChange={this.handleChange}
                    ></textarea>
                    <div className="invalid-feedback">{errors[[data.id]]}</div>
                    {data.description !== '' && data.description !== undefined ? (
                        <div className="tooltip">
                            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                 viewBox="0 0 100 100">
                                <path
                                    d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                                    transform="translate(6544 153.999)"
                                    fill="#8f9293"/>
                            </svg>
                            <div className="left">
                                {data.description}
                            </div>
                        </div>
                    ) : ('')}
                </div>
            </div>
        );
    }

    componentWillUnmount() {
    }

    handleChange(e) {
        let _this = e.target;
        let {errors, errorClasses} = this.state;
        const name = _this.name;
        const type = _this.getAttribute('data-type');
        const filed = _this.getAttribute('data-filed');
        const sku = _this.getAttribute('data-sku');
        let value = _this.value;
        const productField = this.state.productField;
        let customProjectName = this.state.customProjectName;
        const customTemplateProductField = this.state.customTemplateProductField;

        productField[name] = value;

        if (name in customTemplateProductField) {
            customTemplateProductField[name] = value;
        }
        if (sku === 'custom_template') {
            customProjectName = value
        }
        if (type === "number") {

            if (filed === 'isFloat' && /^-?\d*[.]?\d{0,5}$/.test(value) && (value === "" || parseInt(value, 0) <= 999999)) {
                const fieldMin = parseFloat(_this.min);
                const fieldMax = parseFloat(_this.max);
                value = parseFloat(value)
                let unitText = _this.getAttribute("data-unit");
                if (value < fieldMin) {
                    errors[name] = `Minimum must be ${fieldMin}${unitText}`;
                    errorClasses[name] = "is-invalid";

                    this.setState({
                        errors,
                        errorClasses
                    });
                } else if (value > fieldMax) {
                    errors[name] = `Maximum must be ${fieldMax}${unitText}`;
                    errorClasses[name] = "is-invalid";

                    this.setState({
                        errors,
                        errorClasses
                    });
                } else {
                    errors[name] = "";
                    errorClasses[name] = "";
                    this.setState({
                        errors,
                        errorClasses
                    });
                    if (!isNaN(value)) {
                        if (value !== undefined && value !== "") {
                            this.calculatePrice();
                        }
                    }
                }
            }
            if (filed === 'isNumeric' && /^[0-9]*$/.test(value) && (value === "" || parseInt(value, 0) <= 999999)) {
                const fieldMin = parseFloat(_this.min);
                const fieldMax = parseFloat(_this.max);
                value = parseFloat(value);
                let unitText = _this.getAttribute("data-unit");
                if (value < fieldMin) {
                    errors[name] = `Minimum must be ${fieldMin}${unitText}`;
                    errorClasses[name] = "is-invalid";

                    this.setState({
                        errors,
                        errorClasses
                    });
                } else if (value > fieldMax) {
                    errors[name] = `Maximum must be ${fieldMax}${unitText}`;
                    errorClasses[name] = "is-invalid";

                    this.setState({
                        errors,
                        errorClasses
                    });
                } else {
                    errors[name] = "";
                    errorClasses[name] = "";
                    this.setState({
                        errors,
                        errorClasses
                    });
                    if (!isNaN(value)) {
                        if (value !== undefined && value !== "") {
                            this.calculatePrice();
                        }
                    }
                }
            }
        } else {
            this.setState({
                customProjectName,
                productField,
                customTemplateProductField,
                customProductTemplate: '',
                productTemplate: ""
            });
        }
    }

    handleSwatchChange(e) {
        const {productField, customTemplateProductField, mar} = this.state
        const name = e.target.name;

        let printingMethodUpload = mar
        productField[name] = e.target.id;

        if (name in customTemplateProductField) {
            customTemplateProductField[name] = e.target.id;
        }
        let dataReload = e.target.getAttribute('data-refresh')
        let downloadable = e.target.getAttribute('data-downloadable')

        if (downloadable === 'true' && downloadable !== null) {
            printingMethodUpload = true

        } else if (downloadable === 'false' && downloadable !== null) {
            printingMethodUpload = false
        }

        if (dataReload === 'true') {
            this.setState({
                printingMethodUpload,
                mar: printingMethodUpload,
                productField,
                customTemplateProductField,
                customProductTemplate: '',
                productTemplate: ""
            });
            this.getProductChildren();

        } else {
            this.setState(
                {
                    mar: printingMethodUpload,
                    printingMethodUpload,
                    productField,
                    customTemplateProductField,
                    customProductTemplate: '',
                    productTemplate: ""
                },
                () => {
                    this.calculatePrice();
                }
            );
        }
    }

    handleCheckBoxChange(e) {
        const name = e.target.name;
        const productCheckBoxList = this.state.productCheckBoxList;
        const customTemplateCheckBoxList = this.state.customTemplateCheckBoxList;
        const errors = this.state.errors;
        errors[name] = ''

        let dataReload = e.target.getAttribute('data-refresh')
        if (e.target.checked) {
            productCheckBoxList[name][e.target.id] = true;
            if (name in customTemplateCheckBoxList) {
                customTemplateCheckBoxList[name][e.target.id] = true;
            }
        } else {
            productCheckBoxList[name][e.target.id] = false;
            if (name in customTemplateCheckBoxList) {
                customTemplateCheckBoxList[name][e.target.id] = false;
            }
        }
        if (dataReload === 'true') {
            this.setState({
                productCheckBoxList,
                customTemplateCheckBoxList,
                productTemplate: "",
                errors
            });
            this.getProductChildren();

        } else {
            this.setState(
                {
                    productCheckBoxList,
                    customTemplateCheckBoxList,
                    productTemplate: "",
                    errors
                },
                () => {
                    this.calculatePrice();
                }
            );
        }

    }

    dropdownChange(val, name) {

        let {producthasBothSide, bothSideUpload} = this.state
        const productField = this.state.productField;
        const customTemplateProductField = this.state.customTemplateProductField;
        let selectedOptionValue = val.value;
        let field = document.getElementsByName(`${name.name}`);

        let result = this.state.productOptions.filter(obj => {
            return obj.id === name.name;
        });

        let fieldName = name.name;

        if (result[0].need_reload === true) {
            productField[fieldName] = `${selectedOptionValue}`;
            if (fieldName in customTemplateProductField) {
                customTemplateProductField[fieldName] = `${selectedOptionValue}`;
            }
            this.setState({
                customTemplateProductField,
                productField,
                productTemplate: "",
                customProductTemplate: '',
            });
            this.getProductChildren();
        } else {
            if (val.pdf_file !== undefined && val.pdf_file !== "") {
                if (field[0].nextSibling) {
                    field[0].nextSibling.remove();
                }
                let pdfUrl = val.pdf_file;
                if (!field[0].nextSibling) {
                    let html = ` <a target="_blank" href="${pdfUrl}">
        <svg class="pdf-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681 679.28">
        <g>
        <g>
        <path class="cls-1" d="M116.61,181.54V53.31A36.31,36.31,0,0,1,152.92,17H627.69A36.31,36.31,0,0,1,664,53.31V626a36.31,36.31,0,0,1-36.31,36.31H152.92A36.31,36.31,0,0,1,116.61,626V499.54M584,463.85V217.22a35.69,35.69,0,0,0-35.69-35.68H52.69A35.69,35.69,0,0,0,17,217.22V463.85a35.69,35.69,0,0,0,35.69,35.69H548.31A35.69,35.69,0,0,0,584,463.85ZM103,423.54V262.77M269.2,423.54V262.77m237.8,0H436V423.54M103,357.73h36.52A47.48,47.48,0,0,0,187,310.25h0a47.48,47.48,0,0,0-47.48-47.48H103M269.2,423.54h33.13a52.31,52.31,0,0,0,52.31-52.31V315.07a52.3,52.3,0,0,0-52.31-52.3H269.2M496,342H436"/>
        </g>
        </g>
        </a> `;
                    let div = document.createElement("div");
                    div.innerHTML = html;
                    div.classList.add("option-pdf");
                    field[0].parentNode.appendChild(div);
                }
            } else {
                if (field[0].nextSibling) {
                    field[0].nextSibling.remove();
                }
            }


            if (producthasBothSide) {
                if (val.sku !== "" && val.sku !== undefined && val.sku === 'both_side') {
                    bothSideUpload = true
                }
                if (val.sku !== "" && val.sku !== undefined && val.sku === 'one_side') {
                    bothSideUpload = false
                }
            }
            productField[fieldName] = `${selectedOptionValue}`;
            if (fieldName in customTemplateProductField) {
                customTemplateProductField[fieldName] = `${selectedOptionValue}`;
            }
            this.setState(
                {
                    customTemplateProductField,
                    bothSideUpload,
                    productField,
                    customProductTemplate: '',
                    productTemplate: ""
                },
                () => {
                    this.calculatePrice();
                }
            );
        }
    }

    calculatePrice = async () => {
        let requestUrl = `/calcprice`;
        let {productId, productField, productCheckBoxList} = this.state;
        var selected = {};
        productField.map((item, key) => {
            return selected[key] = item;
        });
        Object.keys(productCheckBoxList).map(function (key, item) {
            // console.log(key)
            // console.log(productCheckBoxList[key])
            return selected[key] = productCheckBoxList[key]
        })
        const queryParams = {
            productId: productId,
            selected: JSON.stringify(selected)
        };
        // queryParams.productId = productId;
        // queryParams.selected = JSON.stringify(productField);
        let res = await axios.post(apiUrl + requestUrl, queryParams,{ headers: authHeader()});

        let {data} = res;
        if (data.error === null) {
            this.props.productPrice(data.payload);

            if (data.payload.priceInclTax > 0) {
                let loader = document.getElementById("price-loader");
                loader.classList.add("show");
            }
        }
    };

    templateDropdownChange(val, name) {
        // const productTemplate = this.state.productTemplate;
        let {templateOptions} = this.state;
        // console.log(val);

        let selectedOptionValue = val.value;
        let checkIfExist = templateOptions.find(
            opt => opt.value === Number(selectedOptionValue)
        );
        if (checkIfExist !== undefined) {
            this.setState(
                {
                    productTemplate: selectedOptionValue,
                    customProductTemplate: '',
                    errors: {},
                    errorClasses: {}
                },
                () => {
                    this.getTemplateData(selectedOptionValue);
                    if (this.state.productAdditionalUploadFile) {
                        this.getProductUploadedFiles2(this.state.productId)
                    }
                }
            );
        }
    }

    customTemplateDropdownChange(val, name) {
        let {customTemplateOptions} = this.state;

        let selectedOptionValue = val.value;
        let checkIfExist = customTemplateOptions.find(
            opt => opt.value === Number(selectedOptionValue)
        );
        if (checkIfExist !== undefined) {
            this.setState(
                {
                    customProductTemplate: selectedOptionValue,
                    productTemplate: "",
                    errors: {},
                    errorClasses: {}
                },
                () => {
                    this.getCustomTemplateData(selectedOptionValue);
                    if (this.state.productAdditionalUploadFile) {
                        this.getProductUploadedFiles2(this.state.productId)
                    }
                }
            );
        }
    }

    globalInfoHandleChange(e) {
        let _this = e.target;
        const productGlobalInfo = this.state.productGlobalInfo;
        let value = _this.value;
        const name = _this.name;

        let regEx =  /^[A-Za-z0-9\s\p{L}]*$/u;



        if(value.match(regEx)) {
            productGlobalInfo[name] = value;
            this.setState({
                productGlobalInfo,
                productTemplate: ""
            });
        }
    }

    closeLightbox() {
        this.setState({
            open: false
        });
    }

    zoomImage(e) {
        var imageUrl = e.target.getAttribute("data-image");
        if (imageUrl !== undefined || imageUrl !== "") {
            this.setState({
                open: true,
                urlToLargeImageFile: imageUrl
            });
        }
    }

    getTemplateData = async templateId => {
        let requestUrl = `/templates/getById/${templateId}`;

        let res = await axios.get(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            let optionJson = data.payload.optionsJson;
            // console.log(typeof optionJson);
            this.getProductChildren(optionJson);
            this.setState({
                productGlobalInfo: {
                    projectName: data.payload.projectName,
                    projectDescription: data.payload.projectDescription
                }
            });
        }
    };
    getCustomTemplateData = async templateId => {
        let requestUrl = `/customTemplates/getById/${templateId}`;

        let res = await axios.get(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            let optionJson = data.payload.optionsJson;
            this.getProductChildren(optionJson);
            this.setState({
                productGlobalInfo: {
                    projectName: '',
                    projectDescription: ''
                }
            });
        }
    };

    getOrderData = async orderId => {
        let requestUrl = `/orders/optionjson/${orderId}`;

        let res = await axios.get(apiUrl + requestUrl, {
            headers: authHeader()
        });

        let {data} = res;
        if (data.error === null) {
            let optionJson = data.payload.optionsJson;
            this.getProductChildren(optionJson);
            this.setState({
                productGlobalInfo: {
                    projectName: data.payload.projectName,
                    projectDescription: data.payload.projectDescription
                }
            });
        }
    };


    renderThumb({style, ...props}) {
        const thumbStyle = {
            right: 0,
            backgroundColor: `rgba(0, 0, 0, 0.2)`,
            cursor: `pointer`,
            borderRadius: `inherit`
        };
        return <div style={{...style, ...thumbStyle}} {...props} />;
    }

    handleUpdate(values) {
        const {top} = values;
        this.setState({top});
    }

    onCancel(e) {
    }


    getCustomersList = async (
        start = "0",
        end,
        pageNumber,
        reset = true
    ) => {
        if (reset === true) {
            this.setState({ customerList: [] });
        } else {
        }
        let requestUrl = `/customers/index`;
        let currentComponent = this;
        const queryParams = {};


        queryParams._start = start;
        queryParams._end = end;

        let res = await axios.get(apiUrl + requestUrl, {
            params: queryParams,
            headers: authHeader()
        });

        let { data } = res;
        if (data.error === null) {
            currentComponent._isMounted = true;
            let customerList = data.payload.customers.rows;
            let customerObj = {}
            let customers = []
             customerList.map((item) => {
                return customers.push(customerObj = {
                    value: item.id,
                    label: item.firstname + " " + item.lastname
                })
            })

            currentComponent.setState(
                {
                    customerList:customers
                }
            );
        }
    };
    selectedCustomerChange(val, meta) {
        var selectedOptionValue = val.value;
        const selectedCustomer = this.state.selectedCustomer;
        console.log("selectedOptionValue", selectedOptionValue)


        // var customer = "customer";
        // selectedCustomer[customer] = 8;
        this.setState({
            selectedCustomer:selectedOptionValue
        });

    }
    render() {
        let groupId = localStorage.getItem("groupId")
        if (
            this.state.productIsExist !== true ||
            this.state.productFieldReady !== true
        ) {
            return false;
        }

        const {
            errors,
            errorClasses,
            productGlobalInfo,
            productTemplate,
            customProductTemplate,
            showUploadModal,
            uploadErrorMsgText,
            templateOptions,
            templateField,
            customTemplateOptions,
            customTemplateField,
            productIsTesting,
            selectedFile,
            selectedFile2,
            uploadedFiles,
            uploadedFiles2,
            showFaildModal,
            showSuccessModal,
            showPermissionModal,
            producthasBothSide,
            bothSideUpload,
            faceSideCount,
            backSideCount,
            productHasCustomTemplate,
            productHasAttachFile,
            printingMethodUpload,
            customerList,
            selectedCustomer
        } = this.state;
        let currentUser = JSON.parse(localStorage.getItem("user"));
        return (
            <React.Fragment>
                <div id="global-loader" className="loader fixed circle">
                    <svg
                        width="200px"
                        height="200px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-rolling"
                    >
                        <circle
                            cx="50"
                            cy="50"
                            fill="none"
                            ng-attr-stroke="{{config.color}}"
                            ng-attr-stroke-width="{{config.width}}"
                            ng-attr-r="{{config.radius}}"
                            ng-attr-stroke-dasharray="{{config.dasharray}}"
                            stroke="#B8BE14"
                            strokeWidth="2"
                            r="30"
                            strokeDasharray="141.37166941154067 49.12388980384689"
                            transform="rotate(159.346 50 50)"
                        >
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                calcMode="linear"
                                values="0 50 50;360 50 50"
                                keyTimes="0;1"
                                dur="1.4s"
                                begin="0s"
                                repeatCount="indefinite"
                            ></animateTransform>
                        </circle>
                    </svg>
                </div>

                <ReactModal
                    isOpen={showUploadModal}
                    onRequestClose={this.hideUploadModal}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    portalClassName="modal upload"
                >
                    <React.Fragment>
                        <div id="popup-loader" className="loader circle">
                            <svg
                                width="200px"
                                height="200px"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                                className="lds-rolling"
                            >
                                <circle
                                    cx="50"
                                    cy="50"
                                    fill="none"
                                    stroke="#B8BE14"
                                    strokeWidth="2"
                                    r="30"
                                    strokeDasharray="141.37166941154067 49.12388980384689"
                                    transform="rotate(159.346 50 50)"
                                >
                                    <animateTransform
                                        attributeName="transform"
                                        type="rotate"
                                        calcMode="linear"
                                        values="0 50 50;360 50 50"
                                        keyTimes="0;1"
                                        dur="1.4s"
                                        begin="0s"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                            </svg>
                        </div>
                        <div className="title">
                            {this.state.uploadType === 'global' ?
                                producthasBothSide ?
                                    'Upload Face Side'
                                    :
                                    'Upload File'
                                :
                                this.state.uploadType === 'backSide' ?
                                    'Upload Back Side'
                                    : this.state.uploadType === 'printingMethod' ?
                                    'Upload Mounting Details'
                                    : 'Upload Die-Cut Template'}
                        </div>
                        <div className="description">
                            Supported file format: PSD, AI, INDD, PDF, TIFF, PNG, JPG, SLDPRT,
                            STL, OBJ, ZIP, RAR, VIDEO
                        </div>
                        <div
                            id="drop_file_zone"
                            onDragEnter={this.handleDragIn}
                            onDragLeave={this.handleDragOut}
                            onDragOver={this.handleDrag}
                            onDrop={this.handleDrop}
                        >
                            <div id="drag_upload_file">
                                <p className="drop-title">Drop file here</p>
                                <p className="btn-wrap">
                                    <span>or </span>{" "}
                                    <span
                                        className="selectBtn"
                                        onClick={this.handleSelectFileClick}
                                    >
                    Select File
                  </span>
                                </p>
                                <input
                                    type="file"
                                    id="selectFile"
                                    name="files[]"
                                    data-value=""
                                    multiple
                                    onChange={this.handleInputFileChange}
                                />
                            </div>
                            <label className="error">{uploadErrorMsgText}</label>
                        </div>
                        <div className="picker-row">
                            <GooglePicker
                                onToken={token => this.setState({googleToken: token})}
                                onSuccess={this.googleOnSuccess}
                            />
                            <Dropbox onSuccess={this.dropBoxOnSuccess}/>

                        </div>
                        <Scrollbars
                            autoHeight
                            autoHeightMax={150}
                            renderThumbVertical={this.renderThumb}
                        >
                            <div id="file-box" className="file-box">
                                {selectedFile.length > 0
                                    ? selectedFile.map((item, key) => {
                                        let string = item.file;
                                        let result = string.substring(
                                            string.lastIndexOf("/") + 1
                                        );
                                        return (
                                            <div className="item" key={key} data-id={item.id}>
                                                <span className="name">{result}</span>
                                                <span
                                                    data-id={item.id}
                                                    data-type="popup"
                                                    onClick={this.handleRemoveFile}
                                                    className="remove"
                                                >
                            remove
                          </span>
                                            </div>
                                        );
                                    })
                                    : ""}
                                {selectedFile2.length > 0
                                    ? selectedFile2.map((item, key) => {
                                        let string = item.file;
                                        let result = string.substring(
                                            string.lastIndexOf("/") + 1
                                        );
                                        return (
                                            <div className="item" key={key} data-id={item.id}>
                                                <span className="name">{result}</span>
                                                <span
                                                    data-id={item.id}
                                                    data-type="popup"
                                                    onClick={this.handleRemoveFile2}
                                                    className="remove"
                                                >
                            remove
                          </span>
                                            </div>
                                        );
                                    })
                                    : ""}
                            </div>
                        </Scrollbars>
                        <div className="button-row">
                            <div onClick={this.hideUploadModal}>Discard</div>
                            <div onClick={this.handleSaveClick}>Save</div>
                        </div>
                    </React.Fragment>
                </ReactModal>

                <ReactModal
                    isOpen={showSuccessModal}
                    onRequestClose={this.hideSuccessModal}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={false}
                    portalClassName="modal product-success"
                >
                    <div className="icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="57"
                            height="57"
                            viewBox="0 0 57 57"
                        >
                            <g>
                                <path
                                    fill="#7ed321"
                                    d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                                />
                            </g>
                        </svg>
                    </div>
                    <div className="title">Project Successfully Created</div>
                    <div className="text">
                        You will be redirected to dashboard shortly.
                    </div>
                </ReactModal>
                <ReactModal
                    isOpen={showFaildModal}
                    onRequestClose={this.hideFaildModal}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={true}
                    portalClassName="modal product-success"
                >
                    <div className="icon">
                        <svg
                            width="57px"
                            height="57px"
                            viewBox="0 0 57 57"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <g
                                    id="Splash-Copy"
                                    transform="translate(-159.000000, -201.000000)"
                                >
                                    <g
                                        id="ic_error_24px"
                                        transform="translate(159.000000, 201.000000)"
                                    >
                                        <mask id="mask-2" fill="white">
                                            <path
                                                d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                                                id="path-1"
                                            ></path>
                                        </mask>
                                        <g id="Shape" fillRule="nonzero"></g>
                                        <g mask="url(#mask-2)" fill="#EE1D4E">
                                            <g
                                                transform="translate(-5.000000, -5.000000)"
                                                id="Rectangle"
                                            >
                                                <rect x="0" y="0" width="69" height="69"></rect>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <div className="title">
                        Something went wrong please try again later
                    </div>
                </ReactModal>

                <ReactModal
                    isOpen={showPermissionModal}
                    onRequestClose={this.hidePermissionModal}
                    ariaHideApp={false}
                    shouldCloseOnOverlayClick={true}
                    portalClassName="modal product-success"
                >
                    <div className="icon">
                        <svg
                            width="57px"
                            height="57px"
                            viewBox="0 0 57 57"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g
                                id="Page-1"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                            >
                                <g
                                    id="Splash-Copy"
                                    transform="translate(-159.000000, -201.000000)"
                                >
                                    <g
                                        id="ic_error_24px"
                                        transform="translate(159.000000, 201.000000)"
                                    >
                                        <mask id="mask-2" fill="white">
                                            <path
                                                d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                                                id="path-1"
                                            ></path>
                                        </mask>
                                        <g id="Shape" fillRule="nonzero"></g>
                                        <g mask="url(#mask-2)" fill="#EE1D4E">
                                            <g
                                                transform="translate(-5.000000, -5.000000)"
                                                id="Rectangle"
                                            >
                                                <rect x="0" y="0" width="69" height="69"></rect>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>

                    <div className="title">
                        You do not have permission to submit order.
                    </div>
                </ReactModal>

                <div className="option-box">
                    <div id="loader" className="loader  circle">
                        <svg
                            width="200px"
                            height="200px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                            className="lds-rolling"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                ng-attr-stroke="{{config.color}}"
                                ng-attr-stroke-width="{{config.width}}"
                                ng-attr-r="{{config.radius}}"
                                ng-attr-stroke-dasharray="{{config.dasharray}}"
                                stroke="#B8BE14"
                                strokeWidth="2"
                                r="30"
                                strokeDasharray="141.37166941154067 49.12388980384689"
                                transform="rotate(159.346 50 50)"
                            >
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    calcMode="linear"
                                    values="0 50 50;360 50 50"
                                    keyTimes="0;1"
                                    dur="1.4s"
                                    begin="0s"
                                    repeatCount="indefinite"
                                ></animateTransform>
                            </circle>
                        </svg>
                    </div>
                    {groupId === "3" ? (
                        <div className="row">
                            <div className="item">
                                <div className="label"><span className="name"> Customer</span></div>
                                <Select
                                    classNamePrefix="react-select"
                                    isMulti={false}
                                    isSearchable={false}
                                    value={[
                                        customerList.find(
                                            opt => opt.value === selectedCustomer
                                        )
                                    ]}
                                    name="customer"
                                    options={customerList}
                                    onChange={(newValue, actionMeta) =>
                                        this.selectedCustomerChange(newValue, actionMeta)
                                    }
                                    className={errorClasses.productTemplate + " react-select"}
                                    placeholder="Please Select Template"
                                />
                                <div className="invalid-feedback">{errors.productTemplate}</div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {templateField ? (
                        <div className="row">
                            <div className="item">
                                <div className="label"><span className="name"> Product Template</span></div>
                                <Select
                                    classNamePrefix="react-select"
                                    isMulti={false}
                                    isSearchable={false}
                                    value={[
                                        templateOptions.find(
                                            opt => opt.value === Number(productTemplate)
                                        )
                                    ]}
                                    name="productTemplate"
                                    options={templateOptions}
                                    onChange={(newValue, actionMeta) =>
                                        this.templateDropdownChange(newValue, actionMeta)
                                    }
                                    className={errorClasses.productTemplate + " react-select"}
                                    placeholder="Please Select Template"
                                />
                                <div className="invalid-feedback">{errors.productTemplate}</div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {customTemplateField ? (
                        <div className="row">
                            <div className="item">
                                <div className="label"><span className="name">MY PRINTING PRESS DETAILS</span></div>
                                <Select
                                    classNamePrefix="react-select"
                                    isMulti={false}
                                    isSearchable={false}
                                    value={[
                                        customTemplateOptions.find(
                                            opt => opt.value === Number(customProductTemplate)
                                        )
                                    ]}
                                    name="productTemplate"
                                    options={customTemplateOptions}
                                    onChange={(newValue, actionMeta) =>
                                        this.customTemplateDropdownChange(newValue, actionMeta)
                                    }
                                    className={errorClasses.customProductTemplate + " react-select"}
                                    placeholder="Please Select Template"
                                />
                                <div className="invalid-feedback">{errors.customProductTemplate}</div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="row">
                        <div className="item">
                            <div className="label"><span className="name">Project Name</span> <span
                                className="sign">*</span></div>

                            <input
                                value={productGlobalInfo.projectName}
                                onKeyPress={this.handleKeyPress}
                                type="text"
                                name="projectName"
                                className={errorClasses.projectName}
                                onChange={this.globalInfoHandleChange}
                            />
                            <div className="invalid-feedback">{errors.projectName}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="item">
                            <div className="label"><span className="name">Project Description</span> <span
                                className="sign">*</span></div>
                            <textarea
                                value={productGlobalInfo.projectDescription}
                                onKeyPress={this.handleKeyPress}
                                name="projectDescription"
                                className={errorClasses.projectDescription}
                                onChange={this.globalInfoHandleChange}
                            ></textarea>

                            <div className="invalid-feedback">
                                {errors.projectDescription}
                            </div>
                        </div>
                    </div>

                    {this.drawOptions()}
                    {productIsTesting ?
                        productHasCustomTemplate ? (
                            <div className="row">
                                <div className="item">
                                    <input
                                        autoComplete="new-saveAsTemplate"
                                        className="custom-checkbox"
                                        id="saveForCustomTemplate"
                                        onChange={this.handelCustomTemplateChecked}
                                        type="checkbox"
                                        name="saveForCustomTemplate"
                                    />
                                    <label htmlFor="saveForCustomTemplate" className="custom-lable">
                                        Save My Printing Press Details
                                    </label>
                                </div>
                            </div>
                        ) : (
                            ''
                        )
                        : (
                            <React.Fragment>
                                <div className="row">
                                    <div className="item">
                                        <input
                                            autoComplete="new-saveAsTemplate"
                                            className="custom-checkbox"
                                            id="saveAsTemplate"
                                            onChange={this.handelChecked}
                                            type="checkbox"
                                            name="saveAsTemplate"
                                        />
                                        <label htmlFor="saveAsTemplate" className="custom-lable">
                                            Save project details as a template
                                        </label>
                                    </div>
                                </div>
                                {productHasCustomTemplate ? (
                                    <div className="row">
                                        <div className="item">
                                            <input
                                                autoComplete="new-saveAsTemplate"
                                                className="custom-checkbox"
                                                id="saveForCustomTemplate"
                                                onChange={this.handelCustomTemplateChecked}
                                                type="checkbox"
                                                name="saveForCustomTemplate"
                                            />
                                            <label htmlFor="saveForCustomTemplate" className="custom-lable">
                                                Save My Printing Press Details
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )
                                }

                                {currentUser ? (
                                    <React.Fragment>
                                        <div className="row row-half button-row">
                                            <div className="item">
                                                <div
                                                    onClick={this.showUploadModal}
                                                    className="attach-btn"
                                                >
                                                    {producthasBothSide ? 'Upload Face Side' : 'Upload Your File'}
                                                </div>
                                            </div>
                                            {bothSideUpload ? (
                                                <div className="item">
                                                    <div
                                                        onClick={this.showUploadModal}
                                                        className="attach-btn"
                                                        data-type="back-side"
                                                    >
                                                        Upload Back Side
                                                    </div>
                                                </div>
                                            ) : ('')}
                                            {printingMethodUpload && productHasAttachFile ? (
                                                <div className="item">
                                                    <div
                                                        onClick={this.showUploadModal}
                                                        className="attach-btn"
                                                        data-type="printing-method"
                                                    >
                                                        Upload Mounting Details PDF
                                                    </div>
                                                </div>
                                            ) : ('')}
                                            {this.state.productAdditionalUploadFile ? (
                                                <div className="item">
                                                    <div
                                                        onClick={this.showUploadModal}
                                                        className="attach-btn"
                                                        data-type="additional-file"
                                                    >
                                                        Upload Label Die-Cut Template if available
                                                    </div>
                                                </div>
                                            ) : ('')}
                                        </div>
                                        <div className="clear-div"></div>

                                        {uploadedFiles.length > 0 ?
                                            faceSideCount > 0 && producthasBothSide ? (
                                                <div className="uploaded-section-title">Your Files</div>
                                            ) : ('')
                                            :
                                            ('')
                                        }
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={260}
                                            renderThumbVertical={this.renderThumb}
                                            className="uploaded-files-box"

                                        >
                                            <div className="uploaded-files">
                                                {uploadedFiles.length > 0
                                                    ? uploadedFiles.map((item, key) => {
                                                        let string = item.file;
                                                        let result = string.substring(
                                                            string.lastIndexOf("/") + 1
                                                        );
                                                        if (item.bothSide) {
                                                            return false
                                                        }
                                                        return (
                                                            <div className="item" key={key} data-id={item.id}>
                                  <span
                                      className="image-box"
                                      onClick={this.zoomImage}
                                      data-image={item.file}
                                      style={{
                                          backgroundImage: `url(${item.thumbnail})`
                                      }}
                                  >
                                    <img
                                        onClick={this.zoomImage}
                                        data-image={item.file}
                                        alt=""
                                        src={item.thumbnail}
                                    />
                                  </span>
                                                                <span className="name">{result}</span>
                                                                <span
                                                                    data-id={item.id}
                                                                    data-type="face"
                                                                    onClick={this.handleRemoveFile}
                                                                    className="remove"
                                                                >
                                    remove
                                  </span>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div>
                                        </Scrollbars>
                                        {(uploadedFiles.length > 0 && bothSideUpload && backSideCount > 0) ? (
                                            <div className="uploaded-section-title">Back Side Files</div>
                                        ) : ('')}
                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={260}
                                            renderThumbVertical={this.renderThumb}
                                            className="uploaded-files-box"

                                        >
                                            <div className="uploaded-files">
                                                {uploadedFiles.length > 0 && bothSideUpload > 0
                                                    ? uploadedFiles.map((item, key) => {
                                                        let string = item.file;
                                                        let result = string.substring(
                                                            string.lastIndexOf("/") + 1
                                                        );
                                                        if (!item.bothSide) {
                                                            return false
                                                        }
                                                        return (
                                                            <div className="item" key={key} data-id={item.id}>
                                <span
                                    className="image-box"
                                    onClick={this.zoomImage}
                                    data-image={item.file}
                                    style={{
                                        backgroundImage: `url(${item.thumbnail})`
                                    }}
                                >
                                  <img
                                      onClick={this.zoomImage}
                                      data-image={item.file}
                                      alt=""
                                      src={item.thumbnail}
                                  />
                                </span>
                                                                <span className="name">{result}</span>
                                                                <span
                                                                    data-id={item.id}
                                                                    data-type="back"
                                                                    onClick={this.handleRemoveFile}
                                                                    className="remove"
                                                                >
                                  remove
                                </span>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div>
                                        </Scrollbars>
                                        {uploadedFiles2.length > 0 ? (
                                            <div className="uploaded-section-title">Die-Cut Template</div>
                                        ) : ('')}

                                        <Scrollbars
                                            autoHeight
                                            autoHeightMax={260}
                                            renderThumbVertical={this.renderThumb}
                                            className="uploaded-files-box"
                                        >
                                            <div className="uploaded-files">
                                                {uploadedFiles2.length > 0
                                                    ? uploadedFiles2.map((item, key) => {
                                                        let string = item.file;
                                                        let result = string.substring(
                                                            string.lastIndexOf("/") + 1
                                                        );
                                                        return (
                                                            <div className="item" key={key} data-id={item.id}>
                                <span
                                    className="image-box"
                                    onClick={this.zoomImage}
                                    data-image={item.file}
                                    style={{
                                        backgroundImage: `url(${item.thumbnail})`
                                    }}
                                >
                                  <img
                                      onClick={this.zoomImage}
                                      data-image={item.file}
                                      alt=""
                                      src={item.thumbnail}
                                  />
                                </span>
                                                                <span className="name">{result}</span>
                                                                <span
                                                                    data-id={item.id}
                                                                    data-type=""
                                                                    onClick={this.handleRemoveFile2}
                                                                    className="remove"
                                                                    data-templateid={item.templateId !== undefined ? item.templateId : ''}
                                                                >
                                  remove
                                </span>
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </div>
                                        </Scrollbars>
                                    </React.Fragment>
                                ) : (
                                    ""
                                )}
                            </React.Fragment>
                        )}
                </div>
                {this.state.open ? (
                    <Lightbox
                        medium={this.state.urlToLargeImageFile}
                        onClose={this.closeLightbox}
                        hideDownload={false}
                    />
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }
}

export default ProductOptions;
