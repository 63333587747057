import React, { Component } from "react";
import GlobalLoader from "../../globalLoader";
import Select from "react-select";

import { authHeader } from "../../../_helpers";
import axios from "axios";
import ReactModal from "react-modal";
import validator from "validator";
import FormValidator from "../../validations";
const apiUrl = process.env.REACT_APP_API;

class AddressForm extends Component {
  constructor(props) {
    super(props);
    const isEdit = props.isEdit;
    const addressesId = props.addressesId;
    this.state = {
      addressData: {
        firstName: "",
        lastName: "",
        middleName: "",
        company: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phoneNumber: "",
        fax: "",
        defaultBilling: false,
        defaultShipping: false,
        saveAsShipping: false
      },
      errors: [],
      errorClasses: [],
      isEdit,
      countries: [],
      isSuccess: false,
      isDelete: false,
      isEdited: false,
      addressesId,
      showModal: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleAddAddress = this.handleAddAddress.bind(this);

    this.handleFormView = this.handleFormView.bind(this);

    this.getCountryList = this.getCountryList.bind(this);
    this.countryOptions = this.countryOptions.bind(this);
    this.countryChange = this.countryChange.bind(this);
    this.getAddressById = this.getAddressById.bind(this);

    this.handleDeleteAddress = this.handleDeleteAddress.bind(this);

    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.hideDeleteModal = this.hideDeleteModal.bind(this);
    this.handleDropDownOpenClose = this.handleDropDownOpenClose.bind(this);
  }
  _isLoaded = false;
  _isMounted = false;
  componentDidMount() {
    this._isMounted = true;
    this.getCountryList();
  }
  getAddressById() {
    axios
      .get(apiUrl + "/addresses/editData/" + this.state.addressesId, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.error === null) {
          let data = response.data.payload.address;
          let middleName = "";
          if (data.middlename !== undefined) {
            middleName = data.middlename;
          }
          let addressData = {
            firstName: data.firstname,
            lastName: data.lastname,
            middleName: middleName,
            company: data.company,
            address: data.street,
            city: data.city,
            state: data.region,
            zip: data.postcode,
            country: data.countryId,
            phoneNumber: data.telephone,
            fax: data.fax,
            defaultBilling: data.isDefaultBilling,
            defaultShipping: data.isDefaultShipping,
            saveAsShipping: data.useAsShipping
          };
          this.setState(
            {
              addressData
            },
            () => {
              // this._isLoaded = true
            }
          );
        }
      });
  }

  handleDropDownOpenClose(param) {
    let target = document.getElementsByClassName("country-drop-down");
    if (param === true) {
      target[0].classList.add("open");
    } else {
      target[0].classList.remove("open");
    }
  }

  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First Name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last Name field is required."
      },
      {
        field: "address",
        method: validator.isEmpty,
        validWhen: false,
        message: "Address field is required."
      },
      {
        field: "city",
        method: validator.isEmpty,
        validWhen: false,
        message: "City field is required."
      },
      {
        field: "phoneNumber",
        method: validator.isEmpty,
        validWhen: false,
        message: "Phone Number field is required."
      },
      {
        field: "zip",
        method: validator.isEmpty,
        validWhen: false,
        message: "Zip Code field is required."
      },
      // {
      //   field: "fax",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "Fax field is required."
      // },
      // {
      //   field: "state",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "State field is required."
      // },
      {
        field: "country",
        method: validator.isEmpty,
        validWhen: false,
        message: "Cpontry field is required."
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.addressData);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  showDeleteModal() {
    this.setState({
      showModal: true
    });
  }
  hideDeleteModal() {
    this.setState({
      showModal: false
    });
  }
  handleDeleteAddress() {
    axios
      .delete(`${apiUrl}/addresses/delete/${this.state.addressesId}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.error === null) {
          // console.log(response.data)
          this.setState(
            {
              isSuccess: true,
              isDelete: true
            },
            () => {
              this.handleSuccess();
            }
          );
        }
      });
  }
  handleChange(e) {
    const name = e.target.name;
    const addressData = this.state.addressData;
    const errors = this.state.errors;
    const errorClasses = this.state.errorClasses;

    if (isNaN(e.target.value) && (name === "phoneNumber")) {
      errors[name] = "Type only number";
      errorClasses[name] = "is-invalid";
      this.setState({
        errors
      });
    }else if(name === "zip"){
      let val = /^[a-z0-9]+$/i.test(e.target.value)
      errors[name] = "";
      errorClasses[name] = "";
      if(val || e.target.value === '') {
        addressData[name] = e.target.value;
        this.setState({
          addressData,
          errors,
          errorClasses
        });
      }
    } else {
      addressData[name] = e.target.value;
      errors[name] = "";
      errorClasses[name] = "";
      this.setState({
        addressData,
        errors,
        errorClasses
      });
    }
  }
  getCountryList() {
    axios.get(apiUrl + "/countries/index").then(response => {
      if (response.data.error === null) {
        let countries = response.data.payload.countries;
        this.setState(
          {
            countries
          },
          () => {
            this.countryOptions();
            this._isLoaded = true;

            if (this.state.isEdit === true) {
              // console.log('edit')
              this.getAddressById();
            }
          }
        );
      }
    });
  }
  countryOptions() {
    var countryList = this.state.countries;
    var option = {};
    var sayHello = Object.keys(countryList).map(function(i) {
      option = {
        value: countryList[i].id,
        label: countryList[i].country_name
      };
      return option;
    });

    return sayHello;
  }


  countryChange(val, name) {
    var selectedOptionValue = val.value;
    const addressData = this.state.addressData;

    var country = "country";
    addressData[country] = selectedOptionValue;
    this.setState({
      addressData
    });
  }
  handelChecked(e) {
    const addressData = this.state.addressData;

    const name = e.target.name;

    if (e.target.checked) {
      if (name === "defaultShipping") {
        const defaultShipping = "defaultShipping";
        const saveAsShipping = "saveAsShipping";
        addressData[saveAsShipping] = true;
        addressData[defaultShipping] = true;
      } else {
        addressData[name] = true;
      }
      this.setState({
        addressData
      });
    } else {
      if (name === "saveAsShipping") {
        const defaultShipping = "defaultShipping";
        const saveAsShipping = "saveAsShipping";
        addressData[saveAsShipping] = false;
        addressData[defaultShipping] = false;
      } else {
        addressData[name] = false;
      }
      this.setState({
        addressData
      });
    }
  }

  handleEditAddress(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      let currentComponent = this;
      let { addressData } = this.state;
      let data = {
        countryId: addressData.country,
        city: addressData.city,
        firstname: addressData.firstName,
        lastname: addressData.lastName,
        middlename: addressData.middleName,
        company: addressData.company,
        fax: addressData.fax,
        postcode: addressData.zip,
        region: addressData.state,
        street: addressData.address,
        telephone: addressData.phoneNumber,
        isDefaultBilling: addressData.defaultBilling,
        isDefaultShipping: addressData.defaultShipping,
        useAsShipping: addressData.saveAsShipping,
        id: this.state.addressesId
      };

      axios
        .post(`${apiUrl}/addresses/update`, data, {
          headers: authHeader()
        })
        .then(response => {
          if (response.data.error === null) {
            currentComponent.setState(
              {
                isSuccess: true,
                isEdited: true
              },
              currentComponent.handleSuccess()
            );
          }
        });
    }
  }
  handleAddAddress(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      let currentComponent = this;
      let { addressData } = this.state;
      let data = {
        countryId: addressData.country,
        city: addressData.city,
        firstname: addressData.firstName,
        lastname: addressData.lastName,
        middlename: addressData.middleName,
        company: addressData.company,
        fax: addressData.fax,
        postcode: addressData.zip,
        region: addressData.state,
        street: addressData.address,
        telephone: addressData.phoneNumber,
        isDefaultBilling: addressData.defaultBilling,
        isDefaultShipping: addressData.defaultShipping,
        useAsShipping: addressData.saveAsShipping
      };

      axios
        .post(`${apiUrl}/addresses/create`, data, {
          headers: authHeader()
        })
        .then(response => {
          if (response.data.error === null) {
            currentComponent.setState(
              {
                isSuccess: true
              },
              currentComponent.handleSuccess()
            );
          }
        });
    }
  }

  handleSuccess() {
    this.props.formSuccess(true);
  }

  handleFormView() {
    this.props.closeFormView(true);
  }
  render() {
    if (this.state.isEdit === true) {
      if (!this._isLoaded) {
        return <GlobalLoader loaderState={false} />;
      }
    }
    const {
      errors,
      showModal,
      errorClasses,
      addressData,
      isEdit,
      isSuccess,
      isDelete,
      isEdited
    } = this.state;

    let textInfo = "added";

    if (isDelete) {
      textInfo = "deleted";
    }
    if (isEdited) {
      textInfo = "edited";
    }
    let data = this.countryOptions();
    return isSuccess ? (
      <div className="thank-you">
        <div className="table">
          <div className="cell">
            <div className="title">Thank you</div>
            <div className="text">Your address was successful {textInfo}</div>
          </div>
        </div>
      </div>
    ) : (
      <div className="wrapper form-wrapper form">
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal delete"
        >
          <div className="title">Are you sure!!</div>
          <div className="btn" onClick={this.hideDeleteModal}>
            No
          </div>
          <div className="btn" onClick={this.handleDeleteAddress}>
            Yes
          </div>
        </ReactModal>
        <div className="row row-3 clear">
          <div className="item">
            <div className="label">Full Name</div>
            <input
              autoComplete="new-firstName"
              value={addressData.firstName}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.firstName = el)}
              name="firstName"
              className={errorClasses.firstName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.firstName}</div>
          </div>
          <div className="item">
            <div className="label">Middle Name (optional)</div>
            <input
              autoComplete="new-middleName"
              value={addressData.middleName}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.middleName = el)}
              name="middleName"
              className={errorClasses.middleName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.middleName}</div>
          </div>
          <div className="item">
            <div className="label">Last Name</div>
            <input
              autoComplete="new-lastName"
              value={addressData.lastName}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.lastName = el)}
              name="lastName"
              className={errorClasses.lastName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.lastName}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">COMPANY (OPTIONAL)</div>
            <input
              autoComplete="new-company"
              value={addressData.company}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.company = el)}
              name="company"
              className={errorClasses.company}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.company}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">ADDRESS</div>
            <input
              autoComplete="new-address"
              value={addressData.address}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.address = el)}
              name="address"
              className={errorClasses.address}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.address}</div>
          </div>
        </div>
        <div className="row row-2 clear">
          <div className="item">
            <div className="label">CITY</div>
            <input
              autoComplete="new-city"
              value={addressData.city}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.city = el)}
              name="city"
              className={errorClasses.city}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.city}</div>
          </div>
          <div className="item">
            <div className="label">STATE/PROVINCE</div>
            <input
              autoComplete="new-state"
              value={addressData.state}
              onKeyPress={this.handleKeyPress}
              type="text"
              name="state"
              className={errorClasses.state}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.state}</div>
          </div>
        </div>
        <div className="row row-2 clear">
          <div className="item">
            <div className="label">ZIP/POSTAL CODE</div>
            <input
              autoComplete="new-zip"
              value={addressData.zip}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.zip = el)}
              name="zip"
              className={errorClasses.zip}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.zip}</div>
          </div>
          <div className="item">
            <div className="label">COUNTRY</div>
            <Select
              classNamePrefix="react-select"
              isMulti={false}
              isSearchable={false}
              value={[data.find(opt => opt.value === addressData.country)]}
              name="country"
              options={data}
              onChange={(newValue, actionMeta) =>
                this.countryChange(newValue, actionMeta)
              }
              className={
                errorClasses.country +
                " react-select country-drop-down"
              }
              placeholder=""
            />
            <div className="invalid-feedback">{errors.country}</div>
          </div>
        </div>
        <div className="row row-2 clear">
          <div className="item">
            <div className="label">PHONE</div>
            <input
              autoComplete="new-phoneNumber"
              value={addressData.phoneNumber}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.phoneNumber = el)}
              name="phoneNumber"
              className={errorClasses.phoneNumber}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.phoneNumber}</div>
          </div>
          <div className="item">
            <div className="label">FAX</div>
            <input
              autoComplete="new-fax"
              value={addressData.fax}
              onKeyPress={this.handleKeyPress}
              type="text"
              ref={el => (this.fax = el)}
              name="fax"
              className={errorClasses.fax}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.fax}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <input
              className="custom-checkbox"
              checked={addressData.saveAsShipping}
              id="saveAsShipping"
              onChange={this.handelChecked}
              type="checkbox"
              ref={el => (this.saveAsShipping = el)}
              name="saveAsShipping"
            />
            <label htmlFor="saveAsShipping" className="custom-lable">
              Save Also as Shipping Address
            </label>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <input
              className="custom-checkbox"
              id="defaultShipping"
              onChange={this.handelChecked}
              type="checkbox"
              ref={el => (this.defaultShipping = el)}
              checked={addressData.defaultShipping}
              name="defaultShipping"
            />
            <label htmlFor="defaultShipping" className="custom-lable">
              Use as default Shipping Address
            </label>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <input
              className="custom-checkbox"
              id="defaultBilling"
              onChange={this.handelChecked}
              type="checkbox"
              ref={el => (this.defaultBilling = el)}
              checked={addressData.defaultBilling}
              name="defaultBilling"
            />
            <label htmlFor="defaultBilling" className="custom-lable">
              Use as default Billing Address
            </label>
          </div>
        </div>
        {isEdit ? (
          <div className="row clear">
            <div className="delete" onClick={this.showDeleteModal}>
              Delete Address
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="button-row">
          <div onClick={this.handleFormView}>Discard</div>
          {isEdit ? (
            <div onClick={this.handleEditAddress}>Save changes</div>
          ) : (
            <div onClick={this.handleAddAddress}>Add</div>
          )}
        </div>
      </div>
    );
  }
}
export default AddressForm;
