import React, { Component } from "react";
import GlobalLoader from "../../globalLoader";
import { Link } from "react-router-dom";

import axios from "axios";
import { authHeader } from "../../../_helpers";
import {Helmet} from "react-helmet";

import ReactModal from "react-modal";
const apiUrl = process.env.REACT_APP_API;
class ReorderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuccessModal: false,
      showFaildModal: false,
      _isLoaded: false,
      orderSpecs: {},
      orderInfo: {},
      orderColors: {},
      colorsField: [],
      reorderSpecificColors: false,
      price: 0,
      colorErrorMessage: ""
    };
    this.handelChecked = this.handelChecked.bind(this);
    this.handleReorder = this.handleReorder.bind(this);
    this.colorsValidation = this.colorsValidation.bind(this);
    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.hideSuccessModal = this.hideSuccessModal.bind(this);

    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);
    this.handelReorderSpecificColors = this.handelReorderSpecificColors.bind(
      this
    );
  }
  hideSuccessModal() {
    this.setState({
      showSuccessModal: false
    });
  }
  showSuccessModal() {
    this.setState({
      showSuccessModal: true
    });
  }
  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  componentDidMount() {
    document.body.classList.add("order-page", "order-reorder");
    

    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let REORDER;
    if(userPermission) {
      REORDER = userPermission.find(
        opt => opt.key === 'REORDER'
      )
    }
    if(currentUser.isMember === false || (currentUser.isMember === true && REORDER !== undefined)){
      this.getOrderSpecs();
      window.scroll(0, 0);
    }else {
      window.location = "/not-found";
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("order-page", "order-reorder");
  }

  handelChecked = e => {
    let { colorsField, price, colorPrice } = this.state;
    this.setState({
      colorErrorMessage: ""
    });
    if (e.target.checked) {
      colorsField.push(e.target.name);
      price = colorsField.length * colorPrice;
      this.setState({ colorsField, price });
    } else {
      const index = colorsField.indexOf(e.target.name);
      if (index > -1) {
        colorsField.splice(index, 1);
      }
      price = colorsField.length * colorPrice;
      this.setState({ colorsField, price });
    }
  };
  handleReorder = async e => {
    let { reorderSpecificColors, colorsField } = this.state;

    let orderWithoutChanges = reorderSpecificColors ? 0 : 1;
    let postData = {
      colors: JSON.stringify(colorsField),
      orderWithoutChanges: orderWithoutChanges
    };
    let loader = document.getElementById("loader");

    if (this.colorsValidation()) {
      loader.classList.add("show");
      let requestUrl = `/orders/reorder/submit/${this.props.match.params.id}`;
      let res = await axios.post(apiUrl + requestUrl, postData, {
        headers: authHeader()
      });

      let { data } = res;
      if (data.error === null) {
        if (loader.classList.contains("show")) {
          loader.classList.remove("show");
        }
        this.showSuccessModal();
        setTimeout(() => {
          window.location = "/dashboard";
        }, 3000);
      } else {
        if (loader.classList.contains("show")) {
          loader.classList.remove("show");
        }
        this.setState({
          showFaildModal: true
        });
      }
    }
  };

  colorsValidation() {
    let { reorderSpecificColors, colorsField } = this.state;
    if (reorderSpecificColors) {
      if (colorsField.length > 0) {
        return true;
      } else {
        this.setState({
          colorErrorMessage: "Plase select at last one color"
        });
      }
    } else {
      return true;
    }
  }
  handelReorderSpecificColors = e => {
    this.setState({
      reorderSpecificColors: true
    });
  };
  getOrderSpecs = async e => {
    // let loader = document.getElementById("loader");
    let requestUrl = `/orders/reorder/${this.props.match.params.id}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      let orderSpecs = data.payload.options;
      let projectName = data.payload.projectName;
      let projectDescription = data.payload.projectDescription;
      let options = this.configOptions(orderSpecs);
      let colors = data.payload.colors;
      let colorPrice = data.payload.oneColorPrice;
      let files = data.payload.files
      let additionalFiles = data.payload.additionalFiles !== undefined ? data.payload.additionalFiles : ''
      this.setState(
        {
          orderSpecs: options,
          projectName,
          projectDescription,
          orderInfo: data.payload,
          orderColors: colors,
          colorPrice,
          files,
          additionalFiles,
          _isLoaded: true
        },
        () => {
          //   if (loader.classList.contains("show")) {
          //     loader.classList.remove("show");
          //   }
        }
      );
    } else {
      window.location = "/not-found";
    }
  };

  configOptions(options) {
    if (options.length === 0) return [];
    const customizedOptions = [];
    const oneFieldsOptions = ["swatch", "textarea"];
    for (let i = 0; i <= options.length - 1; i++) {
      const item = options[i];
      if (oneFieldsOptions.includes(item.fieldType)) {
      } else {
        const lastItem = customizedOptions[customizedOptions.length - 1];
        if (
          lastItem &&
          lastItem !== undefined &&
          lastItem.half &&
          lastItem.items.length === 1
        ) {
          customizedOptions[customizedOptions.length - 1].items.push(item);
        } else {
          customizedOptions.push({
            items: [item],
            half: true
          });
        }
      }
    }
    return customizedOptions;
  }
  render() {
    let {
      orderSpecs,
      orderInfo,
      _isLoaded,
      projectName,
      projectDescription,
      showSuccessModal,
      showFaildModal,
      files,
      additionalFiles
    } = this.state;
    if (_isLoaded !== true) {
      return <GlobalLoader loaderState={false} />;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Reorder | Flexoeasy.com</title>
          <meta name="title" content={'Reorder | Flexoeasy.com'} />
          <meta name="description" content={'Flexoeasy.com is a digital platform that makes flexographic plate-making easy and allows customers to access high-quality printing from anywhere in the world.'} />
        </Helmet>
        <ReactModal
          isOpen={showSuccessModal}
          onRequestClose={this.hideSuccessModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="57"
              viewBox="0 0 57 57"
            >
              <g>
                <path
                  fill="#7ed321"
                  d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                />
              </g>
            </svg>
          </div>
          <div className="title">Reorder is success</div>
          <div className="text">
            You will be redirected to dashboard shortly.
          </div>
        </ReactModal>
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            Something went wrong please try again later
          </div>
        </ReactModal>

        <div id="loader" className="loader  fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>

        <div className="reorder-container main-container">
          <div className="wrapper-medium">
            <h1 className="page-title">Order Details</h1>
            {/* <div className="description">IPACK_Layout</div> */}
            <div className="specs-container">
              <div className="row half clear">
                <div className="item">
                  <div className="label">Project Name</div>
                  <div className="text">{projectName}</div>
                </div>
                <div className="item">
                  <div className="label">Project Description</div>
                  <div className="text">{projectDescription}</div>
                </div>
              </div>
              {orderSpecs.map((item, key) => {
                let items = item.items;
                return (
                  <div className="row half clear" key={key}>
                    {items.map((item, key) => {
                      return (
                        <div className="item" key={key}>
                          <div className="label">{item.name}</div>
                          <div className="text">{item.value}</div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
              <div className="row files-row">
                <div className="row-label">Files</div>
                <div className="files-box">
                  {files.map((item , key) => {
                    return (
                      <div className="item" key={key}>
                        <a rel="noopener noreferrer" target="_blank" href={item.file}>{item.name}</a>
                      </div>
                    )
                  })}
                </div>
              </div>
              {additionalFiles.length > 0 ? (
                <div className="row files-row">
                <div className="row-label">Die-Cut Template</div>
                <div className="files-box">
                  {additionalFiles.map((item , key) => {
                    return (
                      <div className="item" key={key}>
                        <a rel="noopener noreferrer" target="_blank" href={item.file}>{item.name}</a>
                      </div>
                    )
                  })}
                </div>
              </div>
              ):('')}
              
              {/* <div className="row color-row">
                <div className="row-label">Colors</div>

                <div className="color-box">
                  {reorderSpecificColors
                    ? Object.keys(orderColors).map(function(i) {
                        let item = orderColors[i];
                        return (
                          <div className="item" key={i}>
                            <input
                              className="custom-checkbox"
                              type="checkbox"
                              name={item}
                              onChange={currentComponent.handelChecked}
                              id={`color-${item}`}
                            />
                            <label
                              htmlFor={`color-${item}`}
                              className="custom-lable"
                            >
                              {item}
                            </label>
                          </div>
                        );
                      })
                    : Object.keys(orderColors).map(function(i) {
                        let item = orderColors[i];
                        return (
                          <div className="item" key={i}>
                            <label className="custom-lable-text">{item}</label>
                          </div>
                        );
                      })}
                </div>
                <div className="invalid-feedback">{colorErrorMessage}</div>
              </div> */}
            </div>
            {/* {reorderSpecificColors ? (
              <div className="reorder-submit-box">
                <div className="price-box">
                  <div className="row">
                    <div className="item text">ORDER TOTAL</div>
                    <div className="item price">{`AED ${price.toFixed(
                      2
                    )}`}</div>
                  </div>
                  <div className="row">
                    <div className="item"></div>
                    <div className="item sub-text">
                      All prices include 5% VAT
                    </div>
                  </div>
                </div>
                <div className="button-row">
                  <div onClick={this.handleReorder} className="btn">
                    Reorder
                  </div>
                </div>
              </div>
            ) : (
              <div className="button-row">
                <div className="btn" onClick={this.handleReorder}>
                  Reorder Without Changes
                </div>
                {orderInfo.orderType === "physical" ? (
                  <div
                    onClick={this.handelReorderSpecificColors}
                    className="btn"
                  >
                    Reorder Only Specific Colors
                  </div>
                ) : (
                  ""
                )}
                <Link
                  to={`/products/${orderInfo.productUrl}?orderId=${this.props.match.params.id}`}
                  className="btn"
                >
                  Copy setup to new project
                </Link>
              </div>
            
            )} */}
            <div className="button-row">
                <div className="btn" onClick={this.handleReorder}>
                  Reorder Without Changes
                </div>
                <Link
                  to={`/products/${orderInfo.productUrl}?orderId=${this.props.match.params.id}`}
                  className="btn"
                >
                  Copy setup to new project
                </Link>
              </div>
            
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ReorderPage;
