import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import MetaTags from "../metaTags"

import validator from "validator";
import FormValidator from "../validations";

import axios from "axios";
var qs = require("query-string");
const apiUrl = process.env.REACT_APP_API;

class Forgotpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: ""
      },
      submitted: false,
      messageFromServer: "",
      redirect: false,
      redirectUrl: "",
      errors: [],
      errorClasses: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }
  componentDidMount() {
    window.scroll(0, 0);

    if (localStorage.getItem("user")) {
      let currentComponent = this;
      currentComponent.setState({
        redirect: true,
        redirectUrl: "/"
      });
    } else {
      document.body.classList.add("forgot");
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("forgot");
  }

  handleValidation() {
    //let fields = this.state.user;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "email",
        method: validator.isEmpty,
        validWhen: false,
        message: "Email field is required."
      },
      {
        field: "email",
        method: validator.isEmail,
        validWhen: true,
        message: "Please enter a valid email address."
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handleChange(e) {
    const name = e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
      user
    });
  }
  handleKeyPress = event => {
    if (event.key === "Enter") {
      let currentComponent = this;
      currentComponent.handleReset(event);
    }
  };
  handleReset(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      var currentComponent = this;
      let requestUrl = "/forgotpassword/index";
      let { errors, errorClasses } = this.state;

      let postdata = {
        email: this.state.user.email
      };

      axios
        .post(apiUrl + requestUrl, qs.stringify(postdata), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(function(response) {
          if (response.data.error === null) {
            console.log(response.data.payload);
            currentComponent.setState({
              messageFromServer: response.data.payload.message
            });
            // currentComponent.email.value = "";
          } else {
            if (response.data.error.code === 400) {
              errors["email"] = response.data.error.message;
              errorClasses["email"] = "is-invalid";
              currentComponent.setState({
                errors,
                errorClasses
              });
            }
          }
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    window.scroll(0, 0);
    let { messageFromServer } = this.state;
    return (
      <div className="forgot-container">
        <MetaTags/>
        <div className="wrapper">
          <div className="table">
            {messageFromServer ? (
              <div className="cell">
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">{messageFromServer}</div>
                </div>
              </div>
            ) : (
              <div className="cell">
                <h1 className="page-title">Forgot password</h1>

                <div className="form-wrap">
                  <div className="row clear">
                    <div className="label">Email</div>
                    <input
                      autoComplete="new-email"
                      onKeyPress={this.handleKeyPress}
                      type="email"
                      tabIndex="1"
                      ref={el => (this.email = el)}
                      name="email"
                      className={this.state.errorClasses.email}
                      onChange={this.handleChange}
                    />
                    <div className="invalid-feedback">
                      {this.state.errors.email}
                    </div>
                  </div>

                  <div className="row bottom">
                    <button onClick={this.handleReset}>
                      <span>Reset Password</span>
                    </button>
                  </div>
                  {this.state.messageFromServer ? (
                    <div className="message-box">
                      <div className="message">
                        {this.state.messageFromServer}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Forgotpage;
