// import { authHeader } from '../_helpers';
// import { userActions } from '../_actions';
// import { alertActions } from '../_actions';
import axios from "axios";
import uuid from "react-uuid";

const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");

export const userService = {
  login,
  logout
};

function login(email, password) {
  let uniqueId = uuid();
console.log(123)
  let postdata = {
    password,
    email,
    uniqueId
  };
  return axios
    .post(`${apiUrl}/users/authenticate`, qs.stringify(postdata), {
      crossDomain: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    })
    .then(user => {
      console.log("user.data", user.data)
      if (user.data.error === null) {
        if (user.data.payload.token) {
          console.log("user.data", user.data)
          localStorage.setItem("user", JSON.stringify(user.data.payload));
          localStorage.setItem("uniqueId", uniqueId);
          localStorage.setItem("groupId", user.data.payload.groupId);
        }
        return user.data;
      } else {
        var error = {
          message: user.data.error.message
        };
        return Promise.reject(error);
      }
    });
}

function logout() {
  if (localStorage.getItem("user")) {
    // let user = JSON.parse(localStorage.getItem("user"));
    // localStorage.removeItem("user");
    // let uniqueId = uuid();
    let uniqueId = localStorage.getItem("uniqueId")

    let postdata = {
      uniqueId
    };
    return axios
      .post(`${apiUrl}/users/logout`, qs.stringify(postdata), {
        crossDomain: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
      .then(user => {
          // let sentToServer = localStorage.getItem('sentToServer')
          localStorage.clear();
          // localStorage.setItem('sentToServer' , sentToServer)
          window.location.reload();
      });
  } else {
    return "";
  }
}