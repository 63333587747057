import React, { Component } from "react";
import { authHeader } from "../../_helpers";
import GlobalLoader from "../globalLoader";

import OrderSidebar from "./sidebar";
import OrederNavigation from "./navigation";
import Messenger from "./messenger/index";
import {Helmet} from "react-helmet";

import OrderSpecifications from "./specifications/index";
import OrderFileDocs from "./filesAndDocs/index";
import OrderFilesDownload from "./download/index";
import OrderApprove from "./approve/index";
import OrderMembers from "./members/index";
import OrderNotifications from "./notifications/index";
import ProjectDetailsReport from "./detailsReport/index";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;
const Components = {
  projectDetails: OrderSpecifications,
  members: OrderMembers,
  notifications: OrderNotifications,
  approve: OrderApprove,
  files: OrderFileDocs,
  downloadables: OrderFilesDownload,
  projectDetailsReport: ProjectDetailsReport,
};
class OrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderExist: false,
      orderInfo: {}
    };
    this.checkIfExist = this.checkIfExist.bind(this);
  }

  checkIfExist = async () => {
    let requestUrl = `/orders/isexist/${this.props.match.params.id}`;
    
    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null && Components[this.props.match.params.tabId] !== undefined) {
      //   console.log(data);

      this.setState({
        orderExist: true,
        orderInfo: data.payload.result
      },()=> {
        
      })
    } else {
      window.location = "/not-found";
    }
  };
  componentDidMount() {
    this.checkIfExist();
    document.body.classList.add(
      "order-page",
      `order-${this.props.match.params.tabId}`
    );
    window.scroll(0, 0);
  }
  componentWillUnmount() {
    document.body.classList.remove(
      "order-page",
      `order-${this.props.match.params.tabId}`
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      document.body.className = "";
      if(document.body.classList.contains('mobile')) {
        document.body.classList.add(
          "mobile"
        );
      }
      document.body.classList.add(
        "order-page",
        `order-${this.props.match.params.tabId}`
        
      );
    }
  }
  showChatBox() {
    let chatBox = document.getElementById('chat-box')

    if(!chatBox.classList.contains('opened')) {
      chatBox.classList.add('opened')
    }
  }
  render() {
    if (this.state.orderExist !== true) {
      return <GlobalLoader loaderState={false} />;
    }
    window.scroll(0,0)
    let MyComponent = Components[this.props.match.params.tabId];

    let { orderInfo } = this.state;

    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let CHAT;
    if(userPermission) {
      CHAT = userPermission.find(
        opt => opt.key === 'CHAT')
        
    }
    // console.log(orderInfo)
    return (
      <div
        className={`order-${this.props.match.params.tabId}-container main-container`}
      >
        <Helmet>
          <title>{`Order #${orderInfo.id} | Flexoeasy.com`}</title>
          <meta name="title" content={`Order #${orderInfo.id} | Flexoeasy.com`} />
          <meta name="description" content={'Flexoeasy.com is a digital platform that makes flexographic plate-making easy and allows customers to access high-quality printing from anywhere in the world.'} />
        </Helmet>
        <div className="main-wrap">
          <div className="wrapper">
            <OrderSidebar
              orderInfo={orderInfo}
              orderId={this.props.match.params.id}
            />
            <div className="main-box">
              <React.Fragment>
                <OrederNavigation orderId={this.props.match.params.id} tabId={this.props.match.params.tabId}/>
                <div className="content">
                  {React.createElement(MyComponent, {
                    orderId: this.props.match.params.id,
                    tabId: this.props.match.params.tabId,
                    orderStatusId: orderInfo.statusId
                  })}
                </div>
              </React.Fragment>
            </div>
            
          </div>
          {currentUser.isMember === false || (currentUser.isMember === true && CHAT !== undefined) ? (
              <React.Fragment>
                <Messenger orderId={this.props.match.params.id} />
                <div className="message-box">
                  <div className="message-btn-box">
                    <div className="message-btn" onClick={this.showChatBox}>
                      <svg viewBox="0 0 24 24" aria-hidden="true"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></path></svg>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ):('')}
        </div>
      </div>
    );
  }
}

export default OrderPage;
