import React, { Component } from "react";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
let moment = require("moment");

class NotificationListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pp: "15",
      notificationList: {},
      showModal: false,
      thankYou: false,
      thankYouTitle: "",
      thankYouText: ""
    };
    this.getNotificatonList = this.getNotificatonList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  _isMounted = false;
  _isRolesReady = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let start = "0",
      end = this.state.pp,
      reset = true;
    this.getNotificatonList(start, end, 1, reset);
  }


  getNotificatonList = async (
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ notificationList: [] });
    } else {
    }
    let requestUrl = `/orderdetails/notifications/${this.props.orderId}`;
    let currentComponent = this;
    const queryParams = {};
 
    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      let notificationList = data.payload.message.rows;
      let total = data.payload.message.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          notificationList
        },
        () => {
          let element = document.getElementById(`notification_${this.props.orderId}`)
          // let notificationCountBox  = document.getElementById('header-notification-count')
          if( element !== undefined && element !== null) {
            // console.log(element)
            // console.log(notificationCountBox)
            // console.log(notificationCountBox.innerHTML)
            // element.remove();
            //   if(notificationCountBox !== undefined && notificationCountBox !== null) {
            //     console.log('2222')

            //     notificationCountBox.innerHTML = notificationCountBox.innerHTML - 1
            //   }
          }
        }
      );
    }
  };
  handlePageChange(pageNumber) {
    let { pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getNotificatonList(start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  render() {
    // console.log(this.state);
    if (this._isMounted !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {notificationList ,pp , total,activePage} = this.state
    return (
      <React.Fragment>
        
          {notificationList.length > 0 ? (
            <div className="table-grid ">
              <div className="table-row table-head">
                <div className="table-cell">
                  <div className="text">Status</div>
                </div>
                <div className="table-cell">
                  <div className="text">Description</div>
                </div>
                <div className="table-cell">
                  <div className="text">Date</div>
                </div>
              </div>
              {notificationList.map((item , key) => {
                return(
                  <div className="table-row" key={key}>
                    <div className="table-cell">
                      <div className="text">
                        <div>Status:</div>
                        <div>{item.orderStatuses.name}</div>
                      </div>
                    </div>
                    <div className="table-cell">
                      <div className="text">
                        <div>Description:</div>
                        <div>
                          {
                            item.description !== '' ? 
                              item.description
                            :
                              item.orderStatuses.description
                          }
                        </div>
                      </div>
                    </div>
                    <div className="table-cell">
                      <div className="text light">
                        <div>Date:</div>
                        <div>{moment(item.createdAt).format('MMM D, YYYY [at] HH:mm z')}</div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          ):(
            <div className="no-result-box">
              <div className="text">No new notifications for this order</div>
            </div>
          )}
          
        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default NotificationListView;
