import React, { Component } from "react";
import AccountSidebar from "../sidebar";
import MetaTags from "../../metaTags"

import CustomersListView from "./customersList";
class AccountCustomers extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.body.classList.add("account", "account-members");
  }
  componentWillUnmount() {
    document.body.classList.remove("account", "account-members");
  }
  customersListReady = childData => {
    let { customersListReady } = this.state;
    if (customersListReady === false) {
      this.setState({
        customersListReady: childData
      });
    }
    console.log("customersListReady", customersListReady)
    console.log("childData", childData)
  };
  render() {

    return (
      <div className="account-container account-members-container">
        <MetaTags/>
        <div className="wrapper">
          <AccountSidebar />

          <div className="members-container main-box">
            <h1 className="page-title">Customers</h1>
            <div className="main-wrap">
              <CustomersListView customersListReady={this.customersListReady} />
            </div>
          </div>
        </div>
      </div>
    
    );
  }
}

export default AccountCustomers;
