import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import validator from "validator";
import FormValidator from "../validations";
import axios from "axios";
import SocialButton from "./SocialButton";
import uuid from "react-uuid";
import MetaTags from "../metaTags"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");

class Registerpage extends Component {
  constructor(props) {
    super(props);
    let userTempData = JSON.parse(localStorage.getItem("userTemp"));
    this.state = {
      user: {
        firstName:
          userTempData && userTempData.firstName ? userTempData.firstName : "",
        lastName:
          userTempData && userTempData.lastName ? userTempData.lastName : "",
        phoneNumber: "",
        email: userTempData && userTempData.email ? userTempData.email : "",
        password: "",
        rePassword: "",
        companyName: "",
        newsletter: false,
        corporate: false,
        tradeLicense: "",
        trnCertificate: ""
      },
      filesInfo: {
        tradeLicense: [],
        trnCertificate: []
      },
      corporateInfo: false,
      submitted: false,
      thankYouMessageFromServer: "",
      thankYou: false,
      messageFromServer: "",
      redirect: false,
      redirectUrl: "",
      errors: [],
      errorClasses: [],
      isActiveTab: "0"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handelCorporateChecked = this.handelCorporateChecked.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    

    this.handleSocialLogin = this.handleSocialLogin.bind(this);
    this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.handleClick("0");
    if (localStorage.getItem("user")) {
      let currentComponent = this;
      currentComponent.setState({
        redirect: true,
        redirectUrl: "/"
      });
    } else {
      document.body.classList.add("register");
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("register");
  }

  handleValidation() {
    let fields = this.state.user;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last name field is required."
      },
      {
        field: "phoneNumber",
        method: validator.isNumeric,
        validWhen: true,
        message: "Phone number field is required."
      },
      {
        field: "email",
        method: validator.isEmpty,
        validWhen: false,
        message: "Email field is required."
      },
      {
        field: "email",
        method: validator.isEmail,
        validWhen: true,
        message: "Please enter a valid email address."
      },
      {
        field: "password",
        method: validator.isEmpty,
        validWhen: false,
        message: "Password field is required."
      },
      {
        field: "phoneNumber",
        method: validator.isLength,
        args: [
          {
            min: 10
          }
        ],
        validWhen: true,
        message: "Please enter at least 10 characters."
      },
      {
        field: "password",
        method: validator.isLength,
        args: [
          {
            min: 6
          }
        ],
        validWhen: true,
        message: "Please enter at least 6 characters."
      },
      {
        field: "rePassword",
        method: validator.isEmpty,
        validWhen: false,
        message: "Repeat Password field is required."
      },
      {
        field: "rePassword",
        method: validator.equals,
        validWhen: true,
        args: { comparison: fields.password },
        message: "Repet Password and Password fields does not match"
      }
    ];

    if (this.state.corporateInfo) {
      validationRules.push(
        {
          field: "tradeLicense",
          method: validator.isEmpty,
          validWhen: false,
          message: "Trade license field is required."
        },
        {
          field: "trnCertificate",
          method: validator.isEmpty,
          validWhen: false,
          message: "Trn certificate field is required."
        }
      );
    }

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handleChange(e) {
    
    const name = e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
      user
    });
  }
  handlePhoneNumberChange(value, country, e, formattedValue) {
    // console.log(value)
    // console.log(country)
    // console.log(e)
    // console.log(formattedValue)
    // console.log(e.target) 
    const user = this.state.user;
    user['phoneNumber'] = value;
    this.setState({
      user
    });
    
  }
  handleClick = event => {
    this.setState({
      isActiveTab: event
    })
  }
  handleKeyPress = event => {
    if (event.key === "Enter") {
      let currentComponent = this;
      currentComponent.handleRegister(event);
    }
  };

  handelChecked(e) {
    const user = this.state.user;
    const name = e.target.name;
    if (e.target.checked) {
      user[name] = true;

      this.setState({
        user
      });
    } else {
      user[name] = false;
      this.setState({
        user
      });
    }
  }

  handelCorporateChecked(e) {
    const user = this.state.user;
    const name = e.target.name;
    var corporateInfo = false;
    if (e.target.checked) {
      user[name] = true;
      if (name === "corporate") {
        corporateInfo = true;
      }
      this.setState({
        user,
        corporateInfo
      });
    } else {
      user[name] = false;
      this.setState({
        user,
        corporateInfo
      });
    }
  }

  handleRegister = async e => {
    e.preventDefault();

    if (this.handleValidation()) {
      if (
        !this.state.user.password.match(
          /^(?=.{6,})(?=.*[a-z])(?=.*[0-9])|(?=.*[!@#$%^&*()-+=]).*$/
        )
      ) {
        let { errors, errorClasses } = this.state;
        errors["password"] =
          "Your password must contain one number or special character";
        errorClasses["password"] = "is-invalid";
        this.setState({
          errors,
          errorClasses
        });
        return false;
      }

      let loader = document.getElementById("loader");

      loader.classList.add("show");
      let requestUrl = "/users/registration";
      const sendingData = new FormData();
      const { user,isActiveTab } = this.state;
      // console.log(user)

      sendingData.append("firstname", user.firstName);
      sendingData.append("lastname", user.lastName);
      sendingData.append("email", user.email);
      sendingData.append("password", user.password);
      sendingData.append("repassword", user.rePassword);
      sendingData.append("phone", user.phoneNumber);
      sendingData.append("companyName", user.companyName);
      sendingData.append("tradeLicense", user.tradeLicense);
      sendingData.append("trnCertificate", user.trnCertificate);
      sendingData.append("newsletter", user.newsletter);
      sendingData.append("agent", isActiveTab);


      // console.log(sendingData)
      let res = await axios.post(apiUrl + requestUrl, sendingData);
      let { data } = res;
      // console.log(data)
      if (data.error === null) {
        this.setState(
          {
            thankYou: true
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
              if (localStorage.getItem("userTemp")) {
                localStorage.removeItem("userTemp");
              }
            }
          }
        );
        //   console.log(data);
      } else {
        if (data.error.code === 401) {
          let { errors, errorClasses } = this.state;
          errors["email"] = data.error.message;
          errorClasses["email"] = "is-invalid";
          this.setState({
            errors,
            errorClasses
          });
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      }
    }
  };
  handleSocialLogin = async user => {
    let requestUrl = "/users/social-login";
    let loader = document.getElementById("loader");

    loader.classList.add("show");
    var tokenType = "";
    if (user._provider === "google") {
      tokenType = user._token.idToken;
    } else {
      tokenType = user._token.accessToken;
    }
    let uniqueId = uuid();

    let postdata = {
      firstName: user._profile.firstName,
      lastName: user._profile.lastName,
      email: user._profile.email,
      token: tokenType,
      type: user._provider,
      uniqueId
    };
    let res = await axios.post(apiUrl + requestUrl, qs.stringify(postdata), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    let { data } = res;
    if (data.error === null) {
      if (localStorage.getItem("userTemp")) {
        localStorage.removeItem("userTemp");
      }
      localStorage.setItem("user", JSON.stringify(data.payload));
      localStorage.setItem("uniqueId", uniqueId);

      window.location = "/";
    } else {
      if (data.error.code === 204) {
        this.setState(
          {
            user: {
              firstName: postdata.firstName ? postdata.firstName : "",
              lastName: postdata.lastName ? postdata.lastName : "",
              email: postdata.email ? postdata.email : ""
            }
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
          }
        );
      }
      if (data.error.code === 203) {
        this.setState(
          {
            messageFromServer: data.error.message
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
          }
        );
      }
    }
  };
  handleSocialLoginFailure = err => {
    console.error(err);
  };

  handleFileChange(e) {
    var file = e.target.files[0];
    var currentComponent = this;
    const name = e.target.name;
    const user = this.state.user;
    user[name] = file;
    e.target.value = ''
    const filesInfo = this.state.filesInfo;
    if (file && file.size > 10485760) {
      filesInfo[name] = { error: "Maximum file size: 10MB" };
      currentComponent.setState({ filesInfo });
    } else {
      filesInfo[name] = { name: file.name, size: file.size, error: "" };
      currentComponent.setState({ user, filesInfo });
    }
   
  }
  handleRemoveFile(e) {
    const name = e.currentTarget.getAttribute("data-name");
    const user = this.state.user;
    const filesInfo = this.state.filesInfo;
    user[name] = "";
    filesInfo[name] = "";
    this.setState({ user, filesInfo });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }

    // console.log(this.state.user)
    const { corporateInfo, filesInfo, errors } = this.state;
    function bytesToSize(bytes) {
      var sizes = ["Bytes", "Kb", "Mb"];
      if (bytes === 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }

    console.log("isActiveTab", this.state.isActiveTab)
    return (
      <div className="register-container">
        <MetaTags/>
        <div className="wrapper">
          <div id="loader" className="loader circle">
            <svg
              width="200px"
              height="200px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
              className="lds-rolling"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                ng-attr-stroke="{{config.color}}"
                ng-attr-stroke-width="{{config.width}}"
                ng-attr-r="{{config.radius}}"
                ng-attr-stroke-dasharray="{{config.dasharray}}"
                stroke="#B8BE14"
                strokeWidth="2"
                r="30"
                strokeDasharray="141.37166941154067 49.12388980384689"
                transform="rotate(159.346 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="linear"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                  dur="1.4s"
                  begin="0s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <div className="table">
            <div className="cell">
              {this.state.thankYou ? (
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">
                    Account confirmation is required. Please, check your email
                    for the confirmation link.
                  </div>
                </div>
              ) : (
                <React.Fragment>
                  <h1 className="page-title">Sign up</h1>
                  <div className="button-wrap row">
                      <button className={this.state.isActiveTab === "0" ? `tab-button active` : `tab-button`} onClick={() => this.handleClick("0")} >Personal</button>
                      <button className={this.state.isActiveTab === "1" ? `tab-button active` : `tab-button`}   onClick={() => this.handleClick("1")}>Agent</button>
                  </div>
                  <div className="form-wrap">
                    <div className="row clear">
                      <div className="label">First Name</div>
                      <input
                        value={this.state.user.firstName}
                        autoComplete="new-firstName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        tabIndex="1"
                        ref={el => (this.firstName = el)}
                        name="firstName"
                        className={this.state.errorClasses.firstName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.firstName}</div>
                    </div>
                    <div className="row clear">
                      <div className="label">Last Name</div>
                      <input
                        value={this.state.user.lastName}
                        autoComplete="new-lastName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        tabIndex="2"
                        ref={el => (this.lastName = el)}
                        name="lastName"
                        className={this.state.errorClasses.lastName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.lastName}</div>
                    </div>
                    <div className="row clear phone-number">
                      <div className="label">Phone Number</div>
                      <PhoneInput
                        inputProps={{
                          'name': 'phoneNumber'
                        }}
                        country={'ae'}
                        onChange={(value, country, e, formattedValue) => this.handlePhoneNumberChange(value, country, e, formattedValue)}
                        autoComplete="new-phoneNumber"
                        onKeyPress={this.handleKeyPress}
                        type="tel"
                        tabIndex="3"
                        value={this.state.user.phoneNumber}
                        ref={el => (this.phoneNumber = el)}
                        inputClass={this.state.errorClasses.phoneNumber}
                        id="phoneNumber"
                      />
                      <div className="invalid-feedback">
                        {errors.phoneNumber}
                      </div>
                    </div>
                    <div className="row clear">
                      <div className="label">Email</div>
                      <input
                        value={this.state.user.email}
                        autoComplete="new-email"
                        onKeyPress={this.handleKeyPress}
                        type="email"
                        tabIndex="4"
                        ref={el => (this.email = el)}
                        name="email"
                        className={this.state.errorClasses.email}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.email}</div>
                    </div>
                    <div className="row clear">
                      <div className="label">Password</div>
                      <input
                        autoComplete="new-password"
                        onKeyPress={this.handleKeyPress}
                        type="password"
                        tabIndex="5"
                        ref={el => (this.password = el)}
                        name="password"
                        className={this.state.errorClasses.password}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.password}</div>
                    </div>
                    <div className="row clear">
                      <div className="label">Repet Password</div>
                      <input
                        autoComplete="new-rePassword"
                        onKeyPress={this.handleKeyPress}
                        type="password"
                        tabIndex="6"
                        ref={el => (this.rePassword = el)}
                        name="rePassword"
                        className={this.state.errorClasses.rePassword}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {errors.rePassword}
                      </div>
                    </div>
                    <div className="row clear">
                      <div className="label">Company name</div>
                      <input
                        autoComplete="new-company_name"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        tabIndex="6"
                        ref={el => (this.companyName = el)}
                        name="companyName"
                        className={this.state.errorClasses.companyName}
                        onChange={this.handleChange}
                      />

                      <div className="invalid-feedback">
                        {errors.companyName}
                      </div>
                    </div>
                      {this.state.isActiveTab === "0" && <div className="row clear">
                          <input
                              autoComplete="new-corporate"
                              className="custom-checkbox"
                              id="corporate"
                              onChange={this.handelCorporateChecked}
                              type="checkbox"
                              tabIndex="7"
                              ref={el => (this.corporate = el)}
                              name="corporate"
                          />
                          <label htmlFor="corporate" className="custom-lable">
                              corporate
                          </label>
                      </div>}
                    {corporateInfo ? (
                      <div>
                        <div className="row clear">
                          <div className="label">trade license</div>
                          <div className="attach-btn-box">
                            <input
                              type="file"
                              name="tradeLicense"
                              onChange={this.handleFileChange}
                              id="trade_license"
                              accept="image/*,.pdf"
                            />
                            {!filesInfo.tradeLicense.name ? (
                              <label
                                htmlFor="trade_license"
                                className="attach-btn"
                              >
                                Upload File
                              </label>
                            ) : (
                              <div className="attach-name-box">
                                <span className="name">
                                  {filesInfo.tradeLicense.name + " "}
                                </span>
                                <span className="size">{` ( ${bytesToSize(filesInfo.tradeLicense.size)}) `}</span>
                                <span
                                  className="remove"
                                  data-name="tradeLicense"
                                  onClick={this.handleRemoveFile}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <g transform="translate(-810 -1063)">
                                      <circle
                                        cx="10"
                                        cy="10"
                                        r="10"
                                        transform="translate(810 1063)"
                                        fill="#acacac"
                                      />
                                      <path
                                        d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                        transform="translate(821.553 1090.545)"
                                        fill="#fff"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="invalid-feedback">
                            {filesInfo.tradeLicense.error}
                          </div>
                        </div>

                        <div className="row clear">
                          <div className="label"> trn certificate</div>
                          <div className="attach-btn-box">
                            <input
                              type="file"
                              name="trnCertificate"
                              onChange={this.handleFileChange}
                              id="trn_certificate"
                              accept="image/*,.pdf"
                            />
                            {!filesInfo.trnCertificate.name ? (
                              <label
                                htmlFor="trn_certificate"
                                className="attach-btn"
                              >
                                Upload File
                              </label>
                            ) : (
                              <div className="attach-name-box">
                                <span className="name">
                                  {filesInfo.trnCertificate.name + " "}
                                </span>
                                <span className="size">{` ( ${bytesToSize(filesInfo.trnCertificate.size)}) `}</span>
                                <span
                                  className="remove"
                                  data-name="trnCertificate"
                                  onClick={this.handleRemoveFile}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <g transform="translate(-810 -1063)">
                                      <circle
                                        cx="10"
                                        cy="10"
                                        r="10"
                                        transform="translate(810 1063)"
                                        fill="#acacac"
                                      />
                                      <path
                                        d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                        transform="translate(821.553 1090.545)"
                                        fill="#fff"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="invalid-feedback">
                            {filesInfo.trnCertificate.error}
                          </div>
                        </div>
                      </div>
                    ) : ""}
                    <div className="row clear">
                      <input
                        autoComplete="new-newsletter"
                        className="custom-checkbox"
                        id="newsletter"
                        onChange={this.handelChecked}
                        type="checkbox"
                        tabIndex="7"
                        ref={el => (this.newsletter = el)}
                        name="newsletter"
                      />
                      <label htmlFor="newsletter" className="custom-lable">
                        Sign-up for our newsletter.
                      </label>
                    </div>
                    <div className="row bottom">
                      <button onClick={this.handleRegister}>Sign up</button>
                    </div>
                    {this.state.messageFromServer ? (
                      <div className="message-box">
                        <div className="message">
                          {this.state.messageFromServer}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="social-login-box">
                    <SocialButton
                      provider="facebook"
                      appId="508602630015771"
                      onLoginSuccess={this.handleSocialLogin}
                      onLoginFailure={this.handleSocialLoginFailure}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 257.67 42"
                      >
                        <rect
                          className="fb-cls-1"
                          width="257.67"
                          height="42"
                          rx="5"
                          ry="5"
                        />
                        <path
                          className="fb-cls-2"
                          d="M32,8.51H10A1.51,1.51,0,0,0,8.49,10V32A1.51,1.51,0,0,0,10,33.49H21.65v-9.7H18.34V20h3.31v-2.9c0-3.73,2.6-5,4.59-5h3.47v3.65H27.26c-1.05,0-1.66.61-1.66,1.85V20h3.84l-.54,3.78H25.6v9.7H32A1.52,1.52,0,0,0,33.46,32V10A1.52,1.52,0,0,0,32,8.51Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M62.2,22.85a6,6,0,0,1-1.84,3.76A5.48,5.48,0,0,1,56.56,28a5.35,5.35,0,0,1-4.27-1.85,7.36,7.36,0,0,1-1.59-5,7.24,7.24,0,0,1,1.65-5,6.26,6.26,0,0,1,8-.73,4.69,4.69,0,0,1,1.7,3.11h-1.7a3.06,3.06,0,0,0-1.19-2,4,4,0,0,0-2.44-.7,3.76,3.76,0,0,0-3.1,1.43,6,6,0,0,0-1.16,3.86,6.16,6.16,0,0,0,1.08,3.92,3.71,3.71,0,0,0,3.1,1.35,3.54,3.54,0,0,0,2.51-.93,4.74,4.74,0,0,0,1.32-2.64Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M64.61,26.5a5.26,5.26,0,0,1-1.22-3.61,5.24,5.24,0,0,1,1.22-3.61,4.34,4.34,0,0,1,6.35,0,5.29,5.29,0,0,1,1.21,3.61A5.3,5.3,0,0,1,71,26.5a4.36,4.36,0,0,1-6.35,0Zm5.16-1a4.31,4.31,0,0,0,.74-2.64,4.33,4.33,0,0,0-.74-2.64,2.39,2.39,0,0,0-2-1,2.36,2.36,0,0,0-2,1,4.33,4.33,0,0,0-.74,2.64,4.31,4.31,0,0,0,.74,2.64,2.35,2.35,0,0,0,2,1A2.38,2.38,0,0,0,69.77,25.53Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M73.79,18.18H75.3v1.4l.35-.47a3.21,3.21,0,0,1,5.84,2v6.46H79.9V21.69a2.7,2.7,0,0,0-.48-1.79,2,2,0,0,0-1.56-.55,2.17,2.17,0,0,0-1.81.86,3.75,3.75,0,0,0-.67,2.35v5.05H73.79Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M87.11,27.61a9,9,0,0,1-1.18.12,1.91,1.91,0,0,1-1.46-.46A2.26,2.26,0,0,1,84,25.69V19.48H82.73v-1.3H84V15.54h1.59v2.64h1.48v1.3H85.63v6.21a.61.61,0,0,0,.25.51,1.11,1.11,0,0,0,.67.18h.56Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M88.52,14.66h1.62v1.8H88.52Zm0,3.52h1.59v9.43H88.54Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M92.32,18.18h1.5v1.4l.36-.47a3.2,3.2,0,0,1,5.83,2v6.46H98.42V21.69a2.7,2.7,0,0,0-.48-1.79,2,2,0,0,0-1.55-.55,2.19,2.19,0,0,0-1.82.86,3.81,3.81,0,0,0-.67,2.35v5.05H92.32Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M103.81,24.27a2.61,2.61,0,0,0,.46,1.71,1.9,1.9,0,0,0,1.49.52,2.16,2.16,0,0,0,1.82-.88,3.93,3.93,0,0,0,.67-2.4v-5h1.58v9.43h-1.5V26.18a5.92,5.92,0,0,1-.35.48,3.18,3.18,0,0,1-2.54,1.21,3.29,3.29,0,0,1-2.41-.8,3.3,3.3,0,0,1-.8-2.41V18.18h1.58Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M115.88,27.87a4,4,0,0,1-3.15-1.27,5.06,5.06,0,0,1-1.15-3.51,5.58,5.58,0,0,1,1.22-3.78,4.06,4.06,0,0,1,3.25-1.41,3.68,3.68,0,0,1,3,1.38,5.46,5.46,0,0,1,1.14,3.61l0,.56h-6.89a4.22,4.22,0,0,0,.64,2.16,2.49,2.49,0,0,0,2.12.89,2.55,2.55,0,0,0,1.58-.51,2,2,0,0,0,.83-1.3H120a4,4,0,0,1-1.41,2.37A4.33,4.33,0,0,1,115.88,27.87Zm2.65-5.69a3.54,3.54,0,0,0-.66-2.05,2.26,2.26,0,0,0-1.88-.81,2.45,2.45,0,0,0-2,.93,3.6,3.6,0,0,0-.71,1.93Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M128.49,27.61l-2.68-9.43h1.74l1.82,7.51h0l1.85-7.51H133l1.89,7.57h0l1.91-7.57h1.57l-2.67,9.43H134L132,20.25h0l-1.84,7.36h-1.7Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M139.62,14.66h1.62v1.8h-1.62Zm0,3.52h1.58v9.43h-1.58Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M146.88,27.61a9.18,9.18,0,0,1-1.18.12,1.92,1.92,0,0,1-1.47-.46,2.26,2.26,0,0,1-.43-1.58V19.48h-1.3v-1.3h1.3V15.54h1.59v2.64h1.49v1.3h-1.49v6.21a.61.61,0,0,0,.25.51,1.13,1.13,0,0,0,.67.18h.57Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M148.25,14.66h1.59v4.8l.27-.35a3.57,3.57,0,0,1,5-.36,3.25,3.25,0,0,1,.84,2.4v6.46h-1.58V21.55a2.57,2.57,0,0,0-.45-1.69,1.86,1.86,0,0,0-1.46-.51,2.35,2.35,0,0,0-1.91.86,3.64,3.64,0,0,0-.7,2.35v5.05h-1.59Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M163.32,27.61V14.66h9v1.55h-7.22v4h6.32v1.55h-6.32v5.89h-1.76Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M176.28,27.87a3.15,3.15,0,0,1-2.2-.74,2.57,2.57,0,0,1-.81-2,2.69,2.69,0,0,1,.76-2,3.73,3.73,0,0,1,2.23-.93l2.16-.28a2.74,2.74,0,0,0,.5-.08.86.86,0,0,0,.48-.32,1,1,0,0,0,.16-.6v-.08a1.41,1.41,0,0,0-.57-1.19,2.64,2.64,0,0,0-1.59-.42,2.33,2.33,0,0,0-1.53.45A2,2,0,0,0,175.2,21h-1.46a3.1,3.1,0,0,1,1.09-2.33,4.4,4.4,0,0,1,2.8-.78,4,4,0,0,1,2.62.75,2.65,2.65,0,0,1,.9,2.17V26a.5.5,0,0,0,.54.53l.21,0,.29-.05v1.15l-.48.14a2.69,2.69,0,0,1-.44.05,1.58,1.58,0,0,1-1.3-.49,2.11,2.11,0,0,1-.38-.93,2.7,2.7,0,0,1-.41.42A4.15,4.15,0,0,1,176.28,27.87Zm3.28-4v-1a3.62,3.62,0,0,1-1,.29l-1.45.21a3.8,3.8,0,0,0-1.67.53,1.17,1.17,0,0,0-.53,1,1.64,1.64,0,0,0,.43,1.22,1.69,1.69,0,0,0,1.22.43,3.34,3.34,0,0,0,2.12-.68,2.34,2.34,0,0,0,.78-1.08,1.19,1.19,0,0,0,.06-.36C179.55,24.27,179.56,24.08,179.56,23.88Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M191.09,24.22a4.45,4.45,0,0,1-1.26,2.72,3.85,3.85,0,0,1-2.7.93,3.71,3.71,0,0,1-3-1.35A5.54,5.54,0,0,1,183,22.89a5.44,5.44,0,0,1,1.16-3.64,3.92,3.92,0,0,1,3.12-1.35,3.84,3.84,0,0,1,2.71.93,3.51,3.51,0,0,1,1.09,2.56h-1.53a2.35,2.35,0,0,0-.7-1.57,2.32,2.32,0,0,0-1.62-.55,2.2,2.2,0,0,0-1.89,1,4.59,4.59,0,0,0-.68,2.66,4.7,4.7,0,0,0,.64,2.71,2.38,2.38,0,0,0,3.43.31,3,3,0,0,0,.82-1.69Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M196.34,27.87a4,4,0,0,1-3.15-1.27,5.06,5.06,0,0,1-1.14-3.51,5.57,5.57,0,0,1,1.21-3.78,4.07,4.07,0,0,1,3.25-1.41,3.68,3.68,0,0,1,3,1.38,5.46,5.46,0,0,1,1.14,3.61l0,.56h-6.89a4.12,4.12,0,0,0,.65,2.16,2.47,2.47,0,0,0,2.11.89,2.6,2.6,0,0,0,1.59-.51,2.06,2.06,0,0,0,.82-1.3h1.57a4,4,0,0,1-1.41,2.37A4.33,4.33,0,0,1,196.34,27.87ZM199,22.18a3.54,3.54,0,0,0-.66-2.05,2.25,2.25,0,0,0-1.88-.81,2.43,2.43,0,0,0-2,.93,3.53,3.53,0,0,0-.72,1.93Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M202.23,14.66h1.59v4.75a3.09,3.09,0,0,1,2.69-1.51,3.67,3.67,0,0,1,2.93,1.27,5.16,5.16,0,0,1,1.08,3.45,6.14,6.14,0,0,1-1.09,3.85,3.62,3.62,0,0,1-3,1.4,3.06,3.06,0,0,1-2.54-1.22l-.15-.2v1.16h-1.49Zm4.1,11.84a2.16,2.16,0,0,0,1.89-.94,4.85,4.85,0,0,0,.64-2.74,4.48,4.48,0,0,0-.62-2.62,2.18,2.18,0,0,0-1.9-.85,2.09,2.09,0,0,0-1.92,1,5.91,5.91,0,0,0-.6,3,3.71,3.71,0,0,0,.65,2.33A2.24,2.24,0,0,0,206.33,26.5Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M212.89,26.5a5.25,5.25,0,0,1-1.21-3.61,5.24,5.24,0,0,1,1.21-3.61,4.34,4.34,0,0,1,6.35,0,5.29,5.29,0,0,1,1.21,3.61,5.3,5.3,0,0,1-1.21,3.61,4.36,4.36,0,0,1-6.35,0Zm5.16-1a4.31,4.31,0,0,0,.74-2.64,4.33,4.33,0,0,0-.74-2.64,2.51,2.51,0,0,0-4,0,4.33,4.33,0,0,0-.74,2.64,4.31,4.31,0,0,0,.74,2.64,2.53,2.53,0,0,0,4,0Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M222.75,26.5a5.25,5.25,0,0,1-1.21-3.61,5.24,5.24,0,0,1,1.21-3.61,4.34,4.34,0,0,1,6.35,0,5.29,5.29,0,0,1,1.21,3.61,5.3,5.3,0,0,1-1.21,3.61,4.36,4.36,0,0,1-6.35,0Zm5.16-1a4.31,4.31,0,0,0,.74-2.64,4.33,4.33,0,0,0-.74-2.64,2.39,2.39,0,0,0-2-1,2.36,2.36,0,0,0-2,1,4.33,4.33,0,0,0-.74,2.64,4.31,4.31,0,0,0,.74,2.64,2.35,2.35,0,0,0,2,1A2.38,2.38,0,0,0,227.91,25.53Z"
                        />
                        <path
                          className="fb-cls-2"
                          d="M232,14.66h1.58v7.47l4-3.95h2L236,21.68l3.82,5.93h-2.06l-2.93-4.79L233.55,24v3.57H232Z"
                        />
                      </svg>
                    </SocialButton>
                    <SocialButton
                      provider="google"
                      appId="627878665831-367fi4iuvklf5aa8stvk4tupp35ua27j.apps.googleusercontent.com"
                      onLoginSuccess={this.handleSocialLogin}
                      onLoginFailure={this.handleSocialLoginFailure}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 257.67 43"
                      >
                        <rect
                          className="g-cls-1"
                          width="257.67"
                          height="43"
                          rx="3.48"
                          ry="3.48"
                        />
                        <g id="surface1">
                          <path
                            className="g-cls-2"
                            d="M33.21,19.05h-1V19H21v5H28a7.5,7.5,0,1,1-2.09-8.09l3.53-3.54a12.54,12.54,0,1,0,3.74,6.68Z"
                          />
                          <path
                            className="g-cls-3"
                            d="M9.93,15.69l4.1,3a7.47,7.47,0,0,1,11.91-2.79l3.53-3.54A12.45,12.45,0,0,0,9.93,15.69Z"
                          />
                          <path
                            className="g-cls-4"
                            d="M21,34a12.4,12.4,0,0,0,8.37-3.25l-3.86-3.27A7.47,7.47,0,0,1,13.93,24L9.86,27.17A12.47,12.47,0,0,0,21,34Z"
                          />
                          <path
                            className="g-cls-5"
                            d="M21,19v5H28a7.52,7.52,0,0,1-2.55,3.47h0l3.86,3.27a12,12,0,0,0,4.12-9.24,12.92,12.92,0,0,0-.25-2.5Z"
                          />
                        </g>
                        <path
                          className="g-cls-6"
                          d="M72,23.49a4.46,4.46,0,0,1-1.45,3.08,5,5,0,0,1-3.37,1.1,4.77,4.77,0,0,1-2.6-.7,4.6,4.6,0,0,1-1.73-2,7.31,7.31,0,0,1-.63-3V20.85a7.25,7.25,0,0,1,.62-3.07,4.65,4.65,0,0,1,1.77-2A4.94,4.94,0,0,1,67.26,15a4.8,4.8,0,0,1,3.28,1.1A4.6,4.6,0,0,1,72,19.25H69.85a3.05,3.05,0,0,0-.77-1.92,2.57,2.57,0,0,0-1.82-.59,2.51,2.51,0,0,0-2.15,1,5.1,5.1,0,0,0-.76,3v1.09a5.44,5.44,0,0,0,.71,3,2.39,2.39,0,0,0,2.1,1.05,2.79,2.79,0,0,0,1.89-.56,3,3,0,0,0,.8-1.89Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M73.31,22.84a5.44,5.44,0,0,1,.53-2.42,4,4,0,0,1,1.5-1.66,4.18,4.18,0,0,1,2.22-.58,4,4,0,0,1,3,1.19,4.7,4.7,0,0,1,1.25,3.17V23a5.55,5.55,0,0,1-.52,2.42,3.91,3.91,0,0,1-1.49,1.65,4.34,4.34,0,0,1-2.24.58,4,4,0,0,1-3.1-1.29,5,5,0,0,1-1.16-3.44Zm2.06.18A3.67,3.67,0,0,0,76,25.23a1.9,1.9,0,0,0,1.62.8,1.86,1.86,0,0,0,1.62-.81,4.06,4.06,0,0,0,.58-2.38,3.63,3.63,0,0,0-.6-2.2,2,2,0,0,0-3.21,0A4,4,0,0,0,75.37,23Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M85.46,18.35l.06,1.06a3.28,3.28,0,0,1,2.66-1.23q2.86,0,2.91,3.27V27.5H89V21.57a1.89,1.89,0,0,0-.38-1.29,1.58,1.58,0,0,0-1.23-.42A2,2,0,0,0,85.58,21V27.5H83.52V18.35Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M95.76,16.13v2.22h1.62v1.52H95.76V25a1.13,1.13,0,0,0,.21.76,1,1,0,0,0,.74.23,3.08,3.08,0,0,0,.72-.09v1.59a5.09,5.09,0,0,1-1.36.2c-1.58,0-2.36-.87-2.36-2.61V19.87H92.2V18.35h1.51V16.13Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M99,16a1.05,1.05,0,0,1,1.16-1.11,1.13,1.13,0,0,1,.86.31,1.12,1.12,0,0,1,.3.8,1.09,1.09,0,0,1-.3.78,1.17,1.17,0,0,1-.86.31,1.16,1.16,0,0,1-.86-.31A1.09,1.09,0,0,1,99,16Zm2.18,11.53H99.07V18.35h2.06Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M105.27,18.35l.06,1.06A3.3,3.3,0,0,1,108,18.18q2.85,0,2.9,3.27V27.5h-2.05V21.57a1.89,1.89,0,0,0-.38-1.29,1.58,1.58,0,0,0-1.23-.42A2,2,0,0,0,105.39,21V27.5h-2V18.35Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M118.54,26.6A3.19,3.19,0,0,1,116,27.67a2.84,2.84,0,0,1-2.25-.87,3.7,3.7,0,0,1-.77-2.52V18.35h2v5.9c0,1.16.49,1.74,1.45,1.74a2,2,0,0,0,2-1.07V18.35h2.06V27.5h-1.94Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M126.65,27.67a4.26,4.26,0,0,1-3.17-1.23,4.46,4.46,0,0,1-1.21-3.28v-.25a5.38,5.38,0,0,1,.53-2.45,3.93,3.93,0,0,1,3.61-2.28,3.6,3.6,0,0,1,2.89,1.19,5.05,5.05,0,0,1,1,3.38v.83h-6a2.71,2.71,0,0,0,.76,1.79,2.27,2.27,0,0,0,1.67.66,2.8,2.8,0,0,0,2.3-1.14l1.1,1.05a3.7,3.7,0,0,1-1.46,1.28A4.62,4.62,0,0,1,126.65,27.67Zm-.25-7.84a1.73,1.73,0,0,0-1.36.59,3.12,3.12,0,0,0-.67,1.65h3.92v-.15a2.52,2.52,0,0,0-.55-1.56A1.73,1.73,0,0,0,126.4,19.83Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M144.26,24.67l1.46-6.32h2l-2.5,9.15h-1.69l-2-6.28-1.93,6.28H138l-2.5-9.15h2l1.48,6.25,1.88-6.25h1.55Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M149.1,16a1.05,1.05,0,0,1,1.16-1.11,1.13,1.13,0,0,1,.86.31,1.12,1.12,0,0,1,.3.8,1.09,1.09,0,0,1-.3.78,1.17,1.17,0,0,1-.86.31,1.16,1.16,0,0,1-.86-.31A1.09,1.09,0,0,1,149.1,16Zm2.18,11.53h-2.06V18.35h2.06Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M156.09,16.13v2.22h1.61v1.52h-1.61V25a1.13,1.13,0,0,0,.21.76A1,1,0,0,0,157,26a3.08,3.08,0,0,0,.72-.09v1.59a5.09,5.09,0,0,1-1.36.2c-1.58,0-2.36-.87-2.36-2.61V19.87h-1.51V18.35H154V16.13Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M161.3,19.35a3.21,3.21,0,0,1,2.54-1.17q2.92,0,3,3.34v6h-2.05V21.6a1.82,1.82,0,0,0-.41-1.34,1.67,1.67,0,0,0-1.21-.4A2,2,0,0,0,161.3,21V27.5h-2.06v-13h2.06Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M182.83,25.9A4.07,4.07,0,0,1,181,27.22a7.51,7.51,0,0,1-2.69.45,5.39,5.39,0,0,1-2.74-.7,4.7,4.7,0,0,1-1.83-2,7,7,0,0,1-.68-3.05V21a6.51,6.51,0,0,1,1.34-4.36A4.62,4.62,0,0,1,178.11,15a4.88,4.88,0,0,1,3.26,1A4.39,4.39,0,0,1,182.83,19h-2.1a2.37,2.37,0,0,0-2.58-2.23,2.54,2.54,0,0,0-2.19,1,5.35,5.35,0,0,0-.77,3v.94a5,5,0,0,0,.84,3.09,2.76,2.76,0,0,0,2.34,1.1,3.06,3.06,0,0,0,2.33-.74V22.81h-2.53V21.18h4.66Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M184.6,22.84a5.44,5.44,0,0,1,.53-2.42,4,4,0,0,1,1.5-1.66,4.18,4.18,0,0,1,2.22-.58,4,4,0,0,1,3,1.19,4.7,4.7,0,0,1,1.25,3.17V23a5.55,5.55,0,0,1-.52,2.42,3.85,3.85,0,0,1-1.49,1.65,4.34,4.34,0,0,1-2.24.58,4,4,0,0,1-3.1-1.29,5,5,0,0,1-1.16-3.44Zm2.06.18a3.67,3.67,0,0,0,.58,2.21,1.9,1.9,0,0,0,1.62.8,1.86,1.86,0,0,0,1.62-.81,4.06,4.06,0,0,0,.58-2.38,3.7,3.7,0,0,0-.59-2.2,1.91,1.91,0,0,0-1.62-.81,1.89,1.89,0,0,0-1.6.8A4,4,0,0,0,186.66,23Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M194.46,22.84a5.44,5.44,0,0,1,.53-2.42,4,4,0,0,1,1.5-1.66,4.18,4.18,0,0,1,2.22-.58,4,4,0,0,1,3,1.19A4.7,4.7,0,0,1,203,22.54V23a5.55,5.55,0,0,1-.52,2.42A3.91,3.91,0,0,1,201,27.09a4.34,4.34,0,0,1-2.24.58,4,4,0,0,1-3.1-1.29,5,5,0,0,1-1.16-3.44Zm2.06.18a3.67,3.67,0,0,0,.58,2.21,1.9,1.9,0,0,0,1.62.8,1.86,1.86,0,0,0,1.62-.81,4.06,4.06,0,0,0,.58-2.38,3.63,3.63,0,0,0-.6-2.2,2,2,0,0,0-3.21,0A4,4,0,0,0,196.52,23Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M204.35,22.86a5.36,5.36,0,0,1,1-3.41A3.23,3.23,0,0,1,208,18.18a3,3,0,0,1,2.46,1.09l.09-.92h1.85v8.87a3.67,3.67,0,0,1-1.12,2.84,4.28,4.28,0,0,1-3,1,4.88,4.88,0,0,1-2-.42,3.55,3.55,0,0,1-1.46-1.09l1-1.24a2.94,2.94,0,0,0,2.33,1.13,2.29,2.29,0,0,0,1.62-.56,2.13,2.13,0,0,0,.59-1.62v-.62a3,3,0,0,1-2.37,1,3.23,3.23,0,0,1-2.63-1.28A5.57,5.57,0,0,1,204.35,22.86Zm2,.17A3.71,3.71,0,0,0,207,25.2a1.81,1.81,0,0,0,1.56.79,2,2,0,0,0,1.85-1.06v-4a2,2,0,0,0-1.83-1,1.82,1.82,0,0,0-1.58.81A4.13,4.13,0,0,0,206.39,23Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M216.71,27.5h-2.06v-13h2.06Z"
                        />
                        <path
                          className="g-cls-6"
                          d="M223,27.67a4.24,4.24,0,0,1-3.16-1.23,4.46,4.46,0,0,1-1.22-3.28v-.25a5.51,5.51,0,0,1,.53-2.45,4,4,0,0,1,1.49-1.68,3.89,3.89,0,0,1,2.13-.6,3.6,3.6,0,0,1,2.88,1.19,5.05,5.05,0,0,1,1,3.38v.83h-6a2.81,2.81,0,0,0,.76,1.79,2.27,2.27,0,0,0,1.67.66,2.78,2.78,0,0,0,2.3-1.14l1.11,1.05A3.8,3.8,0,0,1,225,27.22,4.62,4.62,0,0,1,223,27.67Zm-.24-7.84a1.75,1.75,0,0,0-1.37.59,3.11,3.11,0,0,0-.66,1.65h3.91v-.15a2.46,2.46,0,0,0-.55-1.56A1.71,1.71,0,0,0,222.73,19.83Z"
                        />
                      </svg>
                    </SocialButton>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Registerpage;
