import React, { Component } from "react";
import AccountSidebar from "../sidebar";
import AccountForm from "./accountForm";
import MetaTags from "../../metaTags"

class AccountInformation extends Component {
  componentDidMount() {
    document.body.classList.add("account", "account-information");
  }
  componentWillUnmount() {
    document.body.classList.remove("account", "account-information");
  }
  render() {
    return (
      <div className="account-container account-information-container">
        <MetaTags/>
        {/* <div className="wrapper">
          <h1 className="page-title">My Account</h1>
        </div> */}
        <div className="wrapper">
          <AccountSidebar />
          <div className="information-container main-box">
            <h1 className="page-title">My Account</h1>

            <AccountForm />
          </div>
        </div>
      </div>
    );
  }
}

export default AccountInformation;
