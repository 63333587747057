import React, { Component } from "react";

class MembersSearchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: ""
    };
    this.handleSearch = this.handleSearch.bind(this);
  }
  handleSearch(e) {
    var searchValue = e.target.value;
    this.setState({
      searchValue
    });
    this.props.searchValue(searchValue);
  }

  componentDidMount() {}
  componentWillUnmount() {}
  render() {
    return (
      <div className="search-box">
        <div className="search-wrap">
          <input type="text" onChange={this.handleSearch} />
          <div className="icon">
            <svg viewBox="0 0 11.735 11.735">
              <path
                d="M8.125-3.678A3.043,3.043,0,0,0,7.2-5.91a3.043,3.043,0,0,0-2.232-.927,3.043,3.043,0,0,0-2.232.927,3.043,3.043,0,0,0-.927,2.232,3.043,3.043,0,0,0,.927,2.232,3.043,3.043,0,0,0,2.232.927A3.043,3.043,0,0,0,7.2-1.446,3.043,3.043,0,0,0,8.125-3.678ZM11.736,2.19a.868.868,0,0,1-.268.635.868.868,0,0,1-.635.268.838.838,0,0,1-.635-.268L7.779.413a4.83,4.83,0,0,1-2.814.875A4.879,4.879,0,0,1,3.036.9,4.964,4.964,0,0,1,1.449-.162,4.964,4.964,0,0,1,.391-1.749,4.879,4.879,0,0,1,0-3.678,4.879,4.879,0,0,1,.391-5.607,4.964,4.964,0,0,1,1.449-7.194,4.964,4.964,0,0,1,3.036-8.251a4.879,4.879,0,0,1,1.929-.391,4.879,4.879,0,0,1,1.929.391A4.964,4.964,0,0,1,8.481-7.194,4.964,4.964,0,0,1,9.539-5.607,4.879,4.879,0,0,1,9.93-3.678,4.83,4.83,0,0,1,9.056-.864l2.419,2.419A.864.864,0,0,1,11.736,2.19Z"
                transform="translate(0 8.643)"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default MembersSearchView;
