import React, { Component } from "react";

import { authHeader } from "../../_helpers";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;

class PaymentInformationStep extends Component {
  constructor(props) {
    super(props);
    let globalstatus = props.status;
    let orderId = props.orderId;
    this.state = {
      orderId,
      globalstatus,
      paymentMethods: {},
      selectedType: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getPaymentMethods = this.getPaymentMethods.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }
  _asd = false;

  componentDidMount() {
    this.getPaymentMethods();
    this._asd = true;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let globalStatus = nextProps.status;
    this.setState({
      globalStatus
    });
  }
  getPaymentMethods = async () => {
    let orderId = this.state.orderId;
    let response = await axios.get(
      `${apiUrl}/checkout-payment/stepfive?orderId=${orderId}`,
      {
        headers: authHeader()
      }
    );
    let { data } = response;
    if (data.error === null) {
      this.setState({
        paymentMethods: data.payload
      });
    }
  };
  handleChange(e) {
    let {paymentMethods} = this.state
    let selectedType = e.target.id;

    let paymentText = paymentMethods.find(opt => opt.id === Number(selectedType));
    this.setState({
      selectedType
    },()=> {
      this.props.selectedMethod(paymentText)
    });
  }
  handleSubmit() {
    let requestUrl = `/checkout-payment/stepsix`;
    let { selectedType, orderId } = this.state;
    if (selectedType !== "") {
      let data = {
        payment_method: selectedType,
        orderId: orderId
      };
      axios
        .post(apiUrl + requestUrl, data, {
          headers: authHeader()
        })
        .then(res => {
          let resData = res.data;
          if (resData.error === null) {
            this.props.success(true);
            this.props.hendleToFinsh(true);
            let summary = resData.payload.summary;
            this.props.summary(summary);
            this.setState({
                paymentErrorMessage:''
            })
          }
        });
    }else {
      this.setState({
        paymentErrorMessage:'Please select payment method'
      })
    }
  }
  handleEdit() {
    this.props.editPayment(true);
  }

  render() {
    let { paymentMethods, globalStatus, selectedType } = this.state;
    let component = this;
    let paymentText = "";
    if (selectedType !== "") {
      paymentText = paymentMethods.find(opt => opt.id === Number(selectedType));

    }
    if (this._asd !== true) {
      return (
        <div className="wrapper passive-box-wrapper">
          <div className="passive-step-title">
            <span>4. </span>
            <span>Payment Information</span>
          </div>
        </div>
      );
    }
    if (globalStatus === "ready") {
      return (
        <div className="wrapper ready-box-wrapper">
          <div className="ready-container">
            <div className="info-box">
              <div className="passive-step-title">
                <span>4 </span>
                <span>Payment Information</span>
              </div>
              <div className="name">{paymentText.name}</div>
            </div>
            <div onClick={this.handleEdit} className="edit">
              Edit
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div className="wrapper checkout-form-wrapper form">
            <React.Fragment>
              <div className="step-title">
                <span>4. </span>
                <span>Payment Information</span>
              </div>
              {Object.keys(paymentMethods).map(function(i) {
                return (
                  <div className="row clear" key={i}>
                    <div className="item checkbox">
                      <input
                        className="css-checkbox"
                        id={paymentMethods[i].id}
                        type="radio"
                        name="paymentMethodChoose"
                        onChange={component.handleChange}
                        defaultChecked={paymentMethods[i].id === selectedType ? true : false}
                      />
                      <label
                        className="css-label"
                        htmlFor={paymentMethods[i].id}
                      >
                        {paymentMethods[i].name}
                      </label>
                    </div>
                  </div>
                );
              })}
               <div className="invalid-feedback">
                {this.state.paymentErrorMessage}
              </div>
              <div className="button-row">
                <div onClick={this.handleSubmit}>Finish</div>
              </div>
            </React.Fragment>
          </div>
        </React.Fragment>
      );
    }
  }
}
export default PaymentInformationStep;