import React, { Component } from "react";
import DropboxChooser from "react-dropbox-chooser";

class Dropbox extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.state = {
      selectedItem: []
    };
  }

  onSuccess(files) {
    this.props.onSuccess(files);
    // console.log(files)
  }
  onCancel(e) {}
  render() {
    return (
      <DropboxChooser
        appKey={"3rolezc3fi4e7tl"}
        success={files => this.onSuccess(files)}
        cancel={() => this.onCancel()}
        multiselect={true}
        extensions={[".pdf", "images"]}
      >
        <div id="dropbox">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="30"
              viewBox="0 0 41 30"
            >
              <g transform="translate(-483 -789)">
                <rect
                  width="41"
                  height="30"
                  transform="translate(483 789)"
                  fill="none"
                />
                <g transform="translate(489 792)">
                  <path
                    d="M-2955.208,4287.539l-7.056,4.494,7.056,4.493-7.056,4.494-7.055-4.494,7.055-4.493-7.055-4.494,7.055-4.494Zm-7.077,14.972,7.056-4.494,7.056,4.494-7.056,4.494Zm7.077-5.985,7.056-4.493-7.056-4.494,7.056-4.494,7.056,4.494-7.056,4.494,7.056,4.493-7.056,4.494Z"
                    transform="translate(2969.32 -4283.044)"
                    fill="#0061ff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="name">Dropbox</div>
        </div>
      </DropboxChooser>
    );
  }
}
export default Dropbox;
