import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

class OrderFailed extends Component {
  componentDidMount() {
    document.body.classList.add("order-page", "order-failed");
    window.scroll(0, 0);
  }
  componentWillUnmount() {
    document.body.classList.remove("order-page", "order-failed");
  }

  render() {
    return (
      <div className="order-failed-container">
        <Helmet>
          <title>Payment failed | Flexoeasy.com</title>
          <meta name="title" content={'Payment failed | Flexoeasy.com'} />
          <meta name="description" content={'Flexoeasy.com is a digital platform that makes flexographic plate-making easy and allows customers to access high-quality printing from anywhere in the world.'} />

        </Helmet>
        <div className="wrapper">
          <div className="table">
            <div className="cell">
              <div className="icon">
                <svg
                  width="57px"
                  height="57px"
                  viewBox="0 0 57 57"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      id="Splash-Copy"
                      transform="translate(-159.000000, -201.000000)"
                    >
                      <g
                        id="ic_error_24px"
                        transform="translate(159.000000, 201.000000)"
                      >
                        <mask id="mask-2" fill="white">
                          <path
                            d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                            id="path-1"
                          ></path>
                        </mask>
                        <g id="Shape" fillRule="nonzero"></g>
                        <g mask="url(#mask-2)" fill="#EE1D4E">
                          <g
                            transform="translate(-5.000000, -5.000000)"
                            id="Rectangle"
                          >
                            <rect x="0" y="0" width="69" height="69"></rect>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>

              <div className="title">Sorry, your payment failed.</div>
              <div className="text">No charges were made.</div>
              <div className="button-box">
                <Link className="button" to="/dashboard">
                  Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderFailed;
