import React, { Component } from "react";
import Select from "react-select";
import { authHeader } from "../../../_helpers";
import InputMask from "react-input-mask";

import validator from "validator";
import FormValidator from "../../validations";
import ReactModal from "react-modal";
import axios from "axios";
import SVG from "../../svg.json"

const apiUrl = process.env.REACT_APP_API;

class ProductOptions extends Component {
  constructor(props) {
    super(props);

    const productIsExist = props.productIsExist;
    const productId = props.productId;
    const productIsTesting = props.productIsTesting;
    const productOptions = props.options;
    const projectDescription = props.projectDescription;
    const projectName = props.projectName;
    const orderId = props.orderId;
    const orderStatusId = props.orderStatusId
    this.state = {
      productIsExist,
      orderId,
      productId,
      productIsTesting,
      showSuccessModal: false,
      errors: [],
      errorClasses: [],
      template: "",
      productOptions: productOptions,
      productField: [],
      productCheckBoxList: [],
      productGlobalInfo: {
        projectName,
        projectDescription
      },
      saveAsTemplate: false,
      productTemplate: "",
      showUploadModal: false,
      dragging: false,
      globalUploadeCount: 0,
      selectedFile: [],
      urlToLargeImageFile: "",
      uploadedFiles: [],
      showPermissionModal:false,
      nothingChagedMessage:'',
      orderStatusId
    };

    //this.getProductOption = this.getProductOption.bind(this);
    this.getProductChildren = this.getProductChildren.bind(this);
    this.drawOptions = this.drawOptions.bind(this);
    this.configOptions = this.configOptions.bind(this);
    this.drawNumberField = this.drawNumberField.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.setupProductForState = this.setupProductForState.bind(this);
    this.globalInfoHandleChange = this.globalInfoHandleChange.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSwatchChange = this.handleSwatchChange.bind(this);

    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.hideSuccessModal = this.hideSuccessModal.bind(this);

    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);

    this.showPermissionModal = this.showPermissionModal.bind(this);
    this.hidePermissionModal = this.hidePermissionModal.bind(this);
  }

  _isMounted = false;
  _isProductField = false;
  hideSuccessModal() {
    this.setState({
      showSuccessModal: false
    });
  }
  showSuccessModal() {
    this.setState({
      showSuccessModal: true
    });
  }
  hideFaildModal() {
    this.setState({
      showFaildModal: false,
      nothingChagedMessage:''
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  hidePermissionModal() {
    this.setState({
      showPermissionModal: false
    });
  }
  showPermissionModal() {
    this.setState({
      showPermissionModal: true
    });
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let productUrlName = nextProps.productUrlName;
    let productIsExist = nextProps.productIsExist;
    let handleSubmit = nextProps.handleSubmit;

    const productId = nextProps.productId;
    if (productIsExist) {
      this._isMounted = true;
      this.setState(
        {
          productId,
          productUrlName,
          productIsExist
        },
        () => {}
      );
    }
    if (handleSubmit) {
      this.handleSubmit();
    }
    // console.log(" submit : " + handleSubmit);
  }

  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    let { productOptions, productField } = this.state;
    const validationRules = [];
    Object.keys(productOptions).map(function(i) {
      if (productOptions[i].required === true && productOptions[i].fieldType !== 'checkbox') {
        var validationRule = {
          field: productOptions[i].id,
          method: validator.isEmpty,
          validWhen: false,
          message: "This field is required"
        };
        if (productOptions[i].fieldType === "number" && productOptions[i].min !== undefined) {
          let fieldMin = productOptions[i].min;
          let fieldMax = productOptions[i].max;
          let units = productOptions[i].units;

          let unitsText = "";

          if (units === "mm") {
            unitsText = "mm";
          }
          if (units === "percent") {
            unitsText = "%";
          }
          validationRule = {
            field: productOptions[i].id,
            method: validator.isFloat,
            args: [
              {
                min: fieldMin,
                max: fieldMax
              }
            ],
            validWhen: true,
            message: `minimum ${fieldMin}${unitsText} and maximum ${fieldMax}${unitsText}`
          };
        }
        if (productOptions[i].fieldType === "cylinder") {
          validationRule = {
            field: productOptions[i].id,
            method: validator.isLength,
            args: [
              {
                min: 4
              }
            ],
            validWhen: true,
            message: "Minimum digits qty : 4"
          };
        }
        validationRules.push(validationRule);
      }

      return validationRule;
    });
    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(productField);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleSubmit = async e => {
    let { productField, orderId, productCheckBoxList, productOptions, errors } = this.state;
      let currentComponent = this

    if (this.handleValidation()) {
      for (let [key] of Object.entries(productCheckBoxList)) {
        let productCheckBoxRequired = productOptions.find(opt => opt.id === Number(key)).required
          
          if (productCheckBoxRequired && Object.values(productCheckBoxList[key]).indexOf(true) === -1) {
            errors[key] = 'Pleas select at last one item';
            currentComponent.setState({
              errors,
            });
            return false;
          }
      }
      let currentUser = JSON.parse(localStorage.getItem('user'))
      let userPermission = JSON.parse(localStorage.getItem('permissions'))
      let VIEW_SPECIFICATION;
      if(userPermission) {
        VIEW_SPECIFICATION = userPermission.find(
          opt => opt.key === 'VIEW_SPECIFICATION')
          
      }

      if(currentUser.isMember === false || (currentUser.isMember === true && VIEW_SPECIFICATION !== undefined)){
        let loader = document.getElementById("global-loader");
        loader.classList.add("show");
        var selected = {};

        productField.map((item, key) => {
          return selected[key] = item;
        });
        Object.keys(productCheckBoxList).map(function(key , item) {
          return selected[key] = productCheckBoxList[key]
        })
        let postData = {
          optionsJson: JSON.stringify(selected)
        };

        // console.log(postData);
        let requestUrl = `/orderdetails/changeOrderOptions/${orderId}`;
        let res = await axios.post(apiUrl + requestUrl, postData, {
          headers: authHeader()
        });

        let { data } = res;

        if (data.error === null) {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
          this.showSuccessModal();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
          if(data.error.code === 400) {
            this.setState({
              nothingChagedMessage:'Please add/modify missing/wrong attributes.',
              showFaildModal: true
            })
          }else {
            this.setState({
              showFaildModal: true,
            });
          }
          
        }
      }else {
        this.setState({
          showPermissionModal:true
        })
      }
    }
  };

  componentDidMount() {
    let {
      productOptions,
      productIsTesting
    } = this.state;
    // if (templateId !== "" && templateId !== undefined) {
    // }
    let user = JSON.parse(localStorage.getItem("user"));

    if (user && productIsTesting === false) {
      this.getTemplateByProduct();
    } else {
      // this.getProductOption(productUrlName);
      this.setupProductForState(productOptions);
    }
  }

  getProductChildren = async (templateData = "") => {
    let loader = document.getElementById("loader");
    if (loader !== null) {
      loader.classList.add("show");
    }
    let requestUrl = `/getoptionchildren`;
    let { productId, productField,productCheckBoxList } = this.state;
    var selected = {};
    if (templateData === "") {
      productField.map((item, key) => {
       return selected[key] = item;
      });
      Object.keys(productCheckBoxList).map(function(key , item) {
        return selected[key] = productCheckBoxList[key]
      })
    } else {
      selected = templateData;
    }
    const queryParams = {
      productId: productId,
      selected: templateData === "" ? JSON.stringify(selected) : selected
    };
    // queryParams.productId = productId;
    // queryParams.selected = JSON.stringify(productField);
    let res = await axios.post(apiUrl + requestUrl, queryParams);

    let { data } = res;
    this.setupProductForState(data.payload.options);
    this.setState({
      productOptions:[]
    })
    this.setState(
      {
        productOptions: data.payload.options
      },
      () => {
        this.calculatePrice();
        if (loader !== null && loader.classList.contains("show")) {
          loader.classList.remove("show");
        }
      }
    );
  };

  configOptions(options) {
    if (options.length === 0) return [];
    const customizedOptions = [];
    const oneFieldsOptions = ["swatch", "textarea", "checkbox", "staticText", "downloadableFile"];
    for (let i = 0; i <= options.length - 1; i++) {
      const item = options[i];
      if(item.isForOneLine) {
        customizedOptions.push({
          items: [item],
          half: false,
          isForOneLine:true
        });
      }else {
        if (oneFieldsOptions.includes(item.fieldType)) {
          customizedOptions.push({
            items: [item],
            half: false,
            isForOneLine:false
          });
        } else {
          const lastItem = customizedOptions[customizedOptions.length - 1];
          if (
            lastItem &&
            lastItem !== undefined &&
            lastItem.half &&
            lastItem.items.length === 1
          ) {
            customizedOptions[customizedOptions.length - 1].items.push(item);
          } else {
            customizedOptions.push({
              items: [item],
              half: true,
              isForOneLine:false
            });
          }
        }
      }
    }
    return customizedOptions;
  }

  setupProductForState(data) {
   
    let field = [];
    let customTemplateField = [];
    const checkBoxList = {};
    var selected = {}
    let customTemplateCheckBoxList = {};


    for (var i = 0; i <= data.length - 1; i++) {
      const item = data[i];
      if (item.fieldType === "swatch") {
        if (item.value !== "") {
          field[item.id] = item.value;
          if(item.isForCustomTemplate) {
            customTemplateField[item.id] = item.value
          }
        } else {
          field[item.id] = ``;
          if(item.isForCustomTemplate) {
            customTemplateField[item.id] = `${item.optionsFields[0].id}`;
          }
        }
      } else if (item.fieldType === "downloadableFile"){
        if (item.value !== "") {
          field[item.id] = item.value;
        } else {
          field[item.id] = ``;
        }
      }  else if(item.fieldType === "checkbox"){
        let itemId = item.id;
        let options = item.optionsFields;
        
        for(var j=0; j <= options.length-1; j++) {
          const optionItem = options[j]
          const optionID =  optionItem.id
          const optionVal =  optionItem.selected
          selected[optionID] = optionVal
          if(optionItem.parentId === itemId) {
            checkBoxList[itemId] = checkBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
            checkBoxList[itemId][optionID] = optionVal
          }
        }
        if(item.isForCustomTemplate) {
          for(var j1=0; j1 <= options.length-1; j1++) {
            const optionItem = options[j1]
            const optionID =  optionItem.id
            const optionVal =  optionItem.selected
            if(optionItem.parentId === itemId) {
              customTemplateCheckBoxList[itemId] = customTemplateCheckBoxList[itemId] === undefined ? {} : checkBoxList[itemId]
              customTemplateCheckBoxList[itemId][optionID] = optionVal
            }
          }
        }
      } else if(item.fieldType === "staticText") {
         
      }else {
        field[item.id] = item.value;
        if(item.isForCustomTemplate) {
          customTemplateField[item.id] = item.value;
        }
      }
    }
    this.setState(
      {
        productCheckBoxList:checkBoxList,
        customTemplateCheckBoxList:customTemplateCheckBoxList,
        checkBox:selected,
        productField: field,
        customTemplateProductField: customTemplateField,
        productFieldReady: true
      },
      () => {}
    );
  }
  drawOptions() {
    let { productOptions } = this.state;
    const productOptionData = this.configOptions(productOptions);
    // console.log(productOptionData);
    return productOptionData.map((list, key) => {
      let items = list.items;
      if(list.isForOneLine) {
        return items.map((item, key) => {
          if (item.fieldType === "downloadableFile") {
            return this.drawDownloadableFileSwatchField(item);
          }
          if (item.fieldType === "swatch") {
            return this.drawSwatchField(item);
          }
          if (item.fieldType === "textarea") {
            return this.drawTextAreaField(item);
          }
          if(item.fieldType === "checkbox") {
            return this.drawCheckboxField(item);
          }
          if(item.fieldType === "staticText") {
            return this.drawStaticText(item);
          }

          if (item.fieldType === "number") {
            return (
              <div className="row row-half clear" key={item.id}>
                {this.drawNumberField(item)}
              </div>
            )
          }
          if (item.fieldType === "cylinder") {
            return (
              <div className="row row-half clear" key={item.id}>
                {this.drawCylinderField(item)}
              </div>
            )
          }
          if (item.fieldType === "text") {
            return (
              <div className="row row-half clear" key={item.id}>
                {this.drawTextField(item)}
              </div>
            )
          }
          if (item.fieldType === "dropdown") {
            return (
              <div className="row row-half clear" key={item.id}>
                {this.drawDropDownField(item)}
              </div>
            )
          }
          return true
        });
      }else {
        if (list.half) {
          return (
            <div className="row row-half clear" key={key}>
              {items.map((item, key) => {
                if (item.fieldType === "number") {
                  return this.drawNumberField(item);
                }
                if (item.fieldType === "cylinder") {
                  return this.drawCylinderField(item);
                }
                if (item.fieldType === "text") {
                  return this.drawTextField(item);
                }
                if (item.fieldType === "dropdown") {
                  return this.drawDropDownField(item);
                }
                return true
              })}
            </div>
          );
        } else {
          return items.map((item, key) => {
            if (item.fieldType === "downloadableFile") {
              return this.drawDownloadableFileSwatchField(item);
            }
            if (item.fieldType === "swatch") {
              return this.drawSwatchField(item);
            }
            if (item.fieldType === "textarea") {
              return this.drawTextAreaField(item);
            }
            if(item.fieldType === "checkbox") {
              return this.drawCheckboxField(item);
            }
            if(item.fieldType === "staticText") {
              return this.drawStaticText(item);
            }
            return true
          });
        }
      }
      
    });
  }
  drawStaticText(data) {
    return (
      <div className="row row-static-text" key={data.id}>
       {data.description}
      </div>
    );
  }

  drawNumberField(data) {
    // console.log(data);
    const { errors, errorClasses, productField } = this.state;

    let fieldLabelText = "";

    if (data.units === "mm") {
      fieldLabelText = "mm";
    }
    if (data.units === "percent") {
      fieldLabelText = "%";
    }
    return (
      <div className="item" key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className={`wrap-box ${data.units}`}>
          <input
            value={productField[data.id] ? productField[data.id] : ''}
            id={data.id}
            min={data.min}
            max={data.max}
            type={'text'}
            data-type={data.fieldType}
            name={data.id}
            className={errorClasses[[data.id]]}
            onChange={this.handleChange}
            placeholder={data.min ? `Min:${data.min}${fieldLabelText} - Max:${data.max}${fieldLabelText}` : ''}
            data-unit={fieldLabelText}
            data-filed={data.isNumeric !== undefined ? 'isNumeric' : 'isFloat'}
            disabled={this.state.orderStatusId !== 2}

          />

          {this.state.orderStatusId !== 2 ? <div className="locked-icon-wrapper">
            <svg viewBox="0 0 20 20" id="locked">
              <path
                  d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
            </svg>
          </div> : null}

          {fieldLabelText ? (
            <div className="field-label">{fieldLabelText}</div>
          ) : (
            ""
          )}
        </div>
        <div className="invalid-feedback">{errors[[data.id]]}</div>
      </div>
    );
  }

  drawCylinderField(data) {
    const { errors, errorClasses, productField } = this.state;

    let fieldLabelText = "";

    if (data.units === "mm") {
      fieldLabelText = "mm";
    }
    if (data.units === "percent") {
      fieldLabelText = "%";
    }
    return (
      <div className="item" key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className={`wrap-box ${data.units}`}>
          <InputMask
          
            id={data.id}
            min={data.min}
            max={data.max}
            type={'text'}
            data-type={data.fieldType}
            name={data.id}
            className={errorClasses[[data.id]]}
            data-unit={fieldLabelText}
            value={productField[data.id] ? productField[data.id].toString() : ''}
            mask="9 9 9 9"
            // maskchar= '_'
            maskPlaceholder="_"
            onChange={this.handleChange}
          />
          {fieldLabelText ? (
            <div className="field-label">{fieldLabelText}</div>
          ) : (
            ""
          )}
        </div>
        <div className="invalid-feedback">{errors[[data.id]]}</div>
        {data.description !== '' && data.description !== undefined ? (
          <div className="tooltip">
            <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
              <path
                d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                transform="translate(6544 153.999)"
                fill="#8f9293"/>
            </svg>
            <div class="left">
                {data.description}
            </div>
        </div>
        ):('')}
      </div>
    );
  }

  drawTextField(data) {
    // console.log(data);
    const { errors, errorClasses, productField } = this.state;
    return (
      <div className="item " key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className="wrap-box">
          <input
              value={productField[data.id] ? productField[data.id] : ''}
              onKeyPress={this.handleKeyPress}
              type={data.fieldType}
              name={data.id}
              className={errorClasses[[data.id]]}
              onChange={this.handleChange}
              disabled = {this.state.orderStatusId !== 2}
          />
          {this.state.orderStatusId !== 2 ? <div className="locked-icon-wrapper">
            <svg viewBox="0 0 20 20" id="locked">
              <path
                  d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
            </svg>
          </div> : null}
        </div>

        <div className="invalid-feedback">{errors[[data.id]]}</div>
      </div>
    );
  }

  drawDropDownField(data) {
    // console.log(data.optionsFields);
    const { errors, errorClasses, productField } = this.state;
    return (
      <div className="item" key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className="wrap-box">
          <Select
              classNamePrefix="react-select"
              isMulti={false}
              isSearchable={false}
              value={[
                data.optionsFields.find(
                    opt => opt.value === Number(productField[data.id])
                )
              ]}
              name={data.id}
              options={data.optionsFields}
              onChange={(newValue, actionMeta) =>
                  this.dropdownChange(newValue, actionMeta)
              }
              data-refresh={data.need_reload}
              className={errorClasses[data.id] + " react-select"}
              placeholder="Please Select"
              isDisabled={this.state.orderStatusId !== 2}
          />
          {this.state.orderStatusId !== 2 ? <div className="locked-icon-wrapper">
            <svg viewBox="0 0 20 20" id="locked">
              <path
                  d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
            </svg>
          </div> : null}
        </div>

        <div className="invalid-feedback">{errors[data.id]}</div>
      </div>
    );
  }
  drawSvg(data) {
    return SVG[data]
  }
  drawDownloadableFileSwatchField(data) {
    // console.log(data);
    const { errors} = this.state;
    let description = data.description;
        description = description.replace(/(\r\n|\n|\r)/gm, "<br />");
    return (
        <div className={`row row-swatch clear`} key={data.id}>
          <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
            <div className="wrap-box">
              {data.optionsFields.map((list, key) => {
                return (
                  <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                    <input
                      type="radio"
                      id={list.id}
                      defaultChecked={list.id === Number(data.value) ? true : false}
                      name={data.id}
                      onChange={this.handleSwatchChange}
                      data-refresh={data.need_reload}
                      data-downloadable={list.hasAttachFile}
                      disabled={this.state.orderStatusId !== 2}
                    />
                    
                    <label
                      htmlFor={list.id}
                      className={`custom-label-radio  ${list.image || list.sku ? "with-image" : ""}`}
                    >
                      {list.sku !== '' && list.sku !== undefined ? 
                        (
                          <div dangerouslySetInnerHTML={{
                            __html: this.drawSvg(list.sku)
                          }}>
                          </div>
                        )
                      :
                    
                      list.image ? <img src={list.image} alt={list.title} /> : 
                        (
                          <div className="icons">
                            <svg className="icon-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                              <rect className="cls-1" width="14" height="14"/><polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                            </svg>
                            <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                <rect className="cls-1" width="14" height="14"/>
                                <circle className="cls-2" cx="7" cy="7" r="3.63"/>
                            </svg>
                          </div>
                        )
                      }
                      
                      <div className="text">
                        <div className="text-box">
                          {list.title}
                        </div>
                      </div>
                    </label>
                    
                  </div>
                );
              })}
              <div className="invalid-feedback">{errors[[data.id]]}</div>

              {data.description !== '' && data.description !== undefined ? (
                <div className="tooltip">
                  <svg className="question-icon" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                    <path
                      d="M-6494-54a49.689,49.689,0,0,1-19.463-3.929,50.069,50.069,0,0,1-8.493-4.61,50.362,50.362,0,0,1-7.4-6.105,50.354,50.354,0,0,1-6.105-7.4,49.954,49.954,0,0,1-4.609-8.493A49.679,49.679,0,0,1-6544-104a49.682,49.682,0,0,1,3.93-19.463,49.957,49.957,0,0,1,4.609-8.493,50.361,50.361,0,0,1,6.105-7.4,50.362,50.362,0,0,1,7.4-6.105,50.072,50.072,0,0,1,8.493-4.61A49.689,49.689,0,0,1-6494-154a49.688,49.688,0,0,1,19.462,3.929,50.013,50.013,0,0,1,8.493,4.61,50.382,50.382,0,0,1,7.4,6.105,50.416,50.416,0,0,1,6.105,7.4,50.031,50.031,0,0,1,4.609,8.493A49.688,49.688,0,0,1-6444-104a49.685,49.685,0,0,1-3.93,19.462,50.028,50.028,0,0,1-4.609,8.493,50.416,50.416,0,0,1-6.105,7.4,50.381,50.381,0,0,1-7.4,6.105,50.018,50.018,0,0,1-8.493,4.61A49.688,49.688,0,0,1-6494-54Zm-.4-31.651a6.4,6.4,0,0,0-4.606,1.732,5.873,5.873,0,0,0-1.8,4.4,5.822,5.822,0,0,0,1.761,4.341,6.4,6.4,0,0,0,4.646,1.712,6.412,6.412,0,0,0,4.647-1.712A5.826,5.826,0,0,0-6488-79.517a5.873,5.873,0,0,0-1.8-4.4A6.4,6.4,0,0,0-6494.4-85.65Zm.791-37.675a6,6,0,0,1,4.627,1.683,7.283,7.283,0,0,1,1.543,5.049,8.251,8.251,0,0,1-1.187,4.241,25.425,25.425,0,0,1-4.765,5.217,18.287,18.287,0,0,0-4.924,6.511,24.192,24.192,0,0,0-1.344,8.881h10.122l.158-2.43a11,11,0,0,1,3.441-6.81l3.2-3.066a26.4,26.4,0,0,0,5.26-6.671,14.161,14.161,0,0,0,1.5-6.392,17.714,17.714,0,0,0-1.156-6.606,12.939,12.939,0,0,0-3.47-4.923,15.214,15.214,0,0,0-5.566-3.062,24.975,24.975,0,0,0-7.444-1.021,23.885,23.885,0,0,0-7.429,1.075,15.839,15.839,0,0,0-5.68,3.226,13.9,13.9,0,0,0-3.623,5.127,18.328,18.328,0,0,0-1.261,6.78h11.467a7.179,7.179,0,0,1,1.839-5A6.264,6.264,0,0,1-6493.613-123.325Z"
                      transform="translate(6544 153.999)"
                      fill="#8f9293"/>
                  </svg>
                  <div className="left" dangerouslySetInnerHTML={{
                      __html: description
                    }}>
                  </div>
              </div>
            ):('')}
            </div>
        </div>
     

    );
  }
  drawSwatchField(data) {
    return (
      <div className="row row-swatch clear" key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className="wrap-box">
          {data.optionsFields.map((list, key) => {
            return (
              <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                <input
                  type="radio"
                  id={list.id}
                  defaultChecked={list.id === Number(data.value) ? true : false}
                  name={data.id}
                  onChange={this.handleSwatchChange}
                  data-refresh={data.need_reload}
                  disabled={this.state.orderStatusId !== 2}
                />
                 <label
                  htmlFor={list.id}
                  className={`custom-label-radio  ${list.image || list.sku ? "with-image" : ""}`}
                >
                  {list.sku !== '' && list.sku !== undefined ? 
                    (
                      <div dangerouslySetInnerHTML={{
                        __html: this.drawSvg(list.sku)
                      }}>
                      </div>
                    )
                  :
                
                  list.image ? <img src={list.image} alt={list.title} /> : 
                    (
                      <div className="icons">
                        <svg className="icon-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                          <rect className="cls-1" width="14" height="14"/><polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                        </svg>
                        <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                            <rect className="cls-1" width="14" height="14"/>
                            <circle className="cls-2" cx="7" cy="7" r="3.63"/>
                        </svg>
                      </div>
                    )
                  }
                  
                  <div className="text">
                    <div className="text-box">
                      {list.title}
                    </div>
                  </div>
                </label>
              
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  drawCheckboxField(data) {
    const { errors} = this.state;
    return (
      <div className="row row-checkbox clear" key={data.id}>
        <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
        <div className="wrap-box">
          {data.optionsFields.map((list, key) => {
            return (
              <div className={`item ${list.image || list.sku ? "with-image" : ""}`} key={list.id}>
                <input
                  type="checkbox"
                  id={list.id}
                  defaultChecked={list.selected}
                  name={data.id}
                  onChange={this.handleCheckBoxChange}
                  data-refresh={data.need_reload}
                  disabled = {this.state.orderStatusId !== 2}
                />
                <label
                  htmlFor={list.id}
                  className={`custom-label-checkbox  ${list.image ? "with-image" : ""}`}
                >
                  <div className="icons">
                    <svg className="icon-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <rect className="cls-1" width="14" height="14"/><polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                    </svg>
                    <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                      <rect className="cls-1" width="14" height="14"/><path className="cls-2" d="M7,11.5v-9M2.5,7h9"/>
                    </svg>
                  </div>
                  {list.image ? <img src={list.image} alt={list.title} /> : ""}
                  <div className="text">
                    <div className="text-box">
                      {list.title}
                    </div>
                  </div>
                </label>
              </div>
            );
          })}
        </div>
        <div className="invalid-feedback">{errors[[data.id]]}</div>
      </div>
    );
  }

  drawTextAreaField(data) {
    // console.log(data);
    const { errors, errorClasses, productField } = this.state;
    return (
      <div className="row" key={data.id}>
        <div className="item" key={data.id}>
          <div className="label"><span className="name">{data.label}</span> {data.required ? (<span className="sign">*</span>) : ''}</div>
          <div className="wrap-box">
           <textarea
               value={productField[data.id]}
               onKeyPress={this.handleKeyPress}
               name={data.id}
               className={errorClasses[[data.id]]}
               onChange={this.handleChange}
               disabled = {this.state.orderStatusId !== 2}
           />
            {this.state.orderStatusId != 2 ? <div className="locked-icon-wrapper">
              <svg viewBox="0 0 20 20" id="locked">
                <path
                    d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
              </svg>
            </div> : null}
          </div>
          <div className="invalid-feedback">{errors[[data.id]]}</div>
        </div>
      </div>
    );
  }

  handleChange(e) {
    let _this = e.target;
    let { errors, errorClasses,productField } = this.state;
    const name = _this.name;
    const type = _this.getAttribute('data-type');
    let value = _this.value;
    const filed = _this.getAttribute('data-filed');
    
    // if(type === 'cylinder') {
    //   value = value.replace(/[_\s]/g, '');
    // }
    productField[name] = value;
    if (type === "number") {
      if(filed === 'isFloat' && /^-?\d*[.]?\d{0,2}$/.test(value) && (value === "" || parseInt(value,0) <= 999999)) {
        const fieldMin = parseFloat(_this.min);
        const fieldMax = parseFloat(_this.max);
        value = parseFloat(value);
        let unitText = _this.getAttribute("data-unit");
        if (value < fieldMin) {
          errors[name] = `Minimum must be ${fieldMin}${unitText}`;
          errorClasses[name] = "is-invalid";
  
          this.setState({
            errors,
            errorClasses
          });
        } else if (value > fieldMax) {
          errors[name] = `Maximum must be ${fieldMax}${unitText}`;
          errorClasses[name] = "is-invalid";
  
          this.setState({
            errors,
            errorClasses
          });
        } else {
          errors[name] = "";
          errorClasses[name] = "";
          this.setState({
            errors,
            errorClasses
          });
          // console.log(value);
          if (!isNaN(value)) {
            if (value !== undefined && value !== "") {
              // console.log("request");
              this.calculatePrice();
            }
          }
        }
       }
       if(filed === 'isNumeric' && /^[0-9]*$/.test(value) && (value === "" || parseInt(value,0) <= 999999)) {
        const fieldMin = parseFloat(_this.min);
        const fieldMax = parseFloat(_this.max);
        value = parseFloat(value);
        let unitText = _this.getAttribute("data-unit");
        if (value < fieldMin) {
          errors[name] = `Minimum must be ${fieldMin}${unitText}`;
          errorClasses[name] = "is-invalid";
  
          this.setState({
            errors,
            errorClasses
          });
        } else if (value > fieldMax) {
          errors[name] = `Maximum must be ${fieldMax}${unitText}`;
          errorClasses[name] = "is-invalid";
  
          this.setState({
            errors,
            errorClasses
          });
        } else {
          errors[name] = "";
          errorClasses[name] = "";
          this.setState({
            errors,
            errorClasses
          });
          // console.log(value);
          if (!isNaN(value)) {
            if (value !== undefined && value !== "") {
              // console.log("request");
              this.calculatePrice();
            }
          }
        }
       }
    } else {
      this.setState({
        productField,
        productTemplate: ""
      });
    }
  }

  handleSwatchChange(e) {
    const name = e.target.name;
    const productField = this.state.productField;
    productField[name] = e.target.id;
    // console.log(name);
    // console.log(e.target.id);
    
    
    let dataReload = e.target.getAttribute('data-refresh')

    if(dataReload === 'true') {
      this.setState({
        productField,
        productTemplate: ""
      });
      this.getProductChildren();

    }else {
      this.setState(
        {
          productField,
          productTemplate: ""
        },
        () => {
          this.calculatePrice();
        }
      );
    }
  }
  handleCheckBoxChange(e) {
    const name = e.target.name;
    const {productCheckBoxList, errors} = this.state
    errors[name] = ''
    let dataReload = e.target.getAttribute('data-refresh')
    if (e.target.checked) {
      productCheckBoxList[name][e.target.id] = true;
    } else {
      productCheckBoxList[name][e.target.id] = false;
    }
    if(dataReload === 'true') {
      this.setState({
        productCheckBoxList,
        productTemplate: "",
        errors
      });
      this.getProductChildren();
    }else {
      this.setState(
        {
          productCheckBoxList,
          productTemplate: "",
          errors
        },
        () => {
          this.calculatePrice();
        }
      );
    }
  }
  dropdownChange(val, name) {
    const productField = this.state.productField;
    let selectedOptionValue = val.value;
    let field = document.getElementsByName(`${name.name}`);
    // console.log(typeof this.state.productOptions);
    // console.log(name.name);

    let result = this.state.productOptions.filter(obj => {
      return obj.id === name.name;
    });

    // console.log(result[0].need_reload);
    let fieldName = name.name;

    if (result[0].need_reload === true) {
      productField[fieldName] = `${selectedOptionValue}`;
      this.setState({
        productField,
        productTemplate: ""
      });
      this.getProductChildren();
    } else {
      // console.log(field.getAttribute("data-refresh"));
      if (val.pdf_file !== undefined && val.pdf_file !== "") {
        if (field[0].nextSibling) {
          field[0].nextSibling.remove();
        }
        let pdfUrl = val.pdf_file;
        // console.log(field[0].parentNode);
        if (!field[0].nextSibling) {
          let html = ` <a target="_blank" href="${pdfUrl}">
        <svg class="pdf-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681 679.28">
        <g>
        <g>
        <path class="cls-1" d="M116.61,181.54V53.31A36.31,36.31,0,0,1,152.92,17H627.69A36.31,36.31,0,0,1,664,53.31V626a36.31,36.31,0,0,1-36.31,36.31H152.92A36.31,36.31,0,0,1,116.61,626V499.54M584,463.85V217.22a35.69,35.69,0,0,0-35.69-35.68H52.69A35.69,35.69,0,0,0,17,217.22V463.85a35.69,35.69,0,0,0,35.69,35.69H548.31A35.69,35.69,0,0,0,584,463.85ZM103,423.54V262.77M269.2,423.54V262.77m237.8,0H436V423.54M103,357.73h36.52A47.48,47.48,0,0,0,187,310.25h0a47.48,47.48,0,0,0-47.48-47.48H103M269.2,423.54h33.13a52.31,52.31,0,0,0,52.31-52.31V315.07a52.3,52.3,0,0,0-52.31-52.3H269.2M496,342H436"/>
        </g>
        </g>
        </a> `;
          let div = document.createElement("div");
          div.innerHTML = html;
          div.classList.add("option-pdf");
          // console.log(div);
          field[0].parentNode.appendChild(div);
          // console.log(field[0].previousElementSibling);
        }
      } else {
        if (field[0].nextSibling) {
          field[0].nextSibling.remove();
        }
      }
      // console.log(selectedOptionValue ,fieldName)
      productField[fieldName] = `${selectedOptionValue}`;
      this.setState(
        {
          productField,
          productTemplate: ""
        },
        () => {
          this.calculatePrice();
        }
      );
    }
  }
  calculatePrice = async () => {
    // let requestUrl = `/calcprice`;
    // let currentComponent = this;
    // let { productId, productField } = this.state;
    // var selected = {};
    // productField.map((item, key) => {
    //   selected[key] = item;
    // });
    // const queryParams = {
    //   productId: productId,
    //   selected: JSON.stringify(selected)
    // };
    // // queryParams.productId = productId;
    // // queryParams.selected = JSON.stringify(productField);
    // let res = await axios.post(apiUrl + requestUrl, queryParams);
    // let { data } = res;
    // if (data.error === null) {
    //   this.props.productPrice(data.payload.priceInclTax);
    //   if (data.payload.priceInclTax > 0) {
    //     let loader = document.getElementById("price-loader");
    //     loader.classList.add("show");
    //   }
    // }
  };

  globalInfoHandleChange(e) {
    let _this = e.target;
    const productGlobalInfo = this.state.productGlobalInfo;
    let value = _this.value;

    const name = _this.name;

    productGlobalInfo[name] = value;
    this.setState({
      productGlobalInfo,
      productTemplate: ""
    });
  }

  onCancel(e) {}
  render() {
    if (this.state.productFieldReady !== true) {
      return false;
    }

    const {
      productGlobalInfo,
      showFaildModal,
      showSuccessModal,
      showPermissionModal,
      nothingChagedMessage
    } = this.state;

    return (
      <React.Fragment>
        <div id="global-loader" className="loader fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>

        <ReactModal
          isOpen={showSuccessModal}
          onRequestClose={this.hideSuccessModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="57"
              height="57"
              viewBox="0 0 57 57"
            >
              <g>
                <path
                  fill="#7ed321"
                  d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                />
              </g>
            </svg>
          </div>
          <div className="title">Thank You</div>
          <div className="text">
            Specifications successfully updated, the page will be reloaded shortly.
          </div>
        </ReactModal>
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            {nothingChagedMessage !== '' ? nothingChagedMessage : 'Something went wrong please try again later'}
          </div>
        </ReactModal>
        
        <ReactModal
          isOpen={showPermissionModal}
          onRequestClose={this.hidePermissionModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            You do not have permission to change attributes. 
          </div>
        </ReactModal>
        
        <div className="option-box">
          <div id="loader" className="loader circle">
            <svg
              width="200px"
              height="200px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
              className="lds-rolling"
            >
              <circle
                cx="50"
                cy="50"
                fill="none"
                ng-attr-stroke="{{config.color}}"
                ng-attr-stroke-width="{{config.width}}"
                ng-attr-r="{{config.radius}}"
                ng-attr-stroke-dasharray="{{config.dasharray}}"
                stroke="#B8BE14"
                strokeWidth="2"
                r="30"
                strokeDasharray="141.37166941154067 49.12388980384689"
                transform="rotate(159.346 50 50)"
              >
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  calcMode="linear"
                  values="0 50 50;360 50 50"
                  keyTimes="0;1"
                  dur="1.4s"
                  begin="0s"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
            </svg>
          </div>
          <div className="row">
            <div className="item">
              <div className="label"><span className="name">Project Name</span> <span className="sign">*</span></div>
              <div className="disabled-row">
                <span>{productGlobalInfo.projectName}</span>
                <svg viewBox="0 0 20 20" id="locked">
                  <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="item">
              <div className="label"><span className="name">Project Description</span> <span className="sign">*</span></div>
              <div className="disabled-row textarea">
                <span>{productGlobalInfo.projectDescription}</span>
                <svg viewBox="0 0 20 20" id="locked">
                  <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                </svg>
              </div>
            </div>
          </div>

          {this.drawOptions()}
          
        </div>
      </React.Fragment>
    );
  }
}

export default ProductOptions;
