import React, { Component } from "react";
import { authHeader } from "../../_helpers";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;

class ShippingMethodStep extends Component {
  constructor(props) {
    super(props);
    let globalStatus = props.status;
    let orderId = props.orderId;
    this.state = {
      globalStatus,
      shippingMethods: {},
      awdawd: {},
      redirect: false,
      orderId,
      selectedType: "",
      selectedMethod: {},
      _isMounted: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getShippingMethods = this.getShippingMethods.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
  }

  _asd = false;
  componentDidMount() {
    this.getShippingMethods();
    this._asd = true;
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let globalStatus = nextProps.status;
    this.setState({
      globalStatus
    });
  }
  getShippingMethods = async () => {
    let orderId = this.state.orderId;
    let response = await axios.get(
      `${apiUrl}/checkout-shipping/stepthree?orderId=${orderId}`,
      {
        headers: authHeader()
      }
    );
    let { data } = response;
    if (data.error === null) {
      this.setState(
        {
          shippingMethods: data.payload.shipping_methods,
          _isMounted: true
        },
        () => {}
      );
    }
  };
  handleChange(e) {
    let selectedType = e.target.id;
    this.setState({
      selectedType
    });
  }

  handleSubmit() {
    let requestUrl = `/checkout-shipping/stepfour`;
    let { selectedType, orderId } = this.state;
    if (selectedType !== "") {
      let data = {
        shipping_method: selectedType,
        orderId: orderId
      };
      axios
        .post(apiUrl + requestUrl, data, {
          headers: authHeader()
        })
        .then(res => {
          let resData = res.data;
          if (resData.error === null) {
            this.props.hendleToPaymentMethodStep(true);
            this.setState({
              selectedMethod: resData.payload.shipping_data,
              shippingErrorMessage:''
            });
            let summary = resData.payload.summary;
            this.props.summary(summary);
          }
        });
    }else {
      this.setState({
        shippingErrorMessage:'Please select shipping method'
      })
    }
  }
  handleEdit() {
    this.props.editShippingMethod(true);
  }

  render() {
    if (this.state._isMounted !== true) {
      return (
        <div id="loader" className="loader circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    } else {
      let { shippingMethods, selectedMethod, globalStatus,selectedType } = this.state;
      let component = this;
      if (globalStatus === "ready") {
        return (
          <div className="wrapper ready-box-wrapper">
            <div className="ready-container">
              <div className="info-box">
                <div className="passive-step-title">
                  <span>3. </span>
                  <span>Shipping Method</span>
                </div>
                <div className="name type">{selectedMethod.typeName}</div>
                <div className="name">{selectedMethod.name}</div>
              </div>
              <div onClick={this.handleEdit} className="edit">
                Edit
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="wrapper checkout-form-wrapper form">
          <React.Fragment>
            <div className="step-title">
              <span>3. </span>
              <span>Shipping Method</span>
            </div>
            {Object.keys(shippingMethods).length > 0 ? (
              Object.keys(shippingMethods).map(function(i) {
                return (
                  <div className="row clear" key={i}>
                    <div className="row-name">
                      {shippingMethods[i].shipping_method_type_name}
                    </div>
                    <div className="item checkbox">
                      <input
                        className="css-checkbox"
                        id={shippingMethods[i].id}
                        type="radio"
                        name="shippingMethodChoose"
                        onChange={component.handleChange}
                        defaultChecked={shippingMethods[i].id === selectedType ? true : false}
                        disabled={shippingMethods[i].error !== undefined && shippingMethods[i].error.code === 400}
                      />
                      <label
                        className={`css-label ${shippingMethods[i].error !== undefined && shippingMethods[i].error.code === 400 ? 'disabled' : ''}`}
                        htmlFor={shippingMethods[i].id}
                      >
                        <span>{shippingMethods[i].name} </span>{" "}
                        <span className="price">
                          AED {shippingMethods[i].price}
                        </span>
                        {shippingMethods[i].error !== undefined && shippingMethods[i].error.code === 400 ? (
                            <span className="error-msg">{shippingMethods[i].error.message}</span>
                        ) : null}
                      </label>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="no-shipping-container">
                <div>Your Country is not supported</div>
                <span>Please contact us </span>
                <span className="email">
                  <a href="mailto:support@flexoeasy.com">
                    support@flexoeasy.com
                  </a>
                </span>
              </div>
            )}
            <div className="invalid-feedback">
              {this.state.shippingErrorMessage}
            </div>
            {Object.keys(shippingMethods).length > 0 ? (
              <div className="button-row">
                <div onClick={this.handleSubmit}>Next</div>
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        </div>
      );
    }
  }
}
export default ShippingMethodStep;
