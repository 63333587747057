import React, { Component } from "react";

import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";
import validator from "validator";
import FormValidator from "../../validations";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
const apiUrlDomain = process.env.REACT_APP_API_DOMAIN;

class CustomersListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "15",
      customer: {
        firstName: "",
        lastName: "",
        customerId: "",
        telephone: "",
        company:"",
        tradeLicense: "",
        trnCertificate: ""
      },
      customerList: {},
      showModal: false,
      isEdit: false,
      customerId: "",
      errors: [],
      errorClasses: [],
      thankYou: false,
      thankYouTitle: "",
      thankYouText: "",
      faildModalMessage:'',
      popupTimeout:'',
      filesInfo: {
        tradeLicense: [],
        trnCertificate: []
      },
    };
    this.getCustomersList = this.getCustomersList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleEditCustomer = this.handleEditCustomer.bind(this);
    this.handleAddCustomer = this.handleAddCustomer.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);



  }

  _isMounted = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
      let start = "0",
      end = this.state.pp,
      reset = true;
    this.getCustomersList( start, end, 1, reset);
  }

  getCustomersList = async (
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ customerList: [] });
    } else {
    }
    let requestUrl = `/customers/index`;
    let currentComponent = this;
    const queryParams = {};


    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      let customerList = data.payload.customers.rows;
      let total = data.payload.customers.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          customerList
        },
        () => {
          currentComponent.props.customersListReady(true);
        }
      );
    }
  };



  getCustomerData = async id => {

    let requestUrl = `/customers/${id}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      this.setState(
        {
          customer: {
            firstName: data.payload.customer.firstname,
            lastName: data.payload.customer.lastname,
            telephone: data.payload.customer.telephone,
            customerId: data.payload.customer.id,
            company: data.payload.customer.company,
            tradeLicense: data.payload.customer.tradeLicense,
            trnCertificate: data.payload.customer.trnCertificate
          }
        },
        () => {

          let loader = document.getElementById("popup-loader");
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let {  pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getCustomersList( start, end, pageNumber, false);
    window.scroll(0, 0);
  }



  handleOpenModal(e) {
    var target = e.currentTarget;
    if (target.getAttribute("data-customerid")) {
      var customerId = target.getAttribute("data-customerid");
      this.setState(
        {
          showModal: true,
          isEdit: true,
          customerId
        },
        () => {
          this.getCustomerData(customerId);
        }
      );
    } else {
      this.setState({
        showModal: true
      });
    }
    this.setState({
      customer: {
        firstName: "",
        lastName: "",
        company: "",
        customerId: "",
        telephone: "",
        tradeLicense:"",
        trnCertificate:"",
      },
      filesInfo: {
        tradeLicense: [],
        trnCertificate: []
      },
    });
    console.log("state", this.state)
  }

  handleCloseModal() {
    clearTimeout(this.state.popupTimeout);
    this.setState({
      showModal: false,
      thankYou: false,
      thankYouText: "",
      isEdit: false,
      customerId: "",
      errors: [],
      errorClasses: []
    });
  }

  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last name field is required."
      },
      {
        field: "telephone",
        method: validator.isEmpty,
        validWhen: false,
        message: "Phone number field is required."
      },
    ];


    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.customer);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleChange(e) {
    const name = e.target.name;
    const customer = this.state.customer;
    customer[name] = e.target.value;
    this.setState({
      customer
    });
  }

  handelChecked(e) {
    const customer = this.state.customer;
    const name = e.target.name;
    customer[name] = e.target.id;
    this.setState({
      customer
    });
  }

  handleEditCustomer = async e => {
      let start = "0",
      end = this.state.pp,
      reset = true;
    const sendingData = new FormData();
    if (this.handleValidation()) {
      let loader = document.getElementById("popup-loader");

      loader.classList.add("show");
      let requestUrl = `/customers/update`;
      let currentComponent = this;
      let customer = this.state.customer;
      sendingData.append("firstname", customer.firstName);
      sendingData.append("lastname", customer.lastName);
      sendingData.append("company", customer.company);
      sendingData.append("telephone", customer.telephone);
      sendingData.append("id", customer.customerId);


      if(customer.tradeLicense){
        sendingData.append("tradeLicense", customer.tradeLicense);
      }
      if( customer.trnCertificate){
        sendingData.append("trnCertificate", customer.trnCertificate);
      }
      let res = await axios.post(apiUrl + requestUrl, sendingData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        this.getCustomersList( start, end, 1, reset);

        this.setState(
          {
            thankYou: true,
            thankYouText: `User edited successfully.`,
            customer: {
              firstName: "",
              lastName: "",
              company: "",
              customerId: "",
              telephone: "",
              tradeLicense:"",
              trnCertificate:""
            },
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
            this.state.popupTimeout = setTimeout(function() {
              currentComponent.setState({
                thankYou: false,
                thankYouText: "",
                showModal: false
              });
             }, 3000);
          }
        );
      }
    }
  };

  handleAddCustomer = async e => {
    e.preventDefault();
      let start = "0",
      end = this.state.pp,
      reset = true;
    const sendingData = new FormData();
    if (this.handleValidation()) {
      let loader = document.getElementById("popup-loader");

      loader.classList.add("show");
      let requestUrl = `/customers/create`;
      let currentComponent = this;
      let customer = this.state.customer;
      sendingData.append("firstname", customer.firstName);
      sendingData.append("lastname", customer.lastName);
      sendingData.append("company", customer.company);
      sendingData.append("telephone", customer.telephone);
      sendingData.append("tradeLicense", customer.tradeLicense);
      sendingData.append("trnCertificate", customer.trnCertificate);
      let res = await axios.post(apiUrl + requestUrl, sendingData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        this.getCustomersList( start, end, 1, reset);

        this.setState(
          {
            thankYou: true,
            thankYouText: `Thank you user with ${customer.email} email invited.`,
            customer: {
              firstName: "",
              lastName: "",
              company: "",
              customerId: "",
              telephone: "",
              tradeLicense:"",
              trnCertificate:"",
            },
            filesInfo: {
              tradeLicense: [],
              trnCertificate: []
            },
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
            this.state.popupTimeout =setTimeout(function() {
              currentComponent.setState({

                thankYou: false,
                thankYouText: "",
                showModal: false
              });
            }, 3000);
          }
        );

      } else {
        if (data.error.code === 401) {
          let { errors, errorClasses } = this.state;
          errors["email"] = data.error.message;
          errorClasses["email"] = "is-invalid";
          this.setState({
            errors,
            errorClasses
          });
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      }

    }
  };
  handleFileChange(e) {
    var file = e.target.files[0];
    var currentComponent = this;
    const name = e.target.name;
    const customer = this.state.customer;
    customer[name] = file;
    e.target.value = ''
    const filesInfo = this.state.filesInfo;
    if (file && file.size > 10485760) {
      filesInfo[name] = { error: "Maximum file size: 10MB" };
      currentComponent.setState({ filesInfo });
    } else {
      filesInfo[name] = { name: !currentComponent.state.isEdit ?  file.name : "", size: file.size, error: "" } ;
      currentComponent.setState({ customer, filesInfo });
    }
  }
  handleRemoveFile(e) {
    const name = e.currentTarget.getAttribute("data-name");
    const customer = this.state.customer;
    const filesInfo = this.state.filesInfo;
    customer[name] = "";
    filesInfo[name] = "";
    this.setState({ customer, filesInfo });
  }
  render() {


    // if (this._isRolesReady !== true) {
    //   return (
    //     <div id="loader" className="loader noBG show circle">
    //       <svg
    //         width="200px"
    //         height="200px"
    //         xmlns="http://www.w3.org/2000/svg"
    //         viewBox="0 0 100 100"
    //         preserveAspectRatio="xMidYMid"
    //         className="lds-rolling"
    //       >
    //         <circle
    //           cx="50"
    //           cy="50"
    //           fill="none"
    //           ng-attr-stroke="{{config.color}}"
    //           ng-attr-stroke-width="{{config.width}}"
    //           ng-attr-r="{{config.radius}}"
    //           ng-attr-stroke-dasharray="{{config.dasharray}}"
    //           stroke="#B8BE14"
    //           strokeWidth="2"
    //           r="30"
    //           strokeDasharray="141.37166941154067 49.12388980384689"
    //           transform="rotate(159.346 50 50)"
    //         >
    //           <animateTransform
    //             attributeName="transform"
    //             type="rotate"
    //             calcMode="linear"
    //             values="0 50 50;360 50 50"
    //             keyTimes="0;1"
    //             dur="1.4s"
    //             begin="0s"
    //             repeatCount="indefinite"
    //           ></animateTransform>
    //         </circle>
    //       </svg>
    //     </div>
    //   );
    // }

    let {
      pp,
      total,
      activePage,
      showModal,
      isEdit,
      errors,
      errorClasses,
      customer,
      customerList,
      thankYou,
      thankYouText,
      filesInfo
    } = this.state;
    let showModalLoader = "";
    isEdit ? (showModalLoader = "show") : (showModalLoader = "");
    let currentUser = JSON.parse(localStorage.getItem('user'))

    function bytesToSize(bytes) {
      var sizes = ["Bytes", "Kb", "Mb"];
      if (bytes === 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    }
    return (
      <React.Fragment>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal add-edit-member"
        >
          <React.Fragment>
            <div
              id="popup-loader"
              className={`loader ${showModalLoader} circle`}
            >
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
            {thankYou ? (
              <React.Fragment>
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">{thankYouText}</div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="title">
                  {isEdit ? "Edit customer" : "Add new customer"}
                </div>
                <div className="form-box">
                  <div className="row row-2 clear">
                    <div className="item">
                      <div className="label">Customer Name</div>
                      <input
                        autoComplete="new-firstName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        value={customer.firstName}
                        ref={el => (this.firstName = el)}
                        name="firstName"
                        className={errorClasses.firstName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.firstName}</div>
                    </div>
                    <div className="item">
                      <div className="label">Customer Last Name</div>
                      <input
                        autoComplete="new-lastName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        value={customer.lastName}
                        ref={el => (this.lastName = el)}
                        name="lastName"
                        className={errorClasses.lastName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.lastName}</div>
                    </div>
                  </div>

                    <div className="row  row-2  clear">
                      <div className="item">
                        <div className="label">Contact Number</div>
                        <input
                            autoComplete="new-phoneNumber"
                            onKeyPress={this.handleKeyPress}
                            type="number"
                            value={customer.telephone}
                            ref={el => (this.telephone = el)}
                            name="telephone"
                            className={errorClasses.telephone}
                            onChange={this.handleChange}
                        />
                        <div className="invalid-feedback">{errors.telephone}</div>
                      </div>

                      <div className="item">
                        <div className="label">Company Name</div>
                        <input
                            autoComplete="new-company"
                            onKeyPress={this.handleKeyPress}
                            type="text"
                            value={customer.company}
                            ref={el => (this.company = el)}
                            name="company"
                            className={errorClasses.company}
                            onChange={this.handleChange}
                        />
                      </div>
                    </div>

                </div>
                <div className="row attach-row">
                  <div>
                    <div className="row clear">
                      <div className="label">trade license</div>
                      <div className="attach-btn-box">
                        <input
                            type="file"
                            name="tradeLicense"
                            onChange={this.handleFileChange}
                            id="trade_license"
                            accept="image/*,.pdf"
                            ref={input => this.trade_license = input}
                        />
                        {!isEdit ? (!filesInfo.tradeLicense.name ? (
                            <label
                                htmlFor="trade_license"
                                className="attach-btn"
                            >
                              Upload File
                            </label>
                        ):  <div className="attach-name-box flex-container">
                                <span className="name">
                                  {filesInfo.tradeLicense.name}
                                </span>
                          <span className="size">{` (${bytesToSize(filesInfo.tradeLicense.size)}) `}</span>
                          <span
                              className="remove"
                              data-name="tradeLicense"
                              onClick={this.handleRemoveFile}
                          >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                  >
                                    <g transform="translate(-810 -1063)">
                                      <circle
                                          cx="10"
                                          cy="10"
                                          r="10"
                                          transform="translate(810 1063)"
                                          fill="#acacac"
                                      />
                                      <path
                                          d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                          transform="translate(821.553 1090.545)"
                                          fill="#fff"
                                      />
                                    </g>
                                  </svg>
                                </span>
                        </div>): ""}
                        {isEdit ?( !customer.tradeLicense ?  (
                            <label
                                htmlFor="trade_license"
                                className="attach-btn"
                            >
                              Upload File
                            </label>
                        ) : (
                            <div className="attach-name-box flex-container" >
                              <span className="name" onClick={()=>{this.trade_license.click()}}>
                                {customer.tradeLicense.name ?  customer.tradeLicense.name  : customer.tradeLicense.replace('/uploads/agentusers/', '') + " "}
                              </span>
                                <a
                                    target="_blank"
                                    href={apiUrlDomain+customer.tradeLicense}
                                >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20.565"
                                      height="12.48"
                                      viewBox="0 0 20.565 12.48"
                                  >
                                    <path
                                        d="M.008-21.24c-3.78,0-6.735,2.43-10.29,6.24C-7.237-11.82-4.657-8.76.008-8.76c4.695,0,8.085-3.9,10.275-6.165C8-17.64,4.628-21.24.008-21.24Zm0,.78c4.005,0,6.99,2.91,9.225,5.505C7.058-12.675,4.028-9.54.008-9.54c-4.05,0-6.39-2.49-9.21-5.46C-5.932-18.45-3.322-20.46.008-20.46Zm0,8.61A3.15,3.15,0,0,0,3.158-15a3.15,3.15,0,0,0-3.15-3.15A3.14,3.14,0,0,0-3.142-15,3.14,3.14,0,0,0,.008-11.85Zm0-.78A2.356,2.356,0,0,1-2.362-15a2.356,2.356,0,0,1,2.37-2.37A2.365,2.365,0,0,1,2.378-15,2.365,2.365,0,0,1,.008-12.63Z"
                                        transform="translate(10.283 21.24)"
                                    />
                                  </svg>
                                </a>
                              </div>
                        )): ""}
                      </div>
                    </div>

                    <div className="row clear">
                      <div className="label">trn certificate</div>
                      <div className="attach-btn-box">
                        <input
                            type="file"
                            name="trnCertificate"
                            onChange={this.handleFileChange}
                            id="trn_certificate"
                            accept="image/*,.pdf"
                            ref={input => this.trn_certificate = input}
                        />
                        {!isEdit ? (!filesInfo.trnCertificate.name ? (
                            <label
                                htmlFor="trn_certificate"
                                className="attach-btn"
                            >
                              Upload File
                            </label>
                        ):  <div className="attach-name-box flex-container">
                                <span className="name">
                                  {filesInfo.trnCertificate.name}
                                </span>
                          <span className="size">{` (${bytesToSize(filesInfo.trnCertificate.size)}) `}</span>
                          <span
                              className="remove"
                              data-name="trnCertificate"
                              onClick={this.handleRemoveFile}
                          >
                                  <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                  >
                                    <g transform="translate(-810 -1063)">
                                      <circle
                                          cx="10"
                                          cy="10"
                                          r="10"
                                          transform="translate(810 1063)"
                                          fill="#acacac"
                                      />
                                      <path
                                          d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                          transform="translate(821.553 1090.545)"
                                          fill="#fff"
                                      />
                                    </g>
                                  </svg>
                                </span>
                        </div>): ""}
                        {isEdit ?( !customer.trnCertificate ?  (
                            <label
                                htmlFor="trn_certificate"
                                className="attach-btn"
                            >
                              Upload File
                            </label>
                        ) : (
                            <div className="attach-name-box flex-container" >
                              <span className="name" onClick={()=>{this.trn_certificate.click()}}>
                                {customer.trnCertificate.name ?  customer.trnCertificate.name  : customer.trnCertificate.replace('/uploads/agentusers/', '') + " "}
                              </span>
                              <a
                                  target="_blank"
                                  href={apiUrlDomain+customer.trnCertificate}
                              >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20.565"
                                    height="12.48"
                                    viewBox="0 0 20.565 12.48"
                                >
                                  <path
                                      d="M.008-21.24c-3.78,0-6.735,2.43-10.29,6.24C-7.237-11.82-4.657-8.76.008-8.76c4.695,0,8.085-3.9,10.275-6.165C8-17.64,4.628-21.24.008-21.24Zm0,.78c4.005,0,6.99,2.91,9.225,5.505C7.058-12.675,4.028-9.54.008-9.54c-4.05,0-6.39-2.49-9.21-5.46C-5.932-18.45-3.322-20.46.008-20.46Zm0,8.61A3.15,3.15,0,0,0,3.158-15a3.15,3.15,0,0,0-3.15-3.15A3.14,3.14,0,0,0-3.142-15,3.14,3.14,0,0,0,.008-11.85Zm0-.78A2.356,2.356,0,0,1-2.362-15a2.356,2.356,0,0,1,2.37-2.37A2.365,2.365,0,0,1,2.378-15,2.365,2.365,0,0,1,.008-12.63Z"
                                      transform="translate(10.283 21.24)"
                                  />
                                </svg>
                              </a>
                            </div>
                        )): ""}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-row">
                  <div onClick={this.handleCloseModal}>Discard</div>
                  {isEdit ? (
                    <div onClick={this.handleEditCustomer}>Save changes</div>
                  ) : (
                    <div onClick={this.handleAddCustomer}>Add</div>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </ReactModal>
        <div className="container-header">
          {currentUser.isMember === false ? (
            <div
            className="add-customer-btn add-btn"
            onClick={this.handleOpenModal}
          >
            Add Customer
          </div>
          ):('')}
          
        </div>

        {customerList.length > 0 ? (
          <div className="members-list-box table-grid">
            <div className="table-row table-head">
              <div className="table-cell">
                <div className="text">Customer Name</div>
              </div>
              <div className="table-cell">
                <div className="text">Customer Last Name</div>
              </div>
              <div className="table-cell">
                <div className="text">Contact Number</div>
              </div>
              <div className="table-cell">
                <div className="text">Company name</div>
              </div>
              <div className="table-cell">
                <div className="text"> </div>
              </div>
            </div>

            {customerList.map((item, key) => {
              return (
                <div className="table-row" key={key}>
                  <div className="table-cell">
                    <div className="text">
                      <div>Name:</div>
                      <div>
                        {item.firstname}
                      </div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Customer Last Name</div>
                      <div>{item.lastname}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Contact Number</div>
                      <div>{item.telephone}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Company</div>
                      <div>{item.company}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    {currentUser.isMember === false ? (
                      <div className="btn">
                        <span
                          data-customerid={item.id}
                          onClick={this.handleOpenModal}
                        >
                          Edit
                        </span>
                    </div>
                    ):('')}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-result-box">
            <div className="text">
              No Customer
            </div>
          </div>
        )}

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default CustomersListView;
