import React, { Component } from 'react';

import { HashLink as Link } from 'react-router-hash-link';
import MetaTags from "../metaTags"

class LegalPage extends Component {
  constructor(props) {
    super(props);
    this.scrollToView = this.scrollToView.bind(this);

  }
  _isMounted = false;

  componentDidMount(){
    let currentComponent = this;
    window.onload = function (event) {
      currentComponent.scrollToView()
    }
    currentComponent.scrollToView()
    document.body.classList.add('legal');
  }
  componentWillUnmount(){
    document.body.classList.remove('legal');
  }

  scrollToView() {
    if (this.props.location.hash && this.props.location.hash !== undefined) {
      var hash = this.props.location.hash.replace('#','');
      const elementPosition = document.getElementById(hash).offsetTop + 30;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth"
      });
    }else {
      window.scroll(0,0)
    }
  }
  render(){
    const scrollWithOffset = (el) => {
      const elementPosition = el.offsetTop + 30;
      window.scroll({
        top: elementPosition,
        left: 0,
        behavior: "smooth"
      });
    }
    return(
        <div className="legal-container">
          <MetaTags/>
          <div className="wrapper">
            <h1 className="page-title">Legal</h1>
            <div className="left">
              <Link  scroll={el => scrollWithOffset(el)}  to="#privacy-policy">
                Privacy Policy
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#cancellation-policy">
                Cancellation Policy
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#delivery-conditions">
                Delivery Conditions
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#limitation-on-liability">
                Limitation on Liability
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#refund-policy">
                Refund Policy
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#terms-and-conditions">
                Terms and Conditions
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#dispute-resolution">
                Dispute Resolution
              </Link >
              <Link  scroll={el => scrollWithOffset(el)}  to="#eula">
                End User License Agreement
              </Link >
            </div>
            <div className="right">
                <section  id="privacy-policy">
                    <h2>Privacy Policy</h2>
                    <div className="text-box">
                      <p>The privacy of our customers is of utmost importance to us. Accordingly, your personal information including your name, contact details, delivery details, email address and any other personal information you share with us will not be shared nor passed onto any third-party.</p>
                      <p>
                        <strong>Note:</strong> Your credit and/or debit card details will <b>not</b> be stored by us, nor sold, shared, rented or leased to any third-party under any circumstances.</p>
                      <p>
                        <strong>Use of cookies by Flexoeasy.com</strong>
                      </p>
                      <p>Flexoeasy.com uses cookies to provide services that enhance and improve the effectiveness of the website relating to the activities of its users.</p>
                      <p>
                        <strong>Here is a list of how Flexoeasy.com uses cookies:</strong>
                      </p>
                      <p>- We use cookies to assist in the browsing of anonymous and registered users on our site, allowing users to correctly navigate our site, granting full functionality of our site to all users, and access to safe areas of our site for registered users.</p>
                      <p>- We analyze the use of cookies to better understand how our users navigate and use the site. This analysis is done in order to optimize the site’s functionality and make it more interesting and relevant to users. This information is anonymous and used for statistical purposes only.</p>
                      <p>- We may use cookies to display our advertisements on some of the other websites that you visit. You are most likely to see our ads when you are browsing other sites of a similar nature to ours or that have products similar to ours. These cookies are usually inserted by trusted third parties (such as Google through remarketing for display advertising). Nonetheless, Flexoeasy.com does not allow these companies, or any other company, to collect personal information about users. This means that viewers will remain anonymous and personal data will not be used by other websites or marketing databases.</p>
                      <p>
                        <strong>How to change or turn off cookies in preferences:</strong>
                      </p>
                      <p>In your browser (Internet Explorer, Safari, Firefox, Chrome, etc.) you can determine which cookies you want to receive and which you do not. To find out more about this topic, and where you can find these settings, please use the "help" button of your browser.</p>
                      <p>For any more information on your privacy while using our website, please contact us.</p>
                    </div>
                </section>

                
                <section  id="cancellation-policy">
                    <h2>Cancellation Policy</h2>
                    <div className="text-box">
                    <p>
                      You may cancel your order only if it has not been processed yet. You can check the status of your order by clicking on "My Dashboard". Thank you for your understanding and co-operation.
                    </p>
                    </div>
                </section>

                

                
                <section  id="delivery-conditions">
                    <h2>Delivery Conditions</h2>
                    <div className="text-box">
                      <p>All our deliveries inside UAE are carried out by our logistics partner Aramex, while DHL is our partner for the international deliveries, and you can track your shipments on their websites. You will receive an automated email confirming your order and details of the same.</p>
                      <p>The conditions for the delivery are listed below:</p>
                      <p>- We dispatch our items by Aramex in UAE, with standard delivery within 24 to 48 hours. International deliveries handled by DHL may take 4-5 days based on the destination. Once the order is dispatched, you can track it on the courier's website.</p>
                      <p>- It is not possible to schedule a delivery i.e. it is not possible to set a specific time or be contacted beforehand by the courier to arrange one. Goods are normally delivered by the courier between 9am and 6pm Sunday through Thursday.</p>
                      <p>- It is not possible to request multiple delivery addresses for a single order. If you need to ship the goods to more than one address, you will have to place more than one order.</p>
                      <p>- Our couriers do not guarantee deliveries at trade shows.</p>
                      <p>- Flexoeasy.com will NOT deal or provide any services or delivery of shipments to Qatar and OFAC sanctions countries such as Afghanistan, Iran, North Korea, Somalia, Sudan, Syria and Yemen.</p>
                    </div>
                </section>

                

                
                <section  id="limitation-on-liability">
                    <h2>Limitation on Liability</h2>
                    <div className="text-box">
                      <p>
                        In the unlikely event that you receive damaged goods from us, Flexoeasy.com is solely liable for exchanging such goods or for paying 100% of the amount on the invoice, depending on the specific circumstances, but Flexoeasy.com shall not be liable for any other damages as our warranty is limited only to the value of the goods (plates).
                      </p>
                    </div>
                </section>
                

                
                <section  id="refund-policy">
                    <h2>Refund Policy</h2>
                    <div className="text-box">
                      <p>
                        In the event of a refund, it will be processed through the original mode of payment only. Our Refund Policy is convenient and hassle-free. While we would like you to keep and use the item you have purchased on Flexoeasy.com, you have the peace of mind knowing that an item bought can be easily returned, provided that you could give us a reasonable basis as to why you are not satisfied with such item. Nothing is more important to us than the satisfaction of our customers.
                      </p>
                      <p>
                        If you provide us with a reasonable basis as to why you are not satisfied with your product, you may return an item within 48 hours from the date of its delivery to you, provided that the item(s), and any related accessories and/or tools and components – along with the Original Packaging – are in the original state and condition when purchased and received.
                      </p>
                    </div>
                </section>

                
                <section  id="terms-and-conditions">
                    <h2>Terms and Conditions</h2>
                    <div className="text-box">
                      <p>- You can pay either using Visa or Master credit card or through your PayPal account.</p>
                      <p>- All payments should be made using the currency of United Arab Emirates dirham (AED) only.</p>
                      <p>- Effective 1 January 2018, VAT is applicable in the UAE. VAT is charged at 5% on orders shipped within the UAE. 5% VAT will be added at check out. To obtain your Tax Invoice for claiming, kindly contact <a href="mailto:support@flexoeasy.com">support@flexoeasy.com</a>.</p>
                      <p>- If item(s) are sold with a no-refund policy, such policy will be mentioned prior to or simultaneously with making the order for the item(s).</p>
                      <p>- If you find the product to be unsatisfactory to you, we will arrange for the return shipment at no extra charge to you, provided that you give us a reasonable basis of why you are not satisfied with the product. You can contact our Customer Services Team to arrange a courier pick-up for your item and a quick Return. Please visit our <a href="/contact-us">CONTACT</a> page on our website or call us on <a href="tel:+97144355919">+971 4 4355919</a> or send us an email at <a href="mailto:support@flexoeasy.com">support@flexoeasy.com</a> with your order number in the subject line.</p>
                      <p>- Check your item(s) as soon as you receive it in order to ensure your full satisfaction with your purchase(s), so that you could take advantage of our Refund Policy within the conditional time-frames for returning an item.</p>
                      <p>- If an item received is not in the same condition as it was described when ordered, it doesn’t work or function as advertised, is missing any component or accessory, or is faulty or damaged, you have the right to return it within the 2 day period mentioned in our Refund Policy above.</p>
                      <p>- Refunds of your purchase are done seamlessly once a return is requested, subject to the above terms and conditions. However, it might take a few days for your funds to be credited back to you.</p>
                    </div>
                </section>

                
                <section  id="dispute-resolution">
                    <h2>Dispute Resolution</h2>
                    <div className="text-box">
                      <p>In the event of a dispute between you and Flexoeasy.com, UAE law shall apply, and the Courts of Dubai shall resolve such dispute.</p>
                      <p><strong>A PROMISE IS A PROMISE</strong></p>
                    </div>
                </section>
                


                <section  id="eula">
                    <h2>End User License Agreement</h2>
                    <div className="text-box">
                      <p>
                        This End-User License Agreement ("EULA") is a legal agreement between you and Flexoeasy.com
                      </p>
                      <p>
                        This EULA agreement governs your acquisition and use of our Flexoeasy.com software ("Software") directly from Flexoeasy.com or indirectly through a Flexoeasy.com authorized reseller or distributor (a "Reseller").
                      </p>
                      <p>
                        Please read this EULA agreement carefully before completing the installation process and using the Flexoeasy.com software. It provides a license to use the Flexoeasy.com software and contains warranty information and liability disclaimers.
                      </p>
                      <p>
                        If you register for a free trial of the Flexoeasy.com software, this EULA agreement will also govern that trial. By clicking "accept" or installing and/or using the Flexoeasy.com software, you are confirming your acceptance of the Software and agreeing to become bound by the terms of this EULA agreement.
                      </p>
                      <p>
                        If you are entering into this EULA agreement on behalf of a company or other legal entity, you represent that you have the authority to bind such entity and its affiliates to these terms and conditions. If you do not have such authority or if you do not agree with the terms and conditions of this EULA agreement, do not install or use the Software, and you must not accept this EULA agreement.
                      </p>
                      <p>
                        This EULA agreement shall apply only to the Software supplied by Flexoeasy.com herewith regardless of whether other software is referred to or described herein. The terms also apply to any Flexoeasy.com updates, supplements, Internet-based services, and support services for the Software, unless other terms accompany those items on delivery. If so, those terms apply.
                      </p>
                      <p>
                        <strong>License Grant</strong>
                      </p>
                      <p>
                        Flexoeasy.com hereby grants you a personal, non-transferable, non-exclusive license to use the Flexoeasy.com software on your devices in accordance with the terms of this EULA agreement.
                      </p>
                      <p>
                        You are permitted to load the Flexoeasy.com software (for example a PC, laptop, mobile or tablet) under your control. You are responsible for ensuring your device meets the minimum requirements of the Flexoeasy.com software.
                      </p>
                      <p>
                        <strong>You are not permitted to:</strong>
                      </p>
                      <p>
                        - Edit, alter, modify, adapt, translate or otherwise change the whole or any part of the Software nor permit the whole or any part of the Software to be combined with or become incorporated in any other software, nor decompile, disassemble or reverse engineer the Software or attempt to do any such things
                      </p>
                      <p>
                        - Reproduce, copy, distribute, resell or otherwise use the Software for any commercial purpose
                      </p>
                      <p>
                        - Allow any third party to use the Software on behalf of or for the benefit of any third party
                      </p>
                      <p>
                        - Use the Software in any way which breaches any applicable local, national or international law
                      </p>
                      <p>
                        - Use the Software for any purpose that Flexoeasy.com considers is a breach of this EULA agreement.
                      </p>
                      <p>
                        <strong>Intellectual Property and Ownership</strong>
                      </p>
                      <p>
                        Flexoeasy.com shall at all times retain ownership of the Software as originally downloaded by you and all subsequent downloads of the Software by you. The Software (and the copyright, and other intellectual property rights of whatever nature in the Software, including any modifications made thereto) are and shall remain the property of Flexoeasy.com. Flexoeasy.com reserves the right to grant licenses to use the Software to third parties.
                      </p>
                      <p>
                        <strong>Termination</strong>
                      </p>
                      <p>
                        This EULA agreement is effective from the date you first use the Software and shall continue until terminated. You may terminate it at any time upon written notice to Flexoeasy.com.
                      </p>
                      <p>
                        <strong>Governing Law</strong>
                      </p>
                      <p>
                        This EULA agreement and any dispute arising out of or in connection with this EULA agreement shall be governed by and construed in accordance with the laws.
                      </p>
                    </div>
                </section>
            </div>
        </div>
      </div>
    )

  }

}



export default LegalPage;
