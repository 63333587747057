import React, { Component } from "react";
import Banner from "./Assets/images/maintenance-banner.jpg";

class MaintenanceView extends Component {
  componentDidMount() {
    document.body.classList.add("maintenance");
  }
  componentWillUnmount() {
    document.body.classList.remove("maintenance");
  }
  render() {
    return (
      <div className="wrapper">
        <div className="table">
          <div className="cell">
            <img src={Banner} alt="" />
            <h1>Maintenance Break</h1>
            <div className="text">
              for questions and urgent inquiries please contact us at
            </div>
            <a href="mailto:support@flexoeasy.com">support@flexoeasy.com</a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="189.461"
              height="22.167"
              viewBox="0 0 189.461 22.167"
            >
              <g transform="translate(-224.241 -1009.926)">
                <rect
                  width="3.198"
                  height="2.898"
                  transform="translate(365.315 1028.964)"
                  fill="#B8BE14"
                />
                <path
                  d="M508.235,1037.175c-1.923,0-3.412-1.6-3.412-4.222v-.056c0-2.558,1.428-4.2,3.374-4.2a4.188,4.188,0,0,1,2.956,1.319l1.793-2.109a6.688,6.688,0,0,0-11.2,4.955v.171c0,4.029,2.559,6.674,6.367,6.674a6.6,6.6,0,0,0,4.9-1.975l-1.8-1.94a4.192,4.192,0,0,1-2.973,1.382"
                  transform="translate(-130.998 -7.661)"
                  fill="#B8BE14"
                />
                <path
                  d="M534.526,1026.093a6.373,6.373,0,0,0-6.553,6.713v.231a6.544,6.544,0,1,0,13.083-.032v-.236a6.324,6.324,0,0,0-6.53-6.675m3.437,6.885c0,2.6-1.433,4.221-3.437,4.221s-3.483-1.641-3.483-4.241v-.125c0-2.6,1.431-4.22,3.456-4.22,2.009,0,3.464,1.637,3.464,4.237Z"
                  transform="translate(-143.381 -7.632)"
                  fill="#B8BE14"
                />
                <path
                  d="M369.97,1026.093a6.373,6.373,0,0,0-6.553,6.713v.231A6.544,6.544,0,1,0,376.5,1033v-.236a6.324,6.324,0,0,0-6.531-6.675m3.437,6.885c0,2.6-1.433,4.221-3.437,4.221s-3.483-1.641-3.483-4.241v-.125c0-2.6,1.432-4.22,3.457-4.22,2.009,0,3.463,1.637,3.463,4.237Z"
                  transform="translate(-65.7 -7.632)"
                  fill="#B8BE14"
                />
                <path
                  d="M568.576,1026.524l-3.185,5.344-3.224-5.344h-3.144V1039.7h2.918v-8.375l3.4,5.4h.053l3.414-5.435v8.407h2.936v-13.173Z"
                  transform="translate(-158.039 -7.835)"
                  fill="#B8BE14"
                />
                <path
                  d="M241.781,1009.926a4.176,4.176,0,0,0-4.381,4.394,4.391,4.391,0,0,1-4.385,4.389h-4.391a4.131,4.131,0,0,0-4.383,4.373v4.393a4.386,4.386,0,0,0,4.383,4.387h4.391a4.388,4.388,0,0,0,4.385-4.387v-4.393a4.374,4.374,0,0,1,4.381-4.373,4.391,4.391,0,1,0,0-8.783"
                  transform="translate(0 0)"
                  fill="#B8BE14"
                />
                <path
                  d="M265.785,1039.7h2.785v-5.1h6.039v-2.636H268.57v-2.808h6.853v-2.636h-9.638Z"
                  transform="translate(-19.611 -7.83)"
                  fill="#B8BE14"
                />
                <path
                  d="M292.93,1026.512h-2.785V1039.7h9.1v-2.637H292.93Z"
                  transform="translate(-31.111 -7.83)"
                  fill="#B8BE14"
                />
                <path
                  d="M316.953,1034.348h5.967v-2.582h-5.967v-2.675h6.781v-2.58h-9.548V1039.7h9.638v-2.58h-6.871Z"
                  transform="translate(-42.46 -7.829)"
                  fill="#B8BE14"
                />
                <path
                  d="M350.091,1026.512h-3.165l-2.694,4.276-2.64-4.276h-3.255l4.232,6.479-4.413,6.706h3.166l2.857-4.521,2.839,4.521h3.255l-4.413-6.744Z"
                  transform="translate(-53.775 -7.83)"
                  fill="#B8BE14"
                />
                <path
                  d="M397.1,1034.348h5.967v-2.582H397.1v-2.675h6.782v-2.58h-9.549V1039.7h9.639v-2.58H397.1Z"
                  transform="translate(-80.295 -7.829)"
                  fill="#B8BE14"
                />
                <path
                  d="M423.492,1026.332l-5.426,13.28H420.9l1.158-2.957h5.353l1.157,2.957h2.911l-5.426-13.28Zm-.434,7.761,1.682-4.275,1.681,4.275Z"
                  transform="translate(-91.498 -7.745)"
                  fill="#B8BE14"
                />
                <path
                  d="M453.089,1031.635c-2.189-.584-2.731-.866-2.731-1.732v-.038c0-.64.56-1.149,1.627-1.149a5.793,5.793,0,0,1,3.292,1.3l1.445-2.185a7.135,7.135,0,0,0-4.7-1.678c-2.586,0-4.43,1.583-4.43,3.975v.038c0,2.617,1.646,3.353,4.2,4.031,2.115.566,2.549.943,2.549,1.676v.038c0,.772-.687,1.244-1.825,1.244a5.686,5.686,0,0,1-3.78-1.6l-1.645,2.053a7.863,7.863,0,0,0,5.371,2.11c2.731,0,4.647-1.469,4.647-4.086v-.038c0-2.3-1.447-3.258-4.014-3.956"
                  transform="translate(-105.197 -7.66)"
                  fill="#B8BE14"
                />
                <path
                  d="M479.345,1026.512l-3.074,5.293-3.02-5.293H470l4.863,7.986v5.2h2.784v-5.257l4.865-7.929Z"
                  transform="translate(-116.012 -7.83)"
                  fill="#B8BE14"
                />
              </g>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}
export default MaintenanceView;
