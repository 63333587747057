import React, { Component } from "react";

import { history } from "./_helpers";

import "./Assets/css/style.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import routes from "./routes";
import { PrivateRoute } from "./privateRoute";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";

import AccountInformation from "./components/account/account/information";
import AccountMembers from "./components/account/members/members";
import AccountCredits from "./components/account/credits/credits";
import AccountAddress from "./components/account/address/address";
import PressTemplate from "./components/account/pressTemplate/index";

import Dashboard from "./components/dashboard/dashboardView/index";

import OrderFailed from "./components/order/failed";
import OrderSuccess from "./components/order/success";
import OrderPage from "./components/order/index";
import ReorderPage from "./components/order/reorder/index";
import FilePreparing from "./components/order/filePreparing";

import Checkout from "./components/checkout/checkout";
import CheckoutSuccess from "./components/checkout/success";
import CheckoutFailed from "./components/checkout/failed";
import MyTemplates from "./components/myTemplates/index";

import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { userActions } from "./_actions";
import CacheBuster from "./CacheBuster";
import AccountCustomers from "./components/account/customers/customers";

const apiUrl = process.env.REACT_APP_API;
let user = JSON.parse(localStorage.getItem("user"));
let uniqueId = localStorage.getItem("uniqueId");

if (user) {
  let refreshToken = user.refreshToken;
  const refreshAuthLogic = failedRequest =>
    axios
      .post(`${apiUrl}/users/refresh-token`, {
        refreshToken: refreshToken,
        email: user.email,
        uniqueId
      })
      .then(tokenRefreshResponse => {
        if (tokenRefreshResponse.data.error === null) {
          let userToken = tokenRefreshResponse.data.payload.token;
          let userRefreshToken = tokenRefreshResponse.data.payload.refreshToken;
          let newData = {
            token: userToken,
            refreshToken: userRefreshToken,
            middlename: user.middlename,
            isMember: user.isMember,
            companyName: user.companyName,
            email: user.email,
            firstname: user.firstname,
            lastname: user.lastname,
            phone: user.phone,
            userId:user.userId
          };
          localStorage.removeItem("user");

          localStorage.setItem("user", JSON.stringify(newData));
          failedRequest.response.config.headers["Authorization"] = userToken;
          return Promise.resolve();
        } else {
          this.props.dispatch(userActions.logout());

          // localStorage.removeItem("user");
          // localStorage.removeItem("uniqueId");
          // window.location.reload();
        }
      });

  createAuthRefreshInterceptor(axios, refreshAuthLogic);
}

class App extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      date: new Date().getTime()
    };
  }

  render() {
    return (
      <React.Fragment>

        <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
            if (loading) return null;
            if (!loading && !isLatestVersion) {
              refreshCacheAndReload();
            }

            return "";
          }}
        </CacheBuster>


        <Router history={history}>
          <React.Fragment>
            
            <Header />
            <Switch>
              {routes.map((route, idx) => {
                return route.component ? (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component {...props} key={new Date().getTime()} />
                    )}
                  />
                ) : null;
              })}
              <PrivateRoute
                exact
                path="/account/information"
                component={AccountInformation}
              />
              <PrivateRoute
                  exact
                  path="/account/customers"
                  component={AccountCustomers}
              />
              <PrivateRoute
                exact
                path="/account/members"
                component={AccountMembers}
              />
              <PrivateRoute
                exact
                path="/account/credits"
                component={AccountCredits}
              />
              <PrivateRoute
                exact
                path="/account/address"
                component={AccountAddress}
              />
              <PrivateRoute
                exact
                path="/account/press-template"
                component={PressTemplate}
              />

              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/checkout/:id" component={Checkout} />
              <PrivateRoute exact path="/checkout/:id/success" component={CheckoutSuccess} />
              <PrivateRoute exact path="/checkout/:id/failed" component={CheckoutFailed} />
              <PrivateRoute exact path="/templates" component={MyTemplates} />
              <PrivateRoute
                exact
                path="/order/:id/success"
                component={OrderSuccess}
              />
              <PrivateRoute
                exact
                path="/order/:id/failed"
                component={OrderFailed}
              />
              <PrivateRoute
                exact
                path="/order/:id/reorder"
                component={ReorderPage}
              />
              <PrivateRoute exact path="/order/:id/:tabId" component={OrderPage} />
              <PrivateRoute exact path="/file/:orderId/:docVersionId/:directDownloading/:fileType" component={FilePreparing} />

              <Redirect to={{ pathname: "/not-found" }} />
            </Switch>
            <Footer />
          </React.Fragment>
        </Router>
    
      </React.Fragment>
      
    )
  }
}

export default App;
