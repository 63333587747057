import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import CheckoutSidebar from "./sidebar";
import GlobalLoader from "../globalLoader";

import ProductTypeStep from "./productTypeStep";
import BillingInformationStep from "./billingInformationStep";
import ShippingAddressStep from "./shippingAddressStep";
import ShippingMethodStep from "./shippingMethodStep";
import PaymentInformationStep from "./paymentInformationStep";
import ReactModal from "react-modal";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notPhysicalState: "",
      successpayment: "",
      productTypeStatus: "passive",
      billingStatus: "passive",
      shippingStatus: "passive",
      shippingMethodStatus: "passive",
      PaymentInformationStepStatus: "passive",
      productTypeStepComponetReady: false,
      redirect: false,
      redirectUrl: "",
      orderId: this.props.match.params.id,
      setReadyAddress: {},
      address: {},
      notPhysical: false,
      differentShippingAddress: false,
      defaultBillingAddress: {},
      success: false,
      prices: {},
      selectedPaymentMethod:{},
      showFaildModal:false,
    };
    this.handleShippingChildeData = this.handleShippingChildeData.bind(this);
    this.checkOrderId = this.checkOrderId.bind(this);
    this.getReadyAddress = this.getReadyAddress.bind(this);
    this.handleShippingMethod = this.handleShippingMethod.bind(this);
    this.notPhysical = this.notPhysical.bind(this);
    this.hendleToPaymentMethodStep = this.hendleToPaymentMethodStep.bind(this);
    this.differentShipping = this.differentShipping.bind(this);
    this.editType = this.editType.bind(this);
    this.editBilling = this.editBilling.bind(this);
    this.defaultBillingAddress = this.defaultBillingAddress.bind(this);
    this.hendleToFinsh = this.hendleToFinsh.bind(this);
    this.price = this.price.bind(this);
    this.selectedMethod = this.selectedMethod.bind(this);
  }

  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }

  handleError = childeData => {
    this.setState({
      showFaildModal: childeData
    } , () => {
      setTimeout(function() {
        window.location = '/dashboard'
      }, 3000)
    })
  };
  handleSuccess = childeData => {
    if (childeData === true) {
      this.setState({
        success: true
      });
    }
  };
  handleShippingChildeData = childeData => {
    if (childeData === true) {
      if (this.state.notPhysical === true) {
        this.setState({
          billingStatus: "ready",
          PaymentInformationStepStatus: "active"
        });
      } else {
        this.setState({
          billingStatus: "ready",
          shippingStatus: "active"
        });
      }
    }
  };
  componentDidMount() {
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let CHECKOUT;
    if(userPermission) {
      CHECKOUT = userPermission.find(
        opt => opt.key === 'CHECKOUT'
      )
    }
    if(currentUser.isMember === false || (currentUser.isMember === true && CHECKOUT !== undefined)){
      this.checkOrderId();

    }else {
      window.location = "/account/information";
    }
  }
  checkOrderId() {
    let orderId = this.props.match.params.id;
    if (orderId) {
      document.body.classList.add("checkout");
    } else {
      let currentComponent = this;
      currentComponent.setState({
        redirect: true,
        redirectUrl: "/not-found"
      });
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("checkout");
  }
  orderTypesReady = childeData => {
    if (childeData === true) {
      this.setState({
        productTypeStepComponetReady: childeData
      });
    }
  };
  successProductType = childeData => {
    if (this.state.isDefaultBilling === true) {
      if (childeData === true) {
        this.setState({
          productTypeStatus: "ready",
          billingStatus: "defaultaddress"
        });
      } else {
        this.setState({
          productTypeStatus: "active",
          billingStatus: "passive"
        });
      }
    } else {
      if (childeData === true) {
        this.setState({
          productTypeStatus: "ready",
          billingStatus: "active"
        });
      } else {
        this.setState({
          productTypeStatus: "active",
          billingStatus: "passive"
        });
      }
    }
  };
  getReadyAddress(address) {
    // this.state.address.type = "shipping";
    this.setState(
      {
        address
      },
      () => {
        // this.state.address.type = "shipping";
      }
    );
  }
  handleShippingMethod = childeData => {
    if (childeData === true) {
      this.setState({
        shippingStatus: "ready",
        shippingMethodStatus: "active"
      });
    }
  };
  notPhysical = childeData => {
    if (this.state.isDefaultBilling === true) {
      if (childeData === true) {
        this.setState({
          productTypeStatus: "ready",
          billingStatus: "defaultaddress",
          notPhysical: true,
          notPhysicalState: true
        });
      } else {
        this.setState({
          notPhysical: false,
          notPhysicalState: false
        });
      }
    } else {
      if (childeData === true) {
        this.setState({
          productTypeStatus: "ready",
          billingStatus: "active",
          notPhysical: true,
          notPhysicalState: true
        });
      } else {
        this.setState({
          notPhysical: false,
          notPhysicalState: false
        });
      }
    }
  };
  hendleToPaymentMethodStep = childeData => {
    if (childeData === true) {
      this.setState({
        shippingMethodStatus: "ready",
        PaymentInformationStepStatus: "active"
      });
    }
  };
  hendleToFinsh = childeData => {
    if (childeData === true) {
      this.setState({
        PaymentInformationStepStatus: "ready"
      });
    }
  };

  differentShipping = childeData => {
    if (childeData === true) {
      this.setState({
        differentShippingAddress: true
      });
    }
  };
  editType = childeData => {
    if (childeData === true) {
      this.setState({
        success: false,
        productTypeStatus: "active",
        billingStatus: "passive",
        shippingStatus: "passive",
        shippingMethodStatus: "passive",
        PaymentInformationStepStatus: "passive"
      });
    }
  };
  editBilling = childeData => {
    if (childeData === true) {
      this.setState({
        success: false,
        productTypeStatus: "ready",
        billingStatus: "active",
        shippingStatus: "passive",
        shippingMethodStatus: "passive",
        PaymentInformationStepStatus: "passive"
      });
    }
  };
  defaultBillingAddress = address => {
    this.setState({
      defaultBillingAddress: address,
      isDefaultBilling: true,
      billingStatus: "defaultaddress"
    });
  };
  editShipping = childeData => {
    if (childeData === true) {
      this.setState({
        success: false,
        productTypeStatus: "ready",
        billingStatus: "ready",
        shippingStatus: "active",
        shippingMethodStatus: "passive",
        PaymentInformationStepStatus: "passive"
      });
    }
  };
  editShippingMethod = childeData => {
    if (childeData === true) {
      this.setState({
        success: false,
        productTypeStatus: "ready",
        billingStatus: "ready",
        shippingStatus: "ready",
        shippingMethodStatus: "active",
        PaymentInformationStepStatus: "passive"
      });
    }
  };
  editPayment = childeData => {
    if (childeData === true) {
      if(this.state.notPhysical) {
        this.setState({
          success: false,
          productTypeStatus: "ready",
          billingStatus: "ready",
          PaymentInformationStepStatus: "active"
        });
      }else {
        this.setState({
          success: false,
          productTypeStatus: "ready",
          billingStatus: "ready",
          shippingStatus: "ready",
          shippingMethodStatus: "ready",
          PaymentInformationStepStatus: "active"
        });
      }

    }
  };
  price = childeData => {
    this.setState({
      prices: childeData
    });
  };

  selectedMethod = childeData => {
    this.setState({
      selectedPaymentMethod:childeData
    })
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    /**
     *    STATUS
     *
     *    active
     *    passive
     *    filled
     *
     */
    let {
      differentShippingAddress,
      PaymentInformationStepStatus,
      billingStatus,
      shippingStatus,
      address,
      shippingMethodStatus,
      productTypeStatus,
      productTypeStepComponetReady,
      showFaildModal,
      selectedPaymentMethod
    } = this.state;
    let isCheckoutPageReady = false;

    if (productTypeStepComponetReady === true) {
      isCheckoutPageReady = true;
    }

    let progress = 0;
    // productTypeStatus
    // billingStatus
    // shippingStatus
    // shippingMethodStatus
    // PaymentInformationStepStatus
    if (productTypeStatus === "ready") {
      progress = 1;
    }
    if (billingStatus === "ready") {
      progress = 2;
    }
    if (shippingStatus === "ready") {
      progress = 3;
    }

    if (shippingMethodStatus === "ready") {
      progress = 4;
    }
    if(PaymentInformationStepStatus === "active") {
      progress = 4;
    }
    if (PaymentInformationStepStatus === "ready") {
      progress = 5;
    }
    return (
      <React.Fragment>
        <GlobalLoader loaderState={isCheckoutPageReady} />
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            Something went wrong please try again later
          </div>
        </ReactModal>

        <div className="checkout-container">
          <div className="wrapper">
            <h1 className="page-title"> Checkout </h1>
            <div className="checkout-box">
              <div className="checkout-form">
                <ProductTypeStep
                  defaultBillingAddress={this.defaultBillingAddress}
                  editType={this.editType}
                  notPhysical={this.notPhysical}
                  successProduct={this.successProductType}
                  orderId={this.props.match.params.id}
                  orderTypesReady={this.orderTypesReady}
                  error={this.handleError}
                  status={productTypeStatus}
                  summary={this.price}
                />
                {billingStatus !== "passive" ? (
                  <BillingInformationStep
                    defaultBillingAddress={this.state.defaultBillingAddress}
                    editBilling={this.editBilling}
                    notPhysical={this.notPhysical}
                    notPhysicalState={this.state.notPhysical}
                    differentShipping={this.differentShipping}
                    orderId={this.props.match.params.id}
                    getReadyAddress={this.getReadyAddress}
                    handleFunc={this.handleShippingChildeData}
                    status={billingStatus}
                    summary={this.price}
                  />
                ) : (
                  <div className="wrapper passive-box-wrapper">
                    <div className="passive-step-title">
                      <span> 1. </span> <span> Billing Information </span>
                    </div>
                  </div>
                )}
                {shippingStatus !== "passive" && address !== "" ? (
                  <ShippingAddressStep
                    editShipping={this.editShipping}
                    differentShippingAddress={differentShippingAddress}
                    orderId={this.props.match.params.id}
                    setReadyAddress={address}
                    handleFunc={this.handleShippingMethod}
                    status={shippingStatus}
                    summary={this.price}
                  />
                ) : (
                  <div className="wrapper passive-box-wrapper">
                    <div className="passive-step-title">
                      <span> 2. </span> <span> Shipping Information </span>
                    </div>
                  </div>
                )}
                {shippingMethodStatus !== "passive" ? (
                  <ShippingMethodStep
                    editShippingMethod={this.editShippingMethod}
                    orderTypesReady={this.orderTypesReady}
                    orderId={this.props.match.params.id}
                    status={shippingMethodStatus}
                    hendleToPaymentMethodStep={this.hendleToPaymentMethodStep}
                    summary={this.price}
                  />
                ) : (
                  <div className="wrapper passive-box-wrapper">
                    <div className="passive-step-title">
                      <span> 3. </span> <span> Shipping Method </span>
                    </div>
                  </div>
                )}
                {PaymentInformationStepStatus !== "passive" ? (
                  <PaymentInformationStep
                    success={this.handleSuccess}
                    editPayment={this.editPayment}
                    orderId={this.props.match.params.id}
                    status={PaymentInformationStepStatus}
                    hendleToFinsh={this.hendleToFinsh}
                    summary={this.price}
                    selectedMethod={this.selectedMethod}
                  />
                ) : (
                  <div className="wrapper passive-box-wrapper">
                    <div className="passive-step-title">
                      <span> 4. </span> <span> Payment Information </span>
                    </div>
                  </div>
                )}
              </div>
              <CheckoutSidebar
                orderId={this.props.match.params.id}
                onSuccess={this.state.success}
                price={this.state.prices}
                progress={progress}
                selectedMethod={selectedPaymentMethod}
                billingAddress={address}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Checkout;
