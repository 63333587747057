import React, { Component } from "react";
import { authHeader } from "../../../_helpers";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Scrollbars } from "react-custom-scrollbars";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
class DashboardSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statuses: {},
      showProjectModal: false,
      productList: {},
      top: 0,
      showProductList: false
    };
    this.handleFilterItemClick = this.handleFilterItemClick.bind(this);

    this.getOrderStatuses = this.getOrderStatuses.bind(this);

    this.showProjectModal = this.showProjectModal.bind(this);
    this.hideProjectModal = this.hideProjectModal.bind(this);
    this.getProductList = this.getProductList.bind(this);


    this.hideStatusBox = this.hideStatusBox.bind(this);
  }
  _isMounted = false;
  handleFilterItemClick(e) {
    if (e.currentTarget.classList.contains("active-status")) {
      // console.log('asd')
    } else {
      let box = document.getElementById('stats-box');

      if(box.classList.contains('opened')){
        box.classList.remove('opened')
      }
      let old = document.getElementsByClassName("active-status");

      old[0].classList.remove("active-status");
      e.currentTarget.classList.add("active-status");

      let id = e.currentTarget.getAttribute("data-id");
      window.scroll(0, 0);
      this.sendData(id);
    }
  }

  sendData = param => {
    this.props.handleStausChange(param);
  };
  componentDidMount() {
    this.getOrderStatuses();
  }
  hideStatusBox() {
    let box = document.getElementById('stats-box');

    if(box.classList.contains('opened')){
      box.classList.remove('opened')
    }
  }
  getOrderStatuses = async () => {
    let requestUrl = `/orderstatuses/getList`;
    let currentComponent = this;
    let res = await axios.get(apiUrl + requestUrl, { headers: authHeader() });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;

      currentComponent.setState(
        {
          statuses: data.payload.statuses
        },
        () => {
          currentComponent.props.orderStatusesReady(true);
          currentComponent.props.orderStatuses(data.payload.statuses);
        }
      );
    }
  };

  showProjectModal() {
    this.setState({
      showProjectModal: true
    });
  }
  hideProjectModal() {
    this.setState(
      {
        showProjectModal: false,
        showProductList: false
      },
      () => {
        let projectPopup = document.getElementsByClassName("new-project");
        if (projectPopup[0].classList.contains("product-list")) {
          projectPopup[0].classList.remove("product-list");
        }
      }
    );
  }
  getProductList = async () => {
    let current = this;
    let loader = document.getElementById("loader");
    loader.classList.add("show");

    let requestUrl = `/categories/getList`;
    let res = await axios.get(apiUrl + requestUrl);

    let { data } = res;
    if (data.error === null) {
      current.setState(
        {
          productList: data.payload
        },
        () => {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
          let projectPopup = document.getElementsByClassName("new-project");
          if (!projectPopup[0].classList.contains("product-list")) {
            projectPopup[0].classList.add("product-list");
          }
          current.setState({
            showProductList: true
          });
        }
      );
    }
  };
  componentWillUnmount() {}

  render() {
    if (this._isMounted !== true) {
      return false;
    }
    let {
      statuses,
      showProjectModal,
      showProductList,
      productList
    } = this.state;
    // console.log(statuses);
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let CREATE_ORDER;
    if(userPermission) {
      CREATE_ORDER = userPermission.find(
        opt => opt.key === 'CREATE_ORDER')
        
    }
    return (
      <React.Fragment>
        <ReactModal
          isOpen={showProjectModal}
          onRequestClose={this.hideProjectModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal new-project"
        >
          <React.Fragment>
            <div id="loader" className="loader circle">
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>

            {showProductList ? (
              <React.Fragment>
                <div className="title">Select Product</div>
                <Scrollbars
                  className="scroll-bar-continer"
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax={400}
                >
                  <div className="product-list">
                    {productList.map((list, key) => {
                      return (
                        <div className="item" key={key}>
                        <Link to={`/category/` + list.key}>
                          <img src={list.thumbnail} alt={list.name}/>
                          <div className="text-box">
                            <div className="table">
                              <div className="cell">
                                <div className="title">{list.name}</div>
                                {/* <div className="description">
                                  {list.short_description}
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      );
                    })}
                  </div>
                </Scrollbars>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="title">Create New Project</div>
                <div className="btn" onClick={this.getProductList}>
                  Start from a Blank Project
                </div>
                <Link className="btn" to="/templates/">
                  Use a Project Template
                </Link>
              </React.Fragment>
            )}
          </React.Fragment>
        </ReactModal>
        <div className="sticky-box-container " id="stats-box">
          <div className="overlay" onClick={this.hideStatusBox}></div>
          <div className="sticky-box">
            <ul className="status-box">
              <li
                className="active-status"
                data-id="0"
                onClick={this.handleFilterItemClick}
              >
                <span className="status-label"></span>
                <span className="text">All</span>
              </li>
              {statuses.map((item, key) => {
                return (
                  <li
                    key={key}
                    data-id={item.id}
                    onClick={this.handleFilterItemClick}
                  >
                    {/*<span*/}
                    {/*  className="status-label"*/}
                    {/*  style={{ background: `${item.color}` }}*/}
                    {/*></span>*/}
                    {/*<span className="text" style={{ background: `${item.color}` }}>{item.name}</span>*/}
                    <span className="item-text" style={{ background: `${item.color}` }}>{item.name}</span>
                  </li>
                );
              })}
            </ul>
            <ul className="menu">
              <li>
                <span className="status-label"></span>
                <Link to="/templates" className="text">
                  <span>My Templates</span>
                </Link>
              </li>
              <li>
                <span className="status-label"></span>
                <Link to="/account/information" className="text">
                  <span>Account Settings</span>
                </Link>
              </li>
              {currentUser.isMember === false || (currentUser.isMember === true && CREATE_ORDER !== undefined) ? (
                <div className="btn" id="new-project" onClick={this.showProjectModal}>
                  Create New Project
                </div>
              ):('')}
              
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DashboardSidebar;
