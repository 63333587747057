import React, { Component } from "react";
import ListView from "./list";

class OrderFileDocs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}
  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <ListView orderId={this.props.orderId} orderStatusId={this.props.orderStatusId} />
      </React.Fragment>
    );
  }
}

export default OrderFileDocs;
