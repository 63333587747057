import React, { Component } from "react";
import validator from "validator";
import FormValidator from "../../validations";
import axios from "axios";
import { authHeader } from "../../../_helpers";

const apiUrl = process.env.REACT_APP_API;
class CrediteNotAppliedView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        tradeLicense: "",
        trnCertificate: ""
      },
      filesInfo: {
        tradeLicense: [],
        trnCertificate: []
      },
      errors: [],
      errorClasses: []
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);
    this.handleSendFiles = this.handleSendFiles.bind(this);
  }
  componentDidMount() {}
  componentWillUnmount() {}
  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "tradeLicense",
        method: validator.isEmpty,
        validWhen: false,
        message: "Trade license field is required."
      },
      {
        field: "trnCertificate",
        method: validator.isEmpty,
        validWhen: false,
        message: "Trn certificate field is required."
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handleFileChange(e) {
    var file = e.target.files[0];
    var _URL = window.URL || window.webkitURL;
    var img = new Image();
    var currentComponent = this;
    const name = e.target.name;
    const user = this.state.user;
    user[name] = file;

    const filesInfo = this.state.filesInfo;
    console.log("sad");
     if (file.size > 5242880) {
       filesInfo[name] = { error: "Maximum file size: 5MB" };
       currentComponent.setState({ filesInfo });
     } else {
       filesInfo[name] = { name: file.name, size: file.size, error: "" };
       currentComponent.setState({ user, filesInfo });
     }
    // img.onload = function() {
    //   if (file.size > 5242880) {
    //     filesInfo[name] = { error: "Maximum file size: 5MB" };
    //     currentComponent.setState({ filesInfo });
    //   } else {
    //     filesInfo[name] = { name: file.name, size: file.size, error: "" };
    //     currentComponent.setState({ user, filesInfo });
    //   }
    // };
    img.src = _URL.createObjectURL(file);
  }
  handleRemoveFile(e) {
    const name = e.currentTarget.getAttribute("data-name");
    const user = this.state.user;
    const filesInfo = this.state.filesInfo;
    user[name] = "";
    filesInfo[name] = "";
    this.setState({ user, filesInfo });
  }
  handleSendFiles = async e => {
    e.preventDefault();
    if (this.handleValidation()) {
      let loader = document.getElementById("loader");

      loader.classList.add("show");
      let requestUrl = "/users/uploadFiles";
      const { user } = this.state;
      const sendingData = new FormData();

      sendingData.append("tradeLicense", user.tradeLicense);
      sendingData.append("trnCertificate", user.trnCertificate);
      let res = await axios.post(apiUrl + requestUrl, sendingData, {
        headers: authHeader()
      });
      let { data } = res;
      // console.log(data)
      if (data.error === null) {
        this.setState(
          {
            thankYou: true
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
          }
        );
        //   console.log(data);
      }
    }
  };
  render() {
    const { filesInfo, errors, thankYou } = this.state;
    let currentUser = JSON.parse(localStorage.getItem('user'))

    return (
      <React.Fragment>
        <div id="loader" className="loader fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>

        {thankYou ? (
          <div className="success-box" style={{ textAlign: "center" }}>
            <div className="icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="57"
                height="57"
                viewBox="0 0 57 57"
              >
                <g>
                  <path
                    fill="#7ed321"
                    d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                  />
                </g>
              </svg>
            </div>
            <div className="text">
              Your documents uploaded successfully and under moderation, our team will contact you soon.
            </div>
          </div>
        ) : (
          <div className="credits-box form alignCenter">
            <div className="title">You do not have Credit Limit.</div>

            <div className="text">
              Please provide the following documents if you wish to apply:<br></br>
              If you have any questions please contact us : <a rel="noopener noreferrer" href="mailto:support@flexoeasy.com">support@flexoeasy.com</a>
            </div>
            { currentUser.isMember !== true ? (
              <React.Fragment>
                <div className="row clear">
                  <div className="label">trade license</div>
                  <div className="attach-btn-box">
                    <input
                      type="File"
                      name="tradeLicense"
                      onChange={this.handleFileChange}
                      id="trade_license"
                      accept="image/*,.pdf"
                    />
                    {!filesInfo.tradeLicense.name ? (
                      <label htmlFor="trade_license" className="attach-btn">
                        Upload File
                      </label>
                    ) : (
                      <div className="attach-name-box">
                        <span className="name">
                          {filesInfo.tradeLicense.name + " "}
                        </span>
                        <span className="size">{` ( ${filesInfo.tradeLicense.size} Kb ) `}</span>
                        <span
                          className="remove"
                          data-name="tradeLicense"
                          onClick={this.handleRemoveFile}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g transform="translate(-810 -1063)">
                              <circle
                                cx="10"
                                cy="10"
                                r="10"
                                transform="translate(810 1063)"
                                fill="#acacac"
                              />
                              <path
                                d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                transform="translate(821.553 1090.545)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="invalid-feedback">{errors.tradeLicense}</div>
                </div>

                <div className="row clear">
                  <div className="label"> trn certificate</div>
                  <div className="attach-btn-box">
                    <input
                      type="file"
                      name="trnCertificate"
                      onChange={this.handleFileChange}
                      id="trn_certificate"
                      accept="image/*,.pdf"
                    />
                    {!filesInfo.trnCertificate.name ? (
                      <label htmlFor="trn_certificate" className="attach-btn">
                        Upload File
                      </label>
                    ) : (
                      <div className="attach-name-box">
                        <span className="name">
                          {filesInfo.trnCertificate.name + " "}
                        </span>
                        <span className="size">{` ( ${filesInfo.trnCertificate.size} Kb ) `}</span>
                        <span
                          className="remove"
                          data-name="trnCertificate"
                          onClick={this.handleRemoveFile}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                          >
                            <g transform="translate(-810 -1063)">
                              <circle
                                cx="10"
                                cy="10"
                                r="10"
                                transform="translate(810 1063)"
                                fill="#acacac"
                              />
                              <path
                                d="M3.321-21.3l-.874-.862L-1.417-18.3-5.294-22.16l-.874.862,3.877,3.877-3.877,3.877.874.862,3.877-3.877,3.865,3.877.874-.862L-.556-17.422Z"
                                transform="translate(821.553 1090.545)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="invalid-feedback">{errors.trnCertificate}</div>
                </div>
                <p>The accepted file formats for Trade License and TRN Certificate are PDF, JPG and PNG</p>
                <div className="row bottom">
                  <button onClick={this.handleSendFiles}>Apply</button>
                </div>
              </React.Fragment>
            ) :('')}
            
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default CrediteNotAppliedView;
