import React, { Component } from "react";

class AddressListItem extends Component {
  constructor(props) {
    super(props);
    const addressList = props.list;
    this.state = {
      addressList
    };
    this.handleEditAddress = this.handleEditAddress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const addressList = nextProps.list;
    this.setState({
      addressList
    });
  }
  handleEditAddress(e) {
    var addressId = e.currentTarget.getAttribute("data-addressid");
    this.props.editedAddressId(addressId);
  }
  render() {
    const { addressList } = this.state;

    let tabInfo = document.getElementsByClassName("tab-item");
    let tabItem = "";
    for (var i = 0; i < tabInfo.length; i++) {
      if (tabInfo[i].classList.contains("active")) {
        tabItem = tabInfo[i].getAttribute("data-tab");
      }
    }
    // if(asd= false){
    //     .getAttribute('data-tab')
    // }
    if (addressList.length > 0) {
      return addressList.map((item, key) => {
        return (
          <div className="item" key={key}>
            <div className="item-box">
              <div className="name-box">
                <div className="name">
                  {item.firstname + " " + item.lastname}
                </div>
                {item.isdefault ? <div className="label">Default</div> : ""}
              </div>
              <div className="phone-number">{item.telephone}</div>
              <div className="address">{item.street}</div>
            </div>
            <div className="item-box">
              <div
                className="btn"
                data-addressid={item.id}
                onClick={this.handleEditAddress}
              >
                Edit
              </div>
            </div>
          </div>
        );
      });
    } else {
      return (
        <div className="no-result-box">
          <div className="title">No {tabItem} address</div>
        </div>
      );
    }
  }
}

export default AddressListItem;
