import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import GlobalLoader from "../globalLoader";
import axios from "axios";
import {Helmet} from "react-helmet";
import Pagination from "react-js-pagination";

const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");
class Category extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            categoryInfo: {},
            redirect: false,
            redirectUrl: "",
            categoryIsExist: false,
            isCategoryFieldReady: false,
            pp: "25",
            searchValue: "",
        };
        this.checkCategorytUrl = this.checkCategorytUrl.bind(this);
        this.getCategoryBasicInfo = this.getCategoryBasicInfo.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);

    }
    _isMounted = false;
    getCategoryBasicInfo = async () => {
        let requestUrl = `/category/${this.props.match.params.categoryId}`;
        let currentComponent = this;
        let res = await axios.get(apiUrl + requestUrl);
    
        let { data } = res;
        if (data.error === null) {
          currentComponent.setState({
            categoryInfo: data.payload
          });
        //   let searchValue = this.state.searchValue,
        //     status = this.state.orderStatus,
        //     start = "0",
        //     end = this.state.pp,
        //     reset = true;
        //   return currentComponent.getProductList(searchValue, status, start, end, 1, reset)
          return currentComponent.getProductList();
        }
    };
    checkCategorytUrl = async () => {
        let requestUrl = "/checkcategorykey";
        let currentComponent = this;
        let postdata = {
            key: this.props.match.params.categoryId
        };

        let res = await axios.post(apiUrl + requestUrl, qs.stringify(postdata));

        let { data } = res;
        // console.log(data);
        if (data.error === null) {
            if (data.payload.success !== true) {
                currentComponent.setState({
                    redirect: true,
                    redirectUrl: "/not-found"
                });
            } else {
                currentComponent.setState({
                    isCategoryFieldReady: true
                });
                return currentComponent.getCategoryBasicInfo();
            }
        } else {
            currentComponent.setState({
            redirect: true,
            redirectUrl: "/not-found"
            });
        }
    };
    // getProductList = async (
    //     searchValue = "",
    //     start = "0",
    //     end,
    //     pageNumber,
    //     reset = true
    // ) => {
    //     // console.log("asd");
    //     if (reset === true) {
    //         this._isMounted = false;
        
    //         this.setState({ orderList: [] });
    //     } else {
    //         let current = this;
    
    //         let requestUrl = `/products/getList/${this.props.match.params.categoryId}`;
    //         let currentComponent = this;
    //         const queryParams = {};
    //         if (searchValue !== undefined) {
    //         queryParams.advancedSearch = searchValue;
    //         }
    //         if (start !== undefined) {
    //             queryParams._start = start;
    //         }
    //         if (end !== undefined) {
    //             queryParams._end = end;
    //         }

    //         let res = await axios.get(apiUrl + requestUrl, {
    //             params: queryParams
    //         });
        
    //         let { data } = res;
    //         if (data.error === null) {
    //             currentComponent._isMounted = true;
    //             let productList = data.payload.product.rows;
    //             let total = data.payload.product.count;
    //             current.setState(
    //                 {
    //                     total,
    //                     activePage: pageNumber,
    //                     productList
    //                 }
    //             );
    //         }
    //     }
    // };
    getProductList = async () => {
        // console.log("asd");
        let current = this;
    
        let requestUrl = `/products/getList/${this.props.match.params.categoryId}`;
        let currentComponent = this;
        let res = await axios.get(apiUrl + requestUrl);
    
        let { data } = res;
        if (data.error === null) {
            currentComponent._isMounted = true;
            current.setState(
                {
                    productList: data.payload
                }
            );
        }
    };
    // handlePageChange(pageNumber) {
    //     let { searchValue, pp, orderStatus } = this.state;
    //     let status = orderStatus,
    //       start = (pageNumber - 1) * pp,
    //       end = pp * pageNumber;
    //     this.getProductList(searchValue, status, start, end, 1, false)
    //     window.scroll(0, 0);
    // }
    componentDidMount(){
        document.body.classList.add('category');
        window.scroll(0,0)
        this.checkCategorytUrl();
    }
    componentWillUnmount(){
        document.body.classList.remove('category');
        this._isMounted = false;
    }

    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectUrl} />;
        }
        if (this._isMounted !== true) {
            return <GlobalLoader loaderState={true} />;
        }
        const {
            pp,
            activePage,
            total,
            categoryInfo,
            productList,
          } = this.state;
          
        return(
            <React.Fragment>
                <Helmet>
                    <title>{categoryInfo.meta_title}</title>
                    <meta name="title" content={categoryInfo.meta_title} />
                    <meta name="description" content={categoryInfo.meta_description} />
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:title" content={categoryInfo.meta_title} />
                    <meta property="og:description" content={categoryInfo.meta_description} />
                    <meta property="og:image" content={categoryInfo.image} />
                </Helmet>
                <div className="category-container">
                    <section className="top">
                        <div className="image-box">
                            <img alt="" src={categoryInfo.image} />
                        </div>
                        <div className="text-box">
                            <div className="table">
                                <div className="cell">
                                    <h1 className="page-title"> {categoryInfo.name} </h1>
                                    <div
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                        __html: categoryInfo.gallery_description
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <section className="top" style={backgroundImage}>
                        <div className="wrap-box">
                            <div className="table">
                                <div className="cell">
                                <h1 className="page-title"> {categoryInfo.name} </h1>
                                <div
                                    className="description"
                                    dangerouslySetInnerHTML={{
                                    __html: categoryInfo.gallery_description
                                    }}
                                ></div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    
                    {/* <section className="top" style={backgroundImage}>
                        <div className="wrap-box">
                        <div className="table">
                            <div className="cell">
                            <h1 className="page-title">Plate</h1>
                            <div className="description">
                                Print cut-to-shape stickers and labels of the highest quality. Made exclusively for your brand and shaped perfectly to fit your packaging, they will reflect your brand and its message. No matter the forms and design, we will make sure your prints are of exceptional quality.
                                We recommend you to try Fingerprint before purchasing configured plate
                            </div>
                            </div>
                        </div>
                        </div>
                    </section> */}
                    
                    <section className="products">
                        <div className="wrapper">
                            <h2 className="section-title">Product</h2>
                            <div className="product-list">
                                {productList.map((list, key) => {
                                    return (
                                        <div className="item" key={key}>
                                            <Link to={`/products/` + list.url}>
                                            <img src={list.thumbnail} alt={list.name}/>
                                            <div className="text-box">
                                                <div className="table">
                                                <div className="cell">
                                                    <div className="title">{list.name}</div>
                                                    <div className="description">
                                                    {list.short_description}
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                            {pp < total ? (
                                <Pagination
                                    innerClass="pagination clear"
                                    hideFirstLastPages
                                    pageRangeDisplayed={5}
                                    activePage={activePage}
                                    itemsCountPerPage={pp}
                                    totalItemsCount={total}
                                    onChange={this.handlePageChange}
                                    nextPageText={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 243.01 414.68"
                                    >
                                        <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path
                                            className="cls-1"
                                            d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                                            />
                                        </g>
                                        </g>
                                    </svg>
                                    }
                                    prevPageText={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 243.01 414.68"
                                    >
                                        <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path
                                            className="cls-1"
                                            d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                                            />
                                        </g>
                                        </g>
                                    </svg>
                                    }
                                />
                                ) : (
                                ""
                                )}
                        </div>
                    </section>
                </div>
                
            </React.Fragment>
        )
    }
}



export default Category;
