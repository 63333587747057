import React, { Component } from 'react';
import MapIcon from '../../Assets/images/svg/map-icon.svg'
import validator from 'validator';
import FormValidator from '../validations';
import axios from 'axios';
import ReactModal from 'react-modal';
import MetaTags from "../metaTags"

const apiUrl = process.env.REACT_APP_API;

var qs = require('query-string');

/* global google */

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail:{
        fullName: '',
        phoneNumber:'',
        email: '',
        message:'',
      },
      messageFromServer: '',
      errors: [],
      errorClasses: [],
    }

   this.handleChange = this.handleChange.bind(this);
   this.handleKeyPress = this.handleKeyPress.bind(this);
   this.sendMail = this.sendMail.bind(this);
  }
  handleValidation(){
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: 'fullName',
        method: validator.isEmpty,
        validWhen: false,
        message: 'First name field is required.'
      },
      {
        field: 'email',
        method: validator.isEmpty,
        validWhen: false,
        message: 'Email field is required.'
      },
      {
        field: 'email',
        method: validator.isEmail,
        validWhen: true,
        message: 'Please enter a valid email address.'
      },
      {
        field: 'message',
        method: validator.isEmpty,
        validWhen: false,
        message: 'Message field is required.'
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.mail);
    if(!validation.isValid){
        formIsValid = false;
        for (var variable in validation) {
          if (validation.hasOwnProperty(variable) && variable !== "isValid" ) {
            if(validation[variable]['isInvalid']){
              errors[variable] = validation[variable]['message'];
              errorClasses[variable] = "is-invalid";
            }
          }
        }

    }
    this.setState({errors: errors,errorClasses:errorClasses});
    return formIsValid;
  }

  handleChange(e){
    const name =  e.target.name;
    const mail = this.state.mail;
    mail[name] = e.target.value;
    this.setState({
        mail
    });

  }
 
  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      let currentComponent = this;
      currentComponent.sendMail(event)
    }
  }

  sendMail(e) {
    e.preventDefault();
    if(this.handleValidation()){
      // console.log('send')
      var currentComponent = this;
      let requestUrl = '/contactus';
      let postdata = {
        fullName: this.state.mail.fullName,
        email:  this.state.mail.email,
        phoneNumber:  this.state.mail.phoneNumber,
        message: this.state.mail.message,
      };
      axios.post(apiUrl+requestUrl,qs.stringify(postdata),
        {
          headers: {
              "Content-Type": "application/x-www-form-urlencoded"
          }
        }).then(function(response) {
          if(response.data.error === null) {
            currentComponent.setState({
                showModal:true,
                mail:{
                  fullName: '',
                  phoneNumber:'',
                  email: '',
                  message:'',
                },
            });
            currentComponent.fullName.value = "";
            currentComponent.email.value = "";
            currentComponent.phoneNumber.value = "";
            currentComponent.message.value = "";

            setTimeout(function(){
              currentComponent.setState({
                 showModal:false,
              });
            }, 2000);
          }
        });
    }
  }

  componentDidMount(){
    window.scroll(0,0)
    document.body.classList.add('contact');
    this.handleDrowMap()
  }
  componentWillUnmount(){
    document.body.classList.remove('contact');
  }
  handleDrowMap(id){
    var map ,marker,
    Marker = google.maps.Marker;
    var center = {lat: 25.030397, lng:55.185259};
      map = new google.maps.Map(document.getElementById('map'), {
       center: center,
       zoom: 16,
       disableDefaultUI: true,
       styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#f5f5f5"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#bdbdbd"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "poi.business",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#757575"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#dadada"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#616161"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#e5e5e5"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#eeeeee"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#c9c9c9"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#9e9e9e"
            }
          ]
        }
      ],
      });

      marker = new Marker({
        position: center,
        map: map,
        icon: MapIcon,
        title:''
      });

      var infowindowContent = '<div class="info-window"><div class="title">'+
        '<span>NDigitec (Namma Int’l Digitec FZ LLC)</span>' +
        '</div>'+
        '<div class="text">Plot 36 - Dubai 04 435 5919</div></div>';
      var infowindow = new google.maps.InfoWindow({
        content: infowindowContent,
        maxWidth: 250
    });

    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open(map, marker);
    });
    google.maps.event.addListener(map, "click", function(event) {
      infowindow.close();
    });
   
 }
  render(){
    
    const {showModal,errorClasses ,errors} = this.state

    return(
        <div className="contact-container">
          <MetaTags/>
          <div className="wrapper">
            <h1 className="page-title">Contact us</h1>
            <div id="map"></div>
            <ReactModal
              isOpen={showModal}
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="modal thank-you"
            >
                    <div className="title">Thanks for contacting us!</div>
                    <div className="text">We will be in touch with you shortly.</div>
            </ReactModal>
            <div className="form-wrapper">
                <h2>Feel free to contact us</h2>
                <div className="row">
                  <div className="">
                    <label className="control-label">
                      Full name
                    </label>
                    <input onKeyPress={this.handleKeyPress} className={errorClasses.fullName} onChange={this.handleChange} tabIndex='1' ref={el => this.fullName = el} type="text" name="fullName" placeholder="Enter your full name"/>
                    <div className="invalid-feedback">{errors.fullName}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="">
                    <label className="control-label">
                      EMAIL ADDRESS
                    </label>
                    <input onKeyPress={this.handleKeyPress} className={errorClasses.email} onChange={this.handleChange} tabIndex='2' ref={el => this.email = el} type="email" name="email" placeholder="Enter your email"/>
                    <div className="invalid-feedback">{errors.email}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="">
                    <label className="control-label">
                      PHONE NUMBER
                    </label>
                    <input onKeyPress={this.handleKeyPress} className={errorClasses.phoneNumber} onChange={this.handleChange} tabIndex='3' ref={el => this.phoneNumber = el} type="tel" name="phoneNumber" placeholder="Enter your phone number"/>
                    <div className="invalid-feedback">{errors.phoneNumber}</div>
                  </div>
                </div>

                <div className="row">
                  <div className="">
                    <label className="control-label">
                      message
                    </label>
                    <textarea onKeyPress={this.handleKeyPress} className={errorClasses.message} onChange={this.handleChange} tabIndex='4' ref={el => this.message = el} name="message" placeholder="Message"></textarea>
                    <div className="invalid-feedback">{errors.message}</div>
                  </div>
                </div>
                
                <div className="row">
                  <button type="submit"  onClick={this.sendMail} className="btn">Submit</button>
                </div>
            </div>
          </div>
        </div>
    )

  }

}



export default ContactPage;
