import React, { Component } from "react";
import { authHeader } from "../../_helpers";
import axios from "axios";
import ReactModal from "react-modal";

const apiUrl = process.env.REACT_APP_API;

class CheckoutSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      progress: 0,
      showFaildModal:false,
      selectedPaymentMethod:{},
      billingAddress:{},
      form:'',

      price: {
        subtotal: 0,
        shipping: 0,
        discount: 0,
        grandTotalExclVat: 0,
        vat: 0,
        grandTotalInclVat: 0
      }
    };

    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);
    this.generateInvoice = this.generateInvoice.bind(this);
  }
  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  submit = async e => {
    let id = this.props.orderId;
    let {selectedPaymentMethod,billingAddress,price} = this.state
    let requestUrl = `/checkout/submit`;
    let currentComponent = this;
    let loader = document.getElementById("submit-loader");
    if(selectedPaymentMethod.key === "checkout") {


      let grandTotalPrice = parseFloat(price.grandTotalInclVat).toFixed(2)
      let grand_total = Number(grandTotalPrice)  * 100

      window.Checkout.configure({
          publicKey: 'pk_test_62fa9806-16f2-45bf-9445-f7ff93552224',
          customerName: `${billingAddress.firstname} ${billingAddress.lastname}`,
          value: grand_total,
          currency: 'AED',
          payButtonSelector: '.customButton' || '#customButton',
          paymentMode: 'cards',
          cardFormMode: 'cardTokenisation',
          cardTokenised: function (event) {
            loader.classList.add("show");
            axios.post(
              apiUrl + requestUrl,
              { orderId: id ,cardToken:event.data.cardToken},
              {
                headers: authHeader()
              }
            ).then(res => {

              if(res.data.error === null) {
                if (res.data.payload.redirectUrl) {
                  window.location = res.data.payload.redirectUrl;
                }else {
                  if(loader.classList.contains('show')) {
                    loader.classList.remove("show");
                  }
                  currentComponent.setState({
                    showFaildModal: true
                  });
                }
              }else {
                if(loader.classList.contains('show')) {
                  loader.classList.remove("show");
                }
                currentComponent.setState({
                  showFaildModal: true
                });
              }
            })

            // let request = checkout.doRequest("web/checkout/submitorder", "POST", {cardToken:event.data.cardToken});
            // request
            //     .then(data => {
            //       if (data.result.redirectUrl) {
            //         window.location = data.result.redirectUrl;
            //       }
            //     })
            //     .catch(error => {
            //       console.log("error", error);
            //     });
          }
        });
        window.Checkout.open();
    }else {
      loader.classList.add("show");
      let res = await axios.post(
        apiUrl + requestUrl,
        { orderId: id },
        {
          headers: authHeader()
        }
      );

      let { data } = res;

      if(data.error === null) {
        if (data.payload.type === "paypal") {
          window.location = data.payload.url;
        } else if (data.payload.type === "card") {
          this.setState({
            form:data.payload.form
          }, () => {
            setTimeout(function(){
              document.getElementById("payfortSignupForm").submit();
            }, 300)
          })
        } else if (data.payload.type === "cash") {
          let orderId = data.payload.orderId;
          if (data.payload.success === true) {
            window.location = `/order/${orderId}/success`;
          } else {
            window.location = `/order/${orderId}/failed`;
          }
        } else if (data.payload.type === "pdc") {
          let orderId = data.payload.orderId;
          if (data.payload.success === true) {
            window.location = `/order/${orderId}/success`;
          } else {
            window.location = `/order/${orderId}/failed`;
          }
        } else if (data.payload.type === "dp") {
          let orderId = data.payload.orderId;
          if (data.payload.success === true) {
            window.location = `/order/${orderId}/success`;
          } else {
            window.location = `/order/${orderId}/failed`;
          }
        }
      }else {
        if(loader.classList.contains('show')) {
          loader.classList.remove("show");
        }
        this.setState({
          showFaildModal: true
        });
      }
    }


  };
  generateInvoice  = async e => {
    let loader = document.getElementById("submit-loader"),
        id = this.props.orderId,
        requestUrl = `/checkout/summarypdf/${id}`;
    loader.classList.add("show");
    let res = await axios.get(apiUrl + requestUrl,{headers: authHeader()});
    let { data } = res;

    if(data.error === null) {
      let fileUrl = data.payload.fileUrl
      this.downloadfile(fileUrl)
    }else {
      if(loader.classList.contains('show')) {
        loader.classList.remove("show");
      }
      this.setState({
        showFaildModal: true
      });
    }
  }
  downloadfile(fileUrl) {
    let element = document.createElement('a');
    let loader = document.getElementById("submit-loader");
    element.setAttribute('target','_blank');
    element.setAttribute('href', fileUrl);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    loader.classList.remove("show");

  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let successs = nextProps.onSuccess;
    let price = nextProps.price;
    let progress = nextProps.progress;
    let selectedPaymentMethod = nextProps.selectedMethod;
    let billingAddress = nextProps.billingAddress;
    this.setState({
      success: successs,
      progress: progress,
    });
    if(billingAddress !== undefined) {
      this.setState({
        billingAddress:billingAddress
      });
    }
    if(selectedPaymentMethod !== undefined) {
      this.setState({
        selectedPaymentMethod:selectedPaymentMethod
      });
    }
    if (price !== undefined && price.subtotal !== undefined) {
      this.setState({
        price: price
      });
    }
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://cdn.checkout.com/sandbox/js/checkout.js";
    script.async = true;
    document.head.appendChild(script);


  }
  componentWillUnmount() {}
  render() {
    let { price, progress, showFaildModal, form} = this.state;
    var progressBarStyle = { width: `${progress * 20}%` };
    return (
      <div className="sticky-box-container">
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            Something went wrong please try again later
          </div>
        </ReactModal>
        {form !== '' ? (
          <div dangerouslySetInnerHTML={{
            __html: form
            }}>

          </div>
        ):('')}
        <div id="submit-loader" className="loader fixed  circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        {/* price.subtotal price.shipping price.discount price.grandTotalExclVat
        price.vat price.grandTotalInclVat */}
        <div className="sticky-box">
          <div className="title">Summary</div>
          <div className="pricing-box">
            <div className="row clear subtotal">
              <span className="text">Subtotal</span>
              <span className="value subtotal">{`AED ${price.subtotal.toFixed(
                2
              )}`}</span>
            </div>
            <div className="row clear subtotal">
              <span className="text">Shipping and handling</span>
              <span className="value subtotal">
                {`AED ${price.shipping.toFixed(2)}`}
              </span>
            </div>
            <div className="row clear subtotal">
              <span className="text">Discount</span>
              <span className="value subtotal">
                {`AED ${price.discount.toFixed(2)}`}
              </span>
            </div>
            <div className="row clear grand_total">
              <span className="text">Grand total (Excl. VAT)</span>
              <span className="value grand_total">{`AED ${price.grandTotalExclVat.toFixed(
                2
              )}`}</span>
            </div>
            <div className="row clear vat">
              <span className="text">5% VAT</span>
              <span className="value vat">{`AED ${price.vat.toFixed(2)}`}</span>
            </div>
            <div className="row clear total">
              <span className="text">Grand Total (Incl. VAT)</span>
              <span className="value total">{`AED ${price.grandTotalInclVat.toFixed(
                2
              )}`}</span>
            </div>
          </div>
          {this.state.progress === 4 || this.state.progress === 5 ? (
            <div className="invoice-btn-box">
              <span onClick={this.generateInvoice}>Generate invoice</span>
            </div>
          ):''}
          {this.state.success ? (
            <button
              className="btn"
              id="sumbit-order"
              data-type="active"
              onClick={this.submit}
            >
              Proceed
            </button>
          ) : (
            <button
              disabled
              className="btn"
              id="sumbit-order"
              data-type="disabled"
            >
              Proceed
            </button>
          )}

          <div className="progress-bar">
            <span className="active" style={progressBarStyle}></span>
            <span className="passive"></span>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutSidebar;
