import React, { Component } from "react";
import { authHeader } from "../../_helpers";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;

class ProductTypeStep extends Component {
  constructor(props) {
    super(props);
    let ProductTypeStatus = props.status;
    let orderId = props.orderId;
    this.state = {
      notPhysical: false,
      selectedId: "",
      ProductTypeStatus,
      orderTypes: {},
      orderTypesReady: false,
      showModal: false,
      colors: {},
      orderColors: [],
      orderId,
      readyData: {},
      selectedClass: "",
      orderSelectedType:[],
      errors:[],
      onlyPhysical:false
    };
    this.getOrderType = this.getOrderType.bind(this);
    this.handleSwatchChange = this.handleSwatchChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleColorSelect = this.handleColorSelect.bind(this);
    // this.saveColors = this.saveColors.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleSubmitOrderType = this.handleSubmitOrderType.bind(this);
  }

  componentDidMount() {
    let id = this.state.orderId;
    this.getOrderType(id);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let ProductTypeStatus = nextProps.status;
    this.setState({
      ProductTypeStatus
    });
  }
  getOrderType = async orderId => {
    let requestUrl = `/checkoutOrderType/getOrdertypes/${orderId}`;
    let {orderSelectedType} = this.state
    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      let orderTypes = data.payload.order_types
      let colors
      let summary = data.payload.summary;
      let onlyPhysical = data.payload.onlyPhysical
      orderTypes.map((item , key) => {
        let itemId = item.id;
        orderSelectedType[itemId] = orderSelectedType[itemId] === undefined ? {} : orderSelectedType[itemId]
        orderSelectedType[itemId]['selected'] = orderSelectedType[itemId]['selected'] === undefined ? {} : orderSelectedType[itemId]['selected']
        orderSelectedType[itemId]['selected'] = false

        if(item.colors !== undefined) {
          colors = item.colors
          for(var j=0; j <= colors.length-1; j++) {
            const optionItem = colors[j]
           
            orderSelectedType[itemId] = orderSelectedType[itemId] === undefined ? {} : orderSelectedType[itemId]
            orderSelectedType[itemId]['colors'] = orderSelectedType[itemId]['colors'] === undefined ? {} : orderSelectedType[itemId]['colors']
            orderSelectedType[itemId]['colors'][optionItem] = false
  
          }
        }
        return true;
      })
        
      this.setState(
        {
          orderTypes,
          orderTypesReady: true,
          colors: colors,
          onlyPhysical,
          orderSelectedType
        },
        () => {
          this.props.orderTypesReady(true);
          this.props.summary(summary);
        }
      );
    }else {
      this.props.error(true)
    }
  };
  handleSwatchChange(e) {
    const name = e.target.name;
    const orderSelectedType = this.state.orderSelectedType;
    const {orderTypes} = this.state
    if (e.target.checked) {
      orderSelectedType[e.target.id]['selected'] = true;

      let orderTypeItem = orderTypes.find(
        opt => opt.id === Number(e.target.id)
      )
      let id = orderTypeItem.id
      let colors = orderSelectedType[e.target.id].colors
      if(colors) {
        Object.keys(colors).map((key , item) => {
          let colorId = `${id}-${key}-${item}`
          document.getElementById(colorId).checked = true;
          orderSelectedType[name]['colors'][key] = true;
          return true;
        })
        
      }
    } else {
      orderSelectedType[e.target.id]['selected'] = false
    }
    
    this.setState({
        orderSelectedType,
        orderTypesError:'',
        errors:[]

    });
  }
  handleCloseModal() {
    this.setState({
      showModal: false,
      orderColorsErrorMessage: ""
    });
  }
  handleColorSelect(e) {
    let {orderSelectedType} = this.state
    const name = e.target.name;
    let id = e.target.id;
    
    id =  id.replace(/^[0-9]\-|\-[0-9]/g,'') //eslint-disable-line
    
    if (e.target.checked) {
      orderSelectedType[name]['colors'][id] = true;
    } else {
      orderSelectedType[name]['colors'][id] = false;
    }
    this.setState({
      orderSelectedType,
      errors:[]
    });
  }

  handleEdit() {

    let {
      orderTypes,
      orderSelectedType,
    } = this.state;
    this.setState({
      // orderColors: {},
      ProductTypeStatus: "active",
      orderColorsErrorMessage: ""
    });



    setTimeout(function(){
      for (let [key] of Object.keys(orderSelectedType)) {

        let isSelected = orderSelectedType[key].selected
        if(isSelected) {
          let orderTypeItem = orderTypes.find(
            opt => opt.id === Number(key)
          )
          let id = orderTypeItem.id
          document.getElementById(id).checked = true;
          let colors = orderSelectedType[key].colors

          if(colors) {
            Object.keys(colors).map((key , item) => {
              if(colors[key]) {
                let colorId = `${id}-${key}-${item}`
                document.getElementById(colorId).checked = true;
              }
              return true;
            })
          }
        }
      }
  
    },2)

    this.props.editType(true);
  }
  handleSubmitOrderType = async e => {
    let {errors, orderId,orderSelectedType,orderTypes } = this.state;
    let currentComponent = this
    let isNotSelected = false;
    let showShippingStep = true
    for (let [key] of Object.keys(orderSelectedType)) {

      let isSelected = orderSelectedType[key].selected
      if(isSelected) {
        isNotSelected = true

        let hasShipping = orderTypes.find(opt => opt.id === Number(key)).hasShipping
        if(hasShipping === 1) {
          showShippingStep = false;
        }
      }
      if(isSelected && orderSelectedType[key]['colors'] &&  Object.values(orderSelectedType[key]['colors']).indexOf(true) === -1) {

        errors[key] = 'Please select at last one item';
        currentComponent.setState({
          errors,
        });
        return false;
      }
    }


    if(!isNotSelected) {
      currentComponent.setState({
        orderTypesError:'Please select at last one type',
      });
      return false
    }

    let selected = {}
    Object.keys(orderSelectedType).map(function(key , item) {
      return selected[key] = orderSelectedType[key]
    })
    let postData = {
      orderId: orderId,
      orderTypeId: 2,
      orderTypes: JSON.stringify(selected),
    };
    let requestUrl = `/checkoutOrderType/setOrdertype`;
    let res = await axios.post(apiUrl + requestUrl, postData, {
      headers: authHeader()
    });

    let { data } = res;

    if (data.error === null) {
      // console.log('payload' , data.payload)
      let defaultBillingAddr = data.payload.defaultBillingAddr;
      if (defaultBillingAddr !== undefined) {
        this.props.defaultBillingAddress(defaultBillingAddr);
      }
      this.props.notPhysical(showShippingStep);
      this.props.successProduct(true);
      let summary = data.payload.summary;

      this.props.summary(summary);
    }else {
      console.log('error' , data.error)
    }
  }
  render() {
    let {
      orderTypes,
      selectedId,
      ProductTypeStatus,
      orderTypesReady,
      orderTypesError,
      orderSelectedType,
      onlyPhysical, 
      errors
    } = this.state;
    if (orderTypesReady !== true) {
      return false;
    }
    if (ProductTypeStatus === "ready") {
      var typeName = [];

      for (let [key] of Object.keys(orderSelectedType)) {

        let isSelected = orderSelectedType[key].selected
        if(isSelected) {
          let orderTypeName = orderTypes.find(
            opt => opt.id === Number(key)
          )
          typeName.push(orderTypeName.name)
        }
      }

      return (
        <div className="wrapper ready-box-wrapper form clear">
          <div className="ready-container">
            <div className="info-box">
              <div className="passive-step-title">
                <span>Order Type</span>
              </div>
              <div className="name ready-row">{typeName.filter(Boolean).join(", ")}</div>
            </div>
            <div onClick={this.handleEdit} className="edit">
              Edit
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="wrapper product-type-form-wrapper form clear">
        <div className="step-title">Select order type</div>
        <div className="item-box ">
          {orderTypes.map((item, key) => {
            let color = item.colors,
                itemId = item.id;
            return (
              <React.Fragment key={key}>
                <div className="item" key={key}>
                  <input
                    type="checkbox"
                    defaultChecked={item.id === selectedId ? true : false}
                    id={item.id}
                    name={item.id}
                    onChange={this.handleSwatchChange}
                    data-color="true"
                  />
                  <label htmlFor={item.id} className="label-checkbox">
                    <div className="main-box">
                      <div className="text-box">
                        <div className="title">{item.name}</div>
                        <div className="description">{item.description}</div>
                      </div>
                      {item.priceByColors !== true ? ( <div className="price">AED {item.orderTypePrice.toFixed(2)} {color ? 'per color' : ''} </div> ) : ''}
                    </div>
                    {color !== undefined && color.length > 0 ? 
                      (
                        <div className="color-box">
                          {item.colors.map((item, key) => {
                            return (
                                <div className="color" key={key}>
                                  <input
                                    type="checkbox"
                                    id={`${itemId}-${item}-${key}`}
                                    name={itemId}
                                    onChange={this.handleColorSelect}
                                    data-color="true"
                                    color-name={item}
                                    data-key={key}
                                    disabled={onlyPhysical}
                                  />
                                  <label htmlFor={`${itemId}-${item}-${key}`} className="label-color-checkbox">
                                    <div className="icons">
                                      <svg className="icon-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                        <rect className="cls-1" width="14" height="14"/><polyline className="cls-2" points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                      </svg>
                                      <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                                        <rect className="cls-1" width="14" height="14"/><path className="cls-2" d="M7,11.5v-9M2.5,7h9"/>
                                      </svg>
                                    </div>
                                    <div className="text">
                                      <div className="text-box">
                                        {item}
                                      </div>
                                    </div>
                                  </label>
                                </div>
                            );
                          })}
                        </div>
                      ):(
                        ''
                      )
                    }
                  </label>
                  <div className="invalid-feedback">{errors[itemId]}</div>
                    
                </div>
              </React.Fragment>
              
            );
          })}

        </div>
        <div className="button-row">
          <div className="error-msg">{orderTypesError}</div>

          <div className="next" onClick={this.handleSubmitOrderType}>
            Next
          </div>
        </div>
      </div>
    );
  }
}
export default ProductTypeStep;