import React, { Component } from "react";

import MembersSearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";
import validator from "validator";
import FormValidator from "../../validations";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;

class MembersListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "15",
      searchValue: "",
      member: {
        firstName: "",
        lastName: "",
        email: "",
        memberId: "",
        roleId: ""
      },
      memberList: {},
      showModal: false,
      isEdit: false,
      memberId: "",
      errors: [],
      errorClasses: [],
      rolesList: {},
      thankYou: false,
      thankYouTitle: "",
      thankYouText: ""
    };
    this.getMembersList = this.getMembersList.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleEditMember = this.handleEditMember.bind(this);
    this.handleAddMember = this.handleAddMember.bind(this);
  }

  _isMounted = false;
  _isRolesReady = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getMembersList(searchValue, start, end, 1, reset);
  }

  getMembersList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ memberList: [] });
    } else {
    }
    let requestUrl = `/members`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.advancedSearch = searchValue;
    }
    // if (status !== undefined) {
    //   queryParams.statusId = status;
    // }
    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let memberList = data.payload.members.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.members.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          memberList
        },
        () => {
          currentComponent.props.membersListReady(true);
          currentComponent.getRoles();
        }
      );
    }
  };

  getRoles = async () => {
    let requestUrl = `/roles`;
    let currentComponent = this;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      currentComponent._isRolesReady = true;
      this.setState({
        rolesList: data.payload.roles
      });
      //   console.log(data);
    }
  };

  getMemberData = async id => {
    // let loader = document.getElementById("popup-loader");
    // loader.classList.add("show");
    let requestUrl = `/member/${id}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      //   currentComponent._isRolesReady = true;
      this.setState(
        {
          member: {
            firstName: data.payload.firstname,
            lastName: data.payload.lastname,
            email: data.payload.email,
            memberId: data.payload.id,
            roleId: data.payload.roleId
          }
        },
        () => {
          document.getElementById(data.payload.roleId).checked = true;

          let loader = document.getElementById("popup-loader");
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this._isRolesReady = false;
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getMembersList(searchValue, start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  handleSearchValue = childData => {

    this.setState({
      searchValue: childData
    });
    this.getMembersList(childData, 0, this.state.pp, 1, false);
  };

  handleOpenModal(e) {
    var target = e.currentTarget;
    if (target.getAttribute("data-memberid")) {
      var memberId = target.getAttribute("data-memberid");
      this.setState(
        {
          showModal: true,
          isEdit: true,
          memberId
        },
        () => {
          this.getMemberData(memberId);
        }
      );
    } else {
      this.setState({
        showModal: true
      });
    }
    this.setState({
      member: {
        firstName: "",
        lastName: "",
        email: "",
        memberId: "",
        roleId: ""
      }
    });
  }

  handleCloseModal() {
    this.setState({
      showModal: false,
      isEdit: false,
      memberId: "",
      errors: [],
      errorClasses: []
    });
  }

  handleValidation() {
    // let fields = this.state.member;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last name field is required."
      },
      {
        field: "roleId",
        method: validator.isEmpty,
        validWhen: false,
        message: "Role field is required."
      }
    ];
    if (!this.state.isEdit) {
      validationRules.push(
        {
          field: "email",
          method: validator.isEmpty,
          validWhen: false,
          message: "Email field is required."
        },
        {
          field: "email",
          method: validator.isEmail,
          validWhen: true,
          message: "Please enter a valid email address."
        }
      );
    }

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.member);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleChange(e) {
    const name = e.target.name;
    const member = this.state.member;
    member[name] = e.target.value;
    this.setState({
      member
    });
  }

  handelChecked(e) {
    const member = this.state.member;
    const name = e.target.name;
    member[name] = e.target.id;
    this.setState({
      member
    });
  }

  handleEditMember = async e => {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    if (this.handleValidation()) {
      let loader = document.getElementById("popup-loader");

      loader.classList.add("show");
      let requestUrl = `/members/edit/${this.state.memberId}`;
      let currentComponent = this;
      let member = this.state.member;
      let memberData = {
        firstname: member.firstName,
        lastname: member.lastName,
        email: member.email,
        roleId: member.roleId
      };
      let res = await axios.post(apiUrl + requestUrl, memberData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        this.getMembersList(searchValue, start, end, 1, reset);

        this.setState(
          {
            thankYou: true,
            thankYouText: `User ${member.email} edited successfully.`
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
            setTimeout(function() {
              currentComponent.setState({
                member: {
                  firstName: "",
                  lastName: "",
                  email: "",
                  memberId: "",
                  roleId: ""
                },
                thankYou: false,
                thankYouText: "",
                showModal: false
              });
            }, 3000);
          }
        );
      }
      //
    }
  };

  handleAddMember = async e => {
    e.preventDefault();
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    if (this.handleValidation()) {
      let loader = document.getElementById("popup-loader");

      loader.classList.add("show");
      let requestUrl = `/members/add`;
      let currentComponent = this;
      let member = this.state.member;
      let memberData = {
        firstname: member.firstName,
        lastname: member.lastName,
        email: member.email,
        roleId: member.roleId
      };
      let res = await axios.post(apiUrl + requestUrl, memberData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        this.getMembersList(searchValue, start, end, 1, reset);

        this.setState(
          {
            thankYou: true,
            thankYouText: `Thank you user with ${member.email} email invited.`
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
            setTimeout(function() {
              currentComponent.setState({
                member: {
                  firstName: "",
                  lastName: "",
                  email: "",
                  memberId: "",
                  roleId: ""
                },
                thankYou: false,
                thankYouText: "",
                showModal: false
              });
            }, 3000);
          }
        );
      } else {
        if (data.error.code === 401) {
          let { errors, errorClasses } = this.state;
          errors["email"] = data.error.message;
          errorClasses["email"] = "is-invalid";
          this.setState({
            errors,
            errorClasses
          });
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      }
      //
    }
  };

  render() {
    if (this._isRolesReady !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {
      pp,
      total,
      activePage,
      showModal,
      isEdit,
      errors,
      errorClasses,
      member,
      memberList,
      rolesList,
      thankYou,
      thankYouText,
      searchValue,
    } = this.state;
    let showModalLoader = "";
    isEdit ? (showModalLoader = "show") : (showModalLoader = "");
    // console.log(this.state);
    let currentUser = JSON.parse(localStorage.getItem('user'))
    return (
      <React.Fragment>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal add-edit-member"
        >
          <React.Fragment>
            <div
              id="popup-loader"
              className={`loader ${showModalLoader} circle`}
            >
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
            {thankYou ? (
              <React.Fragment>
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">{thankYouText}</div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="title">
                  {isEdit ? "Edit member" : "Add new member"}
                </div>
                <div className="form-box">
                  <div className="row row-2 clear">
                    <div className="item">
                      <div className="label">First Name</div>
                      <input
                        autoComplete="new-firstName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        value={member.firstName}
                        ref={el => (this.firstName = el)}
                        name="firstName"
                        className={errorClasses.firstName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.firstName}</div>
                    </div>
                    <div className="item">
                      <div className="label">Last Name</div>
                      <input
                        autoComplete="new-lastName"
                        onKeyPress={this.handleKeyPress}
                        type="text"
                        value={member.lastName}
                        ref={el => (this.lastName = el)}
                        name="lastName"
                        className={errorClasses.lastName}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.lastName}</div>
                    </div>
                  </div>
                  {isEdit ? (
                    <div className="row clear">
                      <div className="label">Email</div>
                      <div className="editable-row">
                        <div className="title">
                          <span>{member.email}</span>
                          <svg viewBox="0 0 20 20" id="locked">
                            <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row clear">
                      <div className="label">Email</div>
                      <input
                        autoComplete="new-email"
                        onKeyPress={this.handleKeyPress}
                        type="email"
                        value={member.email}
                        ref={el => (this.email = el)}
                        name="email"
                        className={errorClasses.email}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.email}</div>
                    </div>
                  )}
                </div>
                <div className="row roles-box clear">
                  {rolesList.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <input
                          className="custom-radio"
                          id={item.id}
                          onChange={this.handelChecked}
                          type="radio"
                          ref={el => (this.roleId = el)}
                          name="roleId"
                        />
                        <label htmlFor={item.id} className="custom-lable-radio">
                          {item.name}
                        </label>
                      </div>
                    );
                  })}
                  <div className="invalid-feedback">{errors.roleId}</div>
                </div>
                <div className="button-row">
                  <div onClick={this.handleCloseModal}>Discard</div>
                  {isEdit ? (
                    <div onClick={this.handleEditMember}>Save changes</div>
                  ) : (
                    <div onClick={this.handleAddMember}>Add</div>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </ReactModal>
        <div className="container-header">
          <MembersSearchView searchValue={this.handleSearchValue} />
          {currentUser.isMember === false ? (
            <div
            className="add-member-btn add-btn"
            onClick={this.handleOpenModal}
          >
            Add Member
          </div>
          ):('')}
          
        </div>

        {memberList.length > 0 ? (
          <div className="members-list-box table-grid">
            <div className="table-row table-head">
              <div className="table-cell">
                <div className="text">Name</div>
              </div>
              <div className="table-cell">
                <div className="text">Email</div>
              </div>
              <div className="table-cell">
                <div className="text">Roles</div>
              </div>
              <div className="table-cell">
                <div className="text"> </div>
              </div>
            </div>

            {memberList.map((item, key) => {
              return (
                <div className="table-row" key={key}>
                  <div className="table-cell">
                    <div className="text">
                      <div>Name:</div>
                      <div>
                        {item.firstname} {item.lastname}
                      </div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Email:</div>
                      <div>{item.email}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Roles:</div>
                      <div>{item.role.name}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    {currentUser.isMember === false ? (
                      <div className="btn">
                      <span
                        data-memberid={item.id}
                        onClick={this.handleOpenModal}
                      >
                        Edit
                      </span>
                    </div>
                    ):('')}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-result-box">
            <div className="text">
              {searchValue ? `No result for: ${searchValue}` : `No Members`}
            </div>
          </div>
        )}

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default MembersListView;
