import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

class OrderSuccess extends Component {
  componentDidMount() {
    document.body.classList.add("order-page", "order-success");
    window.scroll(0, 0);
  }
  componentWillUnmount() {
    document.body.classList.remove("order-page", "order-success");
  }

  render() {
    return (
      <div className="order-success-container">
        <Helmet>
          <title>Thank you for your order | Flexoeasy.com</title>
          <meta name="title" content={'Thank you for your order | Flexoeasy.com'} />
          <meta name="description" content={'Flexoeasy.com is a digital platform that makes flexographic plate-making easy and allows customers to access high-quality printing from anywhere in the world.'} />
        </Helmet>
        <div className="wrapper">
          <div className="table">
            <div className="cell">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="57"
                  viewBox="0 0 57 57"
                >
                  <g>
                    <path
                      fill="#7ed321"
                      d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                    />
                  </g>
                </svg>
              </div>
              <div className="title">Your order has been received.</div>
              <div className="text">Thank you for your purchase!</div>
              <div className="text order-text">
                Order ID: <span>{this.props.match.params.id}</span>
              </div>
              <div className="button-box">
                <Link className="button" to="/dashboard">
                  Dashboard
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrderSuccess;
