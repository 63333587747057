import React, { Component } from "react";
import globalLoader from "../Assets/images/loader.gif";

class GlobalLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideLoader: false
    };
  }
  componentDidMount() {}
  componentWillUnmount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    let loaderState = nextProps.loaderState;
    // console.log(loaderState);
    let currentComponet = this;
    if (loaderState === true) {
      setTimeout(function() {
        currentComponet.setState({
          hideLoader: loaderState
        });
      }, 500);
    }
  }
  render() {
    let { hideLoader } = this.state;
    let loaderStyle;
    if (hideLoader === true) {
      loaderStyle = {
        display: "none"
      };
      // if (loaderDiv) {
      //   loaderDiv.remove();
      // }
    }
    return (
      <div id="loading-screen" style={loaderStyle}>
        <div className="table">
          <div className="cell">
            <img src={globalLoader} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
export default GlobalLoader;
