import React, { Component } from 'react';
import axios from 'axios';

import {Helmet} from "react-helmet";

const apiUrl = process.env.REACT_APP_API;


class MetaTags extends Component {
  constructor(props) {
      super(props);
      this.state = {
        metaInfo:{},
      }
  }
  _isLoaded = false;
  componentDidMount() {
    this.getMetaInfo()

  }

  getMetaInfo = async e => {
    let requestUrl = `/routesMeta`;
    let currentComponent = this;
    let queryParams = {
        route: `${window.location.pathname}`
    };

    let res = await axios.get(apiUrl + requestUrl,{params: queryParams,});

    let { data } = res;
    if (data.error === null) {
      currentComponent._isLoaded = true;
      let metaInfo = data.payload.metaData;
      currentComponent.setState(
        {
            metaInfo
        },
        () => {}
      );
    }
  }
  componentWillUnmount() {
    this._isLoaded = false
  }

  render() {
    if(this._isLoaded === false) {
      return false;
    }
    let {metaInfo} = this.state
    return (
      <Helmet>
        <title>{metaInfo.title}</title>
        <meta name="title" content={metaInfo.title} />
        <meta name="description" content={metaInfo.description} />
        <meta name="keywords" content={metaInfo.keywords}/>
        <meta property="og:type" content="website"></meta>
      </Helmet>
    );
  }
}

export default MetaTags;
