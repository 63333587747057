import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { authHeader } from "../../../_helpers";
import axios from "axios";
import io from 'socket.io-client';

let moment = require("moment");
const apiUrl = process.env.REACT_APP_API;
const socketUrl = process.env.REACT_APP_API_SOCKET_URL;


class Messenger extends Component {
  constructor(props) {
    super(props);
    this.socket = {}
    this.state = {
      chatMembersList:[],
      chatHistory:[],
      endpoint: socketUrl,
      chatHeight:400
    };
    this.getChatMembers = this.getChatMembers.bind(this);
    this.getMessageSocket = this.getMessageSocket.bind(this);
    this.getChatHistory = this.getChatHistory.bind(this);
    this.resize = this.resize.bind(this);

  }
  scrollbars = React.createRef();
  _isMounted = false;

  componentDidMount() {
    this.getChatMembers();
    this.getChatHistory();
    this.getMessageSocket()

    window.addEventListener("resize", this.resize.bind(this));
    this.resize()
  }


  resize() {
    if(window.innerWidth <= 1680) {
      this.setState({chatHeight:300});
    }else {
      this.setState({chatHeight:400});
    }
  }
  getChatMembers = async e => {
    let requestUrl = `/chat/members/${this.props.orderId}`;
    let currentComponent = this;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let chatMembersList = data.payload.memberNamesStr
      currentComponent.setState(
        {
          chatMembersList
        },
        () => {}
      );
    }
  }

  getChatHistory = async e => {
    let requestUrl = `/chat/messages/${this.props.orderId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      this._isMounted = true;
      //   console.log(data);
      let chatHistory = data.payload
      this.setState(
        {
          chatHistory
        },
        () => {
          this.scrollbars.current.scrollToBottom()
        }
      );
    }
  }
  getMessageSocket() {
    const { endpoint } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));

    this.socket = io(endpoint , {
      query: `token=${user.token}`,
      path: '/orderchat',
      transports: ['websocket']
    });
    this.socket.emit('join' , this.props.orderId)

    this.socket.on('message', (msg)=>{
      if(msg !== undefined && msg !== '') {
        let {chatHistory} = this.state
        chatHistory.push(msg)
        this.setState({
          chatHistory
        },()=> {
          this.scrollbars.current.scrollToBottom()
        })
      }

      // console.log('message', msg)
    })
  }

  sendMessage(message){
    this.socket.emit('message' , {message})
    let input = document.getElementById('chat-input')
    input.style.cssText = 'height:40px';
  }

  onEnterPress = (e) => {
    let element = e.target
    if(e.keyCode === 13 && e.shiftKey === false ) {
      e.preventDefault();
      if(element.value !== '' && element.value.replace(/\s/g, '').length) {
        this.sendMessage(element.value)
        element.value = ''
      }
    }else if (e.keyCode === 13 && e.shiftKey === true ){
      setTimeout(function(){
        element.style.cssText = 'height:auto; padding:0';
        element.style.cssText = 'height:' + element.scrollHeight + 'px';
      },0);
    }else if (element.value === '') {
      element.style.cssText = 'height:40px';
    }
  }
  componentWillUnmount() {}
  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: 0,
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
      cursor: `pointer`,
      borderRadius: `inherit`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }
  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }
  hideChatBox() {
    let chatBox = document.getElementById('chat-box')

    if(chatBox.classList.contains('opened')) {
      chatBox.classList.remove('opened')
    }
  }
  render() {
    
    let {chatMembersList,chatHistory,chatHeight} = this.state
    let user = JSON.parse(localStorage.getItem("user"));
    return (
      <div className="sticky-box-container right chat-box" id="chat-box">
        <div className="sticky-box">
          <div className="chat-wrap">
            {this._isMounted ? (
              <React.Fragment>
                <div className="chat-header">
                  <div className="chat-members-names">
                    {chatMembersList}
                  </div>
                  <div className="icon" onClick={this.hideChatBox}>
                    <span></span>
                  </div>
                </div>
                <div className="wrap-box">
                  <Scrollbars ref={this.scrollbars} autoHeight autoHeightMax={chatHeight} renderThumbVertical={this.renderThumb} className="chat-area">
                    <div className="chat-content">
                      
                      {chatHistory.length > 0 ? (
                        chatHistory.map((item, key) => {
                          return (
                            <div className={`message-item ${(item.user.id === user.userId && item.isAdmin === false ) ? '' : 'from'}`} key={key}>
                              <div className="message-header">
                              <div className="name">{item.user.firstname}</div>
                                <div className="date">
                                {moment(item.createdAt).format(
                                  'MMM D, YYYY [at] HH:mm z'
                                )}
                                </div>
                              </div>
                              <div className="message-content">
                                {item.message}
                              </div>
                            </div>
                          )
                        })
                      ):(
                        <div className="no-result-box">
                          <div className="text">No Messeges for this order</div>
                        </div>
                      )}
                      
                    </div>
                  </Scrollbars>
                  <div className="field-wrap">
                    <textarea id="chat-input" rows="5" placeholder="Type a message…" onKeyDown={this.onEnterPress} />
                  </div>
                </div>
              </React.Fragment>
            ):('')}
            
          </div>
        </div>
      </div>
    );
  }
}

export default Messenger;
