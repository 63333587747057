import React, { Component } from "react";
import DashboardSidebar from "./sidebar";
import OrderListView from "./orderList";
import GlobalLoader from "../../globalLoader";
import MetaTags from "../../metaTags"

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderStatus: "all",
      orderStatusesReady: false,
      orderListReady: false,
      hideSidebar:false,
    };
  }
  componentDidMount() {
    document.body.classList.add("dashboard");
  }
  componentWillUnmount() {
    document.body.classList.remove("dashboard");
  }
  orderStatusesReady = childData => {
    let { orderStatusesReady } = this.state;

    if (orderStatusesReady === false) {
      this.setState({
        orderStatusesReady: childData
      });
    }
  };
  orderListReady = childData => {
    let { orderListReady } = this.state;

    if (orderListReady === false) {
      this.setState({
        orderListReady: childData
      });
    }
  };
  callbackStausChange = childData => {
    this.setState({
      orderStatus: childData
    });
  };

  orderStatuses = childData => {
    this.setState({
      orderStatusesList: childData
    });
  };
  hideSidebar = childData => {
    this.setState({
      hideSidebar: childData
    });
  }
  openNewProjectPopUp() {
    document.getElementById('new-project').click()
  }
  render() {
    let {
      orderStatus,
      orderStatusesReady,
      orderListReady,
      orderStatusesList,
    } = this.state;
    // console.log(orderStatusesReady);
    // console.log(orderListReady);
    let isDashboardPageReady = false;
    if (orderStatusesReady === true && orderListReady === true) {
      isDashboardPageReady = true;
    }
    return (
      <React.Fragment>
        <MetaTags/>
        <GlobalLoader loaderState={isDashboardPageReady} />
        <div className="dashboard-container">
          <div className="wrapper">
            <DashboardSidebar
              orderStatusesReady={this.orderStatusesReady}
              handleStausChange={this.callbackStausChange}
              orderStatuses={this.orderStatuses}
            />
            <div className="main-box">
              <OrderListView
                orderListReady={this.orderListReady}
                status={orderStatus}
                orderStatuses={orderStatusesList}
              />
            </div>
            <div className="new-project-box">
              <div className="new-project-btn-box">
                <div className="new-project-btn" onClick={this.openNewProjectPopUp}></div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Dashboard;
