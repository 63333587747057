import React, { Component } from "react";
import {  Tooltip } from 'antd';
import 'antd/dist/antd.css';

import SearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
const webCenterUrl = process.env.REACT_APP_WEBCENTER_DOMAIN;
let moment = require("moment");

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "15",
      searchValue: "",
      fileList: {},
      showModal: false,
      fileUrl: ""
    };
    this.getFileList = this.getFileList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleConfigFile = this.handleConfigFile.bind(this);


    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);
  }
  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  _isMounted = false;
  _isRolesReady = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getFileList(searchValue, start, end, 1, reset);
  }

  getFileList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ fileList: [] });
    } else {
    }
    let requestUrl = `/orderdetails/files/${this.props.orderId}`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.filename = searchValue;
    }
    // if (status !== undefined) {
    //   queryParams.statusId = status;
    // }
    queryParams._start = start;
    queryParams._end = end;
    // queryParams.status = 2;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let fileList = data.payload.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          fileList
        },
        () => {}
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getFileList(searchValue, start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  handleSearchValue = childData => {

    this.setState({
      searchValue: childData
    });
    // console.log(childData);
    this.getFileList(childData, 0, this.state.pp, 1, false);
  };

  handleConfigFile =async e => {
    let loader = document.getElementById("loader");
    loader.classList.add("show");
    let uniqueId = localStorage.getItem('uniqueId')

    let requestUrl = `/orderdetails/getFileUrl/${this.props.orderId}/${e.currentTarget.getAttribute('data-id')}/${uniqueId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;

    if (data.error === null) {
      window.open(data.payload.url)
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }
    }else {
      this.setState({
        faildModalMessage:data.error.message,
        showFaildModal: true
      });
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }
    }
  };
  
  render() {
    if (this._isMounted !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {
      pp,
      total,
      activePage,
      fileList,
      searchValue,
      showFaildModal,
      faildModalMessage
    } = this.state;

    // console.log(this.state);
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let APPROVE;
    if(userPermission) {
      APPROVE = userPermission.find(
        opt => opt.key === 'APPROVE')
        
    }
    return (
      <React.Fragment>
        <div id="loader" className="loader  fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            {faildModalMessage}
          </div>
        </ReactModal>
        
        <div className="container-header">
          <SearchView searchValue={this.handleSearchValue} />
        </div>
        <div className="approve-list-box table-grid">
          {fileList.length > 0 ? (
            <React.Fragment>
              <div className="table-row table-head">
                <div className="table-cell">
                  <div className="text">View</div>
                </div>
                <div className="table-cell">
                  <div className="text">Document</div>
                </div>
                {/* <div className="table-cell">
                  <div className="text">Version ID</div>
                </div> */}
                {/*<div className="table-cell">*/}
                {/*  <div className="text">Uploaded Folder</div>*/}
                {/*</div>*/}
                <div className="table-cell date-cell">
                  <div className="text">Uploaded Date</div>
                </div>
                <div className="table-cell ">
                  <div className="text">Status</div>
                </div>
                <div className="table-cell">
                  <div className="text"></div>
                </div>
              </div>

              {fileList.map((item, key) => {
                let flag = false;
                // let fileExtation = item.type;

                // fileExtation = fileExtation.split("/");
                // if (fileExtation[0] === "image") {
                //   flag = true;
                // } else {
                //   if (
                //     fileExtation[0] === "application" &&
                //     fileExtation[1] === "pdf"
                //   ) {
                //     flag = true;
                //   } else {
                //     flag = false;
                //   }
                // }

                if(item.status === 2) {
                  flag = true;
                }
                return (
                  <div className="table-row" key={key}>
                    <div className="table-cell with-icon">
                      <div className="text">
                        {
                          currentUser.isMember === false || (currentUser.isMember === true && APPROVE !== undefined) ? (
                            <div
                            className="icon"
                            data-url={webCenterUrl + item.url}
                            data-id={item.id}
                            onClick={this.handleConfigFile}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.565"
                              height="12.48"
                              viewBox="0 0 20.565 12.48"
                            >
                              <path
                                d="M.008-21.24c-3.78,0-6.735,2.43-10.29,6.24C-7.237-11.82-4.657-8.76.008-8.76c4.695,0,8.085-3.9,10.275-6.165C8-17.64,4.628-21.24.008-21.24Zm0,.78c4.005,0,6.99,2.91,9.225,5.505C7.058-12.675,4.028-9.54.008-9.54c-4.05,0-6.39-2.49-9.21-5.46C-5.932-18.45-3.322-20.46.008-20.46Zm0,8.61A3.15,3.15,0,0,0,3.158-15a3.15,3.15,0,0,0-3.15-3.15A3.14,3.14,0,0,0-3.142-15,3.14,3.14,0,0,0,.008-11.85Zm0-.78A2.356,2.356,0,0,1-2.362-15a2.356,2.356,0,0,1,2.37-2.37A2.365,2.365,0,0,1,2.378-15,2.365,2.365,0,0,1,.008-12.63Z"
                                transform="translate(10.283 21.24)"
                              />
                            </svg>
                          </div>)
                         : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="table-cell document-cell">
                      <div className="text" title={item.filename}>
                        <div>Document:</div>
                        <Tooltip placement="top" title={item.filename} overlayClassName="tooltip-wrapper">
                          <div className="text-ellipsis">{item.filename}</div>
                        </Tooltip>
                      </div>
                    </div>
                    {/* <div className="table-cell document-cell">
                      <div className="text" title={item.docversionId}>
                        <div>Version ID:</div>
                        <div>{item.docversionId}</div>
                      </div>
                    </div> */}
                    {/*<div className="table-cell folder-cell">*/}
                    {/*  <div className="text">*/}
                    {/*    <div>Uploaded Folder:</div>*/}
                    {/*    <div>{item.folderName}</div>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="table-cell date-cell">
                      <div className="text">
                        <div>Uploaded Date:</div>
                        <div>{moment(item.createdAt, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                      </div>
                    </div>
                    <div className="table-cell status-cell">
                      <div className="text light">
                        <div>Status:</div>
                        <div>
                          <span className="medium">
                            {!item.status
                              ? "Rejected"
                              : item.status === 2
                              ? "Waiting for approval"
                              : "Approved"}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="table-cell btn-cell">
                    {flag ? 
                      currentUser.isMember === false || (currentUser.isMember === true && APPROVE !== undefined) ? (
                        <div
                          className="action-btn btn green"
                          data-url={webCenterUrl + item.url}
                          data-id={item.id}
                          onClick={this.handleConfigFile}
                        >
                          <span>Approve</span>
                        </div>):('')
                      :('')}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-result-box">
              <div className="text">
                {searchValue ? `No result for: ${searchValue}` : `You have no files to approve.`}
              </div>
            </div>
          )}
        </div>

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default ListView;
