import React, { Component } from "react";

import TemplateListView from "./templateList";
class MyTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.body.classList.add("my-template");
    window.scroll(0, 0);
  }
  componentWillUnmount() {
    document.body.classList.remove("my-template");
  }
  templetsListReady = childData => {
    let { membersListReady } = this.state;

    if (membersListReady === false) {
      this.setState({
        templetsListReady: childData
      });
    }
  };
  render() {
    // const { showModal, isEdit, errors, errorClasses, member } = this.state;
    // console.log(this.state);
    return (
      <div className="template-container">
        <div className="wrapper-medium">
          <h1 className="page-title">Select From Your Templates</h1>

          <div className="main-box">
            <TemplateListView templetsListReady={this.templetsListReady} />
          </div>
        </div>
      </div>
    );
  }
}

export default MyTemplates;
