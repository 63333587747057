import React, { Component } from "react";
// import Select from "react-dropdown-select";
import Select from "react-select";

import { authHeader } from "../../_helpers";
import axios from "axios";
import validator from "validator";
import FormValidator from "../validations";
import ReactModal from "react-modal";
import { Scrollbars } from "react-custom-scrollbars";
const apiUrl = process.env.REACT_APP_API;
class BillingInformationStep extends Component {
  constructor(props) {
    super(props);
    let globalStatus = props.status;
    let orderId = props.orderId;
    let differentShippingAddress = props.differentShippingAddress;
    this.state = {
      address: {},
      differentShippingAddress,
      orderId,
      globalStatus,
      _isMounted: false,
      checkoutShipping: {
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phoneNumber: "",
        fax: "",
        saveToAddressBook: false
      },
      errors: [],
      errorClasses: [],
      countries: [],
      newShippingAddress: {},
      addressPopup: false
    };
    this.chooseShippingAddress = this.chooseShippingAddress.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.countryOptions = this.countryOptions.bind(this);
    this.countryChange = this.countryChange.bind(this);
    this.handleDropDownOpenClose = this.handleDropDownOpenClose.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.selectAddress = this.selectAddress.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let globalStatus = nextProps.status;
    this.setState({
      globalStatus
    });
    let address = nextProps.setReadyAddress;
    this.setState({
      address,
      _isMounted: true
    });
  }
  componentDidMount() {
    let { globalStatus } = this.state;
    if (globalStatus === "active") {
      this.getCountryList();
    }
    if (this.state.address.differentShippingAddress === true) {
      this.setState({
        billingAddress: false
      });
    } else {
      this.setState({
        billingAddress: true
      });
    }
  }
  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First Name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last Name field is required."
      },
      {
        field: "address",
        method: validator.isEmpty,
        validWhen: false,
        message: "Address field is required."
      },
      {
        field: "city",
        method: validator.isEmpty,
        validWhen: false,
        message: "City field is required."
      },
      {
        field: "phoneNumber",
        method: validator.isEmpty,
        validWhen: false,
        message: "Phone Number field is required."
      },
      {
        field: "zip",
        method: validator.isEmpty,
        validWhen: false,
        message: "Zip Code field is required."
      },
      // {
      //   field: "fax",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "Fax field is required."
      // }
      // {
      //   field: "country",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "Cpontry field is required."
      // }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.checkoutShipping);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  countryChange(val, name) {
    var selectedOptionValue = val.value;
    const checkoutShipping = this.state.checkoutShipping;

    var country = "country";
    checkoutShipping[country] = selectedOptionValue;
    this.setState({
      checkoutShipping
    });
  }
  handleDropDownOpenClose(param) {
    let target = document.getElementsByClassName("country-drop-down");
    if (param === true) {
      target[0].classList.add("open");
    } else {
      target[0].classList.remove("open");
    }
  }

  getCountryList() {
    axios.get(apiUrl + "/countries/index").then(response => {
      if (response.data.error === null) {
        let countries = response.data.payload.countries;
        this.setState(
          {
            countries,
            _isMounted: true
          },
          () => {
            this.countryOptions();
          }
        );
      }
    });
  }
  countryOptions() {
    var countryList = this.state.countries;
    var option = {};
    var sayHello = Object.keys(countryList).map(function(i) {
      option = {
        value: countryList[i].id,
        label: countryList[i].country_name
      };
      return option;
    });
    return sayHello;
  }

  handelChecked(e) {
    const checkoutShipping = this.state.checkoutShipping;
    const name = e.target.name;
    if (e.target.checked) {
      checkoutShipping[name] = true;
    } else {
      checkoutShipping[name] = false;
    }
    this.setState({
      checkoutShipping
    });
  }

  handleRadioButton(e) {
    const checkoutShipping = this.state.checkoutShipping;
    const name = e.target.value;
    checkoutShipping["shipping"] = name;
    this.setState({
      checkoutShipping
    });
  }

  handleChange(e) {
    const name = e.target.name;
    const checkoutShipping = this.state.checkoutShipping;

    const errors = this.state.errors;
    const errorClasses = this.state.errorClasses;

    if (isNaN(e.target.value) && (name === "phoneNumber")) {
      errors[name] = "Type only number";
      errorClasses[name] = "is-invalid";
      this.setState({
        errors
      });
    }else if(name === "zip"){
      let val = /^[a-z0-9]+$/i.test(e.target.value)
      errors[name] = "";
      errorClasses[name] = "";
      if(val || e.target.value === '') {
        checkoutShipping[name] = e.target.value;
        this.setState({
          checkoutShipping,
          errors,
          errorClasses
        });
      }
    } else {
      checkoutShipping[name] = e.target.value;
      errors[name] = "";
      errorClasses[name] = "";
      this.setState({
        checkoutShipping,
        errors,
        errorClasses
      });
    }
  }

  chooseShippingAddress(e) {
    let filedId = e.target.id;
    if (filedId === "sameAsBillingAddress") {
      this.setState({
        billingAddress: true
      });
    } else {
      this.setState({
        billingAddress: false
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.billingAddress === true) {
      let data = this.state.address;
      data.type = "shipping";
      axios
        .post(`${apiUrl}/checkout/steptwo`, data, {
          headers: authHeader()
        })
        .then(response => {
          if (response.data.error === null) {
            this.props.handleFunc(true);
          }
        })
        .catch(err => {});
    } else {
      if (this.handleValidation()) {
        let { checkoutShipping } = this.state;
        let data = {
          orderId: this.state.orderId,
          countryId: checkoutShipping.country,
          city: checkoutShipping.city,
          firstname: checkoutShipping.firstName,
          lastname: checkoutShipping.lastName,
          middlename: checkoutShipping.middleName,
          company: checkoutShipping.company,
          fax: checkoutShipping.fax,
          postcode: checkoutShipping.zip,
          region: checkoutShipping.state,
          street: checkoutShipping.address,
          telephone: checkoutShipping.phoneNumber,
          saveToAddrBook: checkoutShipping.saveToAddressBook,
          shipping: checkoutShipping.shipping,
          type: "shipping",
          addressId: null,
          isDefaultBilling: 0,
          isDefaultShipping: 0,
          useAsShipping: 0,
          deleted: 0
        };
        axios
          .post(`${apiUrl}/checkout/steptwo`, data, {
            headers: authHeader()
          })
          .then(response => {
            if (response.data.error === null) {
              let newShippingAddress = response.data.payload;
              this.setState({
                newShippingAddress,
                addressPopup: false
              });
              this.props.handleFunc(true);
            }
          })
          .catch(err => {});
      }
    }
  }
  handleEdit = async e => {
    this.setState({
      isEdit: true
    });
    var orderId = e.currentTarget.getAttribute("data-orderid");
    let requestUrl = "/checkout/address?type=shipping&orderId=" + orderId;
    // let requestUrl = "/checkout/address?addressId=" + addressId + "&orderId=" + orderId;
    await axios
      .get(apiUrl + requestUrl, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.error === null) {
          let data = response.data.payload.address;
          let checkoutShipping = {
            firstName: data.firstname,
            middleName: data.middlename,
            lastName: data.lastname,
            company: data.company,
            address: data.street,
            city: data.city,
            state: data.region,
            zip: data.postcode,
            country: data.countryId,
            phoneNumber: data.telephone,
            fax: data.fax,
            saveToAddressBook: false,
            type: "shipping"
          };
          this.setState({
            checkoutShipping
          });
          this.props.editShipping(true);
          let summary = response.data.payload.summary;
          this.props.summary(summary);
        }
      });
  };

  getAddresses = async () => {
    let orderId = this.state.orderId;
    await axios
      .get(
        `${apiUrl}/checkout/addressbook?orderId=${orderId}&useAsShipping=1`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        let addressBook = response.data.payload.addresses;
        this.setState({
          addressPopup: true,
          addressBook
        });
      });
  };

  handleCloseModal() {
    this.setState({
      addressPopup: false
    });
  }

  selectAddress(e) {
    let selectedId = parseInt(e.currentTarget.getAttribute("data-id"));
    let selectedAddress = this.state.addressBook.find(
      el => el.id === selectedId
    );
    let checkoutShipping = {
      firstName: selectedAddress.firstname,
      middleName: selectedAddress.middlename,
      lastName: selectedAddress.lastname,
      company: selectedAddress.company,
      address: selectedAddress.street,
      city: selectedAddress.city,
      state: selectedAddress.region,
      zip: selectedAddress.postcode,
      country: selectedAddress.countryId,
      phoneNumber: selectedAddress.telephone,
      fax: selectedAddress.fax,
      saveToAddressBook: false
    };
    this.setState(
      {
        checkoutShipping
      },
      () => {
        this.handleSubmit(e);
      }
    );
  }
  render() {
    let checkoutShipping = this.state.checkoutShipping;
    const {
      errors,
      errorClasses,
      globalStatus,
      address,
      billingAddress,
      addressPopup,
      addressBook,
      newShippingAddress
    } = this.state;
    let data = this.countryOptions();

    if (globalStatus === "ready") {
      if (billingAddress === true) {
        return (
          <div className="wrapper ready-box-wrapper">
            <div className="ready-container">
              <div className="info-box">
                <div className="passive-step-title">
                  <span>2. </span>
                  <span>Shipping Information</span>
                </div>
                <div className="name ready-row">
                  <span>{address.firstname} </span>
                  <span>{address.lastname}</span>
                </div>
                <div className="phone ready-row">{address.telephone}</div>
                <div className="address ready-row">{address.street}</div>
              </div>
              <div
                onClick={this.handleEdit}
                data-orderid={this.state.orderId}
                data-addressid={address.id}
                className="edit"
              >
                Edit
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="wrapper ready-box-wrapper">
            <div className="ready-container">
              <div className="info-box">
                <div className="passive-step-title">
                  <span>2. </span>
                  <span>Shipping Information</span>
                </div>
                <div className="name ready-row">
                  <span>{newShippingAddress.firstname} </span>
                  <span>{newShippingAddress.lastname}</span>
                </div>
                <div className="phone ready-row">
                  {newShippingAddress.telephone}
                </div>
                <div className="address ready-row">
                  {newShippingAddress.street}
                </div>
              </div>
              <div
                onClick={this.handleEdit}
                data-orderid={this.state.orderId}
                data-addressid={newShippingAddress.id}
                className="edit"
              >
                Edit
              </div>
            </div>
          </div>
        );
      }
    }
    if (globalStatus === "active") {
      return (
        <React.Fragment>
          {addressPopup ? (
            <ReactModal
              isOpen={addressPopup}
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="modal choose-address"
            >
              <div className="address-container">
                <div className="address-title">Select Address</div>

                <Scrollbars
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax={260}
                  renderThumbVertical={this.renderThumb}
                  {...this.props}
                  onUpdate={this.handleUpdate}
                >
                  <div className="product-list">
                    {addressBook.length > 0 ? 
                      addressBook.map((item, key) => {
                        return (
                          <div
                            onClick={this.selectAddress}
                            className={"item "}
                            data-id={item.id}
                            key={key}
                          >
                            <div className="name">
                              <span>{item.firstname} </span>
                              <span>{item.lastname}</span>
                            </div>
                            <div className="phone">{item.telephone}</div>
                            <div className="address">{item.street}</div>
                          </div>
                        );
                      })
                      :
                      (
                        <React.Fragment>

                          <div className="no-result-box" style={{marginTop:0}}>
                            <div className="text">
                              You don't have addresses
                            </div>
                          </div>
                          <div className="button-row">
                            <div className="button" onClick={this.handleCloseModal}>
                              Close
                            </div>
                          </div>
                        </React.Fragment>
                        
                      )
                    }
                  </div>
                </Scrollbars>
              </div>
            </ReactModal>
          ) : (
            <div className="wrapper checkout-form-wrapper form">
              {!this.state._isMounted ? (
                <div id="loader" className="loader circle">
                  <svg
                    width="200px"
                    height="200px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    className="lds-rolling"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      ng-attr-stroke="{{config.color}}"
                      ng-attr-stroke-width="{{config.width}}"
                      ng-attr-r="{{config.radius}}"
                      ng-attr-stroke-dasharray="{{config.dasharray}}"
                      stroke="#B8BE14"
                      strokeWidth="2"
                      r="30"
                      strokeDasharray="141.37166941154067 49.12388980384689"
                      transform="rotate(159.346 50 50)"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1.4s"
                        begin="0s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </circle>
                  </svg>
                </div>
              ) : (
                <React.Fragment>
                  <div className="step-title">
                    <span>2. </span>
                    <span>Shipping Information</span>
                  </div>
                  <div className="row clear">
                    <div className="item">
                      <input
                        className="css-checkbox"
                        id="sameAsBillingAddress"
                        type="radio"
                        name="shippingRadioButton"
                        checked={this.state.billingAddress}
                        onChange={this.chooseShippingAddress}
                      />
                      <label
                        className="css-label"
                        htmlFor="sameAsBillingAddress"
                      >
                        Same as Billing Address
                      </label>
                    </div>
                  </div>
                  {this.state.billingAddress ? (
                    <React.Fragment>
                      <div className="ready-box">
                        <div className="ready-row">
                          <div className="name">
                            <span>{address.firstname} </span>
                            <span>{address.lastname}</span>
                          </div>
                        </div>
                        <div className="ready-row">
                          <div className="phone">{address.telephone}</div>
                        </div>
                        <div className="ready-row">
                          <div className="address">{address.street}</div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <div className="row clear">
                    <div className="item">
                      <input
                        className="css-checkbox"
                        id="shippingToDifferentAddress"
                        type="radio"
                        name="shippingRadioButton"
                        onChange={this.chooseShippingAddress}
                        checked={!this.state.billingAddress}
                      />
                      <label
                        className="css-label"
                        htmlFor="shippingToDifferentAddress"
                      >
                        Ship to Different Address
                      </label>
                    </div>
                  </div>
                  {this.state.billingAddress ? (
                    <div className="button-row">
                      <div onClick={this.handleSubmit}>Next</div>
                    </div>
                  ) : (
                    <React.Fragment>
                      <div className="row row-3 clear">
                        <div className="item">
                          <div className="label">First Name</div>
                          <input
                            value={checkoutShipping.firstName}
                            type="text"
                            name="firstName"
                            onChange={this.handleChange}
                            className={errorClasses.firstName}
                          />
                          <div className="invalid-feedback">
                            {errors.firstName}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label">Middle Name (optional)</div>
                          <input
                            value={checkoutShipping.middleName}
                            type="text"
                            name="middleName"
                            onChange={this.handleChange}
                            className={errorClasses.middleName}
                          />
                          <div className="invalid-feedback">
                            {errors.middleName}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label">Last Name</div>
                          <input
                            value={checkoutShipping.lastName}
                            type="text"
                            name="lastName"
                            onChange={this.handleChange}
                            className={errorClasses.lastName}
                          />
                          <div className="invalid-feedback">
                            {errors.lastName}
                          </div>
                        </div>
                      </div>
                      <div className="row clear">
                        <div className="item">
                          <div className="label">COMPANY (OPTIONAL)</div>
                          <input
                            value={
                              checkoutShipping.company !== ""
                                ? checkoutShipping.company
                                : ""
                            }
                            type="text"
                            name="company"
                            onChange={this.handleChange}
                            className={errorClasses.company}
                          />
                          <div className="invalid-feedback">
                            {errors.company}
                          </div>
                        </div>
                      </div>
                      <div className="row clear">
                        <div className="item">
                          <div className="label">ADDRESS</div>
                          <input
                            value={checkoutShipping.address}
                            type="text"
                            name="address"
                            onChange={this.handleChange}
                            className={errorClasses.address}
                          />
                          <div className="invalid-feedback">
                            {errors.address}
                          </div>
                        </div>
                      </div>
                      <div className="row row-2 clear">
                        <div className="item">
                          <div className="label">CITY</div>
                          <input
                            value={checkoutShipping.city}
                            name="city"
                            className={errorClasses.city}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">{errors.city}</div>
                        </div>
                        <div className="item">
                          <div className="label">STATE/PROVINCE</div>
                          <input
                            value={
                              checkoutShipping.state !== ""
                                ? checkoutShipping.state
                                : ""
                            }
                            name="state"
                            className={errorClasses.state}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">{errors.state}</div>
                        </div>
                      </div>
                      <div className="row row-2 clear">
                        <div className="item">
                          <div className="label">ZIP/POSTAL CODE</div>
                          <input
                            value={checkoutShipping.zip}
                            type="text"
                            name="zip"
                            onChange={this.handleChange}
                            className={errorClasses.zip}
                          />
                          <div className="invalid-feedback">{errors.zip}</div>
                        </div>
                        <div className="item">
                          <div className="label">COUNTRY</div>
                          <Select
                            classNamePrefix="react-select"
                            isMulti={false}
                            isSearchable={false}
                            value={[
                              data.find(
                                opt =>
                                  opt.value === Number(checkoutShipping.country)
                              )
                            ]}
                            name="country"
                            options={data}
                            onChange={(newValue, actionMeta) =>
                              this.countryChange(newValue, actionMeta)
                            }
                            className={
                              errorClasses.country +
                              " react-select country-drop-down"
                            }
                            placeholder=""
                          />
                          <div className="invalid-feedback">
                            {errors.country}
                          </div>
                        </div>
                      </div>
                      <div className="row row-2 clear">
                        <div className="item">
                          <div className="label">PHONE</div>
                          <input
                            value={checkoutShipping.phoneNumber}
                            type="text"
                            name="phoneNumber"
                            className={errorClasses.phoneNumber}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">
                            {errors.phoneNumber}
                          </div>
                        </div>
                        <div className="item">
                          <div className="label">FAX</div>
                          <input
                            value={checkoutShipping.fax}
                            type="text"
                            name="fax"
                            className={errorClasses.fax}
                            onChange={this.handleChange}
                          />
                          <div className="invalid-feedback">{errors.fax}</div>
                        </div>
                      </div>
                      <div className="row clear">
                        <div className="item">
                          <input
                            className="custom-checkbox"
                            id="saveToAddressBookShipping"
                            type="checkbox"
                            name="saveToAddressBookShipping"
                            onChange={this.handelChecked}
                          />
                          <label
                            htmlFor="saveToAddressBookShipping"
                            className="custom-lable"
                          >
                            Save to Address Book
                          </label>
                        </div>
                      </div>
                      <div className="row clear">
                        <div className="item">
                          <div
                            onClick={this.getAddresses}
                            className="select-address"
                          >
                            Select from Address Book
                          </div>
                        </div>
                      </div>
                      <div className="button-row">
                        <div onClick={this.handleSubmit}>Next</div>
                      </div>
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </div>
          )}
        </React.Fragment>
      );
    }
  }
}
export default BillingInformationStep;