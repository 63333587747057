import Login from "./components/pages/signin";
import Registerpage from "./components/pages/signup";
import Resetpage from "./components/pages/reset";
import Forgotpage from "./components/pages/forgot";
import HomePage from "./components/pages/home";
import FaqPage from "./components/pages/faq";
import ContactPage from "./components/pages/contact";
import AboutPage from "./components/pages/about";
import LegalPage from "./components/pages/legal";
import legalMobilePage from "./components/pages/legalMobile";
import NotFound from "./components/pages/notFound";
import VerifyAccount from "./components/pages/verifyAccount";
import Product from "./components/product/index";
// import AllProducts from "./components/product/allProducts";
import AllCategory from "./components/category/allCategory";
import Category from "./components/category/index";
import ConfirmMemberAccount from "./components/pages/confirmMemberAccount";
/*
import AccountInformation from './components/account/information'
import AccountMembers from './components/account/members'
import AccountCredits from './components/account/credits'
import AccountAddress from './components/account/address'
*/

const routes = [
  { path: "/login", exact: true, name: "Login Page", component: Login },
  {
    path: "/register",
    exact: true,
    name: "Registration page",
    component: Registerpage
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset password page",
    component: Resetpage
  },
  {
    path: "/confirm-member",
    exact: true,
    name: "Confirm Member Account",
    component: ConfirmMemberAccount
  },
  {
    path: "/forgot-password",
    exact: true,
    name: "Forgot password page",
    component: Forgotpage
  },
  { path: "/", exact: true, name: "Home Page", component: HomePage },
  { path: "/faq", exact: true, name: "FAQ Page", component: FaqPage },
  {
    path: "/contact-us",
    exact: true,
    name: "Contact Page",
    component: ContactPage
  },
  { path: "/about-us", exact: true, name: "About Page", component: AboutPage },
  { path: "/legal-mobile", exact: true, name: "Legal Mobile Page", component: legalMobilePage },
  { path: "/legal", exact: true, name: "Legal Page", component: LegalPage },
  { path: "/not-found", exact: true, name: "Not Found", component: NotFound },
  {
    path: "/verify-account",
    exact: true,
    name: "Verify account",
    component: VerifyAccount
  },
  // { path: "/products", exact: true, name: "All Product page", component: AllProducts },
  { path: "/category", exact: true, name: "All Category page", component: AllCategory },
  { path: "/category/:categoryId", exact: true, name: "Category page", component: Category },
  { path: "/products/:categoryId/:id", exact: true, name: "Product page", component: Product },
];

export default routes;
