import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { Scrollbars } from "react-custom-scrollbars";
import { authHeader } from "../../_helpers";
import axios from "axios";
import io from 'socket.io-client';
import { isMobileOnly } from "react-device-detect";
const devPermission = JSON.parse(localStorage.getItem('devPermission'))
let moment = require("moment");
const apiUrl = process.env.REACT_APP_API;
const socketUrl = process.env.REACT_APP_API_SOCKET_URL;
let showUserPermission = false
if(devPermission !== null && devPermission.showUserPermission === true) {
  showUserPermission = devPermission.showUserPermission;
}
class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      userToolTipClass: "",
      notificationToolTipClass:'',
      notifications:{},
      notificationIsLoaded:false,
      endpoint: socketUrl,
      menuButtonClicked:false,
      categoryList:{}
    };
    this.userLogout = this.userLogout.bind(this);
    this.userToolTip = this.userToolTip.bind(this);
    this.notificationToolTip = this.notificationToolTip.bind(this);
    this.getGenNotification = this.getGenNotification.bind(this);
    this.getNotificationSocket = this.getNotificationSocket.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setNotiWrapperRef = this.setNotiWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleClickOutside2 = this.handleClickOutside2.bind(this);
    this.getUserPermissions = this.getUserPermissions.bind(this);

    this.resize = this.resize.bind(this);
    this.handelMenuButtonClick = this.handelMenuButtonClick.bind(this);
    this.hideMobileMenu = this.hideMobileMenu.bind(this);
    this.handleCategories = this.handleCategories.bind(this);
  }

  handelMenuButtonClick(e){
    let {menuButtonClicked} = this.state
    if(menuButtonClicked) {
      this.setState({
        menuButtonClicked:false
      });
    }else {
      this.setState({
        menuButtonClicked:true
      });
    }
  }

  hideMobileMenu(e) {
    let {menuButtonClicked} = this.state
    if(menuButtonClicked) {
      this.setState({
        menuButtonClicked:false
      });
    }
  }
  componentDidMount() {
    let user = JSON.parse(localStorage.getItem("user"));
    this.resize();
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutside2);
    document.addEventListener("resize", this.resize);
    this.handleCategories()
    if(user) {
      this.getGenNotification()
      this.getNotificationSocket()
      if(user.isMember) {
        this.getUserPermissions()
      }
    }
  }

  resize() {
    if(window.screen.width <= 1024) {
      // this.setState({mobileView: window.innerWidth <= 1024});
      this.setState({mobileView: true});
      document.body.classList.add('mobile');
    }else {
      if(document.body.classList.contains('mobile')){
        document.body.classList.remove('mobile');
      }
      this.setState({mobileView: false});
    }
    if(isMobileOnly) {
      document.body.classList.add('mobile-device');
    }else {
      if(document.body.classList.contains('mobile-device')) {
        document.body.classList.remove('mobile-device');
      }
    }
    
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutside2);
  }
  userLogout(e) {
    this.props.dispatch(userActions.logout());

    // window.location.reload();
  }
  

  getUserPermissions() {
    const { endpoint } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));
    let storagePermissions = JSON.parse(localStorage.getItem("permissions"));

    this.socket = io(endpoint , {
      query: `token=${user.token}`,
      path: '/rolepermissions',
      transports: ['websocket']
    });
    this.socket.on('permissions', (permissions)=>{
      if(permissions !== undefined && permissions !== '') {
        if(showUserPermission === true) {
          console.table(permissions , ['name' , 'key'])
        }
        if(storagePermissions) {
          localStorage.removeItem("permissions");
          localStorage.setItem("permissions", JSON.stringify(permissions));
        }else {
          localStorage.setItem("permissions", JSON.stringify(permissions));
        }
      }
    })
  }
  getNotificationSocket() {
    const { endpoint } = this.state;
    let user = JSON.parse(localStorage.getItem("user"));

    const socket = io(endpoint , {
      query: `token=${user.token}`,
      path: '/socketNotification',
      transports: ['websocket']
    });

    socket.on('notification', (msg)=>{
      if(msg !== undefined && msg !== '') {
        this.setState({
          notifications:msg,
          notificationIsLoaded:true
        })
      }
    })
  }
  handleCategories = async () => {
    const requsetUrl = "/homepagecategories";
    let currentComponent = this;
    let res = await axios.get(apiUrl + requsetUrl);
    let { data } = res;
    // console.log(data);
    if (data.error === null) {
      let categoryList = data.payload;
      currentComponent.setState({
        categoryList,
        _isCategoryList: true
      });
    }
  };
  getGenNotification = async e =>  {
    let requestUrl = `/genNotification`;
    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      this.setState({
        notifications:res.data.payload.message,
        notificationIsLoaded:true
      })
    }
  }

  userToolTip(e) {
    if (this.state.userToolTipClass === "") {
      this.setState({
        userToolTipClass: "show",
      });
    } else {
      this.setState({
        userToolTipClass: "",
      });
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if(nextProps.loggedIn !== this.props.loggedIn){

      if(nextProps.loggedIn === true) {
    
          let user = JSON.parse(localStorage.getItem("user"));
          if(user) {
            this.getGenNotification()
            this.getNotificationSocket()
            if(user.isMember) {
              this.getUserPermissions()
            }
          }
        }
    }
  }
  notificationToolTip(e) {
    if (this.state.notificationToolTipClass === "") {
      this.setState({
        notificationToolTipClass: "show",
      });
    } else {
      this.setState({
        notificationToolTipClass: ""
      });
    }
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setNotiWrapperRef(node) {
    this.notiWrapperRef = node;
  }
  
  onSelect = key => {
    this.setState({
      userToolTipClass: "",
      notificationToolTipClass: "",
      menuButtonClicked:false
    });
  };

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.className !== "user-info"
    ) {
        this.setState({
          userToolTipClass: "",
        });
    }
  }

  handleClickOutside2(event) {
    if (
      this.notiWrapperRef &&
      !this.notiWrapperRef.contains(event.target) &&
      event.target.className !== "notification-btn"
    ) {
        this.setState({
          notificationToolTipClass: "",
        });
    }
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: 0,
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
      cursor: `pointer`,
      borderRadius: `inherit`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }

  render() {
    let groupId = localStorage.getItem("groupId")
    let {notifications,mobileView,menuButtonClicked,categoryList,_isCategoryList} = this.state
    if(_isCategoryList !== true) {
      return false;
    }
   
    let user = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let CREDITS;
    let ADDRESSES;
    if(userPermission) {
      CREDITS = userPermission.find(
        opt => opt.key === 'CREDITS'
      )
      ADDRESSES = userPermission.find(
        opt => opt.key === 'ADDRESSES'
      )
    }

    let burgerClassName,mobileMenuOpened
    if(menuButtonClicked) {
      mobileMenuOpened = 'menu-opened'
      burgerClassName = 'menu-burger clicked'
    } else {
      mobileMenuOpened=''
      burgerClassName = 'menu-burger'
    }

    return (
      <React.Fragment>
        <div className="site-header" id="site-header">
          {mobileView ? (
            ''
          ):(
            <div className="header-top">
              <div className="header-wrapper">
                <div className="right">
                    {user ? (
                        <ul>
                          <li>
                            <div className="notification-box">
                              <div className="notification-btn" onClick={this.notificationToolTip}>
                                {notifications.length > 0 ? (
                                  <div className="notification-count">
                                    <span id="header-notification-count">{
                                      notifications.length >= 100 ? '99+' : notifications.length
                                    }</span>
                                  </div>):('')}
                                Notifications
                              </div>
                              <div className={this.state.notificationToolTipClass + " notification-tooltip"} ref={this.setNotiWrapperRef}>
                                <Scrollbars autoHeight autoHeightMax={260} renderThumbVertical={this.renderThumb} >
        
                                  {notifications.length > 0 ? (
                                    notifications.map((item,key)=> {
                                      return (
                                        <div className="notification-item" key={key} id={`notification_${item.orderNumber}`}>
                                          <a onClick={this.onSelect} href={`/order/${item.orderNumber}/notifications`}>
                                            <div className="date">
        
                                              {moment(item.createdAt).format(
                                                'MMM D, YYYY [at] HH:mm z'
                                              )}
        
                                            </div>
                                            <div className="text">
                                                {item.chatNotification ? (
                                                  `You have new message for ${item.projectName} order.`
                                                ):
                                                  item.orderCount > 1 ? (`You have ${item.orderCount} notifications for ${item.projectName} order.`):(`You have a notification for ${item.projectName} order.`)
                                                }
                                            </div>
                                          </a>
                                        </div>
                                      )
                                    })
                                  ):(
                                    <div className="no-result-box">
                                      <div className="text">No new notifications</div>
                                    </div>
                                  )}
                                {/* {moment(item.createdAt, "YYYY-MM-DD").format(
                                    "ddd, MMM D"
                                  )} */}
                                  
                                </Scrollbars>
                              </div>
                            </div>
                          </li>
                          <li>
                            <NavLink activeClassName="active" to="/dashboard">
                              My Dashboard
                            </NavLink>
                          </li>
        
                          <div className="user-box">
                            <div className="user-info" onClick={this.userToolTip}>
                              <span id="user-name">{user.firstname}</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5.237"
                                height="4.604"
                                viewBox="0 0 5.237 4.604"
                              >
                                <path
                                  id="Path_1250"
                                  data-name="Path 1250"
                                  d="M2.394.13.035,4.215A.259.259,0,0,0,.26,4.6H4.978A.259.259,0,0,0,5.2,4.215L2.844.13A.26.26,0,0,0,2.394.13Z"
                                  transform="translate(5.237 4.604) rotate(180)"
                                ></path>
                              </svg>
                            </div>
                            <div
                              className={this.state.userToolTipClass + " user-tooltip"}
                              ref={this.setWrapperRef}
                            >
                              <div className="item">
                                <NavLink
                                  onClick={this.onSelect}
                                  to="/account/information"
                                  activeClassName="active"
                                >
                                  Account Information
                                </NavLink>
                              </div>
                              {groupId === "3" && <div className="item">
                                <NavLink
                                    onClick={this.onSelect}
                                    to="/account/customers"
                                    activeClassName="active"
                                >
                                  Customers
                                </NavLink>
                              </div>}
                              <div className="item">
                                <NavLink
                                  onClick={this.onSelect}
                                  to="/account/members"
                                  activeClassName="active"
                                >
                                  Members
                                </NavLink>
                              </div>
                              {user.isMember === false || (user.isMember === true && CREDITS !== undefined) ? (
                                <div className="item">
                                <NavLink
                                  onClick={this.onSelect}
                                  to="/account/credits"
                                  activeClassName="active"
                                >
                                  Credits
                                </NavLink>
                              </div>
                              ):('')}
                              {user.isMember === false || (user.isMember === true && ADDRESSES !== undefined) ? (
                                <div className="item">
                                  <NavLink
                                    onClick={this.onSelect}
                                    to="/account/address"
                                    activeClassName="active"
                                  >
                                    Address Book
                                  </NavLink>
                                </div>
                              ):('')}
                              <div className="item">
                                  <NavLink
                                    onClick={this.onSelect}
                                    to="/account/press-template"
                                    activeClassName="active"
                                  >
                                    My Press Settings
                                  </NavLink>
                              </div>
                              <div className="item">
                                <span onClick={this.userLogout}>Logout</span>
                              </div>
                            </div>
                          </div>
                        </ul>
                      ) : (
                        <ul>
                          <li>
                            <NavLink activeClassName="active" to="/login">
                              Sign in
                            </NavLink>
                          </li>
                          <li>
                            <NavLink activeClassName="active" to="/register">
                              Register
                            </NavLink>
                          </li>
                        </ul>
                      )
                    }
                </div>
              </div>
            </div>
          )}
        
          <header id="header" className={mobileMenuOpened}>
            <div className="header-wrapper">
              <div className="logo">
                <Link to="/">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="189.461"
                    height="22.167"
                    viewBox="0 0 189.461 22.167"
                  >
                    <g transform="translate(-224.241 -1009.926)">
                      <rect
                        width="3.198"
                        height="2.898"
                        transform="translate(365.315 1028.964)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M508.235,1037.175c-1.923,0-3.412-1.6-3.412-4.222v-.056c0-2.558,1.428-4.2,3.374-4.2a4.188,4.188,0,0,1,2.956,1.319l1.793-2.109a6.688,6.688,0,0,0-11.2,4.955v.171c0,4.029,2.559,6.674,6.367,6.674a6.6,6.6,0,0,0,4.9-1.975l-1.8-1.94a4.192,4.192,0,0,1-2.973,1.382"
                        transform="translate(-130.998 -7.661)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M534.526,1026.093a6.373,6.373,0,0,0-6.553,6.713v.231a6.544,6.544,0,1,0,13.083-.032v-.236a6.324,6.324,0,0,0-6.53-6.675m3.437,6.885c0,2.6-1.433,4.221-3.437,4.221s-3.483-1.641-3.483-4.241v-.125c0-2.6,1.431-4.22,3.456-4.22,2.009,0,3.464,1.637,3.464,4.237Z"
                        transform="translate(-143.381 -7.632)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M369.97,1026.093a6.373,6.373,0,0,0-6.553,6.713v.231A6.544,6.544,0,1,0,376.5,1033v-.236a6.324,6.324,0,0,0-6.531-6.675m3.437,6.885c0,2.6-1.433,4.221-3.437,4.221s-3.483-1.641-3.483-4.241v-.125c0-2.6,1.432-4.22,3.457-4.22,2.009,0,3.463,1.637,3.463,4.237Z"
                        transform="translate(-65.7 -7.632)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M568.576,1026.524l-3.185,5.344-3.224-5.344h-3.144V1039.7h2.918v-8.375l3.4,5.4h.053l3.414-5.435v8.407h2.936v-13.173Z"
                        transform="translate(-158.039 -7.835)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M241.781,1009.926a4.176,4.176,0,0,0-4.381,4.394,4.391,4.391,0,0,1-4.385,4.389h-4.391a4.131,4.131,0,0,0-4.383,4.373v4.393a4.386,4.386,0,0,0,4.383,4.387h4.391a4.388,4.388,0,0,0,4.385-4.387v-4.393a4.374,4.374,0,0,1,4.381-4.373,4.391,4.391,0,1,0,0-8.783"
                        transform="translate(0 0)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M265.785,1039.7h2.785v-5.1h6.039v-2.636H268.57v-2.808h6.853v-2.636h-9.638Z"
                        transform="translate(-19.611 -7.83)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M292.93,1026.512h-2.785V1039.7h9.1v-2.637H292.93Z"
                        transform="translate(-31.111 -7.83)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M316.953,1034.348h5.967v-2.582h-5.967v-2.675h6.781v-2.58h-9.548V1039.7h9.638v-2.58h-6.871Z"
                        transform="translate(-42.46 -7.829)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M350.091,1026.512h-3.165l-2.694,4.276-2.64-4.276h-3.255l4.232,6.479-4.413,6.706h3.166l2.857-4.521,2.839,4.521h3.255l-4.413-6.744Z"
                        transform="translate(-53.775 -7.83)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M397.1,1034.348h5.967v-2.582H397.1v-2.675h6.782v-2.58h-9.549V1039.7h9.639v-2.58H397.1Z"
                        transform="translate(-80.295 -7.829)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M423.492,1026.332l-5.426,13.28H420.9l1.158-2.957h5.353l1.157,2.957h2.911l-5.426-13.28Zm-.434,7.761,1.682-4.275,1.681,4.275Z"
                        transform="translate(-91.498 -7.745)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M453.089,1031.635c-2.189-.584-2.731-.866-2.731-1.732v-.038c0-.64.56-1.149,1.627-1.149a5.793,5.793,0,0,1,3.292,1.3l1.445-2.185a7.135,7.135,0,0,0-4.7-1.678c-2.586,0-4.43,1.583-4.43,3.975v.038c0,2.617,1.646,3.353,4.2,4.031,2.115.566,2.549.943,2.549,1.676v.038c0,.772-.687,1.244-1.825,1.244a5.686,5.686,0,0,1-3.78-1.6l-1.645,2.053a7.863,7.863,0,0,0,5.371,2.11c2.731,0,4.647-1.469,4.647-4.086v-.038c0-2.3-1.447-3.258-4.014-3.956"
                        transform="translate(-105.197 -7.66)"
                        fill="#B8BE14"
                      />
                      <path
                        d="M479.345,1026.512l-3.074,5.293-3.02-5.293H470l4.863,7.986v5.2h2.784v-5.257l4.865-7.929Z"
                        transform="translate(-116.012 -7.83)"
                        fill="#B8BE14"
                      />
                    </g>
                  </svg>
                </Link>
              </div>
              <div className="right">
                {mobileView ? 
                      user ? (
                        <React.Fragment>
                          <ul>
                            <li>
                              <div className="notification-box">
                                <div className="notification-btn" onClick={this.notificationToolTip}>
                                  {notifications.length > 0 ? (
                                    <div className="notification-count">
                                      <span id="header-notification-count">{
                                        notifications.length >= 100 ? '99+' : notifications.length
                                      }</span>
                                    </div>):('')}
                                  Notifications
                                </div>
                                <div className={this.state.notificationToolTipClass + " notification-tooltip"} ref={this.setNotiWrapperRef}>
                                  <Scrollbars autoHeight autoHeightMax={260} renderThumbVertical={this.renderThumb} >

                                    {notifications.length > 0 ? (
                                      notifications.map((item,key)=> {
                                        return (
                                          <div className="notification-item" key={key} id={`notification_${item.orderNumber}`}>
                                            <a onClick={this.onSelect} href={`/order/${item.orderNumber}/notifications`}>
                                              <div className="date">

                                                {moment(item.createdAt).format(
                                                  'MMM D, YYYY [at] HH:mm z'
                                                )}

                                              </div>
                                              <div className="text">
                                                  {item.chatNotification ? (
                                                    `You have new message for ${item.projectName} order.`
                                                  ):
                                                    item.orderCount > 1 ? (`You have ${item.orderCount} notifications for ${item.projectName} order.`):(`You have a notification for ${item.projectName} order.`)
                                                  }
                                              </div>
                                            </a>
                                          </div>
                                        )
                                      })
                                    ):(
                                      <div className="no-result-box">
                                        <div className="text">No new notifications</div>
                                      </div>
                                    )}
                                  {/* {moment(item.createdAt, "YYYY-MM-DD").format(
                                      "ddd, MMM D"
                                    )} */}
                                    
                                  </Scrollbars>
                                </div>
                              </div>
                            </li>
                          </ul>
                          <div className={burgerClassName}  onClick={this.handelMenuButtonClick}>
                            <i>
                              <span></span>
                              <span></span>
                              <span></span>
                              <span></span>
                            </i>
                          </div>
                        </React.Fragment>
                      ):(
                        <div className={burgerClassName}  onClick={this.handelMenuButtonClick}>
                          <i>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                          </i>
                        </div>
                      )
                : (
                  <ul>
                    <li>
                      <NavLink activeClassName="active" to="/about-us">
                        About
                      </NavLink>
                    </li>
                    <li>
                      {/* <NavLink activeClassName="active" to="/products">
                        Products
                      </NavLink> */}
                      <div className="menu-wrap">
                        <div className="menu-item">
                          <span>Products</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5.237"
                            height="4.604"
                            viewBox="0 0 5.237 4.604"
                          >
                            <path
                              d="M2.394.13.035,4.215A.259.259,0,0,0,.26,4.6H4.978A.259.259,0,0,0,5.2,4.215L2.844.13A.26.26,0,0,0,2.394.13Z"
                              transform="translate(5.237 4.604) rotate(180)"
                            ></path>
                          </svg>
                        </div>
                        <div className="sub-menu-wrap">
                          <div className="sub-menu">
                            {categoryList.map((list, key) => {
                              return (
                                <div className="item" key={key}>
                                  <NavLink activeClassName="active" to={`/category/` + list.key}>
                                    {list.name}
                                  </NavLink>
                                  {list.products.length > 0 ? 
                                    (
                                      <React.Fragment>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="5.237"
                                          height="4.604"
                                          viewBox="0 0 5.237 4.604"
                                        >
                                          <path
                                            d="M2.394.13.035,4.215A.259.259,0,0,0,.26,4.6H4.978A.259.259,0,0,0,5.2,4.215L2.844.13A.26.26,0,0,0,2.394.13Z"
                                            transform="translate(5.237 4.604) rotate(180)"
                                          ></path>
                                        </svg>
                                        <div className="item-sub-menu-wrap">
                                          <div className="item-sub-menu">
                                          {
                                            list.products.map((item , key) => {
                                              return (
                                                <div className="item" key={key}>
                                                  <NavLink activeClassName="active" to={`/products/` + item.url}>
                                                    {item.name}
                                                  </NavLink>
                                                </div>
                                                
                                              )
                                            })
                                          }
                                          </div>
                                      </div>
                                      </React.Fragment>
                                    ):('')
                                  }
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <a rel="noopener noreferrer" target="_blank" href="https://academy.flexoeasy.com/">
                        Academy
                      </a>
                    </li>
                    <li>
                      <NavLink activeClassName="active" to="/contact-us">
                        Contact us
                      </NavLink>
                    </li>
                  </ul>
                )
                }
                
              </div>
            </div>
            {mobileView ? (
              <div className="mobile-menu-container">
                <div className="overlay" onClick={this.hideMobileMenu}></div>
                <div className="mobile-menu-box">
                    <div className="menu-wrap-box">
                      {user ? (
                        <React.Fragment>
                          <ul className="second-ul">
                            <span id="user-name">{user.firstname}</span>
                            <li>
                              <NavLink onClick={this.onSelect} activeClassName="active" to="/dashboard">
                                My Dashboard
                              </NavLink>
                            </li>
                            <li className="item">
                              <NavLink
                                onClick={this.onSelect}
                                to="/account/information"
                                activeClassName="active"
                              >
                                Account Information
                              </NavLink>
                            </li>
                            <li className="item">
                              <NavLink
                                onClick={this.onSelect}
                                to="/account/members"
                                activeClassName="active"
                              >
                                Members
                              </NavLink>
                            </li>
                            {user.isMember === false || (user.isMember === true && CREDITS !== undefined) ? (
                              <li className="item">
                              <NavLink
                                onClick={this.onSelect}
                                to="/account/credits"
                                activeClassName="active"
                              >
                                Credits
                              </NavLink>
                            </li>
                            ):('')}
                            {user.isMember === false || (user.isMember === true && ADDRESSES !== undefined) ? (
                              <li className="item">
                                <NavLink
                                  onClick={this.onSelect}
                                  to="/account/address"
                                  activeClassName="active"
                                >
                                  Address Book
                                </NavLink>
                              </li>
                            ):('')}
                            <li className="item">
                                  <NavLink
                                    onClick={this.onSelect}
                                    to="/account/press-template"
                                    activeClassName="active"
                                  >
                                    My Press Settings
                                  </NavLink>
                              </li>
                            <li>
                              <span onClick={this.userLogout}>Logout</span>
                            </li>
                          </ul>
                        </React.Fragment>
                      ):(
                        <ul className="second-ul">
                          <li>
                            <NavLink onClick={this.onSelect} activeClassName="active" to="/login">
                              Sign in
                            </NavLink>
                          </li>
                          <li>
                            <NavLink onClick={this.onSelect} activeClassName="active" to="/register">
                              Register
                            </NavLink>
                          </li>
                        </ul>
                      )}
                      <ul>
                        <li>
                          <NavLink onClick={this.onSelect} activeClassName="active" to="/about-us">
                            About
                          </NavLink>
                        </li>
                        <li>
                          <NavLink onClick={this.onSelect} activeClassName="active" to="/category">
                            Products
                          </NavLink>
                        </li>
                        <li>
                          <a rel="noopener noreferrer" onClick={this.onSelect} target="_blank" href="https://academy.flexoeasy.com/">
                            Academy
                          </a>
                        </li>
                        {/* {categoryList.map((list, key) => {
                          return (
                            <li key={key}>
                              <NavLink activeClassName="active" to={`/category/` + list.key}>
                                {list.name}
                              </NavLink>
                            </li>
                          );
                        })} */}
                     
                      <li>
                        <NavLink onClick={this.onSelect} activeClassName="active" to="/contact-us">
                          Contact us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink onClick={this.onSelect} activeClassName="active" to='/faq'>FAQ</NavLink>
                      </li>
                      <li>
                        <NavLink onClick={this.onSelect} activeClassName="active" to='/legal'>Privacy Policy</NavLink>
                      </li>
                     
                    </ul>
                    </div>
                </div>
              </div>
            ):('')}
          </header>
        </div>
        
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  return {
    loggedIn
  };
}
const Header = connect(mapStateToProps, null, null, { pure: false })(
  DefaultHeader
);

export default Header;
