import React, { Component } from 'react';
import Banner from '../../Assets/images/about-banner.jpg'
import Vatche from '../../Assets/images/Vatche.jpg'
import Shahe from '../../Assets/images/Shahe.jpg'
import MetaTags from "../metaTags"

class AboutPage extends Component {


  componentDidMount(){
    document.body.classList.add('about');
    window.scroll(0,0)
  }
  componentWillUnmount(){
    document.body.classList.remove('about');
  }

  render(){

    return(
        <div className="about-container">
          <MetaTags/>
          <div className="wrapper">
            <h1 className="page-title">About</h1>
            <img src={Banner} alt=''/>
            <div className="text-box">
              <h2 >Welcome To Flexoeasy.com</h2>
              <div className="description">
                FlexoEasy.com is all about innovative digital prepress & premedia solutions & services platform for flexo packaging converters and printers as well as for the brand owners offered at a click of a button which makes flexographic plate-making easy, eliminating errors and allowing customers to access high-quality printing from anywhere in the world.
                <br></br><br></br>
                FlexoEasy.com platform products and solutions are the new standard globally for processing Digital Flexo Plates which takes flexo printing results to the next level. The world is ever changing with technology being advanced by the day and this in turn is driven by newer demands and specific customer needs.
                <br></br><br></br>
                Behind the platform stands NDIGITEC years of experience in the flexographic printing industry, a polished NDXC process, and over 500 satisfied customers from over 25 countries. Using Flexoeasy.com, you can choose from a wide variety of plates and customize their properties down to the finest detail in a matter of minutes. Our platform allows you to allocate priorities to projects, assign roles, request fingerprints, and have your plates delivered to any location. It is an all-in-one solution you need to create flawless prints.
                <br></br><br></br>
                As the competency of NDigitec, and part of Global Premedia Network our teams work around the clock at the highest standards and technologies to make sure we deliver what we promise every single day. At Flexoeasy.com, we redefine industry standards.
              </div>
            </div>
          </div>
          <div className="line"></div>
          <div className="founders-box">
            <div className="wrapper">
              <div className="text-box">
                <h2>Founders</h2>
              </div>
              <div className="founders-list">
                
                <div className="item">
                  <div className="top">
                    <div className="image-box">
                      <img alt="Vatche Kavlakian" src={Vatche}/>
                    </div>
                    <div className="founder-info">
                      <div className="founder-name">Vatche Kavlakian</div>
                      <div className="founder-position">CEO</div>
                    </div>
                  </div>
                  <div className="bottom">
                  "I believe that the success of a business is the reflection of its stakeholders." With over 25 years of experience, having a computer-engineering background and as an alumni of Yale Business School, the drive towards growth & innovation flows in his very being. Vatche Kavlakian strives constantly for NDigitec to focus on global growth trends thus leveraging the scope for revolution & success as a result.
                  </div>
                </div>
              
                <div className="item">
                  <div className="top">
                    <div className="image-box">
                      <img alt="Shahe Kavlakian" src={Shahe}/>
                    </div>
                    <div className="founder-info">
                      <div className="founder-name">Shahe Kavlakian</div>
                      <div className="founder-position">Executive Manager – Prepress</div>
                    </div>
                  </div>
                  <div className="bottom">
                    Shahe Kavlakian has been instrumental in NDigitec being acknowledged by ESKO as one of its best users in the world and best user in the Middle East. As an R&D expert and pioneer, he has been involved in the Prepress market for over than 2 decades. Shahe Kavlakian has developed great skills sets & expertise in all things Prepress and hence contribute as the Executive Manager for this competency.
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
    )

  }

}



export default AboutPage;
