import React, { Component } from "react";
import AccountSidebar from "../sidebar";
import AddressListItem from "./addressListItem";
import AddressForm from "./addressForm";
import Pagination from "react-js-pagination";
import MetaTags from "../../metaTags"

import { authHeader } from "../../../_helpers";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;
class AccountAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressList: [],
      pp: 5,
      tabs: {
        billing: "active",
        shipping: ""
      },
      tabIndex: "billing",
      render: false,
      formView: false,
      addressFlag: false,
      countries: [],
      formSuccess: false,
      addressesId: ""
    };
    this.handleEditAddress = this.handleEditAddress.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);

    this.handleFormView = this.handleFormView.bind(this);
    this.handleCloseFormView = this.handleCloseFormView.bind(this);

    this.getAddressList = this.getAddressList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleFormSuccess = this.handleFormSuccess.bind(this);
  }
  _isMounted = false;
  componentDidMount() {

    document.body.classList.add("account", "account-address");
    let start = "0",
    end = this.state.pp,
    reset = true;
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let ADDRESSES;
    if(userPermission) {
      ADDRESSES = userPermission.find(
        opt => opt.key === 'ADDRESSES'
      )
    }
    if(currentUser.isMember === false || (currentUser.isMember === true && ADDRESSES !== undefined)){
      this.getAddressList("", start, end, 1, reset);
    }else {
      window.location = "/account/information";
    }
    
  }
  componentWillUnmount() {
    document.body.classList.remove("account", "account-address");
    this._isMounted = false;
  }
  handleEditAddress(id) {
    this.handleFormView("edit", id);
  }
  getAddressList = async (type, start = "0", end, pageNumber, reset = true) => {
    let loader = document.getElementById("loader");

    if (reset === true) {
      this._isMounted = false;

      this.setState({ addressList: [] });
      loader.classList.add("show");
    } else {
    }
    let currentComponent = this;
    let requestUrl = `/addresses/index`;

    const queryParams = {};
    if (type === "shipping") {
      queryParams.shipping = 1;
    } else {
      queryParams.shipping = 0;
    }

    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    // console.log(data);

    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let addressList = data.payload.addresses.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.addresses.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          addressList
        },
        () => {
          // currentComponent.props.membersListReady(true);
          // currentComponent.getRoles();

          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };
  handlePageChange(pageNumber) {
    let { pp, tabIndex } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getAddressList(tabIndex, start, end, pageNumber, false);
    window.scroll(0, 0);
  }
  handleFormView(type, id) {
    if (type !== undefined && type !== "" && type === "edit") {
      //    console.log('edit')
      this.setState({
        formView: true,
        addressFlag: true,
        addressesId: id
      });
    } else {
      this.setState({
        formView: true,
        addressFlag: false
      });
    }
  }

  handleTabChange(e) {
    let tabItem = e.currentTarget;
    let tabIndex = e.currentTarget.getAttribute("data-tab");
    let loader = document.getElementById("loader");

    if (!tabItem.classList.contains("active")) {
      loader.classList.add("show");
      if (tabIndex === "billing") {
        let start = "0",
          end = this.state.pp,
          reset = true;
        this.setState(
          {
            tabs: {
              billing: "active",
              shipping: ""
            },
            total: 0,
            tabIndex
          },
          () => {
            this.getAddressList(tabIndex, start, end, 1, reset);
          }
        );
      } else {
        let start = "0",
          end = this.state.pp,
          reset = true;
        this.setState(
          {
            tabs: {
              billing: "",
              shipping: "active"
            },
            tabIndex,
            total: 0
          },
          () => {
            this.getAddressList(tabIndex, start, end, 1, reset);
          }
        );
      }
    }
  }

  handleCloseFormView(childData) {
    if (childData === true) {
      this.setState({
        formView: false,
        addressFlag: false
      });
    }
  }
  handleFormSuccess(childData) {
    let currentComponent = this;
    if (childData === true) {
      currentComponent.setState({
        formSuccess: true
      });
      let start = "0",
        end = this.state.pp,
        tabIndex = this.state.tabIndex,
        reset = true;
      setTimeout(function() {
        currentComponent.setState(
          {
            addressList: [],
            tabIndex: "billing",
            // tabs: {
            //   billing: "active",
            //   shipping: ""
            // },
            render: false,
            formView: false,
            addressFlag: false,
            countries: [],
            formSuccess: false
          },
          () => {
            currentComponent.getAddressList(tabIndex, start, end, 1, reset);
          }
        );
      }, 3000);
    }
  }
  render() {
    const {
      tabs,
      formView,
      addressesId,
      formSuccess,
      addressFlag,
      addressList,
      pp,
      total,
      activePage
    } = this.state;
    window.scroll(0,0)

    return (
      <div className="account-container account-address-container">
        <MetaTags/>
        {/*!formSuccess ? (
          <div className="wrapper">
            <h1 className="page-title">
              {formView
                ? addressFlag
                  ? "Edit Address"
                  : "Add New Address"
                : "Address Book"}
            </h1>
          </div>
        ) : (
          ""
        )*/}
        {formView ? (
          <AddressForm
            addressesId={addressesId}
            isEdit={addressFlag}
            formSuccess={this.handleFormSuccess}
            closeFormView={this.handleCloseFormView}
          />
        ) : (
          <div className="wrapper">
            <AccountSidebar />
            <div className="address-container main-box">
              {!formSuccess ? (
                <div className="wrapper">
                  <h1 className="page-title">
                    {formView
                      ? addressFlag
                        ? "Edit Address"
                        : "Add New Address"
                      : "Address Book"}
                  </h1>
                </div>
              ) : (
                ""
              )}
              <div className="main-wrap">
                <div id="loader" className="loader circle">
                  <svg
                    width="200px"
                    height="200px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    className="lds-rolling"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      ng-attr-stroke="{{config.color}}"
                      ng-attr-stroke-width="{{config.width}}"
                      ng-attr-r="{{config.radius}}"
                      ng-attr-stroke-dasharray="{{config.dasharray}}"
                      stroke="#B8BE14"
                      strokeWidth="2"
                      r="30"
                      strokeDasharray="141.37166941154067 49.12388980384689"
                      transform="rotate(159.346 50 50)"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1.4s"
                        begin="0s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </circle>
                  </svg>
                </div>
                
                <div className="tab-box">
                  <div
                    data-tab="billing"
                    className={`${tabs.billing} tab-item`}
                    onClick={this.handleTabChange}
                  >
                    Billing Address
                  </div>
                  <div
                    data-tab="shipping"
                    className={`${tabs.shipping} tab-item`}
                    onClick={this.handleTabChange}
                  >
                    Shipping Address
                  </div>
                </div>
                <div
                  className="add-address-btn add-btn"
                  onClick={this.handleFormView}
                >
                  Add Address
                </div>
                <div className="address-list-box">
                  <AddressListItem
                    list={addressList}
                    editedAddressId={this.handleEditAddress}
                  />
                  {pp < total ? (
                    <Pagination
                      innerClass="pagination clear"
                      hideFirstLastPages
                      pageRangeDisplayed={5}
                      activePage={activePage}
                      itemsCountPerPage={pp}
                      totalItemsCount={total}
                      onChange={this.handlePageChange}
                      nextPageText={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 243.01 414.68"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path
                                className="cls-1"
                                d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                              />
                            </g>
                          </g>
                        </svg>
                      }
                      prevPageText={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 243.01 414.68"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <g id="Layer_1-2" data-name="Layer 1">
                              <path
                                className="cls-1"
                                d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                              />
                            </g>
                          </g>
                        </svg>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
           
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default AccountAddress;
