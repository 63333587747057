import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import validator from "validator";
import FormValidator from "../validations";
import MetaTags from "../metaTags"

import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");
class Resetpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        password: "",
        rePassword: ""
      },
      submitted: false,
      thankYouMessageFromServer: "",
      thankYou: false,
      messageFromServer: "",
      errors: [],
      errorClasses: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);

    if (localStorage.getItem("user")) {
      let currentComponent = this;
      currentComponent.setState({
        redirect: true,
        redirectUrl: "/"
      });
    } else {
      document.body.classList.add("reset");
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("reset");
  }
  handleValidation() {
    let fields = this.state.user;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "password",
        method: validator.isEmpty,
        validWhen: false,
        message: "Password field is required."
      },
      {
        field: "password",
        method: validator.isLength,
        args: [
          {
            min: 6
          }
        ],
        validWhen: true,
        message: "Password must be at least 6 characters."
      },
      {
        field: "rePassword",
        method: validator.isEmpty,
        validWhen: false,
        message: "Repeat Password field is required."
      },
      {
        field: "rePassword",
        method: validator.equals,
        validWhen: true,
        args: { comparison: fields.password },
        message: "Repet Password and Password fields does not match"
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.user);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleChange(e) {
    const name = e.target.name;
    const user = this.state.user;
    user[name] = e.target.value;
    this.setState({
      user
    });
  }

  handleKeyPress = event => {
    if (event.key === "Enter") {
      let currentComponent = this;
      currentComponent.handleReset(event);
    }
  };

  handleReset(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      if (
        !this.state.user.password.match(
          /^(?=.{6,})(?=.*[a-z])(?=.*[0-9])|(?=.*[!@#$%^&*()-+=]).*$/
        )
      ) {
        let { errors, errorClasses } = this.state;
        errors["password"] =
          "Your password must contain one number or special character";
        errorClasses["password"] = "is-invalid";
        this.setState({
          errors,
          errorClasses
        });
        return false;
      }


      var currentComponent = this;
      let requestUrl = "/forgotpassword/newPassword";
      var hash = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      }).t;

      // if(this.props.location.query.token !== undefined ) {
      //   hash = this.props.match.params.token
      // }

      let postdata = {
        token: hash,
        password: this.state.user.password,
        repassword: this.state.user.rePassword
      };

      axios
        .post(apiUrl + requestUrl, qs.stringify(postdata), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
        .then(function(response) {
          if (response.data.error === null) {
            currentComponent.setState({
              thankYou: true,
              thankYouMessageFromServer: response.data.payload.message
            });
          } else {
            if (response.data.error.code === 401) {
              let { errors, errorClasses } = currentComponent.state;
              errors["password"] = response.data.error.message;
              errorClasses["password"] = "is-invalid";
              currentComponent.setState({
                errors,
                errorClasses
              });
              
            }else {
              currentComponent.setState({
                messageFromServer: "Something went wrong"
              });
            }
            
          }
        });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    let { thankYouMessageFromServer } = this.state;
    return (
      <div className="reset-container">
        <MetaTags/>
        <div className="wrapper">
          <div className="table">
            <div className="cell">
              {this.state.thankYou ? (
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">{thankYouMessageFromServer}</div>
                  <Link className="button" to="/login">
                    Login
                  </Link>
                </div>
              ) : (
                <div>
                  <h1 className="page-title">Reset password</h1>

                  <div className="form-wrap">
                    <div className="row clear">
                      <div className="label">Password</div>
                      <input
                        autoComplete="new-password"
                        onKeyPress={this.handleKeyPress}
                        type="password"
                        tabIndex="5"
                        ref={el => (this.password = el)}
                        name="password"
                        className={this.state.errorClasses.password}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors.password}
                      </div>
                    </div>
                    <div className="row clear">
                      <div className="label">Repet Password</div>
                      <input
                        autoComplete="new-rePassword"
                        onKeyPress={this.handleKeyPress}
                        type="password"
                        tabIndex="6"
                        ref={el => (this.rePassword = el)}
                        name="rePassword"
                        className={this.state.errorClasses.rePassword}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {this.state.errors.rePassword}
                      </div>
                    </div>
                    <div className="row bottom">
                      <button onClick={this.handleReset}>
                        <span>Submit</span>
                      </button>
                    </div>
                    {this.state.messageFromServer ? (
                      <div className="message-box">
                        <div className="message">
                          {this.state.messageFromServer}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Resetpage;
