import React, { Component } from 'react';

import axios from 'axios';
import GlobalLoader from "../globalLoader";
import MetaTags from "../metaTags"

import {
  Link,Redirect
} from 'react-router-dom';
var qs = require('query-string');
const apiUrl = process.env.REACT_APP_API;


class VerifyAccount extends Component {
  constructor(props) {
    super(props);
    // const type=props.type;
    this.state = {
        messageFromServer: '',
        messageColor:'success',
        checkAccountToken :false,
        isPageReady:false
    }
    this.handelConfirmAccount = this.handelConfirmAccount.bind(this)
  }
  _isMounted = false;

  componentDidMount(){
    window.scroll(0, 0);

    if(localStorage.getItem('user')) {
        let currentComponent=this;
        currentComponent.setState({
            redirect:true,
            redirectUrl:'/'
        });
      }else {
        document.body.classList.add('verify');
        this.handelConfirmAccount()
    }
  }

  handelConfirmAccount = async e => {
    let currentComponent = this
    let requestUrl = '/users/confirm';
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    let postdata = {
        token: query.t
      };

      axios.post(apiUrl+requestUrl,qs.stringify(postdata),'')
      .then(function(response) {
          // if(response.data.error === null) {
            currentComponent.setState({
              isPageReady:true
            })
          if(response.data.error === null) {
            currentComponent.setState({
                  checkAccountToken: true
              });
          }
      });
  }

  componentWillUnmount() {
    document.body.classList.remove('verify');

    this._isMounted = false
  }


  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    let {isPageReady , checkAccountToken} = this.state
    return (
        <React.Fragment>
        <MetaTags/>

          <GlobalLoader loaderState={isPageReady} />
          <div className="verify-container">
              <div className="wrapper">
                  <div className="table">
                      {!checkAccountToken ?
                      <div className="cell">
                          <h1 className="page-title">Something went wrong please try again</h1>
                          
                          <Link className="button" to='/'>Back</Link>
                      </div>
                      :
                      <div className="cell">
                          <h1 className="page-title noMargin">
                          Thank you
                          </h1>
                          <div className="section-description">
                          your account is activated
                          </div>
                          <Link className="button" to='/login'>Sign in</Link>
                      </div>
                      }
                  </div>
              </div>

          </div>
        </React.Fragment>
     
    );
  }
}

export default VerifyAccount;
