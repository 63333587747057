import React, { Component } from "react";
import validator from "validator";
import FormValidator from "../../validations";
import axios from "axios";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";

const apiUrl = process.env.REACT_APP_API;
class AccountForm extends Component {
  constructor(props) {
    super(props);
    let userData = JSON.parse(localStorage.getItem("user"));
    this.state = {
      accountData: {
        firstName: userData.firstname,
        lastName: userData.lastname,
        middleName:
          userData.middlename !== undefined ? userData.middlename : "",
        company: userData.companyName,
        phoneNumber: userData.phone,
        oldPassword: "",
        newPassword: "",
        repetNewPassword: ""
      },
      showPasswordModal: false,
      showSuccessModal: false,
      errors: [],
      errorClasses: [],
      isEdited: false,
      isPasswordChanged: false,
      serverMessage: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleEditAccount = this.handleEditAccount.bind(this);
    this.showPasswordModal = this.showPasswordModal.bind(this);
    this.hidePasswordModal = this.hidePasswordModal.bind(this);



    this.showSuccessModal = this.showSuccessModal.bind(this);
    this.hideSuccessModal = this.hideSuccessModal.bind(this);

    
    this.handleChangeUserPassword = this.handleChangeUserPassword.bind(this);
  }
  componentDidMount() {}
  componentWillUnmount() {}
  showSuccessModal() {
    this.setState({
      showSuccessModal: true
    });
  }
  hideSuccessModal() {
    this.setState({
      showSuccessModal: false
    });
  }
  showPasswordModal() {
    this.setState({
      showPasswordModal: true
    });
  }
  hidePasswordModal() {
    const accountData = this.state.accountData;
    const errors = this.state.errors;
    const errorClasses = this.state.errorClasses;
    accountData["oldPassword"] = "";
    accountData["newPassword"] = "";
    accountData["repetNewPassword"] = "";
    errors["oldPassword"] = "";
    errors["newPassword"] = "";
    errors["repetNewPassword"] = "";
    errorClasses["oldPassword"] = "";
    errorClasses["newPassword"] = "";
    errorClasses["repetNewPassword"] = "";
    this.setState({
      errors,
      errorClasses,
      accountData,
      showPasswordModal: false,
      isPasswordChanged: false
    });
  }
  handleChange(e) {
    const name = e.target.name;
    const accountData = this.state.accountData;
    const errors = this.state.errors;
    const errorClasses = this.state.errorClasses;

    if (isNaN(e.target.value) && (name === "zip" || name === "phoneNumber")) {
      errors[name] = "Type only number";
      this.setState({
        errors
      });
    } else {
      accountData[name] = e.target.value;
      errors[name] = "";
      this.setState({
        accountData,
        errors
      });
    }
    if (name === "oldPassword") {
      errorClasses[name] = "";
      this.setState({
        errorClasses
      });
    }
  }
  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    let userData = JSON.parse(localStorage.getItem("user"));

    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First Name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last Name field is required."
      }
      
    ];
    if (!userData.isMember) {
      validationRules.push(
        {
          field: "phoneNumber",
          method: validator.isEmpty,
          validWhen: false,
          message: "Phone Number field is required."
        }
      );
    }
    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.accountData);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handlePasswordValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    let fields = this.state.accountData
    const validationRules = [
      {
        field: "oldPassword",
        method: validator.isEmpty,
        validWhen: false,
        message: "Old Password field is required."
      },
      {
        field: "newPassword",
        method: validator.isEmpty,
        validWhen: false,
        message: "New Password field is required."
      },
      {
        field: "newPassword",
        method: validator.isLength,
        args: [
          {
            min: 6
          }
        ],
        validWhen: true,
        message: "Please enter at least 6 characters."
      },
      {
        field: "repetNewPassword",
        method: validator.isEmpty,
        validWhen: false,
        message: "Repet New Password field is required."
      },
      {
        field: "repetNewPassword",
        method: validator.equals,
        validWhen: true,
        args: { comparison: fields.newPassword },
        message: "Repet Password and Password fields does not match"
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.accountData);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  handleEditAccount = async e => {
    e.preventDefault();
    if (this.handleValidation()) {
      let loader = document.getElementById('loader')
      loader.classList.add('show')
      // let currentComponent = this;
      let { accountData } = this.state;
      let user = JSON.parse(localStorage.getItem("user"));

      let userData = {}

      if(!user.isMember) {
        userData = {
          firstname: accountData.firstName,
          lastname: accountData.lastName,
          middlename: accountData.middleName,
          companyName: accountData.company,
          phone: accountData.phoneNumber
        };
      }else {
        userData = {
          firstname: accountData.firstName,
          lastname: accountData.lastName,
          middlename: accountData.middleName,
        };
      }
      
      let requestUrl = `/account/update`;
      let currentComponent = this;

      let res = await axios.post(apiUrl + requestUrl, userData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        let curentUser = JSON.parse(localStorage.getItem("user"));

        let userToken = curentUser.token;
        let userRefreshToken = curentUser.refreshToken;

        let newData = {
          middlename: data.payload.middlename,
          isMember: data.payload.isMember,
          companyName: data.payload.companyName,
          email: data.payload.email,
          firstname: data.payload.firstname,
          lastname: data.payload.lastname,
          phone: data.payload.phone,
          userId:data.payload.userId,
          token: userToken,
          refreshToken: userRefreshToken
        };
        document.getElementById("user-name").innerHTML = data.payload.firstname;
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(newData));

        if(loader.classList.contains('show')) {
          loader.classList.remove('show')
        }

        this.setState({
          showSuccessModal:true
        } , () => {
          setTimeout(function() {
            currentComponent.setState({
              showSuccessModal:false
            })
          }, 3000)
        })
        // console.log(data);
      }
    }
  };
  handleChangeUserPassword(e) {
    e.preventDefault();
    if (this.handlePasswordValidation()) {
      if (
        !this.state.accountData.newPassword.match(
          /^(?=.{6,})(?=.*[a-z])(?=.*[0-9])|(?=.*[!@#$%^&*()-+=]).*$/
        )
      ) {
        let { errors, errorClasses } = this.state;
        errors["newPassword"] =
          "Your password must contain one number or special character";
        errorClasses["newPassword"] = "is-invalid";
        this.setState({
          errors,
          errorClasses
        });
        return false;
      }
      let currentComponent = this;
      let { accountData, errors, errorClasses } = this.state;
      let data = {
        oldPassword: accountData.oldPassword,
        newPassword: accountData.newPassword,
        reNewPassword: accountData.repetNewPassword
      };

      axios
        .post(`${apiUrl}/account/changePassword`, data, {
          headers: authHeader()
        })
        .then(response => {
          if (response.data.error === null) {
            this.setState({
              isPasswordChanged: true,
              serverMessage: response.data.payload.message
            });
            setTimeout(function() {
              currentComponent.setState(
                {
                  isPasswordChanged: false,
                  serverMessage: ""
                },
                () => {
                  currentComponent.hidePasswordModal();
                }
              );
            }, 3000);
          } else {
            if (response.data.error.code === 400) {
              errors["oldPassword"] = response.data.error.message;
              errorClasses["oldPassword"] = "is-invalid";
              this.setState({
                errors,
                errorClasses
              });
            }
          }
        });
    }
  }
  render() {
    const {
      errors,
      errorClasses,
      accountData,
      showPasswordModal,
      showSuccessModal,
      isPasswordChanged,
      serverMessage
    } = this.state;
    let userData = JSON.parse(localStorage.getItem("user"));
    // console.log(accountData);
    return (
      <div className="wrapper form-wrapper form">
        <ReactModal
          isOpen={showPasswordModal}
          onRequestClose={this.hidePasswordModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal password-change"
        >
          {isPasswordChanged ? (
            <div>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="57"
                  viewBox="0 0 57 57"
                >
                  <g>
                    <path
                      fill="#7ed321"
                      d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                    />
                  </g>
                </svg>
              </div>
              <div className="text">{serverMessage}</div>
            </div>
          ) : (
            <div>
              <div className="row clear">
                <div className="item">
                  <div className="label">OLD PASSWORD</div>
                  <input
                    value={accountData.oldPassword}
                    type="password"
                    ref={el => (this.oldPassword = el)}
                    name="oldPassword"
                    className={errorClasses.oldPassword}
                    onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">{errors.oldPassword}</div>
                </div>
              </div>
              <div className="row clear">
                <div className="item">
                  <div className="label">NEW PASSWORD</div>
                  <input
                    value={accountData.newPassword}
                    type="password"
                    ref={el => (this.newPassword = el)}
                    name="newPassword"
                    className={errorClasses.newPassword}
                    onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">{errors.newPassword}</div>
                </div>
              </div>
              <div className="row clear">
                <div className="item">
                  <div className="label">REPET NEW PASSWORD</div>
                  <input
                    value={accountData.repetNewPassword}
                    type="password"
                    ref={el => (this.repetNewPassword = el)}
                    name="repetNewPassword"
                    className={errorClasses.repetNewPassword}
                    onChange={this.handleChange}
                  />
                  <div className="invalid-feedback">
                    {errors.repetNewPassword}
                  </div>
                </div>
              </div>
              <div className="btn" onClick={this.hidePasswordModal}>
                Discard
              </div>
              <div className="btn" onClick={this.handleChangeUserPassword}>
                Change
              </div>
            </div>
          )}
        </ReactModal>
        <ReactModal
          isOpen={showSuccessModal}
          onRequestClose={this.hideSuccessModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal password-change"
        >
            <React.Fragment>
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="57"
                  viewBox="0 0 57 57"
                >
                  <g>
                    <path
                      fill="#7ed321"
                      d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                    />
                  </g>
                </svg>
              </div>
              <div className="text">Your account successfully updated</div>
            </React.Fragment>
          
        </ReactModal>
        <div id="loader" className="loader circle">
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
        <div className="row clear">
          <div className="item">
            <div className="label">FIRST NAME</div>
            <input
              value={accountData.firstName}
              type="text"
              ref={el => (this.firstName = el)}
              name="firstName"
              className={errorClasses.firstName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.firstName}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">MIDDLE NAME (OPTIONAL)</div>
            <input
              value={accountData.middleName}
              type="text"
              ref={el => (this.middleName = el)}
              name="middleName"
              className={errorClasses.middleName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.middleName}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">LAST NAME</div>
            <input
              value={accountData.lastName}
              type="text"
              ref={el => (this.lastName = el)}
              name="lastName"
              className={errorClasses.lastName}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.lastName}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">EMAIL</div>
            <div className="disabled-row">
              <span>{userData.email}</span>
              <svg viewBox="0 0 20 20" id="locked">
                <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">Password</div>
            <div className="editable-row">
              <div className="title custom">•••••••••••</div>
              <div className="edit-btn" onClick={this.showPasswordModal}>
                Change
              </div>
            </div>
          </div>
        </div>
        {userData.isMember ? (
          ''
        ):(
          <React.Fragment>
            <div className="row clear">
          <div className="item">
            <div className="label">PHONE NUMBER</div>
            <input
              value={accountData.phoneNumber}
              type="text"
              ref={el => (this.phoneNumber = el)}
              name="phoneNumber"
              className={errorClasses.phoneNumber}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.phoneNumber}</div>
          </div>
        </div>
        <div className="row clear">
          <div className="item">
            <div className="label">Company</div>
            <input
              value={accountData.company}
              type="text"
              ref={el => (this.company = el)}
              name="company"
              className={errorClasses.company}
              onChange={this.handleChange}
            />
            <div className="invalid-feedback">{errors.company}</div>
          </div>
        </div>
          </React.Fragment>
        )}
        <div className="button-row">
          <div onClick={this.handleEditAccount}>Save</div>
        </div>
      </div>
    );
  }
}

export default AccountForm;
