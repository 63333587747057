import React, { Component } from "react";
import NotificationListView from "./notificationList";

class OrderNotifications extends Component {
  constructor(props) {
    super(props);

    // console.log(props.orderId);
    this.state = {};
  }
  componentDidMount() {
    let notificationCountBox = document.getElementById("notification-count");
    if (notificationCountBox && notificationCountBox.getElementsByTagName("span")[0].innerHTML !== "") {
      notificationCountBox.style.cssText="display:none";
    }
  }
  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <NotificationListView orderId={this.props.orderId}/>
      </React.Fragment>
    );
  }
}

export default OrderNotifications;
