import React, { Component } from "react";
import { authHeader } from "../../../_helpers";
import ProductOptions from "./productOptions";

import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
class OrderSpecifications extends Component {
  constructor(props) {
    super(props);
    let orderStatusId = props.orderStatusId;
    this.state = {
      orderSpecs: {},
      _isLoaded: false,
      orderStatusId,
      submitChanges:false,
    };
    this.getOrderSpecs = this.getOrderSpecs.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getOrderSpecs();
  }
  componentWillUnmount() { }

  getOrderSpecs = async e => {
    let loader = document.getElementById("popup-loader");
    let requestUrl = `/orderdetails/specifications/${this.props.orderId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      let orderSpecs = data.payload.options;
      let projectName = data.payload.projectName;
      let projectDescription = data.payload.projectDescription;
      let productOption = data.payload.origOptions;
      let options = this.configOptions(orderSpecs);
      let productId = data.payload.productId;
      let additionalFiles = data.payload.additionalFiles !== undefined ? data.payload.additionalFiles : ''

      this.setState(
        {
          orderSpecs: options,
          projectName,
          projectDescription,
          productOption,
          productId,
          additionalFiles,
          _isLoaded: true
        },
        () => {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };

  handleSubmit(){
    this.setState({
      submitChanges:true
    })
  }
  configOptions(options) {
    if (options.length === 0) return [];
    const customizedOptions = [];
    const oneFieldsOptions = ["swatch", "textarea"];
    for (let i = 0; i <= options.length - 1; i++) {
      const item = options[i];
      if (oneFieldsOptions.includes(item.fieldType)) {
      } else {
        const lastItem = customizedOptions[customizedOptions.length - 1];
        if (
          lastItem &&
          lastItem !== undefined &&
          lastItem.half &&
          lastItem.items.length === 1
        ) {
          customizedOptions[customizedOptions.length - 1].items.push(item);
        } else {
          customizedOptions.push({
            items: [item],
            half: true
          });
        }
      }
    }
    return customizedOptions;
  }
  render() {
    let {
      orderStatusId,
      productOption,
      _isLoaded,
      productId,
      projectName,
      projectDescription,
      additionalFiles
    } = this.state;

    return (
      <React.Fragment>
        <div id="popup-loader" className={`loader show circle`}>
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        {
          _isLoaded ? (
            <React.Fragment>
              <ProductOptions
                projectName={projectName}
                projectDescription={projectDescription}
                options={productOption}
                productId={productId}
                orderId={this.props.orderId}
                handleSubmit={this.state.submitChanges}
                orderStatusId={this.state.orderStatusId}

              />
              <div className="specs-container">
                {additionalFiles.length > 0 ? (
                  <div className="row files-row">
                  <div className="row-label">Die-Cut Template</div>
                  <div className="files-box">
                    {additionalFiles.map((item , key) => {
                      return (
                        <div className="item" key={key}>
                          <a target="_blank" rel="noopener noreferrer" href={item.file}>{item.name}</a>
                        </div>
                      )
                    })}
                  </div>
                </div>
              ):('')}
              </div>
              {
                orderStatusId === 2 ? (
                  <div onClick={this.handleSubmit} className="buttonSubmit">
                    Submit
                  </div>
                ):('')
              }
            </React.Fragment>
           
          ) : (
              ""
            )
        }
        {/* orderStatusId === 2 ? (
          _isLoaded ? (
            <ProductOptions
              projectName={projectName}
              projectDescription={projectDescription}
              options={productOption}
              productId={productId}
              orderId={this.props.orderId}
            />
          ) : (
              ""
            )
        ) : _isLoaded ? (
          <div className="specs-container">
            <div className="row half clear">
              <div className="item">
                <div className="label">Project Name</div>
                <div className="text">{projectName}</div>
              </div>
              <div className="item">
                <div className="label">Project Description</div>
                <div className="text">{projectDescription}</div>
              </div>
            </div>
            {orderSpecs.map((item, key) => {
              let items = item.items;
              return (
                <div className="row half clear" key={key}>
                  {items.map((item, key) => {
                    return (
                      <div className="item" key={key}>
                        <div className="label">{item.name}</div>
                        <div className="text">{item.value}</div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {additionalFiles.length > 0 ? (
                <div className="row files-row">
                <div className="row-label">Die-Cut Template</div>
                <div className="files-box">
                  {additionalFiles.map((item , key) => {
                    return (
                      <div className="item" key={key}>
                        <a target="_blank" rel="noopener noreferrer" href={item.file}>{item.name}</a>
                      </div>
                    )
                  })}
                </div>
              </div>
            ):('')}
          </div>
        ) : (
              ""
        )*/}
      </React.Fragment>
    );
  }
}

export default OrderSpecifications;
