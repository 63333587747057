import React, {Component} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {authHeader} from "../../_helpers";
const apiUrl = process.env.REACT_APP_API;


class ProductSidebar extends Component {

    constructor(props) {
        super(props);
        const productUrlName = props.productUrlName;
        const productInfo = props.productInfo;

        this.state = {
            productUrlName,
            productInfo,
            productServiceType: {},
            productPrice: {
                price: 0
            },
            couponCode:null,
            couponError:"",
            discountPercent:null,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.couponHandleChange = this.couponHandleChange.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.disabledStyle = {};
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        let productUrlName = nextProps.productUrlName;
        let productPrice = nextProps.productPrice;
        let productInfo = nextProps.productInfo;
        let productServiceType = nextProps.productServiceType;
        if (productPrice.selectedPrice === undefined) {
            productPrice = {
                price: 0
            }
        }
        this.setState({
            productUrlName,
            productPrice,
            productInfo,
            productServiceType
        });
        if (parseFloat(productPrice.selectedPrice) > 0) {
            let loader = document.getElementById("price-loader");
            setTimeout(function () {
                if (loader.classList.contains("show")) {
                    loader.classList.remove("show");
                }
            }, 500);
        }


    }

    handleSubmit() {
        this.sendData(true);
    }

    sendData = (param) => {
        console.log("param", param)
        this.props.handleSubmit(param,this.state.couponCode);
    };

    handleCheckBoxChange(e) {
        let id = parseInt(e.target.id)
        document.getElementById(id).click()
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
    couponHandleChange(e){
        let _this = e.target;
        let value = _this.value;

        if(value.match(/^[A-Za-z0-9]*$/)){
            this.setState({
                couponCode:value,
                couponError:""
            })
            this.disabledStyle = {
                cursor: "pointer",
                opacity: 1
            }
        }
        else{
            this.setState({couponError: "Coupon must include only numbers and letters"})
        }
    }

    handleApply = async () => {
        let requestUrl = `/coupon/check`;
        let {couponCode} = this.state;
        const queryParams = {
            code: couponCode,
        };
        let res = await axios.post(apiUrl + requestUrl, queryParams,{ headers: authHeader()});

        let {data} = res;
        if (data.error === null) {
            this.disabledStyle = {
                cursor:"not-allowed",
                opacity: "0.5",
            }
            this.setState({discountPercent: data.payload.percent, couponError: null})

        }
        else{
            this.setState({couponError: data.error.message})
        }
    }
    handleClear = () => {
        this.setState({
            couponCode:"",
            couponError:"",
            discountPercent: null
        })
        console.log("clicked")
    }


    render() {

        const {productPrice, productInfo, productServiceType, discountPercent, couponError, couponCode} = this.state;

        if (productServiceType === undefined) {
            return false
        }
        let user = JSON.parse(localStorage.getItem("user"));
        let visibilityStyle = {};
        let quoteTextColorStyle = {};
        let discountPrice=null;
        if (productServiceType.length > 0) {
            setTimeout(function () {
                productServiceType.map((item, key) => {
                    if (item.selected) {
                        document.getElementById(item.id + '-sidebar').checked = true;
                    } else {
                        document.getElementById(item.id + '-sidebar').checked = false;
                    }
                    return true;
                }, 1)
            })
        }
        if (productPrice.price === 0 || !user) {
            visibilityStyle = {
                visibility: "hidden"
            };

        }
        if (productInfo.quoteTextColor !== '') {
            quoteTextColorStyle = {
                color: productInfo.quoteTextColor
            }
        }
        if(discountPercent){
            discountPrice = (productPrice.selectedPrice - ((productPrice.selectedPrice * discountPercent) / 100)).toFixed(2)
            console.log("discountPrice", discountPrice)

        }

        return (
            <div className="sticky-box-container">
                <div className="sticky-box">
                    <div id="price-loader" className="price-loader loader circle">
                        <svg
                            width="200px"
                            height="200px"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                            className="lds-rolling"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                fill="none"
                                stroke="#B8BE14"
                                strokeWidth="2"
                                r="30"
                                strokeDasharray="141.37166941154067 49.12388980384689"
                                transform="rotate(159.346 50 50)"
                            >
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    calcMode="linear"
                                    values="0 50 50;360 50 50"
                                    keyTimes="0;1"
                                    dur="1.4s"
                                    begin="0s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                        </svg>
                    </div>
                    {user ?
                        productPrice.price === 0 ? (
                            <div className="message-box">
                                <div className="text-box">
                                    Please fill all required fields to get a price estimation.
                                </div>
                            </div>
                        ) : (
                            ""
                        )
                        :
                        (
                            <div className="message-box">
                                <div className="text-box">
                                    Please <Link to="/login">Sign in</Link> or <Link to="/register">Register</Link> to
                                    get price quote.
                                </div>
                            </div>
                        )
                    }
                    <div className="title">Fast Quote</div>

                    <div className="text" style={quoteTextColorStyle}>
                        {productInfo.quoteText ? productInfo.quoteText : 'Final price will be provided after Processing step'}
                    </div>

                    <div className="price-box" style={visibilityStyle}>
                        <div className="price plate" >
                            <div className="title">Total</div>
                            <span>AED </span>
                            <span>{discountPercent && couponCode ? discountPrice : (productPrice.selectedPrice  ? productPrice.selectedPrice.toFixed(2) : '0.00')}</span>
                        </div>
                        {discountPercent && couponCode ?
                            <div className="price plate discount">
                                <span>AED </span>
                                <span>{ productPrice.selectedPrice.toFixed(2)} </span>
                                <span>{discountPercent + "% off" }</span>
                            </div> : null}
                        {productPrice.unitPrice !== undefined && productPrice.unitPrice > 0 ? (
                            <div className="price unit">
                                <div className="title">Unit Price</div>
                                <span>AED </span>
                                <span>{productPrice.unitPrice ? productPrice.unitPrice.toFixed(2) : '0.00'}</span>
                            </div>
                        ) : null}
                        {
                            productServiceType.length > 0 ?
                                productServiceType.map((item, key) => {
                                    return (
                                        <div className="row clear vat" key={key}>
                                            <input
                                                type="checkbox"
                                                id={`${item.id}-sidebar`}
                                                name={item.parentId}
                                                onChange={this.handleCheckBoxChange}
                                            />
                                            <label
                                                htmlFor={`${item.id}-sidebar`}
                                                className={`custom-label-checkbox`}
                                            >
                                                <div className="icons">
                                                    <svg className="icon-check" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <polyline className="cls-2"
                                                                  points="1.27 7.23 4.93 10.9 12.73 3.1"/>
                                                    </svg>
                                                    <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg"
                                                         viewBox="0 0 14 14">
                                                        <rect className="cls-1" width="14" height="14"/>
                                                        <path className="cls-2" d="M7,11.5v-9M2.5,7h9"/>
                                                    </svg>
                                                </div>
                                                <span className="text">{item.title}</span>
                                                <span
                                                    className="value vat">{`AED ${productPrice[`${item.sku}Price`] ? productPrice[`${item.sku}Price`].toFixed(2) : '0.00'}`}</span>
                                            </label>

                                        </div>
                                    )
                                }) : (

                                    <React.Fragment>

                                        <div className="row row-flex vat">

                                            <span className="text">Plate Price</span>
                                            <span
                                                className="value vat">{`AED ${productPrice.physical ? productPrice.physical.toFixed(2) : '0.00'}`}</span>

                                        </div>
                                        <div className="row row-flex vat">

                                            <span className="text">LEN Price</span>
                                            <span
                                                className="value vat">{`AED ${productPrice.lenPrice ? productPrice.lenPrice.toFixed(2) : '0.00'}`}</span>
                                        </div>
                                        <div className="row row-flex vat">

                                            <span className="text">PDF Price</span>
                                            <span
                                                className="value vat">{`AED ${productInfo.pdfPrice ? productInfo.pdfPrice.toFixed(2) : '0.00'}`}</span>
                                        </div>
                                        <div className="row row-flex vat">

                                            <span className="text">Proof Price</span>
                                            <span
                                                className="value vat">{`AED ${productPrice.proofingPrice ? productPrice.proofingPrice.toFixed(2) : '0.00'}`}</span>
                                        </div>
                                    </React.Fragment>


                                )
                        }

                    </div>
                    <div className="text" style={visibilityStyle}>
                        5% VAT will be added for UAE customer during checkout.
                    </div>

                    {!user ? (
                        <div className="button disabled">Submit</div>
                    ) : (
                        <>
                            {/*<div className="flex-container">*/}
                            {/*        <input*/}
                            {/*            value={couponCode}*/}
                            {/*            placeholder="Enter coupon"*/}
                            {/*            type="text"*/}
                            {/*            onChange={this.couponHandleChange}*/}
                            {/*            style={discountPercent ? this.disabledStyle : null}*/}
                            {/*            disabled={discountPercent}*/}
                            {/*        />*/}
                            {/*    {*/}
                            {/*        discountPercent ? <div className="close-icon icons" onClick={this.handleClear}>*/}
                            {/*            <svg className="icon-plus" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14"*/}
                            {/*                 style={{transform: "rotate(45deg)"}}>*/}
                            {/*                <rect className="cls-1" width="14" height="14"></rect>*/}
                            {/*                <path className="cls-2" d="M7,11.5v-9M2.5,7h9"></path>*/}
                            {/*            </svg>*/}
                            {/*        </div> : null*/}
                            {/*    }*/}


                            {/*        <button className="button" style={discountPercent ? this.disabledStyle : null} onClick={this.handleApply} disabled={discountPercent} >Apply</button>*/}
                            {/*    <label className="error">{couponError}</label>*/}
                            {/*</div>*/}
                            <div onClick={this.handleSubmit} className="button">
                                Submit
                            </div>

                        </>

                    )}
                </div>
            </div>
        );
    }
}

export default ProductSidebar;
