import React, { Component } from "react";
import GlobalLoader from "../globalLoader";
import { authHeader } from "../../_helpers";
import axios from "axios";
import ReactModal from "react-modal";
var qs = require("query-string");

const apiUrl = process.env.REACT_APP_API;

class CheckoutFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderState:false,
      showFaildModal:false
    };

    
    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);
  }
  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  
  
  componentDidMount = async e => {
    var hash = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    let requestUrl = `/checkout/checkoutcom`;
    hash = hash['cko-session-id'];
    let res = await axios.post(
      apiUrl + requestUrl,
      { ckoSessionId: hash },
      { headers: authHeader()}
    );

    let { data } = res;


    if(data.error === null) {
      // console.log(data.payload.orderId)
      if(data.payload.success === false) {
        window.location = `/order/${data.payload.orderId}/failed`
      }
    }else {
      window.location = `/not-found`
    }
  }
  componentWillUnmount() {}
  render() {
    let {loaderState , showFaildModal} = this.state
    return(        
        <React.Fragment>

          <ReactModal
            isOpen={showFaildModal}
            onRequestClose={this.hideFaildModal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            portalClassName="modal product-success"
          >
            <div className="icon">
              <svg
                width="57px"
                height="57px"
                viewBox="0 0 57 57"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Splash-Copy"
                    transform="translate(-159.000000, -201.000000)"
                  >
                    <g
                      id="ic_error_24px"
                      transform="translate(159.000000, 201.000000)"
                    >
                      <mask id="mask-2" fill="white">
                        <path
                          d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                          id="path-1"
                        ></path>
                      </mask>
                      <g id="Shape" fillRule="nonzero"></g>
                      <g mask="url(#mask-2)" fill="#EE1D4E">
                        <g
                          transform="translate(-5.000000, -5.000000)"
                          id="Rectangle"
                        >
                          <rect x="0" y="0" width="69" height="69"></rect>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div className="title">
              Something went wrong please try again later
            </div>
          </ReactModal>
          <GlobalLoader loaderState={loaderState} />

        </React.Fragment>
    )
  }
}

export default CheckoutFailed;