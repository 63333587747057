import React, { Component } from "react";

import TemplatesSearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../_helpers";
import axios from "axios";
import ReactModal from "react-modal";

const apiUrl = process.env.REACT_APP_API;
let moment = require("moment");

function _changeActionButtonText(actionButton) {
  for (var i = 0; i < actionButton.length; i++) {
    actionButton[i].innerHTML = "Show Details";
  }
}

function _removeExpandedClasses(mainBox) {
  for (var i = 0; i < mainBox.length; i++) {
    mainBox[i].classList.remove("expand");
  }
}

function _emptyTemplateInfo(templateInfoBox) {
  for (var i = 0; i < templateInfoBox.length; i++) {
    templateInfoBox[i].innerHTML = "";
  }
}

class TemplateListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "25",
      searchValue: "",

      templateList: {},
      templateId: ""
    };
    this.getTemplatesList = this.getTemplatesList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.getTemplateInfo = this.getTemplateInfo.bind(this);
    this.showHideTemplateInfo = this.showHideTemplateInfo.bind(this);

    this.showRemovePopUp = this.showRemovePopUp.bind(this);
    this.hideRemovePopUp = this.hideRemovePopUp.bind(this);

    this.removeTemplate = this.removeTemplate.bind(this);
  }

  _isMounted = false;

  UNSAFE_componentWillReceiveProps(nextProps) {}
  hideRemovePopUp() {
    this.setState({
      showRemovePopUp: false,
      templateId:''
    });
  }
  showRemovePopUp(e) {
    let templateId = e.currentTarget.getAttribute('data-id');
    this.setState({
      showRemovePopUp: true,
      templateId
    });
  }
  removeTemplate = async e => {
    let {templateId} = this.state
    let requestUrl = `/templates/remove/${templateId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      this.hideRemovePopUp()
      document.getElementById(templateId).remove()
    }
  }
  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getTemplatesList(searchValue, start, end, 1, reset);
  }
  getTemplatesList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ memberList: [] });
    } else {
    }
    let requestUrl = `/templates/getTemplates`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.advancedSearch = searchValue;
    }
    // if (status !== undefined) {
    //   queryParams.statusId = status;
    // }
    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let templateList = data.payload.templates.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.templates.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          templateList
        },
        () => {
          currentComponent.props.templetsListReady(true);
        }
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let mainBox = document.querySelectorAll(".table-row");
    let templateInfoBox = document.querySelectorAll(".template-info");
    let actionButton = document.querySelectorAll(".action-btn span");
    _removeExpandedClasses(mainBox);
    _emptyTemplateInfo(templateInfoBox);
    _changeActionButtonText(actionButton);

    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getTemplatesList(searchValue, start, end, pageNumber, false);

    window.scroll(0, 0);
  }

  handleSearchValue = childData => {
    let mainBox = document.querySelectorAll(".table-row");
    let templateInfoBox = document.querySelectorAll(".template-info");
    let actionButton = document.querySelectorAll(".action-btn span");
    _removeExpandedClasses(mainBox);
    _emptyTemplateInfo(templateInfoBox);
    _changeActionButtonText(actionButton);

    this.setState({
      searchValue: childData
    });
    this.getTemplatesList(childData, 0, this.state.pp, 1, false);
  };

  getTemplateInfo = async e => {
    let id = e.currentTarget.id;
    let templateId = e.currentTarget.id;
    let _this = document.getElementById(id);

    let requestUrl = `/templates/templateView/${templateId}`;

    let html = "";

    let loader = _this.querySelector(".loader");
    if (_this.classList.contains("expand")) {
    } else {
      loader.classList.add("show");
    }

    let mainBox = document.querySelectorAll(".flex-row");
    let templateInfoBox = document.querySelectorAll(".template-info");
    let actionButton = document.querySelectorAll(".action-btn span");

    if (!_this.classList.contains("expand")) {
    
      _changeActionButtonText(actionButton);

      let res = await axios.get(apiUrl + requestUrl, {
        headers: authHeader()
      });

      let { data } = res;
      if (data.error === null) {
        let options = this.configOptions(data.payload.options);
        let templateGlobalInfo = data.payload.template;
        _this.querySelector(".action-btn span").innerHTML = "Hide Details";

        html += `
            <div class="row half">
              

              <div class="item">
                <div class="label">Project Description</div>
                <div class="text">${templateGlobalInfo.projectDescription}</div>
              </div>
            </div>
            `;

        options.map((list) => {
          let items = list.items;

          html += `<div class="row half clear" >`;
          items.map((item, key) => {
            html += `<div class="item" >
                      <div class="label">${item.label}</div>
                      <div class="text">${item.value}</div>
                    </div>`;

            return true;
          });
          html += `</div>`;

          return true;
        });

        html += `<div class="button-row">
                <a href="/products/${templateGlobalInfo.productUrl}?templateId=${templateGlobalInfo.id}" class="btn">Select</a>
              </div>
              `;

        let element = document.createElement("div");
        element.className = "template-info-container";
        element.innerHTML = html;

        // console.log(_this.querySelector(".template-info"));
        // console.log(element);

        setTimeout(function() {
          _removeExpandedClasses(mainBox);
          _emptyTemplateInfo(templateInfoBox);
          _this.querySelector(".template-info").appendChild(element);

          _this.classList.add("expand");

          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }

          if (_this.classList.contains("expand")) {
            _this.parentNode.scrollIntoView({
              offsetTop: 10000,
              behavior: "smooth"
            });
          }
        }, 500);
      }
    }
    // console.log(id);
  };

  showHideTemplateInfo(e) {
    let _this = e.currentTarget;
    let _thisParent = _this.parentNode.parentNode;
    if (_thisParent.classList.contains("expand")) {
      e.stopPropagation();

      let mainBox = document.querySelectorAll(".flex-row");
      let templateInfoBox = document.querySelectorAll(".template-info");

      _removeExpandedClasses(mainBox);
      _emptyTemplateInfo(templateInfoBox);

      _thisParent.querySelector(".action-btn span").innerHTML = "Show Details";
    } else {
    }
  }
  configOptions(options) {
    if (options.length === 0) return [];
    const customizedOptions = [];
    const oneFieldsOptions = ["swatch", "textarea"];
    for (let i = 0; i <= options.length - 1; i++) {
      const item = options[i];
      if (oneFieldsOptions.includes(item.fieldType)) {
      } else {
        const lastItem = customizedOptions[customizedOptions.length - 1];
        if (
          lastItem &&
          lastItem !== undefined &&
          lastItem.half &&
          lastItem.items.length === 1
        ) {
          customizedOptions[customizedOptions.length - 1].items.push(item);
        } else {
          customizedOptions.push({
            items: [item],
            half: true
          });
        }
      }
    }
    return customizedOptions;
  }
  render() {
    if (this._isMounted !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let { pp, total, activePage, showRemovePopUp, templateList,searchValue } = this.state;

    // console.log(this.state);
    return (
      <React.Fragment>
        <ReactModal
          isOpen={showRemovePopUp}
          onRequestClose={this.hideRemovePopUp}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal remove-template"
        >
          <div className="title">
            You do not have permission to submit order. 
          </div>
          <div className="button-row">
            <div onClick={this.hideRemovePopUp}>Discard</div>
            <div onClick={this.removeTemplate}>Remove</div>
          </div>
        </ReactModal>
        <div className="container-header">
          <TemplatesSearchView searchValue={this.handleSearchValue} />
        </div>
        <div className="members-list-box flex-grid">
          {templateList.length > 0 ? (
            <React.Fragment>
              <div className="flex-row flex-head">
                <div className="flex-cell first">
                  <div className="text">id</div>
                </div>
                <div className="flex-cell">
                  <div className="text">project name</div>
                </div>
                <div className="flex-cell">
                  <div className="text">product name</div>
                </div>
                <div className="flex-cell">
                  <div className="text">DATE CREATED</div>
                </div>
                <div className="flex-cell last">
                  <div className="text"></div>
                </div>
              </div>

              {templateList.map((item, key) => {
                return (
                  <div
                    className={`flex-row hover-element`}
                    data-id={item.id}
                    key={key}
                    id={item.id}
                    onClick={this.getTemplateInfo}
                  >
                    <div className={`loader circle `}>
                      <svg
                        width="200px"
                        height="200px"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                        className="lds-rolling"
                      >
                        <circle
                          cx="50"
                          cy="50"
                          fill="none"
                          ng-attr-stroke="{{config.color}}"
                          ng-attr-stroke-width="{{config.width}}"
                          ng-attr-r="{{config.radius}}"
                          ng-attr-stroke-dasharray="{{config.dasharray}}"
                          stroke="#B8BE14"
                          strokeWidth="2"
                          r="30"
                          strokeDasharray="141.37166941154067 49.12388980384689"
                          transform="rotate(159.346 50 50)"
                        >
                          <animateTransform
                            attributeName="transform"
                            type="rotate"
                            calcMode="linear"
                            values="0 50 50;360 50 50"
                            keyTimes="0;1"
                            dur="1.4s"
                            begin="0s"
                            repeatCount="indefinite"
                          ></animateTransform>
                        </circle>
                      </svg>
                    </div>
                    <div className="flex-cell first">
                      <div className="text">
                        <div>ID:</div>
                        <div>{item.templateId}</div>
                      </div>
                    </div>
                    <div className="flex-cell template-name">
                      <div className="text" title={item.projectName}>
                        <div>Project Name:</div>
                        <div>{item.projectName}</div>
                      </div>
                    </div>
                    <div className="flex-cell">
                      <div className="text" >
                        <div>Product Name:</div>
                        <div>{item.productName}</div>
                      </div>
                    </div>
                    <div className="flex-cell">
                      <div className="text">
                        <div>Date Created:</div>
                        <div>{moment(item.createdDate, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                      </div>
                    </div>
                    <div className="flex-cell last">
                      <div
                        className="action-btn btn green"
                        onClick={this.showHideTemplateInfo}
                      >
                        <span>Show Details</span>
                      </div>
                      <div className="remove-template" onClick={this.showRemovePopUp} data-id={item.id}>
                        <svg height="427pt" viewBox="-40 0 427 427.00131" width="427pt" xmlns="http://www.w3.org/2000/svg">
                          <path d="m232.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/>
                          <path d="m114.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/>
                          <path d="m28.398438 127.121094v246.378906c0 14.5625 5.339843 28.238281 14.667968 38.050781 9.285156 9.839844 22.207032 15.425781 35.730469 15.449219h189.203125c13.527344-.023438 26.449219-5.609375 35.730469-15.449219 9.328125-9.8125 14.667969-23.488281 14.667969-38.050781v-246.378906c18.542968-4.921875 30.558593-22.835938 28.078124-41.863282-2.484374-19.023437-18.691406-33.253906-37.878906-33.257812h-51.199218v-12.5c.058593-10.511719-4.097657-20.605469-11.539063-28.03125-7.441406-7.421875-17.550781-11.5546875-28.0625-11.46875h-88.796875c-10.511719-.0859375-20.621094 4.046875-28.0625 11.46875-7.441406 7.425781-11.597656 17.519531-11.539062 28.03125v12.5h-51.199219c-19.1875.003906-35.394531 14.234375-37.878907 33.257812-2.480468 19.027344 9.535157 36.941407 28.078126 41.863282zm239.601562 279.878906h-189.203125c-17.097656 0-30.398437-14.6875-30.398437-33.5v-245.5h250v245.5c0 18.8125-13.300782 33.5-30.398438 33.5zm-158.601562-367.5c-.066407-5.207031 1.980468-10.21875 5.675781-13.894531 3.691406-3.675781 8.714843-5.695313 13.925781-5.605469h88.796875c5.210937-.089844 10.234375 1.929688 13.925781 5.605469 3.695313 3.671875 5.742188 8.6875 5.675782 13.894531v12.5h-128zm-71.199219 32.5h270.398437c9.941406 0 18 8.058594 18 18s-8.058594 18-18 18h-270.398437c-9.941407 0-18-8.058594-18-18s8.058593-18 18-18zm0 0"/>
                          <path d="m173.398438 154.703125c-5.523438 0-10 4.476563-10 10v189c0 5.519531 4.476562 10 10 10 5.523437 0 10-4.480469 10-10v-189c0-5.523437-4.476563-10-10-10zm0 0"/>
                        </svg>
                      </div>
                    </div>
                    <div className="template-info"></div>
                  </div>
                );
              })}
            </React.Fragment>
          ):(
            <div className="no-result-box">
              <div className="text">
                {searchValue
                  ? `No result for: ${searchValue}`
                  : `You don’t have any templates.`}
              </div>
            </div>
          )}
        </div>
        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default TemplateListView;
