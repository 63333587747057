import React, { Component } from "react";

import SearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";

import { Scrollbars } from "react-custom-scrollbars";
import Dropbox from "../../product/dropboxPicker";
import GooglePicker from "../../product/googlePicker";

import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
const webCenterUrl = process.env.REACT_APP_WEBCENTER_DOMAIN;
let moment = require("moment");

class ListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "15",
      searchValue: "",
      fileList: {},
      showModal: false,
      fileUrl: "",
      showUploadModal: false,
      globalUploadeCount: 0,
      faildModalMessage:'',
      selectedFile: [],
      uploadedFiles: []
    };
    this.getFileList = this.getFileList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handleConfigFile = this.handleConfigFile.bind(this);
    this.handleIframeLoad = this.handleIframeLoad.bind(this);

    this.showUploadModal = this.showUploadModal.bind(this);
    this.hideUploadModal = this.hideUploadModal.bind(this);

    this.handleSelectFileClick = this.handleSelectFileClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);

    this.fileUpload = this.fileUpload.bind(this);
    this.handleRemoveFile = this.handleRemoveFile.bind(this);

    this.handleSaveClick = this.handleSaveClick.bind(this);

    this.handleInputFileChange = this.handleInputFileChange.bind(this);

    this.dropBoxOnSuccess = this.dropBoxOnSuccess.bind(this);
    this.googleOnSuccess = this.googleOnSuccess.bind(this);

    this.showFaildModal = this.showFaildModal.bind(this);
    this.hideFaildModal = this.hideFaildModal.bind(this);
  }

  hideFaildModal() {
    this.setState({
      showFaildModal: false
    });
  }
  showFaildModal() {
    this.setState({
      showFaildModal: true
    });
  }
  _isMounted = false;
  _isRolesReady = false;

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      let inputField = document.getElementById("selectFile");
      inputField.setAttribute("data-value", "1");
      // let div = document.getElementById("drop_file_zone");
      // if (div.classList.contains("dragging")) {
      //   div.classList.remove("dragging");
      // }
      let files = e.dataTransfer.files;
      this.fileUpload(files, "direct");
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  }
  dropBoxOnSuccess(e) {
    console.log(e);

    let inputField = document.getElementById("selectFile");
    inputField.setAttribute("data-value", "1");

    this.fileUpload(e, "dropbox");
  }

  googleOnSuccess(e) {
    if (e.action === "picked") {
      let { googleToken } = this.state;
      let inputField = document.getElementById("selectFile");
      inputField.setAttribute("data-value", "1");
      console.log(e);
      this.fileUpload(e["docs"], "google", googleToken);
    }
  }
  fileUpload = async (files, type, token = 0) => {
    let { globalUploadeCount } = this.state;
    let loader = document.getElementById("popup-loader");
    let inputField = document.getElementById("selectFile");
    this.setState({
      uploadErrorMsgText: ""
    });
    // console.log(globalUploadeCount);

    loader.classList.add("show");
    var glogalSize = 0;

    let requestUrl = `/orderdetails/missingFile/${this.props.orderId}`;
    let currentComponent = this;
    // console.log(asd)
    // console.log(file_obj)
    if (type === "dropbox") {
      for (let i = 0; i <= files.length - 1; i++) {
        let item = files[i];
        glogalSize += parseInt(item.bytes);
      }
    } else if (type === "onedrive") {
      globalUploadeCount = files.value.length + globalUploadeCount;
      for (let i = 0; i <= files.length - 1; i++) {
        let item = files[i];
        glogalSize += parseInt(item.size);
      }
    } else if (type === "google") {
      if (files !== undefined) {
        globalUploadeCount = files.length + globalUploadeCount;
      }
      for (let i = 0; i <= files.length - 1; i++) {
        let item = files[i];
        glogalSize += parseInt(item.sizeBytes);
      }
    } else {
      globalUploadeCount = files.length + globalUploadeCount;
      // console.log(files);
      for (let i = 0; i <= files.length - 1; i++) {
        let item = files[i];
        glogalSize += parseInt(item.size);
      }
    }
    // console.log(glogalSize);

    var size = glogalSize / (1024 * 1024);
    // console.log(globalUploadeCount);

    if (
      files !== undefined &&
      (files.length > 0 ||
        (files.value !== undefined && files.value.length > 0)) &&
      size < 501 &&
      globalUploadeCount < 32
    ) {
      var formData = new FormData();

      if (type === "dropbox") {
        const links = [];
        for (let i = 0; i <= files.length - 1; i++) {
          let item = files[i];
          links.push(item);
        }
        formData.append("files", JSON.stringify(links));
      } else if (type === "google") {
        const links = [];
        formData.append("access_token", token);

        for (let i = 0; i <= files.length - 1; i++) {
          let item = files[i];
          links.push(item);
        }
        formData.append("files", JSON.stringify(links));
      } else {
        for (let i = 0; i <= files.length - 1; i++) {
          let item = files[i];
          formData.append("files[" + i + "]", item);
        }
      }

      formData.append("cloudName", type);

      let res = await axios.post(apiUrl + requestUrl, formData, {
        headers: authHeader()
      });
      let { data } = res;

      if (data.error === null) {
        let { globalUploadeCount } = this.state;
        let files = data.payload;
        globalUploadeCount = globalUploadeCount + Object.keys(files).length;
        // console.log(files);

        setTimeout(function() {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
          let selectedFiles = [];
          let selectedFile = currentComponent.state.selectedFile;

          Object.keys(files).map(function(i) {
            let item = files[i];

            return selectedFiles += selectedFile.push(item);
            
          });
         
          currentComponent.setState({
            selectedFile
          });
          inputField.value = ''
        }, 500);

        this.setState({
          globalUploadeCount
        });
      }
    } else {
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }

      document.getElementById("selectFile").val = "";
      this.setState({
        uploadErrorMsgText: ""
      });
      // $('#drop_file_zone .error').empty();
      if (globalUploadeCount >= 33) {
        // $('#drop_file_zone .error').text('')
        this.setState({
          uploadErrorMsgText: "Maximum files count is 32."
        });
      } else {
        this.setState({
          uploadErrorMsgText: "Maximum upload size is 300 mb."
        });
        // $('#drop_file_zone .error').text('Maximum upload size is 300 mb.')
      }

    }
  };
  showUploadModal() {
    this.setState({
      showUploadModal: true,
      selectedFile: []
    });
  }
  hideUploadModal = async e => {
    this.setState(
      {
        showUploadModal: false
      },
      () => {
        // let projectPopup = document.getElementsByClassName("new-project");
        // if (projectPopup[0].classList.contains("product-list")) {
        //   projectPopup[0].classList.remove("product-list");
        // }
        
      }
    );
    let requestUrl = `/orderdetails/deleteAllMissingFile/${this.props.orderId}`;
    let res = await axios.post(apiUrl + requestUrl, '',{
      headers: authHeader()
    });

    let { data } = res;

    if (data.error === null) {  
      
    }
  }
  handleSaveClick = async e => {
    let inputField = document.getElementById("selectFile");
    let inputData = inputField.getAttribute("data-value");
    let loader = document.getElementById("popup-loader");
    // console.log(inputField.files);
    // console.log(inputData);
    if (
      inputData !== undefined &&
      inputData !== "" &&
      this.state.globalUploadeCount > 0
    ) {
      loader.classList.add("show");
      let requestUrl = `/orderdetails/saveMissingFiles/${this.props.orderId}`;
      let res = await axios.post(apiUrl + requestUrl, '',{
        headers: authHeader()
      });

      let { data } = res;

      if (data.error === null) {  
        let searchValue = this.state.searchValue,
        start = "0",
        end = this.state.pp,
        reset = true;
        this.getFileList(searchValue, start, end, 1, reset);
        this.setState({
          showUploadModal: false
        } , () => {
          window.location.reload()
        });
      }
    } else {
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }
      this.setState({
        uploadErrorMsgText: "Select file before save"
      });
    }
  }

  handleRemoveFile = async e => {
    let loader = document.getElementById("popup-loader");

    let { selectedFile, uploadedFiles, globalUploadeCount } = this.state;
    let type = e.currentTarget.getAttribute("data-type");
    let id = e.currentTarget.getAttribute("data-id");
    if (type === "popup") {
      loader.classList.add("show");
    }
    let requestUrl = `/orderdetails/deleteMissingFile/${this.props.orderId}/${id}`;
    let res = await axios.post(apiUrl + requestUrl, '',{
      headers: authHeader()
    });

    let { data } = res;

    if (data.error === null) {
      let foundIndex;
      if (type === "popup") {
        foundIndex = selectedFile.findIndex(x => x.id === parseInt(id));
        // console.log(foundIndex);
        selectedFile.splice(foundIndex, 1);
        this.setState({
          selectedFile,
          globalUploadeCount: globalUploadeCount - 1
        });
        if (loader.classList.contains("show")) {
          loader.classList.remove("show");
        }
      } else {
        foundIndex = uploadedFiles.findIndex(x => x.id === parseInt(id));
        // console.log(foundIndex);
        // console.log(id);
        uploadedFiles.splice(foundIndex, 1);
        this.setState({
          uploadedFiles,
          globalUploadeCount: globalUploadeCount - 1
        });
      }
    }

    // console.log("asd");
  };
  handleSelectFileClick(e) {
    let inputField = document.getElementById("selectFile");
    inputField.click();
    inputField.setAttribute("data-value", "1");
  }
  handleInputFileChange(e) {
    let files = e.target.files;
    if (files && files.length > 0) {
      // let div = document.getElementById("drop_file_zone");
      // if (div.classList.contains("dragging")) {
      //   div.classList.remove("dragging");
      // }
      this.fileUpload(files, "direct");
    }
    // console.log(files);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getFileList(searchValue, start, end, 1, reset);
  }

  getFileList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ fileList: [] });
    } else {
    }
    let requestUrl = `/orderdetails/files/${this.props.orderId}`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.filename = searchValue;
    }
    // if (status !== undefined) {
    //   queryParams.statusId = status;
    // }
    queryParams._start = start;
    queryParams._end = end;
    queryParams.status = 3;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      //   console.log(data);
      let fileList = data.payload.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          fileList
        },
        () => {}
      );
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getFileList(searchValue, start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  handleSearchValue = childData => {
    this.setState({
      searchValue: childData
    });
    this.getFileList(childData, 0, this.state.pp, 1, false);
  };

  handleOpenModal(e) {
    this.setState(
      {
        showModal: true
      },
      () => {
        let loader = document.getElementById("loader");

        if (loader.classList.contains("show")) {
          loader.classList.remove("show");
        }
      }
    );
  }

  handleCloseModal() {
    this.setState({
      showModal: false
    });
  }

  handleConfigFile =async e => {
    // let _this = e.currentTarget;
    // let fileUrl = _this.getAttribute("data-url");
    // let loader = document.getElementById("loader");
    // loader.classList.add("show");
    // this.setState(
    //   {
    //     fileUrl: fileUrl
    //   },
    //   () => {
    //     this.handleOpenModal();
    //   }
    // );
    let loader = document.getElementById("loader");
    loader.classList.add("show");
    let uniqueId = localStorage.getItem('uniqueId')

    let requestUrl = `/orderdetails/getFileUrl/${this.props.orderId}/${e.currentTarget.getAttribute('data-id')}/${uniqueId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });

    let { data } = res;

    if (data.error === null) {
      window.open(data.payload.url)
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }
    }else {
      this.setState({
        faildModalMessage:data.error.message,
        showFaildModal: true
      });
      if (loader.classList.contains("show")) {
        loader.classList.remove("show");
      }
    }
  };
  handleIframeLoad() {
    let loader = document.getElementById("popup-loader");
    if (loader.classList.contains("show")) {
      setTimeout(function() {
        loader.classList.remove("show");
      }, 500);
    }
  }

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: 0,
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
      cursor: `pointer`,
      borderRadius: `inherit`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }
  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }

  render() {
    if (this._isMounted !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      
      );
    }

    let {
      pp,
      total,
      activePage,
      fileUrl,
      showModal,
      fileList,
      searchValue,
      uploadErrorMsgText,
      selectedFile,
      showUploadModal,
      showFaildModal,
      faildModalMessage
    } = this.state;

    // console.log(this.state);

    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let UPLOAD_FILES;
    if(userPermission) {
      UPLOAD_FILES = userPermission.find(
        opt => opt.key === 'UPLOAD_FILES')
        
    }
    return (
      <React.Fragment>
        <div id="loader" className="loader  fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal file-viewer"
        >
          <React.Fragment>
            <div id="popup-loader" className="loader show  circle">
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
            <iframe onLoad={this.handleIframeLoad} title={fileUrl} src={fileUrl}></iframe>
          </React.Fragment>
        </ReactModal>
        <ReactModal
          isOpen={showUploadModal}
          onRequestClose={this.hideUploadModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal upload"
        >
          <React.Fragment>
            <div id="popup-loader" className="loader circle">
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
            <div className="title">Upload image</div>
            <div className="description">
              Supported file format: PSD, AI, INDD, PDF, TIFF, PNG, JPG, SLDPRT,
              STL, OBJ, ZIP, RAR, VIDEO
            </div>
            <div
              id="drop_file_zone"
              onDragEnter={this.handleDragIn}
              onDragLeave={this.handleDragOut}
              onDragOver={this.handleDrag}
              onDrop={this.handleDrop}
            >
              <div id="drag_upload_file">
                <p className="drop-title">Drop file here</p>
                <p className="btn-wrap">
                  <span>or </span>{" "}
                  <span
                    className="selectBtn"
                    onClick={this.handleSelectFileClick}
                  >
                    Select File
                  </span>
                </p>
                <input
                  type="file"
                  id="selectFile"
                  name="files[]"
                  data-value=""
                  multiple
                  onChange={this.handleInputFileChange}
                />
              </div>
              <label className="error">{uploadErrorMsgText}</label>
            </div>
            <div className="picker-row">
              <GooglePicker
                onToken={token => this.setState({ googleToken: token })}
                onSuccess={this.googleOnSuccess}
              />
              <Dropbox onSuccess={this.dropBoxOnSuccess} />

            </div>
            <Scrollbars
              autoHeight
              autoHeightMax={150}
              renderThumbVertical={this.renderThumb}
            >
              <div id="file-box" className="file-box">
                {selectedFile.length > 0
                  ? selectedFile.map((item, key) => {
                    
                      return (
                        <div className="item" key={key} data-id={item.id}>
                          <span className="name">{item.filename}</span>
                          <span
                            data-id={item.id}
                            data-type="popup"
                            onClick={this.handleRemoveFile}
                            className="remove"
                          >
                            remove
                          </span>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </Scrollbars>
            <div className="button-row">
              <div onClick={this.hideUploadModal}>Discard</div>
              <div onClick={this.handleSaveClick}>Save</div>
            </div>
          </React.Fragment>
        </ReactModal>
        <ReactModal
          isOpen={showFaildModal}
          onRequestClose={this.hideFaildModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal product-success"
        >
          <div className="icon">
            <svg
              width="57px"
              height="57px"
              viewBox="0 0 57 57"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Splash-Copy"
                  transform="translate(-159.000000, -201.000000)"
                >
                  <g
                    id="ic_error_24px"
                    transform="translate(159.000000, 201.000000)"
                  >
                    <mask id="mask-2" fill="white">
                      <path
                        d="M28.5,0 C12.768,0 0,12.768 0,28.5 C0,44.232 12.768,57 28.5,57 C44.232,57 57,44.232 57,28.5 C57,12.768 44.232,0 28.5,0 Z M31.35,42.75 L25.65,42.75 L25.65,37.05 L31.35,37.05 L31.35,42.75 Z M31.35,31.35 L25.65,31.35 L25.65,14.25 L31.35,14.25 L31.35,31.35 Z"
                        id="path-1"
                      ></path>
                    </mask>
                    <g id="Shape" fillRule="nonzero"></g>
                    <g mask="url(#mask-2)" fill="#EE1D4E">
                      <g
                        transform="translate(-5.000000, -5.000000)"
                        id="Rectangle"
                      >
                        <rect x="0" y="0" width="69" height="69"></rect>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </div>

          <div className="title">
            {faildModalMessage}
          </div>
        </ReactModal>
        
        <div className="container-header">
          <SearchView searchValue={this.handleSearchValue} />
          {this.props.orderStatusId === 7 ? 
            currentUser.isMember === false || (currentUser.isMember === true && UPLOAD_FILES !== undefined) ? (
              <div onClick={this.showUploadModal} className="attach-btn">
                Upload File
              </div>
            ):('')
          :('')}
        </div>
        <div className="files-list-box table-grid">
        {fileList.length > 0 ? (
            <React.Fragment>
              <div className="table-row table-head">

                <div className="table-cell">
                  <div className="text">View</div>
                </div>
                <div className="table-cell">
                  <div className="text">Document</div>
                </div>
                <div className="table-cell">
                  <div className="text">Uploaded Folder</div>
                </div>
                <div className="table-cell date-cell">
                  <div className="text">Uploaded Date</div>
                </div>
                <div className="table-cell">
                  <div className="text"></div>
                </div>
              </div>

              {fileList.map((item, key) => {
                if (item.status === 2) {
                  return true;
                }

                
                let fileExtation = item.type;
                
                if(fileExtation !== 'len') {
                  fileExtation = fileExtation.split("/");
                  fileExtation = fileExtation[1]
                }

                return (
                  <div className="table-row" key={key}>
                    <div className="table-cell with-icon">
                      <div className="text">
                        {
                          currentUser.isMember === false || (currentUser.isMember === true &&  UPLOAD_FILES !== undefined) ? (
                                  <div
                                      className="icon"
                                      data-url={webCenterUrl + item.url}
                                      data-id={item.id}
                                      onClick={this.handleConfigFile}
                                  >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20.565"
                                        height="12.48"
                                        viewBox="0 0 20.565 12.48"
                                    >
                                      <path
                                          d="M.008-21.24c-3.78,0-6.735,2.43-10.29,6.24C-7.237-11.82-4.657-8.76.008-8.76c4.695,0,8.085-3.9,10.275-6.165C8-17.64,4.628-21.24.008-21.24Zm0,.78c4.005,0,6.99,2.91,9.225,5.505C7.058-12.675,4.028-9.54.008-9.54c-4.05,0-6.39-2.49-9.21-5.46C-5.932-18.45-3.322-20.46.008-20.46Zm0,8.61A3.15,3.15,0,0,0,3.158-15a3.15,3.15,0,0,0-3.15-3.15A3.14,3.14,0,0,0-3.142-15,3.14,3.14,0,0,0,.008-11.85Zm0-.78A2.356,2.356,0,0,1-2.362-15a2.356,2.356,0,0,1,2.37-2.37A2.365,2.365,0,0,1,2.378-15,2.365,2.365,0,0,1,.008-12.63Z"
                                          transform="translate(10.283 21.24)"
                                      />
                                    </svg>
                                  </div>)
                              : (
                                  ""
                              )}
                      </div>
                    </div>
                    <div className="table-cell document-cell">
                      <div className="text" title={item.filename}>
                        <div>Document:</div>
                        <div>{item.filename}</div>
                      </div>
                    </div>
                    
                    <div className="table-cell folder-cell">
                      <div className="text">
                        <div>Uploaded Folder:</div>
                        <div>{item.folderName}</div>
                      </div>
                    </div>
                    <div className="table-cell date-cell">
                      <div className="text">
                        <div>Uploaded Date:</div>
                        <div>{moment(item.createdAt, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                      </div>
                    </div>
                    <div className="table-cell with-icon">
                      <div className="text">
                          <div className="icon">
                            {/* <a href={item.downloadLink} download={item.downloadLink} target={item.type === 'application/pdf' ? '_blank' : ''}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" hight="18" viewBox="0 0 512 512">
                                <path d="M512,313V452a60.07,60.07,0,0,1-60,60H60A60.07,60.07,0,0,1,0,452V313H40V452a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V313ZM380.28,264,352,235.72l-76,76V0H236V311.72l-76-76L131.72,264,256,388.28Z"/>
                              </svg>
                            </a> */}
                            <Link target="_blank" to={`/file/${this.props.orderId}/${item.docversionId}/0/${fileExtation}`}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" hight="18" viewBox="0 0 512 512">
                                <path d="M512,313V452a60.07,60.07,0,0,1-60,60H60A60.07,60.07,0,0,1,0,452V313H40V452a20,20,0,0,0,20,20H452a20,20,0,0,0,20-20V313ZM380.28,264,352,235.72l-76,76V0H236V311.72l-76-76L131.72,264,256,388.28Z"/>
                              </svg>
                            </Link>
                          </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-result-box">
              <div className="text">
                {searchValue
                  ? `No result for: ${searchValue}`
                  : `No files for this order.`}
              </div>
            </div>
          )}
        </div>

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default ListView;
