import React, { Component } from 'react';
import MetaTags from "../metaTags"
import { Redirect, Link } from "react-router-dom";
import GlobalLoader from "../globalLoader";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;
class AllCategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productList: [],
            categoryInfo: {},
            redirect: false,
            redirectUrl: "",
            categoryIsExist: false,
            isCategoryFieldReady: false,
            pp: "25",
            searchValue: "",
        };
        this.getCategoryList = this.getCategoryList.bind(this);

    }
    _isMounted = false;
   
    getCategoryList = async () => {
        const requsetUrl = "/homepagecategories";
        let currentComponent = this;
        let res = await axios.get(apiUrl + requsetUrl);
        let { data } = res;
        // console.log(data);
        if (data.error === null) {
        let productList = data.payload;
        currentComponent._isMounted = true
        currentComponent.setState({
            productList,
            _isProductList: true
        });
        }
    };
    
    componentDidMount(){
        document.body.classList.add('category');
        window.scroll(0,0)
        this.getCategoryList();
    }

    componentWillUnmount(){
        document.body.classList.remove('category');
        this._isMounted = false;
    }

    render(){
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectUrl} />;
        }
        if (this._isMounted !== true) {
            return <GlobalLoader loaderState={true} />;
        }
        const {
            productList,
          } = this.state;
          
          let image = "https://apiflexoeasy.digitecintl.com/uploads/products/$2b$10$72JqeXST0Excv.Le3F01g.QMSDqTeduqTF9hPlY65gFpMZ9mhzs9y.jpeg";
        return(
            <React.Fragment>
                <MetaTags/>
                <div className="category-container">
                    {/* <section className="top" style={backgroundImage}>
                        <div className="wrap-box">
                        <div className="table">
                            <div className="cell">
                                <h1 className="page-title">All Category</h1>
                                <div className="description">
                                    Print cut-to-shape stickers and labels of the highest quality. Made exclusively for your brand and shaped perfectly to fit your packaging, they will reflect your brand and its message. No matter the forms and design, we will make sure your prints are of exceptional quality. We recommend you to try Fingerprint before purchasing configured plate
                                </div>
                            </div>
                        </div>
                        </div>
                    </section> */}
                    <section className="top">
                        <div className="image-box">
                            <img alt="" src={image} />
                        </div>
                        <div className="text-box">
                            <div className="table">
                                <div className="cell">
                                    <h1 className="page-title">All Category</h1>
                                    <div className="description">
                                        Print cut-to-shape stickers and labels of the highest quality. Made exclusively for your brand and shaped perfectly to fit your packaging, they will reflect your brand and its message. No matter the forms and design, we will make sure your prints are of exceptional quality. We recommend you to try Fingerprint before purchasing configured plate
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="products">
                        <div className="wrapper">
                            <h2 className="section-title">Product Categories</h2>
                            <div className="product-list">
                                {productList.map((list, key) => {
                                    return (
                                        <div className="item" key={key}>
                                            <Link to={`/category/` + list.key}>
                                            <img src={list.thumbnail} alt={list.name}/>
                                            <div className="text-box">
                                                <div className="table">
                                                <div className="cell">
                                                    <div className="title">{list.name}</div>
                                                    <div className="description">
                                                    {list.products.length > 0 ? 
                                                        list.products.map((item , key) => {
                                                        return (
                                                            <span key={key}>
                                                            {item.name}
                                                            {list.products.length !== key+1 ? ', ':''}
                                                            </span>
                                                        )
                                                        })
                                                        :('')
                                                    }
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </section>
                </div>
                
            </React.Fragment>
        )
    }
}



export default AllCategory;
