import React, { Component } from "react";
import ProductOptions from "./productOptions";
import ProductSidebar from "./sidebar";
import { Redirect } from "react-router-dom";
import GlobalLoader from "../globalLoader";
import axios from "axios";
import {Helmet} from "react-helmet";

const apiUrl = process.env.REACT_APP_API;
var qs = require("query-string");
class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productInfo: {},
      productPrice: {},
      redirect: false,
      redirectUrl: "",
      productIsExist: false,
      handleSubmit: false,
      isProductFieldReady: false,
      templateId: "",
      productServiceType : {},
      couponCode:null
    };
    this.checkProductUrl = this.checkProductUrl.bind(this);
    this.getProductBasicInfo = this.getProductBasicInfo.bind(this);
  }
  _isMounted = false;
  componentDidMount() {
    // var productUrlName = this.props.match.params.id;
    document.body.classList.add("product");
    window.scroll(0,0)

    this.checkProductUrl();

    // let templateId = "";
    // if (this.props.location.search) {
    //   templateId = qs.parse(this.props.location.search, {
    //     ignoreQueryPrefix: true
    //   }).templateId;
    //   if (templateId !== undefined && templateId !== "") {
    //     this.setState({
    //       templateId
    //     });
    //   }
    // }
  }
  callbackSubmit = (childData,couponCode) => {
    let currentComponent = this;
    this.setState(
      {
        handleSubmit: childData,
        couponCode:couponCode
      },
      () => {
        setTimeout(function() {
          currentComponent.setState({
            handleSubmit: false
          });
        }, 200);
      }
    );
  };
  productFieldReady = childData => {
    // console.log(childData);
    // this._isProductFieldReady = true;
    // let currentComponent = this;
    // setTimeout(function() {
    //   currentComponent.setState({
    //     isProductFieldReady: childData
    //   });
    // }, 100);
  };

  productPrice = childData => {
    let currentComponent = this;
    currentComponent.setState({
      productPrice: childData
    });
  };
  productCheckBoxList = childData => {
    let currentComponent = this;
    
    currentComponent.setState({
      productServiceType: childData
    });
  }
  checkProductUrl = async () => {
    let requestUrl = "/checkurl";
    let currentComponent = this;
    let postdata = {
      url: this.props.match.params.id
    };

    let res = await axios.post(apiUrl + requestUrl, qs.stringify(postdata));

    let { data } = res;
    // console.log(data);



    if (data.error === null) {
      if (data.payload.success !== true) {
        currentComponent.setState({
          redirect: true,
          redirectUrl: "/not-found"
        });
      } else {
        currentComponent.setState({
          productIsExist: true
        });
        return currentComponent.getProductBasicInfo();
      }
    } else {
      currentComponent.setState({
        redirect: true,
        redirectUrl: "/not-found"
      });
    }
  };
  getProductBasicInfo = async () => {
    let requestUrl = `/product/${this.props.match.params.id}`;
    let currentComponent = this;
    let res = await axios.get(apiUrl + requestUrl);

    let { data } = res;
    if (data.error === null) {
      currentComponent._isMounted = true;
      currentComponent.setState({
        productInfo: data.payload
      });
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
    document.body.classList.remove("product");
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirectUrl} />;
    }
    if (this._isMounted !== true) {
      return <GlobalLoader loaderState={true} />;
    }
    const {
      productPrice,
      productInfo,
      productIsExist,
      handleSubmit,
      productServiceType,
      couponCode
    } = this.state;
    // console.log(this.state.productInfo);
    // console.log(this.state.productInfo.id);

    // let backgroundImage ={ 
    //   backgroundImage: `url(${productInfo.image})`
    // }
    return (
      <React.Fragment>
        
        <GlobalLoader loaderState={false} />
        <Helmet>
          <title>{productInfo.meta_title}</title>
          <meta name="title" content={productInfo.meta_title} />
          <meta name="description" content={productInfo.meta_description} />
          <meta property="og:type" content="website"></meta>
          <meta property="og:title" content={productInfo.meta_title} />
          <meta property="og:description" content={productInfo.meta_description} />
          <meta property="og:image" content={productInfo.image} />
        </Helmet>
        <div className="product-container">
          {/* <section className="top" style={backgroundImage}>
            <div className="wrap-box">
              <div className="table">
                <div className="cell">
                  <h1 className="page-title"> {productInfo.name} </h1>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{
                      __html: productInfo.gallery_description
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </section> */}
          <section className="top">
            <div className="image-box">
                <img alt="" src={productInfo.image} />
            </div>
            <div className="text-box">
                <div className="table">
                    <div className="cell">
                        <h1 className="page-title">{productInfo.name}</h1>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{
                            __html: productInfo.gallery_description
                          }}
                        ></div>
                    </div>
                </div>
            </div>
          </section>
          <section className="product-option-container">
            <div className="wrapper">
              <div className="section-title"> Project Details </div>
            </div>
            <div className="wrapper">
              <ProductOptions
                productIsExist={productIsExist}
                productUrlName={this.props.match.params.id}
                productId={productInfo.id}
                productIsTesting={productInfo.testProduct}
                productHasCustomTemplate={productInfo.hasCustomTemplate}
                productHasAttachFile={productInfo.hasAttachFile}
                productAdditionalUploadFile={productInfo.additional_upload_file}
                producthasBothSide={productInfo.hasBothSide}
                handleSubmit={handleSubmit}
                productFieldReady={this.productFieldReady}
                productPrice={this.productPrice}
                productServiceType={this.productCheckBoxList}
                // templateIdFromUrl={templateId}
                couponCode={couponCode}
              />
              <ProductSidebar
                productPrice={productPrice}
                productInfo={productInfo}
                productUrlName={this.props.match.params.id}
                handleSubmit={this.callbackSubmit}
                productServiceType={productServiceType}
                couponCode = {couponCode}
              />
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }
}

export default Product;
