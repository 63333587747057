import React, { Component } from "react";

import AccountSidebar from "../sidebar";
// import CrediteAppliedView from "./crediteApplied";
import CreditsListView from "./crediteList";
import CrediteNotAppliedView from "./crediteNotApplied";
import { authHeader } from "../../../_helpers";
import axios from "axios";
import MetaTags from "../../metaTags"

const apiUrl = process.env.REACT_APP_API;

class AccountCredits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allow: true,
      waitingForApprove: false,
      hasCredits: false
    };
  }
  checkCredite = async () => {
    let requestUrl = `/users/hasCredits`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      this.setState({
        hasCredits: data.payload.hasCredits,
        waitingForApprove: data.payload.waitingForApprove,
        _isCreditPageReady: true
      });
      // console.log(data);
    }
  };
  componentDidMount() {
    document.body.classList.add("account", "account-credits");
    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let CREDITS;
    
    if(userPermission) {
      CREDITS = userPermission.find(
        opt => opt.key === 'CREDITS'
      )
    }
    if(currentUser.isMember === false || (currentUser.isMember === true && CREDITS !== undefined)){
      this.checkCredite();
    }else {
      window.location = "/account/information";
    }
  }
  componentWillUnmount() {
    document.body.classList.remove("account", "account-credits");
  }
  render() {
    let isCreditPageReady = false;
    let { hasCredits, waitingForApprove, _isCreditPageReady } = this.state;
    if (_isCreditPageReady === true) {
      isCreditPageReady = true;
    }
    return (
      <React.Fragment>
        <MetaTags/>
        <div className="account-container account-credits-container">
          {/* <div className="wrapper">
            <h1 className="page-title">Credits</h1>
          </div> */}
          <div className="wrapper">
            <AccountSidebar />
            <div className="credits-container main-box">
              <h1 className="page-title">Credits</h1>
              {!isCreditPageReady ? (
                <div className="main-wrap">
                  <div id="loader" className="loader noBG show circle">
                    <svg
                    width="200px"
                    height="200px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    className="lds-rolling"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      ng-attr-stroke="{{config.color}}"
                      ng-attr-stroke-width="{{config.width}}"
                      ng-attr-r="{{config.radius}}"
                      ng-attr-stroke-dasharray="{{config.dasharray}}"
                      stroke="#B8BE14"
                      strokeWidth="2"
                      r="30"
                      strokeDasharray="141.37166941154067 49.12388980384689"
                      transform="rotate(159.346 50 50)"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1.4s"
                        begin="0s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </circle>
                  </svg>
                  </div>
                </div>
              ) : hasCredits ? (
                <div className="main-wrap">
                  <CreditsListView />
                </div>
              ) : waitingForApprove ? (
                <div className="no-result-box">
                  <div className="text">Your documents are under moderation, our team will contact you soon.</div>
                </div>
              ) : (
                <CrediteNotAppliedView />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AccountCredits;
