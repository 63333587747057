import React, { Component } from "react";
import axios from "axios";
import { authHeader } from "../../_helpers";
import { Redirect } from "react-router-dom";
import GlobalLoader from "../globalLoader";

const apiUrl = process.env.REACT_APP_API;

class FilePreparing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      fileIsExist: false,
      redirectUrl: "",
    };
    this.checkIfExist = this.checkIfExist.bind(this);
    this.downloadfile = this.downloadfile.bind(this);
  }
  componentDidMount (){
    document.body.classList.add("file-preparing");
    window.scroll(0, 0);
    this.checkIfExist()
  }

  checkIfExist = async () => {
    let requestUrl = `/orderdetails/checkDocVersionId/${this.props.match.params.orderId}/${this.props.match.params.docVersionId}`;
    let postData = {
      type: this.props.match.params.fileType === 'pdf' ? 'application/pdf' : this.props.match.params.fileType
    };

    let res = await axios.post(apiUrl + requestUrl , postData , {
      headers: authHeader()
    });
 
    let {data} = res

    if(data.error === null) {
      if(data.payload.success) {
        this.setState({
          fileIsExist: true
        },()=>{
          this.downloadfile();
        });
       
      }else {
        this.setState({
          redirect: true,
          redirectUrl: "/not-found"
        });
      }
      
      
    }else {
      this.setState({
        redirect: true,
        redirectUrl: "/not-found"
      });
    }
  }

  downloadfile() {
    let fileUrl = `${apiUrl}/orderdetails/downloadfile/${this.props.match.params.docVersionId}/${this.props.match.params.directDownloading}`
    var element = document.createElement('a');
    element.setAttribute('href', fileUrl);
  
    element.style.display = 'none';
    document.body.appendChild(element);
  
    element.click();
  
    document.body.removeChild(element);

  }

  // downloadfile = async() =>  {
  //   let fileUrl = `${apiUrl}/orderdetails/downloadfile/${this.props.match.params.docVersionId}`

  //     let res = await axios.get(fileUrl, {
  //       headers: {'X-Requested-With': 'XMLHttpRequest'},
  //     });

  //     function download(filename, data) {
  //       var element = document.createElement('a');
  //       element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data));
      
  //       element.style.display = 'none';
  //       document.body.appendChild(element);
      
  //       element.click();
      
  //       document.body.removeChild(element);
    
    
  //     }
  //     let {data} = res 

  //     if(data) {
  //       download('',data)
  //     }
  // }
  componentWillUnmount() {
    document.body.classList.remove("file-preparing");
  }

  render() {
    let {redirect, redirectUrl, fileIsExist} = this.state
    if (redirect) {
      return <Redirect to={redirectUrl} />;
    }
    if (fileIsExist !== true) {
      return <GlobalLoader loaderState={true} />;
    }
    return (
      <div className="file-preparing-container">
        <div className="wrapper">
          <div className="table">
            <div className="cell">
              <div className="title">
                Download File
              </div>
              <div className="text">
                Please wait, we are preparing your file.
              </div>
              <div id="horizontal-loader">
                <div id="horizontal-loader-1" className="horizontal-loader"></div>
                <div id="horizontal-loader-2" className="horizontal-loader"></div>
                <div id="horizontal-loader-3" className="horizontal-loader"></div>
                <div id="horizontal-loader-4" className="horizontal-loader"></div>
                <div id="horizontal-loader-5" className="horizontal-loader"></div>
                <div id="horizontal-loader-6" className="horizontal-loader"></div>
                <div id="horizontal-loader-7" className="horizontal-loader"></div>
                <div id="horizontal-loader-8" className="horizontal-loader"></div>
              </div>
              <div className="text bold">
                Don’t leave this page until download starts
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilePreparing;
