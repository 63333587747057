import React, { Component } from "react";

import CreditsSearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import axios from "axios";
const apiUrl = process.env.REACT_APP_API;
let moment = require("moment");

class CreditsListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pp: "15",
      searchValue: "",
      creditList: {},
      availableBalance: "",
      creditLimit: ""
    };
    this.getCreditsList = this.getCreditsList.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  _isMounted = false;
  _isCreditsReady = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getCreditsList(searchValue, start, end, 1, reset);
  }

  getCreditsList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ memberList: [] });
    } else {
    }
    let requestUrl = `/credits`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.advancedSearch = searchValue;
    }

    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isCreditsReady = true;
      //   console.log(data);
      let creditList = data.payload.credits;
      // // let pp = response.data.result.offset;
      let total = data.payload.count;
      currentComponent.setState({
        total,
        activePage: pageNumber,
        creditList,
        availableBalance: data.payload.availableBalance,
        creditLimit: data.payload.creditLimit
      });
    }
  };

  componentWillUnmount() {
    this._isCreditsReady = false;
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getCreditsList(searchValue, start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  handleSearchValue = childData => {

    this.setState({
      searchValue: childData
    });
    this.getCreditsList(childData, 0, this.state.pp, 1, false);
  };

  render() {
    if (this._isCreditsReady !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {
      pp,
      total,
      activePage,
      creditList,
      availableBalance,
      creditLimit,
      searchValue
    } = this.state;

    return (
      <React.Fragment>
        <div className="container-header">
          <CreditsSearchView searchValue={this.handleSearchValue} />
          <div className="balance-box">
            <div className="item">
              <div className="name">Available Balance</div>
              <div className="price">AED {availableBalance.toFixed(2)}</div>
            </div>
            <div className="item">
              <div className="name">Credit Limit</div>
              <div className="price">AED {creditLimit.toFixed(2)}</div>
            </div>
          </div>
        </div>
        {creditList.length > 0 ? (
          <div className="credits-list-box table-grid">
            <div className="table-row table-head">
              <div className="table-cell">
                <div className="text">Product</div>
              </div>
              <div className="table-cell">
                <div className="text">Type</div>
              </div>
              <div className="table-cell">
                <div className="text">Amount</div>
              </div>
              <div className="table-cell">
                <div className="text">Payment Date</div>
              </div>
              <div className="table-cell">
                <div className="text">Due Date</div>
              </div>
            </div>

            {creditList.map((item, key) => {
              return (
                <div className="table-row" key={key}>
                  <div className="table-cell">
                    <div className="text">
                      <div>Product</div>
                      <div>{item.product}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Type</div>
                      <div>{item.type}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Amount</div>
                      <div>AED {item.amount.toFixed(2)}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Payment Date</div>
                      <div>{moment(item.createdAt, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                    </div>
                  </div>
                  <div className="table-cell">
                    <div className="text">
                      <div>Due Date</div>
                      <div>{moment(item.endDate, "YYYY-MM-DD").format("ddd, MMM D")}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="no-result-box">
            <div className="text">
              {searchValue ? `No result for: ${searchValue}` : `No Credits`}
            </div>
          </div>
        )}

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default CreditsListView;
