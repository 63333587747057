import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import MaintenanceView from "./maintenanceView";
import { Provider } from "react-redux";
import { store } from "./_helpers";
import axios from "axios";
import { authHeader } from "./_helpers";
import Fingerprint2 from "fingerprintjs2";
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: 'GTM-T9RN5GB'
};
const devPermission = JSON.parse(localStorage.getItem('devPermission'))
const apiUrl = process.env.REACT_APP_API;
const appType = process.env.REACT_APP_TYPE;
const isReachable = require("is-reachable");
const url = process.env.REACT_APP_API_DOMAIN;

let currentUser = JSON.parse(localStorage.getItem('user'))
let options = {};

let fingerPrint = localStorage.getItem('fingerPrint');
let showFirebasToken = false
if(devPermission !== null && devPermission.showFirebasToken === true) {
  showFirebasToken = devPermission.showFirebasToken;
}
Fingerprint2.get(options, function(components) {
  let values = components.map(function(component) {
    return component.value;
  });
  let murmur = Fingerprint2.x64hash128(values.join(""), 31);
  if(!fingerPrint && currentUser) {
    localStorage.setItem('fingerPrint' , murmur)
  }
});

if(appType && appType === 'prod') {
  TagManager.initialize(tagManagerArgs)
}

function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

if (isPushNotificationSupported() && currentUser) {
  window.firebase.initializeApp({
    apiKey: "AIzaSyDcJtN0rK1lA69WlaC_-2gq9L-v1JcQ3Y8",
    authDomain: "flexoeasy-d2619.firebaseapp.com",
    databaseURL: "https://flexoeasy-d2619.firebaseio.com",
    projectId: "flexoeasy-d2619",
    storageBucket: "flexoeasy-d2619.appspot.com",
    messagingSenderId: "627878665831",
    appId: "1:627878665831:web:9d8af53eaaa5d87893bec2",
    measurementId: "G-WCR7BKY7V1"
  });

  const messaging = window.firebase.messaging();


  messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
      
      console.log('Token refreshed.');
      setTokenSentToServer(false);
      sendTokenToServer(refreshedToken);
      resetUI();
    }).catch((err) => {
      console.log('Unable to retrieve refreshed token ', err);
    });
  });


  messaging.onMessage((payload) => {
    appendMessage(payload);
  });


  function resetUI() {
    messaging.getToken().then((currentToken) => {
      if (currentToken) {
        if (!isTokenSentToServer()) {
          sendTokenToServer(currentToken);
        }
        if(showFirebasToken === true) {
          console.log( "Firebase TOKEN      ->>>>>>>     ", currentToken)
        }
      } else {
      
        setTokenSentToServer(false);
      }
    }).catch((err) => {
      if(showFirebasToken === true) {
        console.log('An error occurred while retrieving token. ', err);
      }
      
      setTokenSentToServer(false);
    });
  }

  async function sendTokenToServer(currentToken) {
    if (!isTokenSentToServer()) {
      console.log('Sending token to server...');
      
      let requestUrl = `/pushnotifications/update`;
        
        let memberData = {
          deviceId:localStorage.getItem('fingerPrint'),
          token:currentToken
        };
        let res = await axios.post(apiUrl + requestUrl, memberData, {
          headers: authHeader()
        });
        let { data } = res;
        if (data.error === null) {
          setTokenSentToServer(true);
        }

    } else {
      console.log('Token already sent to server so won\'t send it again ' +
          'unless it changes');
    }

  }

  function isTokenSentToServer() {
    return window.localStorage.getItem('sentToServer') === '1';
  }

  function setTokenSentToServer(sent) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
  }

  function appendMessage(payload) {
    let title = payload.notification.title !== undefined ? payload.notification.title : ''
    new Notification(title,payload.notification);
  }


  resetUI();
}
(async () => {
  if (await isReachable(url)) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("flexoeasy-app")
    );
    serviceWorker.unregister();
  } else {
    ReactDOM.render(
      <Provider store={store}>
        <MaintenanceView />
      </Provider>,
      document.getElementById("flexoeasy-app")
    );
    serviceWorker.unregister();
  }
})();
