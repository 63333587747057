import React, { Component } from "react";
// import Select from "react-dropdown-select";

import { authHeader } from "../../_helpers";
import axios from "axios";
import validator from "validator";
import FormValidator from "../validations";
import ReactModal from "react-modal";
import { Scrollbars } from "react-custom-scrollbars";
import Select from "react-select";

const apiUrl = process.env.REACT_APP_API;
class BillingInformationStep extends Component {
  constructor(props) {
    super(props);
    let globalStatus = props.status;
    let orderId = props.orderId;
    let notPhysical = props.notPhysical;
    let notPhysicalState = props.notPhysicalState;
    let defaultBillingAddress = props.defaultBillingAddress;
    this.state = {
      globalStatus,
      notPhysicalState,
      defaultBillingAddress,
      orderId,
      notPhysical,
      checkoutBilling: {
        firstName: "",
        middleName: "",
        lastName: "",
        company: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phoneNumber: "",
        fax: "",
        saveToAddressBook: false,
        id: "",
        orderId: ""
      },
      isEdit: false,
      errors: [],
      errorClasses: [],
      countries: [],
      billingResponse: {},
      addressPopup: false,
      activeClass: false,
      _isMounted: false
    };
    this.handleRadioButton = this.handleRadioButton.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.countryOptions = this.countryOptions.bind(this);
    this.countryChange = this.countryChange.bind(this);
    this.handleDropDownOpenClose = this.handleDropDownOpenClose.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmitBilling = this.handleSubmitBilling.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.getAddresses = this.getAddresses.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.selectAddress = this.selectAddress.bind(this);
    this.newAddress = this.newAddress.bind(this);
  }
  componentDidMount() {
    this.getCountryList();
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let globalStatus = nextProps.status;
    this.setState({
      globalStatus
    });
  }
  handleValidation() {
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      {
        field: "firstName",
        method: validator.isEmpty,
        validWhen: false,
        message: "First Name field is required."
      },
      {
        field: "lastName",
        method: validator.isEmpty,
        validWhen: false,
        message: "Last Name field is required."
      },
      {
        field: "address",
        method: validator.isEmpty,
        validWhen: false,
        message: "Address field is required."
      },
      {
        field: "city",
        method: validator.isEmpty,
        validWhen: false,
        message: "City field is required."
      },
      {
        field: "phoneNumber",
        method: validator.isEmpty,
        validWhen: false,
        message: "Phone Number field is required."
      },
      {
        field: "zip",
        method: validator.isEmpty,
        validWhen: false,
        message: "Zip Code field is required."
      },
      // {
      //   field: "fax",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "Fax field is required."
      // },
      {
        field: "country",
        method: validator.isEmpty,
        validWhen: false,
        message: "Country field is required."
      }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.checkoutBilling);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }
  countryChange(val, name) {
    var selectedOptionValue = val.value;
    const checkoutBilling = this.state.checkoutBilling;

    var country = "country";
    checkoutBilling[country] = selectedOptionValue;
    this.setState({
      checkoutBilling
    });
  }
  handleDropDownOpenClose(param) {
    let target = document.getElementsByClassName("country-drop-down");
    if (param === true) {
      target[0].classList.add("open");
    } else {
      target[0].classList.remove("open");
    }
  }

  getCountryList() {
    axios.get(apiUrl + "/countries/index").then(response => {
      if (response.data.error === null) {
        let countries = response.data.payload.countries;
        this.setState(
          {
            countries,
            _isMounted: true
          },
          () => {
            this.countryOptions();
          }
        );
      }
    });
  }
  countryOptions() {
    // if (document.getElementById("shipToThisAddress")) {
    //   document.getElementById("shipToThisAddress").checked = true;
    // }
    var countryList = this.state.countries;
    var option = {};
    var sayHello = Object.keys(countryList).map(function(i) {
      option = {
        value: countryList[i].id,
        label: countryList[i].country_name
      };
      return option;
    });
    return sayHello;
  }

  handelChecked(e) {
    const checkoutBilling = this.state.checkoutBilling;
    const name = e.target.name;
    if (e.target.checked) {
      checkoutBilling[name] = true;
    } else {
      checkoutBilling[name] = false;
    }
    this.setState({
      checkoutBilling
    });
  }

  handleRadioButton(e) {
    const checkoutBilling = this.state.checkoutBilling;
    const name = e.target.id;
    checkoutBilling["shipping"] = name;
    this.setState({
      checkoutBilling
    });
  }

  handleChange(e) {
    const name = e.target.name;
    const checkoutBilling = this.state.checkoutBilling;

    const errors = this.state.errors;
    const errorClasses = this.state.errorClasses;

    if (isNaN(e.target.value) && (name === "phoneNumber")) {
      errors[name] = "Type only number";
      errorClasses[name] = "is-invalid";
      this.setState({
        errors
      });
    }else if(name === "zip"){
      let val = /^[a-z0-9]+$/i.test(e.target.value)
      errors[name] = "";
      errorClasses[name] = "";
      if(val || e.target.value === '') {
        checkoutBilling[name] = e.target.value;
        this.setState({
          checkoutBilling,
          errors,
          errorClasses
        });
      }
    } else {
      checkoutBilling[name] = e.target.value;
      errors[name] = "";
      errorClasses[name] = "";
      this.setState({
        checkoutBilling,
        errors,
        errorClasses
      });
    }
  }

  handleSubmitBilling(e,selected = false) {
    e.preventDefault();
    let { checkoutBilling, defaultBillingAddress } = this.state;
    let data = ''
    if (this.state.globalStatus === "defaultaddress" && !selected) {
      data = {
        orderId: this.state.orderId,
        countryId: defaultBillingAddress.countryId,
        city: defaultBillingAddress.city,
        firstname: defaultBillingAddress.firstname,
        lastname: defaultBillingAddress.lastname,
        middlename: defaultBillingAddress.middlename,
        company: defaultBillingAddress.company,
        fax: defaultBillingAddress.fax,
        postcode: defaultBillingAddress.postcode,
        region: defaultBillingAddress.region,
        street: defaultBillingAddress.street,
        telephone: defaultBillingAddress.telephone,
        saveToAddrBook: defaultBillingAddress.saveToAddressBook,
        shipping: defaultBillingAddress.shipping,
        type: "billing",
        addressId: null,
        isDefaultBilling: 0,
        isDefaultShipping: 0,
        useAsShipping: 0,
        deleted: 0
      };
    } else {
      if(selected) {
        data = {
          orderId: this.state.orderId,
          countryId: checkoutBilling.country,
          city: checkoutBilling.city,
          firstname: checkoutBilling.firstName,
          lastname: checkoutBilling.lastName,
          middlename: checkoutBilling.middleName,
          company: checkoutBilling.company,
          fax: checkoutBilling.fax,
          postcode: checkoutBilling.zip,
          region: checkoutBilling.state,
          street: checkoutBilling.address,
          telephone: checkoutBilling.phoneNumber,
          saveToAddrBook: checkoutBilling.saveToAddressBook,
          shipping: checkoutBilling.shipping,
          type: "billing",
          addressId: null,
          isDefaultBilling: 0,
          isDefaultShipping: 0,
          useAsShipping: 0,
          deleted: 0
        };
      }
      else if (this.handleValidation()) {
        data = {
          orderId: this.state.orderId,
          countryId: checkoutBilling.country,
          city: checkoutBilling.city,
          firstname: checkoutBilling.firstName,
          lastname: checkoutBilling.lastName,
          middlename: checkoutBilling.middleName,
          company: checkoutBilling.company,
          fax: checkoutBilling.fax,
          postcode: checkoutBilling.zip,
          region: checkoutBilling.state,
          street: checkoutBilling.address,
          telephone: checkoutBilling.phoneNumber,
          saveToAddrBook: checkoutBilling.saveToAddressBook,
          shipping: checkoutBilling.shipping,
          type: "billing",
          addressId: null,
          isDefaultBilling: 0,
          isDefaultShipping: 0,
          useAsShipping: 0,
          deleted: 0
        };
      }
    }
    axios
      .post(`${apiUrl}/checkout/steptwo`, data, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.error === null) {
          this.props.handleFunc(true);
          let billingResponse = response.data.payload;
          if (billingResponse.shipping === "shipToDifferentAddress") {
            billingResponse.differentShippingAddress = true;
          }
          this.props.getReadyAddress(billingResponse);
          this.setState({
            billingResponse,
            addressPopup: false
          });
          this.props.differentShipping(true);
          let summary = response.data.summary;
          this.props.summary(summary);
        }
      })
      .catch(err => {});
  }
  handleEdit = async e => {
    this.setState({
      isEdit: true
    });

    var orderId = e.currentTarget.getAttribute("data-orderid");
    let requestUrl = "/checkout/address?type=billing&orderId=" + orderId;
    await axios
      .get(apiUrl + requestUrl, {
        headers: authHeader()
      })
      .then(response => {
        if (response.data.error === null) {
          let data = response.data.payload.address;
          let checkoutBilling = {
            firstName: data.firstname,
            middleName: data.middlename,
            lastName: data.lastname,
            company: data.company,
            address: data.street,
            city: data.city,
            state: data.region,
            zip: data.postcode,
            country: data.countryId,
            phoneNumber: data.telephone,
            fax: data.fax,
            saveToAddressBook: false
          };
          this.setState({
            checkoutBilling
          });
          this.props.editBilling(true);
        }
      });
  };
  getAddresses = async () => {
    let orderId = this.state.orderId;
    await axios
      .get(`${apiUrl}/checkout/addressbook?orderId=${orderId}`, {
        headers: authHeader()
      })
      .then(response => {
        let addressBook = response.data.payload.addresses;
        this.setState({
          addressPopup: true,
          addressBook
        });
      });
  };
  handleCloseModal() {
    this.setState({
      addressPopup: false
    });
  }

  selectAddress(e) {
    let selectedId = parseInt(e.currentTarget.getAttribute("data-id"));
    let selectedAddress = this.state.addressBook.find(
      el => el.id === selectedId
    );
    let checkoutBilling = {
      firstName: selectedAddress.firstname,
      middleName: selectedAddress.middlename,
      lastName: selectedAddress.lastname,
      company: selectedAddress.company,
      address: selectedAddress.street,
      city: selectedAddress.city,
      state: selectedAddress.region,
      zip: selectedAddress.postcode,
      country: selectedAddress.countryId,
      phoneNumber: selectedAddress.telephone,
      fax: selectedAddress.fax,
      saveToAddressBook: false
    };
    this.setState(
      {
        checkoutBilling
      },
      () => {
        this.handleSubmitBilling(e,true);
      }
    );
  }
  newAddress() {
    this.setState({
      globalStatus: "active"
    });
  }

  render() {
    const {
      errors,
      errorClasses,
      checkoutBilling,
      billingResponse,
      globalStatus,
      addressPopup,
      addressBook,
      orderId,
      defaultBillingAddress,
    } = this.state;

    let data = this.countryOptions();

   
    if (globalStatus === "defaultaddress") {
      return addressPopup ? (
        <div className="wrapper checkout-form-wrapper form">
          <ReactModal
            isOpen={addressPopup}
            onRequestClose={this.handleCloseModal}
            ariaHideApp={false}
            shouldCloseOnOverlayClick={true}
            portalClassName="modal choose-address"
          >
            <div className="address-container">
              <div className="address-title">Select Address</div>

              <Scrollbars
                autoHeight
                autoHeightMin={100}
                autoHeightMax={260}
                renderThumbVertical={this.renderThumb}
                {...this.props}
                onUpdate={this.handleUpdate}
              >
                <div className="product-list">
                    {addressBook.length > 0 ? 
                      addressBook.map((item, key) => {
                        return (
                          <div
                            onClick={this.selectAddress}
                            className={"item "}
                            data-id={item.id}
                            key={key}
                          >
                            <div className="name">
                              <span>{item.firstname} </span>
                              <span>{item.lastname}</span>
                            </div>
                            <div className="phone">{item.telephone}</div>
                            <div className="address">{item.street}</div>
                          </div>
                        );
                      })
                      :
                      (
                        <React.Fragment>

                          <div className="no-result-box" style={{marginTop:0}}>
                            <div className="text">
                              You don't have addresses
                            </div>
                          </div>
                          <div className="button-row">
                            <div className="button" onClick={this.handleCloseModal}>
                              Close
                            </div>
                          </div>
                        </React.Fragment>
                        
                      )
                    }
                  </div>
              </Scrollbars>
            </div>
          </ReactModal>
        </div>
      
      ) : (
        <div className="wrapper default-address-box-wrapper">
          <div className="default-address-container">
            <div className="step-title">
              <span>1. </span>
              <span>Billing Information</span>
            </div>
            <div className="info-box">
              <div className="info">
                <div className="name default-address-row">
                  <span>{defaultBillingAddress.firstname} </span>
                  <span>{defaultBillingAddress.lastname}</span>
                </div>
                <div className="phone default-address-row">
                  {defaultBillingAddress.telephone}
                </div>
                <div className="address default-address-row">
                  {defaultBillingAddress.street}
                </div>
              </div>
              <div
                onClick={this.handleEdit}
                data-orderid={orderId}
                data-addressid={defaultBillingAddress.id}
                className="edit"
              >
                Edit
              </div>
            </div>
            <div className="button-row">
              <div className="item">
                <div onClick={this.getAddresses} className="select-address">
                  Select from Address Book
                </div>
              </div>
              <div className="item">
                <div onClick={this.newAddress}>Add New Address</div>
              </div>
            </div>
            <div className="button-row">
              <div className="next" onClick={this.handleSubmitBilling}>
                Next
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (globalStatus === "ready") {
      return (
        <div className="wrapper ready-box-wrapper">
          <div className="ready-container">
            <div className="info-box">
              <div className="passive-step-title">
                <span>1. </span>
                <span>Billing Information</span>
              </div>
              <div className="name ready-row">
                <span>{billingResponse.firstname} </span>
                <span>{billingResponse.lastname}</span>
              </div>
              <div className="phone ready-row">{billingResponse.telephone}</div>
              <div className="address ready-row">{billingResponse.street}</div>
            </div>
            <div
              onClick={this.handleEdit}
              data-orderid={orderId}
              data-addressid={billingResponse.id}
              className="edit"
            >
              Edit
            </div>
          </div>
        </div>
      );
    }
    if (globalStatus === "active") {
      return (
        <React.Fragment>
          {addressPopup ? (
            <ReactModal
              isOpen={addressPopup}
              onRequestClose={this.handleCloseModal}
              ariaHideApp={false}
              shouldCloseOnOverlayClick={true}
              portalClassName="modal choose-address"
            >
              <div className="address-container">
                <div className="address-title">Select Address</div>

                <Scrollbars
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax={260}
                  renderThumbVertical={this.renderThumb}
                  {...this.props}
                  onUpdate={this.handleUpdate}
                >
                  <div className="product-list">
                    {addressBook.length > 0 ? 
                      addressBook.map((item, key) => {
                        return (
                          <div
                            onClick={this.selectAddress}
                            className={"item "}
                            data-id={item.id}
                            key={key}
                          >
                            <div className="name">
                              <span>{item.firstname} </span>
                              <span>{item.lastname}</span>
                            </div>
                            <div className="phone">{item.telephone}</div>
                            <div className="address">{item.street}</div>
                          </div>
                        );
                      })
                      :
                      (
                        <React.Fragment>

                          <div className="no-result-box" style={{marginTop:0}}>
                            <div className="text">
                              You don't have addresses
                            </div>
                          </div>
                          <div className="button-row">
                            <div className="button" onClick={this.handleCloseModal}>
                              Close
                            </div>
                          </div>
                        </React.Fragment>
                        
                      )
                    }
                  </div>
                </Scrollbars>
              </div>
            </ReactModal>
          ) : (
            <div className="wrapper checkout-form-wrapper form">
              {!this.state._isMounted ? (
                <div id="loader" className="loader circle">
                  <svg
                    width="200px"
                    height="200px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                    className="lds-rolling"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      fill="none"
                      ng-attr-stroke="{{config.color}}"
                      ng-attr-stroke-width="{{config.width}}"
                      ng-attr-r="{{config.radius}}"
                      ng-attr-stroke-dasharray="{{config.dasharray}}"
                      stroke="#B8BE14"
                      strokeWidth="2"
                      r="30"
                      strokeDasharray="141.37166941154067 49.12388980384689"
                      transform="rotate(159.346 50 50)"
                    >
                      <animateTransform
                        attributeName="transform"
                        type="rotate"
                        calcMode="linear"
                        values="0 50 50;360 50 50"
                        keyTimes="0;1"
                        dur="1.4s"
                        begin="0s"
                        repeatCount="indefinite"
                      ></animateTransform>
                    </circle>
                  </svg>
                </div>
              ) : (
                <React.Fragment>
                  <div className="step-title">
                    <span>1. </span>
                    <span>Billing Information</span>
                  </div>
                  <div className="row row-3 clear">
                    <div className="item">
                      <div className="label">First Name</div>
                      <input
                        value={checkoutBilling.firstName}
                        type="text"
                        name="firstName"
                        onChange={this.handleChange}
                        className={errorClasses.firstName}
                      />
                      <div className="invalid-feedback">{errors.firstName}</div>
                    </div>
                    <div className="item">
                      <div className="label">Middle Name (optional)</div>
                      <input
                        value={checkoutBilling.middleName}
                        type="text"
                        name="middleName"
                        onChange={this.handleChange}
                        className={errorClasses.middleName}
                      />
                      <div className="invalid-feedback">
                        {errors.middleName}
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Last Name</div>
                      <input
                        value={checkoutBilling.lastName}
                        type="text"
                        name="lastName"
                        onChange={this.handleChange}
                        className={errorClasses.lastName}
                      />
                      <div className="invalid-feedback">{errors.lastName}</div>
                    </div>
                  </div>
                  <div className="row clear">
                    <div className="item">
                      <div className="label">COMPANY (OPTIONAL)</div>
                      <input
                        value={
                          checkoutBilling.company !== ""
                            ? checkoutBilling.company
                            : ""
                        }
                        type="text"
                        name="company"
                        onChange={this.handleChange}
                        className={errorClasses.company}
                      />
                      <div className="invalid-feedback">{errors.company}</div>
                    </div>
                  </div>
                  <div className="row clear">
                    <div className="item">
                      <div className="label">ADDRESS</div>
                      <input
                        value={checkoutBilling.address}
                        type="text"
                        name="address"
                        onChange={this.handleChange}
                        className={errorClasses.address}
                      />
                      <div className="invalid-feedback">{errors.address}</div>
                    </div>
                  </div>
                  <div className="row row-2 clear">
                    <div className="item">
                      <div className="label">CITY</div>
                      <input
                        value={checkoutBilling.city}
                        type="text"
                        name="city"
                        className={errorClasses.city}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.city}</div>
                    </div>
                    <div className="item">
                      <div className="label">STATE/PROVINCE</div>
                      <input
                        value={
                          checkoutBilling.state !== ""
                            ? checkoutBilling.state
                            : ""
                        }
                        name="state"
                        type="text"
                        className={errorClasses.state}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.state}</div>
                    </div>
                  </div>
                  <div className="row row-2 clear">
                    <div className="item">
                      <div className="label">ZIP/POSTAL CODE</div>
                      <input
                        value={checkoutBilling.zip}
                        type="text"
                        name="zip"
                        onChange={this.handleChange}
                        className={errorClasses.zip}
                      />
                      <div className="invalid-feedback">{errors.zip}</div>
                    </div>
                    <div className="item">
                      <div className="label">COUNTRY</div>

                      <Select
                        classNamePrefix="react-select"
                        isMulti={false}
                        isSearchable={false}
                        value={[
                          data.find(
                            opt => opt.value === Number(checkoutBilling.country)
                          )
                        ]}
                        name="country"
                        options={data}
                        onChange={(newValue, actionMeta) =>
                          this.countryChange(newValue, actionMeta)
                        }
                        className={
                          errorClasses.country +
                          " react-select country-drop-down"
                        }
                        placeholder=""
                      />
                      <div className="invalid-feedback">{errors.country}</div>
                    </div>
                  </div>
                  <div className="row row-2 clear">
                    <div className="item">
                      <div className="label">PHONE</div>
                      <input
                        value={checkoutBilling.phoneNumber}
                        type="text"
                        name="phoneNumber"
                        className={errorClasses.phoneNumber}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">
                        {errors.phoneNumber}
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">FAX</div>
                      <input
                        value={checkoutBilling.fax}
                        type="text"
                        name="fax"
                        className={errorClasses.fax}
                        onChange={this.handleChange}
                      />
                      <div className="invalid-feedback">{errors.fax}</div>
                    </div>
                  </div>
                  <div className="row clear">
                    <div className="item">
                      <input
                        className="custom-checkbox"
                        id="saveToAddressBook"
                        type="checkbox"
                        name="saveToAddressBook"
                        onChange={this.handelChecked}
                      />
                      <label
                        htmlFor="saveToAddressBook"
                        className="custom-lable"
                      >
                        Save to Address Book
                      </label>
                    </div>
                  </div>
                  {!this.state.notPhysicalState ? (
                    <div className="row clear">
                      <div className="item checkbox">
                        <input
                          onChange={this.handleRadioButton}
                          className="css-checkbox"
                          id="shipToThisAddress"
                          type="radio"
                          name="shipping"
                          defaultChecked={true}
                        />
                        <label
                          className="css-label"
                          htmlFor="shipToThisAddress"
                        >
                          Ship to this Address
                        </label>
                      </div>
                      <div className="item checkbox">
                        <input
                          onChange={this.handleRadioButton}
                          className="css-checkbox"
                          id="shipToDifferentAddress"
                          type="radio"
                          name="shipping"
                        />
                        <label
                          className="css-label"
                          htmlFor="shipToDifferentAddress"
                        >
                          Ship to Different Address
                        </label>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row clear">
                    <div className="item">
                      <div
                        onClick={this.getAddresses}
                        className="select-address"
                      >
                        Select from Address Book
                      </div>
                    </div>
                  </div>
                  <div className="button-row">
                    <div onClick={this.handleSubmitBilling}>Next</div>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </React.Fragment>
      );
    }
  }
}
export default BillingInformationStep;