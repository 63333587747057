import React, { Component } from "react";
import GooglePicker from "react-google-picker";

class Googlepicker extends Component {
  constructor(props) {
    super(props);
    this.onSuccess = this.onSuccess.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onToken = this.onToken.bind(this);
    this.state = {
      selectedItem: []
    };
  }

  onSuccess(files) {
    this.props.onSuccess(files);
  }
  onToken(token) {
    this.props.onToken(token);
  }
  onCancel(e) {}
  render() {
    return (
      <GooglePicker
        clientId={
          "627878665831-367fi4iuvklf5aa8stvk4tupp35ua27j.apps.googleusercontent.com"
        }
        developerKey={"AIzaSyAkaEJanpAd1B8c9qqjcwfsdS2bJ3EcPg4"}
        scope={["https://www.googleapis.com/auth/drive.readonly"]}
        onChange={data => this.onSuccess(data)}
        onAuthenticate={token => this.onToken(token)}
        onAuthFailed={data => console.log("on auth failed:", data)}
        multiselect={true}
        navHidden={true}
        authImmediate={false}
        mimeTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/zip', 'application/pdf']}
        viewId={"DOCS"}
      >
        <div id="google">
          <div className="icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="30"
              viewBox="0 0 41 30"
            >
              <g transform="translate(-320 -789)">
                <rect
                  width="41"
                  height="30"
                  transform="translate(320 789)"
                  fill="none"
                />
                <g transform="translate(327 792)">
                  <g>
                    <path
                      d="M-5227.531,3527.157l-8.667-15.013h-9.494l8.667,15.013Z"
                      transform="translate(5254.359 -3512.145)"
                      fill="#ffd34e"
                    />
                    <path
                      d="M-5284.667,3745.926H-5302l-4.747,8.221h17.335Z"
                      transform="translate(5311.495 -3730.913)"
                      fill="#007ee5"
                    />
                    <path
                      d="M-5371.993,3512.145l-8.667,15.013,4.746,8.221,8.668-15.013Z"
                      transform="translate(5380.66 -3512.145)"
                      fill="#0dab62"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
          <div className="name">Google Drive</div>
        </div>
      </GooglePicker>
    );
  }
}
export default Googlepicker;
