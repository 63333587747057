import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import axios from "axios";
import { authHeader } from "../../_helpers";

let moment = require("moment");

const apiUrl = process.env.REACT_APP_API;

class OrderSidebar extends Component {
  constructor(props) {
    super(props);

    let orderInfo = props.orderInfo;
    this.state = {
      showModal: false,
      progresStep: orderInfo.step,
      progresStatusList: {},
      currentStepInfo: {
        name: "",
        description: ""
      },
      orderInfo,
      showTrackingModal:false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.showTrackingModal = this.showTrackingModal.bind(this);
    this.hideTrackingModal = this.hideTrackingModal.bind(this);
    this.getProgressStauses = this.getProgressStauses.bind(this);
    
  }
  componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize.bind(this));

  }

  resize() {
    if(window.innerWidth <= 767) {
      this.setState({mobileView: true});
    }else {
      
      this.setState({mobileView: false});
    }
  }
  componentWillUnmount() {}
  getProgressStauses = async e => {
    ///00002_0000000426
    let lodaer = document.getElementById("progress-loader");
    lodaer.classList.add("show");
    let requestUrl = `/orderstatuses/getKeyForShowList/${this.props.orderId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      //   console.log(data);

      this.setState(
        {
          progresStatusList: data.payload.statuses,
          progresStep: data.payload.currentStep.step,
          currentStepInfo: data.payload.currentStep
        },
        () => {
          this.handleOpenModal();
          if (lodaer.classList.contains("show")) {
            lodaer.classList.remove("show");
          }
        }
      );
    } else {
      // window.location = "/not-found";
    }
  };

  progressBarActive() {
    let { progresStep, progresStatusList, mobileView} = this.state;
    if(mobileView) {
      setTimeout(() => {
        let progressBarActiveHeight = "";
  
        let progresStatusListLenght = progresStatusList.length;
  
        let activeBox = document.getElementById("progress-active");
  
        let activeStep = document.getElementById(`progress-item-${progresStep}`);
        let progressBarHeight = document.getElementById(`progress-bar`)
          .offsetHeight;
  
        if (progresStatusListLenght === progresStep) {
          progressBarActiveHeight =
            ((activeStep.offsetTop + activeStep.offsetHeight) /
              progressBarHeight) *
              100 + 
            "%";
        } else {
          progressBarActiveHeight =
            ((activeStep.offsetTop + activeStep.offsetHeight / 2) /
            progressBarHeight) *
              100 +
            "%";
        }
        activeBox.setAttribute("style", `height: ${progressBarActiveHeight}`);
      }, 300);
    }else {
      setTimeout(() => {
        let progressBarActiveWidth = "";
  
        let progresStatusListLenght = progresStatusList.length;
  
        let activeBox = document.getElementById("progress-active");
  
        let activeStep = document.getElementById(`progress-item-${progresStep}`);
        let progressBarWidth = document.getElementById(`progress-bar`)
          .offsetWidth;
  
        if (progresStatusListLenght === progresStep) {
          progressBarActiveWidth =
            ((activeStep.offsetLeft + activeStep.offsetWidth) /
              progressBarWidth) *
              100 +
            "%";
        } else {
          progressBarActiveWidth =
            ((activeStep.offsetLeft + activeStep.offsetWidth / 2) /
              progressBarWidth) *
              100 +
            "%";
        }
        activeBox.setAttribute("style", `width: ${progressBarActiveWidth}`);
      }, 300);
    }
    
  }

  handleOpenModal(e) {
    this.setState(
      {
        showModal: true
      },
      () => {
        this.progressBarActive();
      }
    );
  }
  handleCloseModal() {
    this.setState({
      showModal: false
    });
  }
  showTrackingModal() {
    this.setState({
      showTrackingModal:true
    })
  }
  hideTrackingModal() {
    this.setState({
      showTrackingModal:false
    })
  }
  render() {
    let {
      showModal,
      progresStep,
      progresStatusList,
      orderInfo,
      currentStepInfo,
      showTrackingModal
    } = this.state;
    // let progresStatusListLenght = progresStatusList.length;
    let progresBar = {
      width: (100 / 5) * progresStep + "%"
    };

    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let groupId =localStorage.getItem('groupId')
    console.log("orderInfo.customerName", orderInfo.customerName)
    let REORDER;
    let CHECKOUT;
    if(userPermission) {
      REORDER = userPermission.find(
        opt => opt.key === 'REORDER'
      )
      CHECKOUT = userPermission.find(
        opt => opt.key === 'CHECKOUT'
      )
    }
    return (
      <React.Fragment>
        <div id="progress-loader" className="loader fixed circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal order-progress"
        >
          <div className="title">{currentStepInfo.name}</div>
          <div className="text">{
            currentStepInfo.additionalDescription !== '' ? 
              currentStepInfo.additionalDescription
            : 
              currentStepInfo.description
          }</div>
          <div className="progress-bar-wrap">
            <div className="progress-bar" id="progress-bar">
              <span className="active" id="progress-active"></span>
              <span className="passive"></span>
            </div>
            <div className="progress-bar-status-wrap">
              {progresStatusList.length > 0
                ? progresStatusList.map((item, key) => {
                    let active = "active";

                    if (progresStep < item.step) {
                      active = "";
                    }
                    return (
                      <div
                        className={`item ${active}`}
                        key={key}
                        id={`progress-item-${item.step}`}
                      >
                        <div className="title">{item.keyForShow}</div>
                        {/* <div className="date">
                    {moment("2020-02-20T10:32:48.000Z", "YYYY-MM-DD").format(
                      "ddd, MMM D"
                    )}
                  </div> */}
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
        </ReactModal>

        <ReactModal
          isOpen={showTrackingModal}
          onRequestClose={this.hideTrackingModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={true}
          portalClassName="modal order-tracking"
        >
          <div className="title">Tracking Info</div>
          <div className="text">Final price will be provided after Processing step</div>
          <div className="text-box">
            {orderInfo.trackingInfo !== null ? (
              <React.Fragment>
                <div className="item">
                  <div className="title">Career name</div>
                  <div className="text">{orderInfo.trackingInfo.currierName}</div>
                </div>
                <div className="item">
                  <div className="title">Tracking Number</div>
                  <div className="text">{
                    orderInfo.trackingInfo.trackingNumberUrl !== '' ? (
                        <a href={orderInfo.trackingInfo.trackingNumberUrl} rel="noopener noreferrer" target="_blank">{orderInfo.trackingInfo.trackingNumber}</a>
                      )
                    :
                      orderInfo.trackingInfo.trackingNumber
                  }</div>
                </div>
              </React.Fragment>
            ):('')}
          </div>
        </ReactModal>
        <div className="sticky-box-container left">
          <div className="sticky-box">
            <Link className="back" to="/dashboard">
              <div className="icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.892"
                  height="10.098"
                  viewBox="0 0 12.892 10.098"
                >
                  <path
                    d="M6.446-11.55H-4.29l3.817-3.707-.77-.792L-6.446-11l5.2,5.049.77-.792L-4.29-10.45H6.446Z"
                    transform="translate(6.446 16.049)"
                    fill="#B8BE14"
                  />
                </svg>
              </div>
              <div className="text">Back to Dashboard</div>
            </Link>
            <div className="title">{orderInfo.projectName}</div>

            <div className="text">
              <span>ID: </span>
              <span>{orderInfo.id}</span>
            </div>

            <div className="text">
              <span>Product: </span>
              <span>{orderInfo.productName}</span>
            </div>

            <div className="text">
              <span>Created: </span>
              <span>
                {moment(orderInfo.createdAt, "YYYY-MM-DD").format("ddd, MMM D")}
              </span>
            </div>

            <div className="text">
              <span>Modified: </span>
              <span>
                {moment(orderInfo.updatedAt, "YYYY-MM-DD").format("ddd, MMM D")}
              </span>
            </div>
            {(groupId === "3" && orderInfo.customerName !== undefined) ? <div className="text">
              <span>Customer: </span>
              <span>
                {orderInfo.customerName}
              </span>
            </div>: null}
            {progresStep === 4 || progresStep === 5 ? (
              orderInfo.isTesting ? (
                <div className="text">
                  <span>Physical Plate: </span>
                  <span>AED {orderInfo.physicalPrice.toFixed(2)}</span>
                </div>
              ) : (
                <React.Fragment>
                  <div className="text">
                    <span>Physical Plate: </span>
                    <span>AED {orderInfo.physicalPrice.toFixed(2)}</span>
                  </div>

                  <div className="text">
                    <span>Len File: </span>
                    <span>AED {orderInfo.lenPrice.toFixed(2)}</span>
                  </div>

                  <div className="text">
                    <span>PDF File: </span>
                    <span>AED {orderInfo.pdfPrice.toFixed(2)}</span>
                  </div>
                </React.Fragment>
              )
            ) : (
              <div className="text">
                <span>Price: </span>
                <span>AED {orderInfo.price.toFixed(2)}</span>
              </div>
            )}

            <div className="status-bar-box">
              <div className="status-bar" onClick={this.getProgressStauses}>
                <span className="passive"></span>
                <span className="active" style={progresBar}></span>
              </div>
              <div className="status-name" onClick={this.getProgressStauses}>
                {orderInfo.statusName}
              </div>
            </div>
            
            {orderInfo.allowPay || orderInfo.allowReorder || orderInfo.isTracking ? 
              orderInfo.allowPay ? 
                currentUser.isMember === false || (currentUser.isMember === true && CHECKOUT !== undefined) ? (
                  <Link to={`/checkout/${this.props.orderId}`} className="button">
                    Pay
                  </Link>
                ):('')
              : 
                orderInfo.allowReorder ? 
                  currentUser.isMember === false || (currentUser.isMember === true && REORDER !== undefined) ?
                    (<Link
                      to={`/order/${this.props.orderId}/reorder`}
                      className="button"
                    >
                      Reorder
                    </Link>):('')
                : 
                  orderInfo.trackingInfo !== null ? (
                    <div className="button" onClick={this.showTrackingModal}>Track order</div>
                  ) : (
                    <div className="button disabled">Pay</div>
                  )
             : (
              <div className="button disabled">Pay</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderSidebar;
