import React, { Component } from "react";

import MembersSearchView from "./searchBox";
import Pagination from "react-js-pagination";
import { authHeader } from "../../../_helpers";
import ReactModal from "react-modal";
import FormValidator from "../../validations";
import axios from "axios";

import { Scrollbars } from "react-custom-scrollbars";

const apiUrl = process.env.REACT_APP_API;


class MembersListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderList: {},
      pp: "15",
      searchValue: "",
      member: {
        firstName: "",
        lastName: "",
        email: "",
        memberId: "",
        roleId: ""
      },
      memberNotChoosedMessage:'',
      availableMember: {},
      memberList: {},
      showModal: false,
      isEdit: false,
      memberId: "",
      errors: [],
      errorClasses: [],
      rolesList: {},
      thankYou: false,
      thankYouTitle: "",
      thankYouText: ""
    };
    this.getMembersList = this.getMembersList.bind(this);
    this.getAvailableMember = this.getAvailableMember.bind(this);
    // this.getRoles = this.getRoles.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.handelMemberChecked = this.handelMemberChecked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handelChecked = this.handelChecked.bind(this);
    this.handleEditMember = this.handleEditMember.bind(this);
    this.handleAddMember = this.handleAddMember.bind(this);

    this.renderThumb = this.renderThumb.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  _isMounted = false;
  _isRolesReady = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    // this.setState({
    //   orderStatus
    // });
    // this.getOrderList("", orderStatus, 0, this.state.pp, 1, true);
  }

  componentDidMount() {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    this.getMembersList(searchValue, start, end, 1, reset);
  }

  getMembersList = async (
    searchValue = "",
    start = "0",
    end,
    pageNumber,
    reset = true
  ) => {
    if (reset === true) {
      this._isMounted = false;

      this.setState({ memberList: [] });
    } else {
    }
    let requestUrl = `/orders/allMembers/${this.props.orderId}`;
    let currentComponent = this;
    const queryParams = {};

    if (searchValue !== undefined) {
      queryParams.advancedSearch = searchValue;
    }
    // if (status !== undefined) {
    //   queryParams.statusId = status;
    // }
    queryParams._start = start;
    queryParams._end = end;

    let res = await axios.get(apiUrl + requestUrl, {
      params: queryParams,
      headers: authHeader()
    });

    let { data } = res;
    if (data.error === null) {
      currentComponent._isRolesReady = true;
      currentComponent._isMounted = true;
      //   console.log(data);
      let memberList = data.payload.members.rows;
      // // let pp = response.data.result.offset;
      let total = data.payload.members.count;
      currentComponent.setState(
        {
          total,
          activePage: pageNumber,
          memberList
        },
        () => {
          currentComponent.props.membersListReady(true);
          // currentComponent.getRoles();
        }
      );
    }
  };

  // getRoles = async () => {
  //   let requestUrl = `/roles`;
  //   let currentComponent = this;

  //   let res = await axios.get(apiUrl + requestUrl, {
  //     headers: authHeader()
  //   });
  //   let { data } = res;
  //   if (data.error === null) {
  //     currentComponent._isRolesReady = true;
  //     this.setState({
  //       rolesList: data.payload.roles
  //     });
  //     //   console.log(data);
  //   }
  // };

  getMemberData = async id => {
    // let loader = document.getElementById("popup-loader");
    // loader.classList.add("show");
    let requestUrl = `/orders/orderMember/${id}/${this.props.orderId}`;
    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      
      this.setState(
        {
          member: {
            firstName: data.payload.member.firstname,
            lastName: data.payload.member.lastname,
            email: data.payload.member.email,
            memberId: data.payload.member.id,
            // roleId: data.payload.member.roleId
          }
        },
        () => {
          // document.getElementById(data.payload.member.roleId).checked = true;

          let loader = document.getElementById("popup-loader");
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };

  componentWillUnmount() {
    this._isRolesReady = false;
    this._isMounted = false;
  }

  handlePageChange(pageNumber) {
    let { searchValue, pp } = this.state;
    let start = (pageNumber - 1) * pp,
      end = pp * pageNumber;
    this.getMembersList(searchValue, start, end, pageNumber, false);
    window.scroll(0, 0);
  }

  handleSearchValue = childData => {

    this.setState({
      searchValue: childData
    });
    console.log(childData);
    this.getMembersList(childData, 0, this.state.pp, 1, false);
  };

  handleOpenModal(e) {
    var target = e.currentTarget;
    if (target.getAttribute("data-memberid")) {
      var memberId = target.getAttribute("data-memberid");
      this.setState(
        {
          showModal: true,
          isEdit: true,
          memberId
        },
        () => {
          this.getMemberData(memberId);
        }
      );
    } else {
      this.setState(
        {
          isEdit:false,
          showModal: true
        },
        () => {
          this.getAvailableMember();
        }
      );
    }
    this.setState({
      member: {
        firstName: "",
        lastName: "",
        email: "",
        memberId: "",
        roleId: ""
      }
    });
  }

  getAvailableMember = async e => {
    ///orders/freeMembers/00002_0000000845

    let requestUrl = `/orders/freeMembers/${this.props.orderId}`;

    let res = await axios.get(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      //   currentComponent._isRolesReady = true;
      this.setState(
        {
          availableMember: data.payload.members
        },
        () => {
          // document.getElementById(data.payload.roleId).checked = true;
          let projectPopup = document.getElementsByClassName("add-edit-member");
          if (!projectPopup[0].classList.contains("available-list")) {
            projectPopup[0].classList.add("available-list");
          }
          let loader = document.getElementById("popup-loader");
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
        }
      );
    }
  };

  handleCloseModal() {
    this.setState({
      showModal: false,
      isEdit: false,
      memberId: "",
      errors: [],
      thankYou: false,
      errorClasses: [],
      memberNotChoosedMessage:''
    });

    let projectPopup = document.getElementsByClassName("add-edit-member");
    if (projectPopup[0].classList.contains("available-list")) {
      projectPopup[0].classList.remove("available-list");
    }
  }

  handleValidation() {
    // let fields = this.state.member;
    let errors = {};
    let errorClasses = {};
    let formIsValid = true;
    const validationRules = [
      // {
      //   field: "roleId",
      //   method: validator.isEmpty,
      //   validWhen: false,
      //   message: "Role field is required."
      // }
    ];

    const validatorObj = new FormValidator(validationRules);
    const validation = validatorObj.validate(this.state.member);
    if (!validation.isValid) {
      formIsValid = false;
      for (var variable in validation) {
        if (validation.hasOwnProperty(variable) && variable !== "isValid") {
          if (validation[variable]["isInvalid"]) {
            errors[variable] = validation[variable]["message"];
            errorClasses[variable] = "is-invalid";
          }
        }
      }
    }
    this.setState({ errors: errors, errorClasses: errorClasses });
    return formIsValid;
  }

  handleChange(e) {
    const name = e.target.name;
    const member = this.state.member;
    member[name] = e.target.value;
    this.setState({
      member
    });
  }

  handelChecked(e) {
    const member = this.state.member;
    const name = e.target.name;
    member[name] = e.target.id;
    this.setState({
      member
    });
  }

  handelMemberChecked(e) {
    const member = this.state.member;
    const name = e.target.name;
    member[name] = e.target.id;
    this.setState({
      member
    });
  }
  handleEditMember = async e => {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    if (this.handleValidation()) {
      let loader = document.getElementById("popup-loader");

      loader.classList.add("show");
      let requestUrl = `/orders/updateMember/${this.state.memberId}/${this.props.orderId}`;
      let currentComponent = this;
      let member = this.state.member;
      let memberData = {
        roleId: member.roleId
      };
      let res = await axios.put(apiUrl + requestUrl, memberData, {
        headers: authHeader()
      });
      let { data } = res;
      if (data.error === null) {
        this.getMembersList(searchValue, start, end, 1, reset);

        this.setState(
          {
            thankYou: true,
            thankYouText: `User ${member.email} edited successfully.`
          },
          () => {
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
            setTimeout(function() {
              currentComponent.setState({
                member: {
                  firstName: "",
                  lastName: "",
                  email: "",
                  memberId: "",
                  roleId: ""
                },
                thankYou: false,
                thankYouText: "",
                showModal: false
              });
            }, 3000);
          }
        );
      }
      //
    }
  };

  removeMember = async e => {
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
    let loader = document.getElementById("popup-loader");

    loader.classList.add("show");
    let requestUrl = `/orders/deleteMember/${this.state.memberId}/${this.props.orderId}`;
    let currentComponent = this;
    let member = this.state.member;
    
    let res = await axios.delete(apiUrl + requestUrl, {
      headers: authHeader()
    });
    let { data } = res;
    if (data.error === null) {
      this.getMembersList(searchValue, start, end, 1, reset);

      this.setState(
        {
          thankYou: true,
          thankYouText: `User ${member.email} removed successfully.`
        },
        () => {
          if (loader.classList.contains("show")) {
            loader.classList.remove("show");
          }
          setTimeout(function() {
            currentComponent.setState({
              member: {
                firstName: "",
                lastName: "",
                email: "",
                memberId: "",
                roleId: ""
              },
              thankYou: false,
              thankYouText: "",
              showModal: false,
              isEdit: false
            });
          }, 3000);
        }
      );
      //
    }
  };

  handleAddMember = async e => {
    e.preventDefault();
    let searchValue = this.state.searchValue,
      start = "0",
      end = this.state.pp,
      reset = true;
      let member = this.state.member;

      if(member.memberId) {
        let loader = document.getElementById("popup-loader");

        loader.classList.add("show");
  
        let requestUrl = `/orders/addMember/${member.memberId}/${this.props.orderId}`;
        let currentComponent = this;
        let memberData = {
          roleId: member.roleId
        };
        let res = await axios.post(apiUrl + requestUrl, memberData, {
          headers: authHeader()
        });
        let { data } = res;
        if (data.error === null) {
          this.getMembersList(searchValue, start, end, 1, reset);
  
          this.setState(
            {
              memberNotChoosedMessage:'',
              thankYou: true,
              thankYouText: `Thank you user with ${member.email} email invited.`
            },
            () => {
              if (loader.classList.contains("show")) {
                loader.classList.remove("show");
              }
              let projectPopup = document.getElementsByClassName("add-edit-member");
              if (projectPopup[0].classList.contains("available-list")) {
                projectPopup[0].classList.remove("available-list");
              }
              setTimeout(function() {
                currentComponent.setState({
                  member: {
                    firstName: "",
                    lastName: "",
                    email: "",
                    memberId: "",
                    roleId: ""
                  },
                  thankYou: false,
                  thankYouText: "",
                  showModal: false,
                  isEdit: false
                });
              }, 3000);
            }
          );
        } else {
          if (data.error.code === 401) {
            let { errors, errorClasses } = this.state;
            errors["email"] = data.error.message;
            errorClasses["email"] = "is-invalid";
            this.setState({
              errors,
              errorClasses
            });
            if (loader.classList.contains("show")) {
              loader.classList.remove("show");
            }
          }
        }
      }else {
        this.setState({
          memberNotChoosedMessage:'Pleas select a member'
        })
      }
      //
  };

  renderThumb({ style, ...props }) {
    const thumbStyle = {
      right: 12,
      backgroundColor: `rgba(0, 0, 0, 0.2)`,
      cursor: `pointer`,
      borderRadius: `inherit`
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }
  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }
  render() {
    if (this._isRolesReady !== true) {
      return (
        <div id="loader" className="loader noBG show circle">
          <svg
            width="200px"
            height="200px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
            className="lds-rolling"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              ng-attr-stroke="{{config.color}}"
              ng-attr-stroke-width="{{config.width}}"
              ng-attr-r="{{config.radius}}"
              ng-attr-stroke-dasharray="{{config.dasharray}}"
              stroke="#B8BE14"
              strokeWidth="2"
              r="30"
              strokeDasharray="141.37166941154067 49.12388980384689"
              transform="rotate(159.346 50 50)"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="linear"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
                dur="1.4s"
                begin="0s"
                repeatCount="indefinite"
              ></animateTransform>
            </circle>
          </svg>
        </div>
      );
    }

    let {
      pp,
      total,
      activePage,
      showModal,
      isEdit,
      member,
      memberList,
      thankYou,
      thankYouText,
      availableMember,
      searchValue,
      memberNotChoosedMessage
    } = this.state;
    let showModalLoader = "";
    isEdit ? (showModalLoader = "show") : (showModalLoader = "show");

    let currentUser = JSON.parse(localStorage.getItem('user'))
    let userPermission = JSON.parse(localStorage.getItem('permissions'))
    let ADD_ORDER_MEMBER , UPDATE_ORDER_MEMBER,DELETE_ORDER_MEMBER;
    if(userPermission) {
      ADD_ORDER_MEMBER = userPermission.find(
        opt => opt.key === 'ADD_ORDER_MEMBER'
      )
      UPDATE_ORDER_MEMBER = userPermission.find(
        opt => opt.key === 'UPDATE_ORDER_MEMBER'
      )
      DELETE_ORDER_MEMBER = userPermission.find(
        opt => opt.key === 'DELETE_ORDER_MEMBER'
      )
    }
    let minHeight = 0
    if(availableMember.length > 0) {
      minHeight = 100
    }
    // console.log(this.state);
    return (
      <React.Fragment>
        <ReactModal
          isOpen={showModal}
          onRequestClose={this.handleCloseModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick={false}
          portalClassName="modal add-edit-member"
        >
          <React.Fragment>
            <div
              id="popup-loader"
              className={`loader ${showModalLoader} circle`}
            >
              <svg
                width="200px"
                height="200px"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                className="lds-rolling"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  ng-attr-stroke="{{config.color}}"
                  ng-attr-stroke-width="{{config.width}}"
                  ng-attr-r="{{config.radius}}"
                  ng-attr-stroke-dasharray="{{config.dasharray}}"
                  stroke="#B8BE14"
                  strokeWidth="2"
                  r="30"
                  strokeDasharray="141.37166941154067 49.12388980384689"
                  transform="rotate(159.346 50 50)"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="linear"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                    dur="1.4s"
                    begin="0s"
                    repeatCount="indefinite"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
            {thankYou ? (
              <React.Fragment>
                <div className="success-box">
                  <div className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="57"
                      height="57"
                      viewBox="0 0 57 57"
                    >
                      <g>
                        <path
                          fill="#7ed321"
                          d="M28.5,0A28.5,28.5,0,1,0,57,28.5,28.51,28.51,0,0,0,28.5,0ZM25.63,40,15,29.33l3.53-3.55,7.08,7.12L40.45,18,44,21.56Z"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className="text">{thankYouText}</div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="title">
                  {isEdit ? "Edit member" : "Assign member"}
                </div>
                <div className="form-box">
                  {isEdit ? (
                    <React.Fragment>
                      <div className="row row-2 clear">
                        <div className="item">
                          <div className="label">First Name</div>
                          <div className="editable-row">
                            <div className="title">
                              <span>{member.firstName}</span>
                              <svg viewBox="0 0 20 20" id="locked">
                                <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="label">Last Name</div>
                          <div className="editable-row">
                            <div className="title">
                              <span>{member.lastName}</span>
                              <svg viewBox="0 0 20 20" id="locked">
                                <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row clear">
                        <div className="label">Email</div>
                        <div className="editable-row">
                          <div className="title">
                            <span>{member.email}</span>
                            <svg viewBox="0 0 20 20" id="locked">
                              <path d="M15.8 8H14V5.6C14 2.703 12.665 1 10 1 7.334 1 6 2.703 6 5.6V8H4c-.553 0-1 .646-1 1.199V17c0 .549.428 1.139.951 1.307l1.197.387C5.672 18.861 6.55 19 7.1 19h5.8c.549 0 1.428-.139 1.951-.307l1.196-.387c.524-.167.953-.757.953-1.306V9.199C17 8.646 16.352 8 15.8 8zM12 8H8V5.199C8 3.754 8.797 3 10 3c1.203 0 2 .754 2 2.199V8z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Scrollbars
                        autoHeight
                        autoHeightMin={minHeight}
                        autoHeightMax={260}
                        renderThumbVertical={this.renderThumb}
                        onUpdate={this.handleUpdate}
                      >
                        {availableMember.length > 0 ? (
                          <div className="members-list">
                            {availableMember.map((item, key) => {
                              return (
                                <div className="item" key={key}>
                                  <input
                                    type="radio"
                                    name="memberId"
                                    id={item.id}
                                    onChange={this.handelMemberChecked}
                                  />
                                  <label
                                    htmlFor={item.id}
                                    className="custom-label"
                                  >
                                    <div className="name">
                                      {item.firstname + " " + item.lastname}
                                    </div>
                                    <div className="email">{item.roleName}</div>
                                    <div className="email">{item.email}</div>
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="no-result-box">
                            <div className="text">
                              You don't have available members
                            </div>
                          </div>
                        )}
                      </Scrollbars>
                    </React.Fragment>
                  )}
                </div>
                {isEdit ? 
                  currentUser.isMember === false || (currentUser.isMember === true && DELETE_ORDER_MEMBER !== undefined) ? (
                    <div className="row remove">
                      <div className="item">
                        <div className="btn remove" onClick={this.removeMember}>
                          Remove
                        </div>
                      </div>
                    </div>
                    ):('')
                 : (
                  ""
                )}
                <div className="button-row">
                  <span className="invalid-feedback">{memberNotChoosedMessage}</span>
                  {availableMember.length > 0 ? (
                    <div onClick={this.handleCloseModal}>Discard</div>
                  ):('')}
                  {isEdit ? (
                    <div onClick={this.handleEditMember}>Save changes</div>
                  ) : (
                    availableMember.length > 0 ? (
                      <div onClick={this.handleAddMember}>Assign</div>
                    ):(
                      <div onClick={this.handleCloseModal}>Close</div>
                    )
                  )}
                </div>
              </React.Fragment>
            
            )}
          </React.Fragment>
        </ReactModal>
        <div className="container-header">
          <MembersSearchView searchValue={this.handleSearchValue} />
          {currentUser.isMember === false || (currentUser.isMember === true && ADD_ORDER_MEMBER !== undefined) ? (
            <div
              className="add-member-btn add-btn"
              onClick={this.handleOpenModal}
            >
              Assign member
            </div>
          ):('')}
          
        </div>
        <div className="members-list-box table-grid">
          {memberList.length > 0 ? (
            <React.Fragment>
              <div className="table-row table-head">
                <div className="table-cell">
                  <div className="text">Name</div>
                </div>
                <div className="table-cell">
                  <div className="text">Email</div>
                </div>
                <div className="table-cell">
                  <div className="text">Roles</div>
                </div>
                <div className="table-cell">
                  <div className="text"> </div>
                </div>
              </div>
              {/* {memberList.langht > 0 ? ():()} */}
              {memberList.map((item, key) => {
                return (
                  <div className="table-row" key={key}>
                    <div className="table-cell">
                      <div className="text">
                        <div>Name:</div>
                        <div>{item.firstname} {item.lastname}</div>
                      </div>
                    </div>
                    <div className="table-cell">
                      <div className="text">
                        <div>Email:</div>
                        <div>{item.email}</div>
                      </div>
                    </div>
                    <div className="table-cell">
                      <div className="text">
                        <div>Roles:</div>
                        <div>{item.roleName}</div>
                      </div>
                    </div>
                    <div className="table-cell">
                    {currentUser.isMember === false || (currentUser.isMember === true && UPDATE_ORDER_MEMBER !== undefined) ? (
                      <div className="btn">
                        <span
                          data-memberid={item.id}
                          onClick={this.handleOpenModal}
                        >
                          Edit
                        </span>
                      </div>
                    ):('')}
                      
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          ) : (
            <div className="no-result-box">
              <div className="text">
                {searchValue
                  ? `No result for: ${searchValue}`
                  : `You don’t have any members in this order. Please click Assign Member button to assign`}
              </div>
            </div>
          )}
        </div>

        {pp < total ? (
          <Pagination
            innerClass="pagination clear"
            hideFirstLastPages
            pageRangeDisplayed={5}
            activePage={activePage}
            itemsCountPerPage={pp}
            totalItemsCount={total}
            onChange={this.handlePageChange}
            nextPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M3.78,369.84a13,13,0,0,0,0,18.36l22.67,22.67a13,13,0,0,0,18.38,0L239.2,216.53a13,13,0,0,0,0-18.38L44.86,3.8a13,13,0,0,0-18.38,0L3.81,26.47a13,13,0,0,0,0,18.36L165.53,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
            prevPageText={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 243.01 414.68"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path
                      className="cls-1"
                      d="M239.23,44.84a13,13,0,0,0,0-18.36L216.54,3.81a13,13,0,0,0-18.38,0L3.81,198.15a13,13,0,0,0,0,18.38L198.15,410.88a13,13,0,0,0,18.38,0l22.67-22.67a13,13,0,0,0,0-18.36L77.48,207.34Z"
                    />
                  </g>
                </g>
              </svg>
            }
          />
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default MembersListView;
